<template>
    <button class="confirm__button" @click="closeConfirmation">Продолжить</button>
</template>

<script>
    export default {
        methods: {
            closeConfirmation() {
                this.$emit('close-confirm');
            }
        }
    }
</script>

<style scoped lang="scss">
    .confirm {

         &__button {
          background: #A1ABB5;
          border: solid 1px #d0d0d0;
          border-bottom: solid 3px #b2b1b1;
          border-radius: 3px;
          color: #000;
          display: inline-block;
          font: bold 12px Arial, Helvetica, Clean, sans-serif;
          padding: 12px 68px;
          position: relative;
          text-align: center;
          text-decoration: none;
          font-size: 10px;
          left: 235px;
          top: 142px;


          &:before {
           border-radius: 3px;
           box-shadow: #808080 0 5px 10px;
           content: "";
           display: block;
           height: 100%;
           left: 0;
           padding: 2px 0 0;
           position: absolute;
           top: 0;
           width: 100%;
          }

          &:active:before {
           padding: 1px 0 0;
          }
          &:hover {
           background: #A9B4BE;
          }
          &:active {
           background: #969FA8;
           border: solid 1px #d0d0d0;
          }
         }
       }
</style>