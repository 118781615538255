import { assignInstanceConstructor } from '@/sequences/abc_sequence_element'
import { SequenceStepDefinition, SequenceStepInstance } from '@/sequences/SequenceStep/base_classes'

class SequenceStepWithInternalStateInstance extends SequenceStepInstance {
    constructor(...args) {
        super(...args)

        this.state = this.definition.state()
    }

    processChunkActionExecution() {}
}

export class SequenceStepWithInternalStateDefinition extends assignInstanceConstructor(SequenceStepWithInternalStateInstance, SequenceStepDefinition) {
    constructor({state, ...restArgs}) {
        super(restArgs)

        this.state = state
    }
}
