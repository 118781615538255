export default {
    namespaced: true,
    state: {
        buttonsState: {
            fillButtonBActive: false,
            fillButtonAActive: false,
            autoLoadButtonActive: false,
            primeButtonActive: false,
            moveButtonActive: false,
            manualLoadButtonsActiveA: false,
            manualLoadButtonsActiveB: false,
            checkAirButtonActive: false,
            armButtonActive: false,
            startInjectionButton: false,
        },
    },
    getters: {
        getStatusFillButtonBActive(state) {
            return state.buttonsState.fillButtonBActive
        },
        getStatusFillButtonAActive(state) {
            return state.buttonsState.fillButtonAActive
        },
        getStatusAutoLoadButtonActive(state) {
            return state.buttonsState.autoLoadButtonActive
        },
        getStatusPrimeButtonActive(state) {
            return state.buttonsState.primeButtonActive
        },
        getStatusMoveButtonActive(state) {
            return state.buttonsState.moveButtonActive
        },
        getStatusManualLoadButtonsActiveA(state) {
            return state.buttonsState.manualLoadButtonsActiveA
        },
        getStatusManualLoadButtonsActiveB(state) {
            return state.buttonsState.manualLoadButtonsActiveB
        },
        getStatusCheckAirButtonActive(state) {
            return state.buttonsState.checkAirButtonActive
        },
        getStatusArmButtonActive(state) {
            return state.buttonsState.armButtonActive
        },
        getStartInjectionButton(state) {
            return state.buttonsState.startInjectionButton
        },
    },
    mutations: {
        SET_BUTTON_STATE(state, payload) {
            state.buttonsState = {
                ...state.buttonsState,
                ...payload,
            }
        }
    },
    actions: {
        setButtonState({commit}, payload) {
            commit('SET_BUTTON_STATE', payload);
        },
    },
}