<template>
    <div class="container">
        <div class="liquid-modal">
            <div class="liquid-modal__head">
                <h2>Жидкость А</h2>
                <h3>Подтвердить выбор</h3>
            </div>
            <div class="liquid-modal__main main">
                <div class="main__headers">
                    <ul>
                        <li>Источник</li>
                        <li>Партия</li>
                        <li>Истечение срока годности</li>
                    </ul>
                </div>
                <div class="main__information">
                    <ul>
                        <li>--</li>
                        <li>--</li>
                        <li>--</li>
                    </ul>
                </div>
                <div class="main__buttons buttons">
                    <button class="buttons__confirm" @click="closeModal">ОК</button>
                    <button class="buttons__cancel" @click="closeModal">Отменить</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            closeModal() {
                this.$emit('close-modal');
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../scss/variable";
    .container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;


        .liquid-modal {
            position: absolute;
            border: 3px solid black;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding: 5px;
            z-index: 15;
            box-shadow: 5px 5px 5px black;
            width: 60%;
            background: #C3CEC5;


            &__head {

                border: 1px solid black;
                border-radius: 5px;
                overflow: hidden;

                h2 {
                    font-size: 24px;
                    font-weight: 700;
                    background: #E0EDE2;
                    padding: 5px 30px;
                    margin-bottom: 1px;
                    border-bottom: 1px solid black;

                }

                h3 {
                    font-size: 14px;
                    font-weight: 700;
                    padding: 5px 7px;
                }
            }

            &__main {
                display: flex;
                margin-top: 10px;
                gap: 20px;
                border: 2px solid black;
                border-radius: 5px;
                padding: 10px 5px;
                background-color: #99CAED;
            }
            .main {
                ul {
                    li {
                        margin-bottom: 10px;
                        background-color: #fff;
                        padding: 6px 3px;
                        font-size: 12px;


                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                &__headers {
                    flex: 0 0 30%;
                }

                &__information {
                    flex: 0 0 45%;

                    ul {
                        li {
                            font-weight: 700;
                        }
                    }
                }

                &__buttons {
                    display: flex;
                    flex-direction: column;
                }

                .buttons {
                    &__confirm {
                        background-color: $yellow;
                        padding: 10px;
                        margin-top: 3px;
                        margin-bottom: 10px;
                        font-weight: 700;
                        box-shadow: inset 0px 2px #fff,
                                  2px 0px #fff inset,
                                  0px -3px #D5D580 inset,
                                  -3px 0px #D5D580 inset;
                    }

                    &__cancel {
                        padding: 10px;
                        font-weight: 700;
                        background-color: #E0EDE2;
                        box-shadow: inset 0px 2px #fff,
                                  2px 0px #fff inset,
                                  0px -3px #ADB7AF inset,
                                  -3px 0px #ADB7AF inset;
                    }
                }
            }

        }
    }
</style>