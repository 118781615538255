<template>
  <button class="info-button" data-enable-pointer-events="true" style="width: 100%;height: 40px;text-align: center;" @click="openModal">
    <div class="button-content">
      <img src="@/img/components/infoButton.svg" alt="" style="height: 20px;">
      <div class="button-text">
        Расшифровка терминов<br>
        и сокращений
      </div>
    </div>
  </button>
</template>

<script>
export default {
  methods: {
    openModal() {
      this.$emit('open-confirm');
    }
  }
}
</script>

<style scoped lang="scss">
.info-button {
  //z-index: 500;
  //background: #FFFFFF ;
  //border: solid 1px #CE6C2B;
  //color: #CE6C2B;
  //opacity: 0.5;
  position: fixed;
  bottom: 6%;
  right: 2%;
  z-index: 6;
  background: #FFFFFF ;
  opacity: 0.5;
  border: solid 1px #CE6C2B;
  font-size: 13px;
  border-radius: 3px;
  color: #CE6C2B;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: pre-line;
}
.info-button:hover {
  background: #FFFFFF ;
  opacity: 0.8;
}
.button-content {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 5%;
    img{
      margin-right: 5%;
    }
  .button-text {
    text-align: left;
    font-family: "Open Sans", sans-serif;
  }
}

//.info {
//
//  &__button {
//    z-index: 6;
//    background: #FFFFFF ;
//    opacity: 0.5;
//    border: solid 1px #CE6C2B;
//    font-size: 13px;
//    border-radius: 3px;
//    color: #CE6C2B;
//    display: inline-block;
//    text-align: center;
//    text-decoration: none;
//
//
//    white-space: pre-line;
//
//    &:hover {
//      background: #FFFFFF ;
//      opacity: 0.8;
//    }
//
//  }
//}
</style>