<template>
    <div>
        <div class="container">
            <div class="computer">
                <div v-if="!workstation_is_on" key="off" class="screen-black"></div>
                <div v-else class="screen" key="enabled">
                    <div class="header system-settings-header">
                        <img src="../img/components/settings_icons/settings.svg">
                        <span>Настройка системы</span>
                    </div>
                    <div v-if="showFirstPage" class="screen-settings">
                        <div class="screen-settings-column first-column">
                            <!-- левое меню настроек первая страница -->
                            <template v-for="(value, name) in settings_left">
                                <v-tooltip
                                        content-class="education-mode-system-settings-tooltip"
                                        attach=".container"
                                        absolute
                                        :disabled="!isEducationModeEnabled"
                                >
                                    <template #activator="{on}">
                                        <left-button v-on="on"
                                                     :data-enable-pointer-events="true"
                                                     :name="name"
                                                     :value="value"
                                                     :isSelected="selectedButton === name"
                                                     @select-button="selectButton(name)"
                                        />
                                    </template>
                                    <template v-if="isEducationModeEnabled" #default>{{getTooltipTextForButton(name)}}
                                    </template>
                                </v-tooltip>
                            </template>
                        </div>
                        <!-- если выбрано из правого меню настройка Дата/время -->
                        <div class="screen-settings-column date-time" v-if="selectedButton === 'Дата/время'">
                            <div class="date-row">
                                <date-time-item v-for="(number, period) in date"
                                                :value="number"
                                                :period="period"
                                                :isSelected="period === selectedPeriod"
                                                @select-period="selectPeriod(period)"
                                                ></date-time-item>
                            </div>
                            <div class="date-row">
                                <date-time-item v-for="(number, period) in time"
                                                :value="number"
                                                :period="period"
                                                 @select-period="selectPeriod(period)"
                                                :isSelected="period === selectedPeriod"
                                ></date-time-item>
                            </div>
                            <div class="date-row button-row">
                                <chevron-button
                                    @change-page="increaseTime"
                                    page="2"
                                />
                                <chevron-button
                                    @change-page="decreaseTime"
                                    page="1"
                                />
                            </div>
                            <div class="system-btn">
                                <system-button @set-date="setDate"></system-button>
                            </div>
                        </div>
                        <!-- если выбрано из правого меню настройка Напоминание о калибровке -->
                        <div class="screen-settings-column date-time" v-else-if="selectedButton === 'Напоминание о калибровке'">
                            <div class="date-row">
                                <date-time-item v-for="(number, period) in reminder"
                                                    :value="number"
                                                    :period="period"
                                                     @select-period="selectPeriod(period)"
                                                    :isSelected="period === selectedPeriod"
                                ></date-time-item>
                            </div>
                            <div class="date-row button-row">
                                <chevron-button
                                    @change-page="decreaseReminder"
                                    page="1"
                                />
                                <chevron-button
                                    @change-page="increaseReminder"
                                    page="2"
                                />
                            </div>
                            <div class="system-btn">
                                <system-button @set-date="setTimeReminder"></system-button>
                            </div>
                        </div>
                         <!-- все остальное из левого и правого меню (не 2 страница) -->
                        <div v-else class="screen-settings-column second-column">
                            <!-- первые 2 option-button показываются только в уникальных случах (выбран Язык или ISI настройка) и при этом нужно показать вторую страницу опций -->
                            <option-button  v-show="selectedButton === 'Язык' && !showFirstOptions"
                                            v-for="value in additional_language"
                                            :value="value"
                                            @select-option="selectOption(value)"/>
                            <option-button  v-show="selectedButton === 'ISI' && !showFirstOptions"
                                            v-for="value in additional_ISI"
                                            :value="value"
                                            @select-option="selectOption(value)"/>
                            <option-button  v-show="showFirstOptions"
                                            v-for="value in options"
                                            :value="value"
                                            @select-option="selectOption(value)"
                                            />
                            <chevron-button class="arrow-language" v-if="selectedButton === 'Язык' || selectedButton === 'ISI'"
                                                                    @change-page="$store.dispatch('station/SystemSettings/changeFirstOptionsVisibility', !showFirstOptions)"
                                                                    :page="pageProps"
                            />
                        </div>
                        <!-- правое меню настроек на первой странице -->
                        <div class="screen-settings-column third-column">
                            <template v-for="(value, name) in settings_right">
                                <v-tooltip
                                        content-class="education-mode-system-settings-tooltip"
                                        attach=".container"
                                        absolute
                                        :disabled="!isEducationModeEnabled"
                                >
                                    <template #activator="{on}">
                                        <right-button :data-enable-pointer-events="true"
                                                      v-on="on"
                                                      :name="name"
                                                      :value="value"
                                                      :isSelected="selectedButton === name"
                                                      @select-button="selectButton(name)"/>
                                    </template>
                                    <template v-if="isEducationModeEnabled" #default>{{getTooltipTextForButton(name)}}
                                    </template>
                                </v-tooltip>
                            </template>
                        </div>
                        <!-- просто кнопки (отмена, ок, по умолчанию) -->
                        <div class="screen-settings-column fourth-column">
                            <button class="btn screen-settings-btn" @pointerdown="setDefaultSettings"><span>По умолчанию</span></button>
                            <button class="btn screen-settings-btn confirm-btn" @pointerdown="$store.dispatch('station/SystemSettings/changeDone', true)">
                                <SequenceChunkWrapper
                                        v-slot="{processChunkAction: processChunkAction_setup_system_press_Ok_button_quiet}"
                                        sequence-id="setup_system"
                                        step-id="press_Ok_button_or_Cancel_button_to_close_system_setup"
                                        chunk-id="press_Ok_button_quiet"
                                        key="press_Ok_button_or_Cancel_button_to_close_system_setup"
                                >
                                    <a @click="processChunkAction_setup_system_press_Ok_button_quiet(() => $router.push({name: 'WorkStation'}), {enabledIfEducationIsInactive: true})">ОК</a>
                                </SequenceChunkWrapper>
                            </button>
                            <SequenceChunkWrapper
                                    v-slot="{processChunkAction: processChunkAction_setup_system_press_Cancel_button_quiet}"
                                    sequence-id="setup_system"
                                    step-id="press_Ok_button_or_Cancel_button_to_close_system_setup"
                                    chunk-id="press_Cancel_button_quiet"
                                    key="press_Ok_button_or_Cancel_button_to_close_system_setup"
                            >
                                <button class="btn screen-settings-btn"
                                        @pointerdown="processChunkAction_setup_system_press_Cancel_button_quiet(() => cancelSettings(),{enabledIfEducationIsInactive: true})">
                                    <a>Отмена</a></button>
                            </SequenceChunkWrapper>
                        </div>
                    </div>
                     <!-- вторая страница -->
                    <div v-else class="screen-settings">
                        <!-- левое меню настроек -->
                        <div class="screen-settings-column first-column">
                            <template v-for="(value, name) in settings_second_page">
                                <v-tooltip
                                        content-class="education-mode-system-settings-tooltip"
                                        attach=".container"
                                        absolute
                                        :disabled="!isEducationModeEnabled"
                                >
                                    <template #activator="{on}">
                                        <left-button
                                                :data-enable-pointer-events="true"
                                                v-on="on"
                                                :name="name"
                                                :value="value"
                                                :isSelected="selectedButton === name"
                                                @select-button="selectButton(name)"/>
                                    </template>
                                    <template v-if="isEducationModeEnabled" #default>{{getTooltipTextForButton(name)}}
                                    </template>
                                </v-tooltip>
                            </template>
                        </div>
                        <div class="screen-settings-column second-column">
                            <!-- для всех опций на второй странице кроме "Яркость дисплея" -->
                            <option-button  v-show="showFirstOptions"
                                            v-for="value in options"
                                            :value="value"
                                            @select-option="selectOption(value)"
                                            />
                            <!-- рэнж для опции "Яркость дисплея" -->
                            <div class="range-column" v-if="selectedButton === 'Яркость дисплея'">
                                <div class="range-scale">
                                    <span>10</span>
                                    <span>0</span>
                                    <span>-10</span>
                                </div>
                                <div class="range-control">
                                    <chevron-button @change-page="increaseRange"/>
                                    <range @on-range="onRange($event)"
                                            :value="rangeValue"
                                    />
                                    <chevron-button @change-page="decreaseRange"/>
                                </div>
                                <div class="range-scale">
                                    <span>10</span>
                                    <span>0</span>
                                    <span>-10</span>
                                </div>
                            </div>
                        </div>
                        <div class="screen-settings-column third-column"></div>
                        <div class="screen-settings-column fourth-column">
                            <button class="btn screen-settings-btn" @pointerdown="setDefaultSettings"><span>По умолчанию</span></button>
                            <button class="btn screen-settings-btn confirm-btn" @pointerdown="$store.dispatch('station/SystemSettings/changeDone', true)">
                                <SequenceChunkWrapper
                                    v-slot="{processChunkAction: processChunkActionSetupSystemPressOkButtonQuiet}"
                                    sequence-id="setup_system"
                                    step-id="press_Ok_button_or_Cancel_button_to_close_system_setup"
                                    chunk-id="press_Ok_button_quiet"
                                    key="press_Ok_button_or_Cancel_button_to_close_system_setup"
                            >
                                    <a @click="processChunkActionSetupSystemPressOkButtonQuiet(() => $router.push({name: 'WorkStation'}), {enabledIfEducationIsInactive: true})">ОК</a>
                                </SequenceChunkWrapper>
                            </button>
                            <SequenceChunkWrapper
                                    v-slot="{processChunkAction: processChunkActionSetupSystemPressCancelButtonQuiet}"
                                    sequence-id="setup_system"
                                    step-id="press_Ok_button_or_Cancel_button_to_close_system_setup"
                                    chunk-id="press_Cancel_button_quiet"
                                    key="press_Ok_button_or_Cancel_button_to_close_system_setup"
                            >
                                <button class="btn screen-settings-btn" @pointerdown="processChunkActionSetupSystemPressCancelButtonQuiet(() => cancelSettings(), {enabledIfEducationIsInactive: true})"><a>Отмена</a></button>
                            </SequenceChunkWrapper>
                        </div>
                    </div>
                    <div class="footer-btn">
                        <button :data-enable-pointer-events="true" class="btn show-more" @pointerdown="goToPage"><i :class="page"></i></button>
                    </div>
                </div>
                <modal-cancel v-if="done && isChanged"
                              :text="modalText"
                              :link="true"
                              @cancel="cancelSettings"
                              @confirm="handleConfirm"

                    />
                <div class="stand">
                    <comp-lamp class="light" :class="isLightOn"/>
                    <input type="checkbox" class="stand__power-button" @input="switchOnComp(true)">
                </div>
            </div>
        </div>
        <router-modal/>
      <InfoButton style="width: 13.5%; min-width: 259px" @open-confirm="openModalTerm"/>
      <TermDefinition v-if="modalEx" style="z-index: 99999" @close-confirm="closeModalTerm"/>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    import LeftButton from '@/components/settingsbuttons/LeftButton';
    import RightButton from '@/components/settingsbuttons/RightButton'
    import OptionButton from '@/components/settingsbuttons/OptionButton'
    import ChevronButton from '@/components/ChevronBtn'
    import SystemButton from '@/components/SystemBtn'
    import DateTime from '@/components/DateTime'
    import DateTimeItem from '@/components/DateTimeItem'
    import Range from '@/components/Range';
    import ModalCancel from '@/components/ResetProtocolModal'
    import CompLamp from '@/components/AppLamp'
    import RouterModal from '@/components/modals/RouterModal'
    import TermDefinition from "@/components/TermDefinition.vue";
    import InfoButton from "@/components/InfoButton.vue";

    export default {
        name: "SystemSettings",
        methods: {
            getTooltipTextForButton(name) {
                 return this.getActiveSequenceId === 'setup_system' ? this.texts_for_settings_tooltips[name] : '';
            },
            handleConfirm() {
                this.$store.dispatch('station/SystemSettings/changeIsChanged', false);
                this.$store.dispatch('station/SystemSettings/changeDone', false);

            },
            selectButton(name) {
                this.$store.dispatch('station/SystemSettings/setSelectedButton', name);
                this.$store.dispatch('station/SystemSettings/changeFirstOptionsVisibility', true);
                this.selectedPeriod = '';
            },
            selectOption(option) {
                this.$store.dispatch('settings/setNewValue', {
                    name: this.selectedButton,
                    value: option,
                })
                this.$store.dispatch('station/SystemSettings/setSelectedButton', '');
                this.$store.dispatch('station/SystemSettings/changeFirstOptionsVisibility', true);
                this.$store.dispatch('station/SystemSettings/changeIsChanged', true);
            },
            selectPeriod(period) {
                this.selectedPeriod = period;
            },
            goToPage() {
                this.$store.dispatch('station/SystemSettings/showFirstPage', !this.showFirstPage);
                this.$store.dispatch('station/SystemSettings/setSelectedButton', '');
            },
            increaseTime() {
                if (this.time.hasOwnProperty(this.selectedPeriod)) {
                    if (this.selectedPeriod === 'hh' && this.time[this.selectedPeriod] < 23) {
                        this.time[this.selectedPeriod]++;
                    } else if (this.selectedPeriod === 'mm' && this.time[this.selectedPeriod] < 59) {
                        this.time[this.selectedPeriod]++;
                    }

                } else if (this.date.hasOwnProperty(this.selectedPeriod)) {
                    let days = new Date(this.date['Год'], this.date['Месяц'], 0).getDate();
                     if (this.selectedPeriod === 'День' && this.date[this.selectedPeriod] < days) {
                        this.date[this.selectedPeriod]++;
                    } else if (this.selectedPeriod === 'Месяц' && this.date[this.selectedPeriod] < 12) {
                        this.date[this.selectedPeriod]++;
                    } else if (this.selectedPeriod === 'Год') {
                         this.date[this.selectedPeriod]++;
                    }
                }
            },
            decreaseTime() {
                 if (this.time.hasOwnProperty(this.selectedPeriod)) {
                    if (this.time[this.selectedPeriod] > 1) {
                        this.time[this.selectedPeriod]--;
                    }

                } else if (this.date.hasOwnProperty(this.selectedPeriod)) {
                    if (this.date[this.selectedPeriod] > 1) {
                        this.date[this.selectedPeriod]--;
                    }
                }
            },
            setDate() {
                let {День: day, Месяц: month, Год: year} = this.date;
                let {hh, mm} = this.time;
                if (day < 10) {
                    day = '0' + day;
                }
                if (month < 10) {
                    month = '0' + month;
                }
                if (hh < 10) {
                    hh = '0' + hh;
                }
                if (mm < 10) {
                    mm = '0' + mm;
                }

                let setted_date = `${day}/${month}/${year} ${hh}:${mm}`;
                this.$store.dispatch('settings/setNewValue', {
                    name: this.selectedButton,
                    value: setted_date,
                })
                this.$store.dispatch('station/SystemSettings/setSelectedButton', '');
                this.selectedPeriod = '';
                this.$store.dispatch('station/SystemSettings/changeIsChanged', true);
            },
            increaseReminder() {
                if (this.selectedPeriod === 'Месяц' && this.reminder[this.selectedPeriod] < 12) {
                    this.reminder[this.selectedPeriod]++;
                } else if (this.selectedPeriod === 'Год') {
                    this.reminder[this.selectedPeriod]++;
                }
            },
            decreaseReminder() {
                if (this.reminder[this.selectedPeriod] > 1) {
                    this.reminder[this.selectedPeriod]--;
                }
            },
            setTimeReminder() {
                let month = this.reminder['Месяц'];
                let year = this.reminder['Год'];

                if (month < 10) {
                    month = '0' + month;
                }
                let setted_date = `${month}/${year}`
                 this.$store.dispatch('settings/setNewValue', {
                    name: this.selectedButton,
                    value: setted_date,
                })
                this.$store.dispatch('station/SystemSettings/setSelectedButton', '');
                this.selectedPeriod = '';
                this.$store.dispatch('station/SystemSettings/changeIsChanged', true);
            },
            setDefaultSettings() {
                this.$store.dispatch('settings/defaultSettings');
            },
            cancelSettings() {
                this.$store.dispatch('settings/cancelSettings', {
                    left: this.cashed_settings_left,
                    right: this.cashed_settings_right,
                    second_page: this.cashed_settings_second_page,
                });
                this.$store.dispatch('station/SystemSettings/changeIsChanged', false);
                this.$store.dispatch('station/SystemSettings/changeDone', false)
                this.$router.push({name: 'WorkStation'})
            },
            onRange(value) {
                this.$store.dispatch('settings/setNewValue', {
                    name: this.selectedButton,
                    value: value,
                })
                this.$store.dispatch('station/SystemSettings/changeRangeValue', value);
            },
            increaseRange() {
                if (this.rangeValue < 10) {
                    this.$store.dispatch('station/SystemSettings/changeRangeValue', this.rangeValue++);
                    this.$store.dispatch('station/SystemSettings/changeIsChanged', true);
                }

            },
            decreaseRange() {
                if (this.rangeValue > -10) {
                    this.$store.dispatch('station/SystemSettings/changeRangeValue', this.rangeValue--);
                    this.$store.dispatch('station/SystemSettings/changeIsChanged', true);
                }
            },
            switchOnComp() {
                if (!this.workstation_is_on) {
                    this.$router.push({name: 'WorkStation'});
                    this.$store.commit('switchStateOfWorkstation', {
                        state: true,
                        confirm: true,
                    });
                }
            },
            openModalTerm() {
              this.modalEx = true;
            },
            closeModalTerm() {
              this.modalEx = false;
             },


        },
        computed: {
            ...mapGetters('settings', [
                'settings_left',
                'settings_right',
                'additional_language',
                'additional_ISI',
                'settings_second_page',
                'texts_for_settings_tooltips'
            ]),
            ...mapGetters(['workstation_is_on']),
            ...mapGetters('sequences_store', ['getActiveSequenceId']),
            ...mapGetters('station/SystemSettings', [
                'showFirstOptions',
                'showFirstPage',
                'isChanged',
                'done',
                'rangeValue',
                'selectedButton'
            ]),
            page() {
                if (this.showFirstPage) {
                    return {
                        'fas fa-chevron-right': true,
                    }
                } else {
                     return {
                        'fas fa-chevron-left': true,
                    }
                }
            },
            options() {
                return this.$store.getters[`settings/${this.system_options[this.selectedButton]}`];
            },
            pageProps() {
                if (this.showFirstOptions) {
                    return 1;
                } else {
                    return 2;
                }
            },
            isLightOn() {
                return {
                    on: this.workstation_is_on,
                }
            },
        },
        data() {
            return {
                modalEx:false,
                selectedPeriod: '',
                system_options: {
                    'Язык': 'language',
                    'Уровень звукового сигнала дисплея': 'volume_level',
                    'Напоминание о калибровке': 'reminder',
                    'Единицы давления': 'pressure',
                    'Единицы веса': 'weight',
                    'Дата/время': 'date_time',
                    'Формат даты': 'date',
                    'Формат времени': 'time',
                    'Звуковое напоминание': 'volume_reminder',
                    'ISI': 'ISI',
                    'XDS': 'XDS',
                    'Режим дисплея отключения инъектора': 'display_off',

                },
                date: {
                    День: 2,
                    Месяц: 9,
                    Год: 2021,
                },
                time: {
                    hh: 12,
                    mm: 13,
                },
                reminder: {
                    Месяц: 1,
                    Год: 2023,
                },
                modalText: 'Параметры конфигурации были изменены. Сохранить?',
                cashed_settings_left: {},
                cashed_settings_right: {},
                cashed_settings_second_page: {},

            }
        },
        components: {
          InfoButton, TermDefinition,
            LeftButton,
            RightButton,
            OptionButton,
            ChevronButton,
            SystemButton,
            DateTime,
            DateTimeItem,
            ModalCancel,
            Range,
            CompLamp,
            RouterModal,
        },
        created() {
            this.cashed_settings_left = Object.assign({}, this.settings_left);
            this.cashed_settings_right = Object.assign({}, this.settings_right);
            this.cashed_settings_second_page = Object.assign({}, this.settings_second_page);
        },
        beforeDestroy() {
            this.$store.dispatch('station/SystemSettings/setDefaultModule');
        },
    }
</script>

<style scoped lang="scss">

    @import "../scss/style";
    @import "../scss/pages/system-settings";


</style>