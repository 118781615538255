<template>
    <div class="add-fluids">
        <button class="add-fluids__button">Добавить</button>
        <div class="add-fluids__values values">
            <p class="values__a">{{totalA - fluids.a}} ml</p>
            <p class="values__b">{{totalB - fluids.b}} ml</p>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex';

    export default {
        computed: {
            ...mapGetters('injector', {
                fluids: 'fluids'
            }),
            ...mapGetters([
                'totalA',
                'totalB',
            ]),
        }
    }
</script>

<style scoped lang="scss">

    @import "../scss/mixin";

    .add-fluids {
        width: 90%;
        margin: 0 auto 10px;
        @include border;

        &__button {
            padding: 10px;
            color: #fff;
            font-weight: 700;
            background: rgba(255, 255, 255, 0.1);
        }

        &__values {
            display: flex;
            padding: 10px;
            color: #fff;
            font-size: 11px;
            justify-content: space-between;
        }

        .values {

            &__a {
                padding-left: 15px;
            }

            &__a, &__b {
                position: relative;
            }

            &__a:before {
                position: absolute;
                content: '';
                top: 0px;
                left: 0px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #5EEA8C;
            }

            &__b:before {
                position: absolute;
                content: '';
                top: 0px;
                left: -15px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #369AFF;
            }

        }
    }
</style>