<template>
    <div class="control">
        <control-btn v-show="getTestA" @action="contrast" class="green border" :value="buttons.testA"/>
        <SequenceChunkWrapper
                v-slot="{processChunkAction: test_introduction_B}"
                sequence-id="protocol_creation"
                step-id="test_introduction_B"
                key="test_introduction_B"
        >
            <control-btn v-show="getTestB" @action=" () => {
                const fn = () => {
                    trial();
                    $store.commit('stateControl', false);
                    $store.dispatch('showRow', false);
                    $store.dispatch('clearHighlightedRow')
                };
                test_introduction_B(fn, {enabledIfEducationIsInactive: true})
                }"
                         class="blue border" :value="buttons.testB"/>
        </SequenceChunkWrapper>

        <control-btn v-show='getContrastShow' class="green border" @action="contrast"
                     :value="buttons.contrast"/>

        <SequenceChunkWrapper
                v-slot="{processChunkAction}"
                sequence-id="protocol_creation"
                step-id="choose_type_phase_Flushing_B"
                key="choose_type_phase_Flushing_B"
        >
            <control-btn v-show="getSalineShow" class="blue border" @action=" () => {
                const fn = () => {
                    saline();
                    $store.commit('stateControl', false);
                    $store.dispatch('showRow', false);
                    $store.dispatch('clearHighlightedRow')
                };
                processChunkAction(fn, {enabledIfEducationIsInactive: true})
            }"
                         :value="buttons.flushing"/>
        </SequenceChunkWrapper>
        <SequenceChunkWrapper
                v-slot="{processChunkAction}"
                sequence-id="protocol_creation"
                step-id="choose_type_of_phase_AB"
                key="choose_type_of_phase_AB"
        >
            <control-btn v-show="getDualShow" class="gradient border" @action=" () => {
                const fn = () => {
                    dualflow();
                    $store.commit('stateControl', false);
                    $store.dispatch('showRow', false);
                    $store.dispatch('clearHighlightedRow')
                };
                processChunkAction(fn, {enabledIfEducationIsInactive: true})}"
                         :value="buttons.percentage"/>
        </SequenceChunkWrapper>
        <control-btn v-show="getShowAwaiting" @action="awaiting" class="dark-gray border"
                     :value="buttons.awaiting"/>
        <SequenceChunkWrapper
                v-slot="{processChunkAction}"
                sequence-id="protocol_creation"
                step-id="select_phase_type_Pause"
        >
            <control-btn v-show="getShowPause" @action=" () => {
                const fn = () => {
                    pause();
                    $store.commit('stateControl', false);
                    $store.dispatch('showRow', false);
                    $store.dispatch('clearHighlightedRow')
                };
                processChunkAction(fn, {enabledIfEducationIsInactive: true})
            }"
                         class="dark-gray border"
                         :value="buttons.pause"/>
        </SequenceChunkWrapper>
        <SequenceChunkWrapper
                v-slot="{processChunkAction}"
                sequence-id="protocol_creation"
                step-id="choose_Delete"
                key="choose_Delete"
        >
            <control-btn
                    v-show="getShowRemove"
                    key="fasdf"
                    @action="() => {
                        const fn = () => {
                            remove();
                            $store.commit('stateControl', false);
                            $store.dispatch('showRow', false);
                            $store.dispatch('clearHighlightedRow')
                        };
                        processChunkAction(fn, {enabledIfEducationIsInactive: true})
                    }"
                    class="gray border"
                    :value="buttons.remove"
        />
        </SequenceChunkWrapper>
        <control-btn class="gray border" :value="buttons.cancel"/>
    </div>
</template>

<script>

    import {mapState, mapGetters} from 'vuex';

    import ControlBtn from '@/components/controlpanel/ControlButton'

    export default {
        components: {
            ControlBtn,
        },

        data() {
            return {
                buttons: {
                    testA: 'Тестовое введение A',
                    testB: 'Тестовое введение',
                    contrast: 'Контрастное в-во А',
                    flushing: 'Промывка В',
                    percentage: 'A% B%',
                    pause: 'Пауза',
                    remove: 'Удалить',
                    cancel: 'Отменить',
                    awaiting: 'Ожидание'
                }
            }
        },
        methods: {
            trial() {
                this.$store.dispatch('setRow', 'trial');
            },

            dualflow() {
                this.$store.state.showDual = true;

                this.$store.dispatch('setRow', 'dualFlowRow');
            },
            contrast() {
                this.$store.dispatch('setRow', 'contrast')
            },
            saline() {
                this.$store.dispatch('setRow', 'saline');
            },
            remove() {
                this.$store.dispatch('removeRow');
            },
            pause() {
                this.$store.dispatch('setRow', 'pause');
            },
            awaiting() {
                this.$store.dispatch('setRow', 'awaiting');
                this.$store.commit('stateControl', false);
                this.$store.dispatch('showRow', false);
                this.$store.dispatch('clearHighlightedRow')
            }
        },

        computed: {
            ...mapState([,
                'showAwaiting'
            ]),
            ...mapGetters([
                'getShowAwaiting',
                'getShowRemove',
                'getTestA',
                'getTestB',
                'getDualShow',
                'getSalineShow',
                'getContrastShow',
                'getShowPause'
            ])
        }


    }
</script>

<style scoped lang="scss">
    .control {
        display: flex;
        align-items: center;
        border: 1px solid black;
        border-radius: 5px;
        padding: 10px 0;
        gap: 7px;
        flex-direction: column;

        .green {
            background: #9DC76C;
            border-radius: 3px;
            box-shadow: 0 -3px #8AAF5F inset;
        }

        .blue {
            background: #61C6F1;
            box-shadow: 0 -3px #57B1D8 inset;
        }

        .gradient {
            background-image: linear-gradient(180deg, #9DC76C 50%, #61C6F1 50%);
            box-shadow: 0 -3px #57B1D8 inset;
        }

        .gray {
            background: #DDEDE6;
            box-shadow: 0 -3px #C7D5CF inset;
        }

        .dark-gray {
            background: #CFDED8;
            box-shadow: 0 -3px #B9C6C1 inset;
        }

        .border {
            border-radius: 3px;
            border: 1px solid #fff;
            border-bottom: none;
        }
    }
</style>