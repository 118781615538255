<template>
    <div class="injections-process-brief">
        <div class="injections-process-brief__overview injections-process-brief__info-block">
            <div class="injections-process-brief__info-block__header">
                Данные процедуры
            </div>
            <div class="injections-process-brief__list-item">
                <div class="injections-process-brief__list-item__text">
                    <span class="injections-process-brief__list-item__title">Предел давления</span>
                    <span class="injections-process-brief__list-item__subtitle">psi</span>
                </div>
                <div class="injections-process-brief__list-item__value">{{maxPressure}}</div>
            </div>
            <div class="injections-process-brief__list-item">
                <div class="injections-process-brief__list-item__text">
                    <span class="injections-process-brief__list-item__title">Концентрация йода</span>
                    <span class="injections-process-brief__list-item__subtitle">mg/ml</span>
                </div>
                <div class="injections-process-brief__list-item__value">-</div>
            </div>
        </div>
        <div class="d-flex flex-column" style="gap: 10px">
            <div class="injections-process-brief__protocols-list injections-process-brief__info-block">
                <div class="injections-process-brief__info-block__header">
                    Protocol*
                </div>
                <completed-row
                        v-for="(protocol, index) in completedProtocolsList"
                        class="injections-process-brief__protocols-list__protocol"
                        :row="protocol.initialData"
                        :index="index"
                        v-if="protocol.initialData.type !== 'Question'"
                >
                    <span>{{protocol.initialData.type}}</span>
                    <template v-if="protocol.initialData">
                        <span>{{protocol.initialData.flowRate}}</span>
                        <span>{{protocol.initialData.volume}}</span>
                        <span>{{protocol.initialData.duration}}</span>
                    </template>
                </completed-row>
            </div>
            <div class="injections-process-brief__total injections-process-brief__info-block">
                <div class="injections-process-brief__info-block__header">
                    Всего
                </div>
                <div class="injections-process-brief__list-item">
                    <div class="injections-process-brief__list-item__text">
                        <span class="injections-process-brief__list-item__title">Длит.</span>
                        <span class="injections-process-brief__list-item__subtitle">hh:mm:ss</span>
                    </div>
                    <div class="injections-process-brief__list-item__value">{{totalDuration}}</div>
                </div>
                <div style="display: flex">
                    <div class="short-volume" style="background-color: #76ccf3">
                        <span class="short-volume__header">Физ. раствор</span>
                        <div>
                            <span class="short-volume__sizing">ml</span>
                            <span class="short-volume__value">{{totalBVolume}}</span>
                        </div>
                    </div>
                    <div class="short-volume" style="background-color: #a5d275">
                        <span class="short-volume__header">Контрастное в-во</span>
                        <div>
                            <span class="short-volume__sizing">ml</span>
                            <span class="short-volume__value">{{totalAVolume}}</span>
                        </div>
                    </div>
                </div>
                <div class="injections-process-brief__list-item">
                    <div class="injections-process-brief__list-item__text">
                        <span class="injections-process-brief__list-item__title">Йод</span>
                        <span class="injections-process-brief__list-item__subtitle">g</span>
                    </div>
                    <div class="injections-process-brief__list-item__value">0</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { computed, ref, watch } from '@vue/composition-api'
    import CompletedRow from '@/components/ManagerRow'

    export default {
        name: "InjectionProcessBrief",
        props: {
            completedProtocolsList: {
                type: Array,
                required: true
            },
            maxPressure: {
                type: Number,
                required: true
            },
            totalDuration: {
                type: String,
                required: true
            }
        },
        components: {
            CompletedRow,
        },
        setup(props) {

            let totalBVolume = ref(0)
            let totalAVolume = ref(0)

            watch(
                () => props.completedProtocolsList,
                () => {
                    totalBVolume.value = totalAVolume.value = 0

                    props.completedProtocolsList.forEach(protocol => {
                        let volume = protocol.injected ? protocol.injected : parseInt(protocol.initialData.volume)
                        // #TODO replace strings by normal constants
                        if (protocol.initialData.type === 'B')
                            totalBVolume.value += volume
                        else if (protocol.initialData.type === 'A')
                            totalAVolume.value += volume
                        else if (protocol.initialData.type === 'dualFlow') {
                            totalBVolume.value += parseFloat((parseInt(protocol.initialData.saline) / 100 * volume).toFixed(1))
                            totalAVolume.value += parseFloat((parseInt(protocol.initialData.contrast) / 100 * volume).toFixed(1))
                        }
                    })
                    return
                },
                {immediate: true}
            )

            return {
                totalBVolume,
                totalAVolume
            }
        }
    }
</script>

<style lang="scss">
    .injections-process-brief {
        display: flex;
        height: 100%;
        padding: 10px;
        gap: 10px;
        border: 2px solid black;

        &__info-block {
            border-radius: 10px;
            border: black 1px solid;
            overflow: hidden;
            background-color: white;

            display: flex;
            flex-direction: column;

            &__header {
                border-bottom: black 1px solid;
                background-color: #dfe4df;
                display: flex;
                align-items: center;
                font-size: 12px;
                padding: 5px;
                color: black;
                font-weight: 700;
            }
        }

        &__overview {
            flex-grow: 1;
        }

        &__protocols-list {
            flex-grow: 1;

            &__protocol {
                display: flex;
                justify-content: space-between;
                padding: 8px 2px;
                border-bottom: #5D87A0 1px solid;
            }
        }

        &__total {

        }

        &__list-item {
            display: flex;
            padding: 5px;
            border-bottom: #2B2A29 1px solid;

            &__text {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }

            &__title {
                font-weight: 600;
                font-size: 10px;
            }
            &__subtitle {
                font-size: 10px;
            }
            &__value {
                font-size: 14px;
                font-weight: 600;
                align-self: flex-end;
            }


        }

        & > div {
            width: 50%;
        }

        .short-volume {
            width: 50%;
            padding: 0px 5px;
            height: 35px;

            div {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-top: 5px;
            }

            &__header {
                font-weight: 600;
                font-size: 10px;
            }

            &__sizing {
                font-size: 10px;
            }

            &__value {
                font-weight: 600;
                font-size: 12px;
            }
        }
    }
</style>