<template>
    <div>
        <div class="pressure">Макс. давление {{ maxPressure }}</div>
        <div style="padding: 0 10px; height: 230px">
            <InjectionProcessChart
                    ref="chart"
                    :max-pressure="maxPressure"
                    :is-running="false"
                    :expired-time="expiredTime * 1000"

                    :frames-per-second="50"
                    :pixels-shift-per-frame="0.5"
                    :timeline-labels-seconds-gap="2"

                    :protocols-list="completedProtocolsList"
            />
        </div>
        <div class="btns-container">
            <div data-enable-pointer-events="true" class="scroll-btn" @click="scrollLeft"><</div>
            <div data-enable-pointer-events="true" class="scroll-btn" @click="scrollRight">></div>
        </div>
    </div>
</template>

<script>
    import InjectionProcessChart from '@/components/InjectionProcess/InjectionProcessChart'

    export default {
        name: "InjectionProcessStaticChart",
        components: {
            InjectionProcessChart,
        },
        props: {
            completedProtocolsList: {
                type: Array,
                required: true
            },
            maxPressure: {
                type: Number,
                required: true
            },
            expiredTime: {
                type: Number,
                required: true
            }
        },
        setup(props, {refs}) {
            const scrollLeft = () => refs.chart.scrollChart(-50)
            const scrollRight = () => refs.chart.scrollChart(50)

            return {
                scrollLeft,
                scrollRight
            }
        }
    }
</script>

<style lang="scss" scoped>
    .btns-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .scroll-btn {
            padding: 10px 30px;
            font-size: 30px;
            font-weight: 700;
            cursor: pointer;
            background-color: #c7cfc6;
            box-shadow: 1px 5px 5px #999F98;
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            border-top: 1px solid white;
            border-left: 1px solid white;
            margin: 0 20px;
            color: #999F98;
        }
    }
    .pressure {
        padding: 5px;
        font-weight: 700;
        font-size: 12px;
        text-align: right;
    }
</style>