import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        showFirstOptions: true,
        showFirstPage: true,
        isChanged: false,
        done: false,
        rangeValue: 0,
        selectedButton: '',
    },
    getters: {
        showFirstOptions(state) {
            return state.showFirstOptions
        },
        showFirstPage(state) {
            return state.showFirstPage
        },
        isChanged(state) {
            return state.isChanged
        },
        done(state) {
            return state.done
        },
        rangeValue(state) {
            return state.rangeValue
        },
        selectedButton(state) {
            return state.selectedButton;
        },

    },
    mutations: {
        changeFirstOptionsVisibility(state, payload) {
            state.showFirstOptions = payload
        },
        showFirstPage(state, payload) {
            state.showFirstPage = payload
        },
        changeIsChanged(state, payload) {
            state.isChanged = payload
        },
        changeDone(state, payload) {
            state.done = payload
        },
        changeRangeValue(state, payload) {
            state.rangeValue = payload
        },
        setSelectedButton(state, type) {
            state.selectedButton = type;
        },
        setDefaultModule(state) {
            state.showFirstOptions = true;
            state.showFirstPage = true;
            state.isChanged = false;
            state.done = false;
            state.rangeValue = 0;
            state.selectedButton = '';
        },
    },
    actions: {
        changeFirstOptionsVisibility({commit}, payload) {
            commit('changeFirstOptionsVisibility', payload)
        },
        showFirstPage({commit}, payload) {
            commit('showFirstPage', payload)
        },
        changeIsChanged({commit}, payload) {
            commit('changeIsChanged', payload)
        },
        changeDone({commit}, payload) {
            commit('changeDone', payload)
        },
        changeRangeValue({commit}, payload) {
            commit('changeRangeValue', payload)
        },
        setSelectedButton({commit}, type) {
            commit('setSelectedButton', type)
        },
        setDefaultModule({commit}) {
            commit('setDefaultModule');
        },

    }
}