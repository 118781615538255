<template>
    <button @pointerdown="changePage" class="btn chevron-btn"><i :class="arrow"></i></button>
</template>

<script>
    export default {
        name: "ChevronBtn",
        props: {
            page: [Number, String],
        },
        methods: {
            changePage() {
                this.$emit('change-page');
            }
        },
        computed: {

            arrow() {
                if (this.page == 1) {
                    return {
                        'fas fa-chevron-down': true,
                    }
                } else if (this.page == 2) {
                    return {
                        'fas fa-chevron-up': true,
                    }
                } else if (this.page == 3) {
                    return {
                        'fas fa-chevron-right': true,
                    }
                } else {
                    return {
                        'fas fa-chevron-left': true,
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    @import "../scss/mixin";
    @import "../scss/variable";

    .btn {
        @include btn;
    }

    .chevron-btn {
        border-radius: 0;
        width: $height-for-main-elements;
        background: transparent;
        border-color: $primary-background;
        box-shadow: 1px 1px $dark-border;
    }

</style>