<template>
    <div class="date-item">
        <div class="item-name">{{period}}</div>
        <div @pointerdown="selectPeriod" :class="selected" class="item-value">{{value}}</div>
    </div>
</template>

<script>
    export default {
        name: "DateTimeItem",
        props: {
            period: String,
            value: Number,
            isSelected: Boolean,
        },
        methods: {
            selectPeriod() {
                this.$emit('select-period')
            }
        },
        computed: {
            selected() {
                return {
                    selected: this.isSelected,
                }
            }
        },
    }
</script>

<style scoped lang="scss">

    @import "../scss/mixin";
    @import "../scss/variable";

    .date-item {

        .item-value {
            display: flex;
            justify-content: center;
            min-width: 45px;
            background: $light-background;
            border: 2px solid;
            width: max-content;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 12px;
            padding-right: 12px;
            border-top-color: $primary-border-color;
            border-right-color: $light-blue-main-elements;
            border-bottom-color: $light-blue-main-elements;
            border-left-color: $primary-border-color;
            cursor: pointer;
        }
        .selected {
            background: $yellow;
        }
    }
</style>