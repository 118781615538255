<template>
    <div class="container">
        <div class="computer">
          <div style="position: absolute;top: 17px;left:27px;width: 25%;display: flex">
            <img src="../img/pictStation.svg" style="width: 12%;">
            <p style=" font-size: 12px; margin-left: 3%">Внешний вид рабочей станции может отличаться</p>
          </div>
            <div v-if="isEducationModeEnabled && workstation_is_on && showConfirmation" class="sticky-tooltips-container">
                <div class="sticky-tooltip"
                >Внимание! В реальности загрузка рабочей станции занимает больше времени, чем в компьютерной эмуляции.
                </div>
            </div>
            <div v-if="!workstation_is_on" key="off" class="screen-black"></div>
            <div v-else-if="showConfirmation" class="screen-confirmation" key="confirm">
                <div>
                    <app-button @close-confirm="switchOnComp(false)"></app-button>
                    <div v-show="isEducationModeEnabled && workstation_is_on" class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-bottom chunk-with-text-attached-to-arrow--align-left"
                 style="top:80%; left: 70%;"><span>Нажмите "Продолжить"</span></div>
                </div>
            </div>
            <div v-else class="screen" key="enabled"  :style="(isEducationModeEnabled && !$store.getters['sequences_store/getActiveSequence']) ? 'pointer-events: none;' : ''">
                <div class="screen__left">
                    <app-bar @show-menu="showMainMenu"
                             @open-liquid-modal="$store.dispatch('station/workstation/changeLiquidModalVisibility', true)"
                    />
                    <menu-main v-show="showMenu"
                               @show-settings="$store.dispatch('station/workstation/changeSettingsVisibility', !showSettings)"
                               @show-shutdown="showModalShutdown"
                               :isSelected="showSettings"
                    />
                    <settings-menu v-show="showSettings"
                                   @click.native="handleOpeningNewPage"
                    />
                    <div v-show="getActiveStepId === 'main'" class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-bottom chunk-with-text-attached-to-arrow--align-left"
                 style="top:95%; z-index: 5000;"><span>Меню и настройки</span></div>
                </div>
                <template v-if="injection_started">
                    <InjectionProcessView ref="injectionProcess"/>
                </template>
                <div v-else class="screen__right right">
                    <div class="header">
                        <div class="middle__header">
                            <img src="../img/Protocol-menu.svg" alt="">
                            <h2>Protocol</h2>
                        </div>
                        <SequenceChunkWrapper
                                sequence-id="protocol_manager"
                                step-id="to_upload_existing_protocol_choose_protocol_manager"
                                key="to_upload_existing_protocol_choose_protocol_manager"
                                v-slot="{processChunkAction: processChunkAction_to_upload_existing_protocol_choose_protocol_manager}"
                        >
                            <SequenceChunkWrapper
                                    sequence-id="protocol_manager"
                                    step-id="to_save_or_upload_select_protocol_manager"
                                    key="to_save_or_upload_select_protocol_manager"
                                    v-slot="{processChunkAction: processChunkAction_to_save_or_upload_select_protocol_manager}"
                            >
                                <span
                                        class="right__manager"
                                        @click="() => {
                                            const fn = () => $router.push({name: 'Manager'})
                                            processChunkAction_to_upload_existing_protocol_choose_protocol_manager(fn, {enabledIfEducationIsInactive: true})
                                            processChunkAction_to_save_or_upload_select_protocol_manager(fn)
                                        }"
                                >Менеджер протоколов</span>
                            </SequenceChunkWrapper>
                        </SequenceChunkWrapper>
                    </div>
                    <div class="script-options">
                        <reminders v-if="reminder"/>
                        <template v-else>
                            <div class="fluid-source-date-time">
                                <div class="fluid-source">
                                    <div class="fluid-header">
                                        <h2>Источник жидкости</h2>
                                        <span>ml</span>
                                    </div>
                                    <div class="fluid-visual">
                                        <fluid-a></fluid-a>
                                        <fluid-b></fluid-b>
                                    </div>
                                    <add-fluids
                                            v-show="this.filling_started.a && this.filling_started.b && (totalA - fluids.a < 0 || totalB - fluids.b < 0)"/>
                                </div>
                                <date-time></date-time>
                            </div>
                            <div v-show="getActiveStepId === 'main'"
                                 class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-top chunk-with-text-attached-to-arrow--align-right"
                                 style="top: 0%; left: 13%; width: 300px; z-index: 5000;"><span>Шприц-колбы инъектора. Зеленый – контрастное средство. Синий - физраствор. Если шприц-колбы не установлены, то оно отображаются белыми</span>
                            </div>
                            <div>

                            </div>
                            <template>
                                <div class="middle__main-area main-area">
                                        <div class="main-area-header">
                                        <div>
                                            <h2 class="header-bold">Расход</h2>
                                            <span>ml/s</span>
                                        </div>
                                        <div>
                                            <h2 class="header-bold">Объем</h2>
                                            <span>ml</span>
                                        </div>
                                        <div class="header-transparent">
                                            <h2 class="header-bold">Длит</h2>
                                            <span>mm:ss</span>
                                        </div>
                                    </div>
                                    <div class="scripts">
                                        <app-row :class="lock_protocol" v-for="(row,index) in getRows" :row="row"
                                                 :index="index" :key="index"/>
                                    </div>
                                    <template>
                                        <div v-show="getActiveStepId === 'main'"
                                             class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-bottom chunk-with-text-attached-to-arrow--align-right"
                                             style="top:100%; right: 85%; width: 200px; z-index: 5000;"><span>Значок R- сбрасывает протокол до заводских значений по умолчанию.</span>
                                        </div>
                                        <div class="scripts-block-button">
                                            <SequenceChunkWrapper
                                                    sequence-id="protocol_manager"
                                                    step-id="press_reset_protocol_button"
                                                    key="press_reset_protocol_button"
                                                    v-slot="{processChunkAction}"
                                            >
                                                <restart
                                                        @showModalReset="processChunkAction(() => $store.dispatch('station/workstation/changeModalResetVisibility', true), {enabledIfEducationIsInactive: true})"/>
                                            </SequenceChunkWrapper>
                                            <template>
                                                <div v-show="getActiveStepId === 'main'"
                                                     class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-bottom chunk-with-text-attached-to-arrow--align-left"
                                                     style="top: 100%; left: 55%; width: 200px; z-index: 5000;"><span>Расчет кол-ва жидкости, которое будет введено при реализации протокола, разделенное по типу жидкости.</span>
                                                </div>
                                                <div>
                                                    <total-a></total-a>
                                                    <total-b></total-b>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                                <div v-show="getActiveStepId === 'main'"
                                 class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-top chunk-with-text-attached-to-arrow--align-left"
                                 style="top: 2.5%; right: 25%; width: 300px; z-index: 5000;"><span>Протокол, где мы видим объем, скорость введения КС и время введения</span>
                                </div>
                            </template>
                        </template>
                        <div class="protocol-manager">
                            <button
                                    @pointerdown="isVisibleReminder($event, false)"
                                    class="close-reminders"
                                    v-if="reminder">
                                ОК
                            </button>
                            <app-calc v-if="showCalc"
                            />
                            <control-panel v-else-if="showControl"/>
                            <pressure-list v-else-if="showPressure"
                                           @closePressure="$store.dispatch('station/workstation/changePressureVisibility', false)"
                            />
                            <template v-else-if="!showControl && !showCalc && !reminder && !showPressure">
                                <template>
                                    <div v-show="getActiveStepId === 'main'"
                                         class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-top chunk-with-text-attached-to-arrow--align-left"
                                         style="top: -29.5%; width: 250px; z-index: 5000;"><span>Менеджер протоколов, предел давления и напоминания</span>
                                    </div>
                                    <div class="top-part-limit">
                                        <div class="right__limit limit shadow">
                                            <div class="limit__header" @pointerdown="!isEducationModeEnabled &&
                                            ($store.dispatch('station/workstation/changePressureVisibility', true))">
                                                Предел давления
                                            </div>
                                            <div class="limit__gray">{{ getPressureLimit }}</div>
                                        </div>
                                        <div @pointerdown="!isEducationModeEnabled && isVisibleReminder($event, true)"
                                             class="right__reminders shadow">
                                            <p>Напоминания</p>
                                            <p class="amount">{{ getReminderAmount }}</p>
                                        </div>
                                    </div>
                                </template>
                                <template>
                                    <div v-show="getActiveStepId === 'main'"
                                         class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-top chunk-with-text-attached-to-arrow--align-left"
                                         style="top: 55%; width: 250px; z-index: 5000;"><span>Блокировка инъектора перед запуском протокола</span>
                                    </div>
                                    <div class="system">
                                        <div class="system-header">
                                            <h2>Система</h2>
                                            <div class="system-header-btn-container">
                                                <button class="check-system-button system-btn"><img
                                                        src="../img/check-system-btn.svg" alt="">
                                                </button>
                                                <button class="confirm-button system-btn">
                                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.49998 19C4.26963 19 0 14.7304 0 9.5C0 4.26967 4.26963 0 9.49998 0C14.7303 0 19 4.26967 19 9.5C19 14.7304 14.7303 19 9.49998 19ZM9.49998 1.06741C4.91008 1.06741 1.06742 4.80338 1.06742 9.5C1.06742 14.0899 4.80334 17.9326 9.49998 17.9326C14.1966 17.9326 17.9326 14.1966 17.9326 9.5C17.9326 4.91012 14.1966 1.06741 9.49998 1.06741Z"
                                                              fill="#36393B"/>
                                                        <circle cx="9.5" cy="9.5" r="8.5" :style="confirm_button"/>
                                                        <path d="M8.30402 13.7811C8.10402 13.7811 7.90402 13.6811 7.80402 13.4811L5.10406 9.5811C4.90406 9.28114 5.00402 8.88115 5.30402 8.68115C5.60402 8.48115 6.00404 8.58113 6.20404 8.88113L8.40405 12.0811L12.6041 6.28112C12.8041 5.98112 13.204 5.88115 13.504 6.18115C13.804 6.38115 13.9041 6.78114 13.6041 7.08114L8.90405 13.6811C8.70405 13.6811 8.50402 13.7811 8.30402 13.7811Z"
                                                              fill="#36393B"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="system-window">
                                            <img src="../img/Group.svg" alt="">
                                            <SequenceChunkWrapper
                                                    v-if="protocol_locked"
                                                    v-slot="{processChunkAction: processChunkActionInjectionProcedurePressChargerButtonAgain}"
                                                    sequence-id="injection_procedure"
                                                    step-id="press_Charger_button_again"
                                                    key="press_Charger_button_again"
                                            >
                                                <SequenceChunkWrapper
                                                        v-slot="{processChunkAction: processChunkActionInjectionProcedurePressChargerButton}"
                                                        sequence-id="injection_procedure"
                                                        step-id="press_Charger_button"
                                                        key="press_Charger_button"
                                                >
                                                    <button class="charger-btn"
                                                            @click="() => {
                                                                processChunkActionInjectionProcedurePressChargerButtonAgain(activateInjection, {enabledIfEducationIsInactive: true})
                                                                processChunkActionInjectionProcedurePressChargerButton(activateInjection)
                                                        }">Зарядка
                                                    </button>
                                                </SequenceChunkWrapper>
                                            </SequenceChunkWrapper>
                                            <SequenceChunkWrapper
                                                    v-else
                                                    v-slot="{processChunkAction: processChunkActionInjectionProcedurePressLockButton}"
                                                    sequence-id="injection_procedure"
                                                    step-id="press_Lock_button"
                                                    key="press_Lock_button"
                                            >
                                                <button class="lock-btn" @click="() => {
                                                            processChunkActionInjectionProcedurePressLockButton(lockProtocol, {enabledIfEducationIsInactive: true})}">
                                                    Блокир.
                                                </button>
                                            </SequenceChunkWrapper>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
                <dual-flow v-show="getShowDual" class="dual-flow"></dual-flow>
                <app-keyboard v-show="getShowKeyboard"/>
                <template v-for="(item, index) in getReminders">
                    <modal-reminder :time="item.time"
                                    :name="item.name"
                                    :index="index"
                                    :show="item.show"
                                    :confirmed="reminder_confirmed"
                    />
                </template>
                <modal-cancel v-if="modalReset"
                              :text="modalText"
                              @confirm="$store.dispatch('station/workstation/changeModalResetVisibility', false)"
                              @cancel="$store.dispatch('station/workstation/changeModalResetVisibility', false)"

                />
                <shutdown-modal v-if="showShutdown"
                                @close-modal="$store.dispatch('station/workstation/changeShutdownVisibility', false)"
                />
                <liquid-modal v-if="showLiquidModal"
                              @close-modal="$store.dispatch('station/workstation/changeLiquidModalVisibility', false)"
                />
                <modal-options
                        v-show="showOptionsModal"
                        @confirm="check_air_confrim"
                        @cancel="$store.dispatch('station/workstation/changeOptionsModalVisibility', false)"
                >
                    <template v-slot:information>
                        {{modalOptionsText}}
                    </template>
                </modal-options>
                <modal-notification
                        @confirm="$store.dispatch('station/workstation/changeModalNotificationVisibility', false)"
                        v-show="showModalNotification"
                >
                </modal-notification>
                <add-fluids-modal v-show="showAddFluidsModal"
                                  @cancel="$store.dispatch('station/workstation/changeAddFluidsModalVisibility', false)"
                                  @confirm="$store.dispatch('station/workstation/changeAddFluidsModalVisibility', false)"
                />
            </div>
            <div class="stand">
                <button v-show="injection_started" class="injection-button-stop"
                        @click="standEventBus.$emit('stop')"></button>
                <SequenceChunkWrapper
                         v-if="injection_started"
                        v-slot="{processChunkAction: processChunkActionInjectionProcedureContinueInjection, isActive: isActive_continue_injection}"
                        sequence-id="injection_procedure"
                        step-id="continue_injection"
                        key="continue_injection"
                >
                    <SequenceChunkWrapper
                            v-slot="{processChunkAction: processChunkActionInjectionProcedurePressStartInjectionButton, isActive: isActive_press_Start_Injection_button}"
                            sequence-id="injection_procedure"
                            step-id="press_Start_Injection_button"
                            key="press_Start_Injection_button"
                    >
                        <button class="injection-button-start"
                                @pointerdown.once="activateReminders"
                                @click="() => {
                                    if (isEducationModeEnabled) {
                                        if (isActive_continue_injection && $store.getters.current_phase === 'TEST')
                                            processChunkActionInjectionProcedureContinueInjection(() => standEventBus.$emit('run'))
                                        if (isActive_press_Start_Injection_button)
                                            processChunkActionInjectionProcedurePressStartInjectionButton(() => standEventBus.$emit('run'))
                                    } else {
                                        standEventBus.$emit('run')
                                    }
                                }">
                            Пуск/Пауза
                        </button>
                    </SequenceChunkWrapper>
                </SequenceChunkWrapper>
                <comp-lamp class="light" :class="isLightOn"/>
                <div style="position: absolute; bottom: 41%; left: 40.3%;">
                    <input  style="transform: scale(1.6); opacity: 0;" :style="!workstation_is_on ? ' cursor: pointer;' : ''" type="checkbox"  @input="switchOnComp(true)">
                    <div v-if="!workstation_is_on && isEducationModeEnabled" class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-bottom chunk-with-text-attached-to-arrow--align-left"
                        style="top: 100%; left: -100%; margin-top: 30px; width: 170px;"><span>Включите рабочую станцию</span>
                    </div>
                </div>
            </div>
        </div>
        <router-modal/>
        <InfoButton style="width: 13.5%; min-width: 259px" @open-confirm="openModal"/>
        <TermDefinition v-if="modalEx" style="z-index: 99999" @close-confirm="closeModal"/>
    </div>
</template>
<script>

    import {mapGetters} from 'vuex';

    import CompLamp from '@/components/AppLamp'
    import AppButton from '@/components/AppButton'
    import AppCalc from '@/components/Calc'
    import TotalA from '@/components/TotalA'
    import TotalB from '@/components/TotalB'
    import Restart from '@/components/Restart'
    import DateTime from '@/components/DateTime'
    import FluidA from '@/components/FluidA'
    import FluidB from '@/components/FluidB'
    import AppBar from '@/components/SideBar'
    import ControlPanel from '@/components/ControlPanel'
    import DualFlow from '@/components/dual_flow/DualFlow'
    import AppRow from '@/components/Row'
    import ModalWindowRemainders from '@/components/reminders/ModalWindowRemainders'
    import Reminders from '@/components/reminders/Reminders'
    import AppKeyboard from '@/components/ScreenKeyboard'
    import ModalReminder from '@/components/reminders/ModalWindowRemainders'
    import PressureList from '@/components/PressureLimit'
    import ModalCancel from '@/components/ResetProtocolModal'
    import MenuMain from '@/components/settingmodals/MenuModal'
    import SettingsMenu from '@/components/settingmodals/SettingsMenuModal'
    import LiquidModal from '@/components/LiquidModal'
    import RouterModal from '@/components/modals/RouterModal'
    import AddFluids from '@/components/AddFluids'
    import ModalOptions from '@/components/modals/ModalOptionsWorkStation'
    import ModalNotification from '@/components/modals/ModalNotificationWorkStation'
    import AddFluidsModal from '@/components/modals/AddFluidsModal'
    import InjectionProcessView from '@/components/InjectionProcess/InjectionProcessView'
    import InfoButton from "@/components/InfoButton.vue";
    import TermDefinition from "@/components/TermDefinition.vue";


    export default {
        components: {
            AppButton,
            AppCalc,
            CompLamp,
            TotalA,
            TotalB,
            Restart,
            DateTime,
            FluidA,
            AppBar,
            FluidB,
            ControlPanel,
            DualFlow,
            AppRow,
            ModalWindowRemainders,
            Reminders,
            AppKeyboard,
            ModalReminder,
            PressureList,
            ModalCancel,
            MenuMain,
            SettingsMenu,
            ShutdownModal: () => import(/* webpackChunkName: "ShutdownModal" */ '@/components/ShutdownModal'),
            LiquidModal,
            RouterModal,
            InjectionProcessView,
            AddFluids,
            ModalOptions,
            ModalNotification,
            AddFluidsModal,
            InfoButton,
            TermDefinition,
        },

        data() {
            return {
                modalText: 'Сбросить протокол?',
                modalOptionsText: '',
                initialDataState: {},
                unsubscribeCleanupBeforeTutorial: undefined,
                modalEx:false,
            }
        },
        created() {
            Object.entries(this.$data).forEach(([key, value]) => {
                if (!['initialDataState', 'unsubscribeCleanupBeforeTutorial'].includes(key))
                    this.initialDataState[key] = value
            })
            this.unsubscribeCleanupBeforeTutorial = this.$store.subscribe(mutation => {
                if (mutation.type === 'FINALIZE_CLEANUP_STORE_BEFORE_TUTORIAL')
                    Object.entries(this.initialDataState).forEach(([key, value]) => this[key] = value)
            })
        },
        beforeDestroy() {
            this.unsubscribeCleanupBeforeTutorial()
        },

        methods: {
            switchOnComp(confirm) {
                if (!this.workstation_is_on || this.showConfirmation) {
                    this.$store.commit('switchStateOfWorkstation', {
                        state: true,
                        confirm: confirm,
                    });
                }
            },
            handleOpeningNewPage() {
                this.$store.dispatch('station/workstation/changeSettingsVisibility', !this.showSettings);
                this.$store.dispatch('station/workstation/changeMenuVisibility', !this.showMenu)
            },
            isVisibleReminder(e, visible) {
                this.$store.dispatch('station/workstation/changeReminder', visible)
                this.$store.commit('showCalc', visible);
                if (!visible) {
                    this.$store.dispatch('calcEvents', false);
                } else {
                    this.$store.dispatch('calcEvents', true);
                    this.$store.commit('setRange', '00:01 - 20:00 (1) mm:ss');
                }
            },
            showMainMenu() {
                this.$store.dispatch('station/workstation/changeMenuVisibility', !this.showMenu)
                this.$store.dispatch('station/workstation/changeSettingsVisibility', false)
            },
            showModalShutdown() {
                this.$store.dispatch('station/workstation/changeShutdownVisibility', true)
                this.$store.dispatch('station/workstation/changeMenuVisibility', false)
            },
            lockProtocol() {
                this.$store.commit('changeStatusProtocolLock', true);
            },
            activateInjection() {
                if (!this.selected_kit[2]?.set) {
                    this.$store.dispatch('station/workstation/changeModalNotificationVisibility', true)
                } else if (!this.check_air) {
                    this.modalOptionsText = 'Действительно ли воздух отсутствует?';
                    this.$store.dispatch('station/workstation/changeOptionsModalVisibility', true);
                } else if (this.totalA - this.fluids.a > 0 || this.totalB - this.fluids.b > 0) {
                    this.$store.dispatch('station/workstation/changeAddFluidsModalVisibility', true)
                } else {
                    this.$store.commit('injector/setInjectionStatus', true);
                }
            },
            check_air_confrim() {
                this.$store.commit('injector/setCheckAirStatus', true)
                this.$store.dispatch('station/workstation/changeOptionsModalVisibility', false);
            },
            activateReminders() {
                this.$store.dispatch('station/workstation/changeReminder_confirmed');
                this.$store.commit('setReminderList');
            },
            openModal() {
                this.modalEx = true;
            },
            closeModal() {
                this.modalEx = false;
            },
        },

        computed: {
            ...mapGetters([
                'testPressed',
                'showControl',
                'showCalc',
                'getRows',
                'getShowDual',
                'getShowKeyboard',
                'getReminders',
                'getPressureLimit',
                'getReminderAmount',
                'workstation_is_on',
                'showConfirmation',
                'totalA',
                'totalB',
                'protocol_locked',
                'current_phase',
            ]),
            ...mapGetters('injector', {
                connected_to_patient: 'connected_to_patient',
                fluids: 'fluids',
                check_air: 'check_air',
                selected_kit: 'selected_kit',
                filling_started: 'filling_started',
                injection_started: 'injection_started'

            }),
            ...mapGetters('station/workstation', {
                reminder: 'reminder',
                reminder_confirmed: 'reminder_confirmed',
                showPressure: 'showPressure',
                modalReset: 'modalReset',
                showMenu: 'showMenu',
                showSettings: 'showSettings',
                showShutdown: 'showShutdown',
                showLiquidModal: 'showLiquidModal',
                showOptionsModal: 'showOptionsModal',
                showModalNotification: 'showModalNotification',
                showAddFluidsModal: 'showAddFluidsModal',
            }),
            ...mapGetters('sequences_store', ['getActiveSequenceId', 'getActiveStepId']),
            confirm_button() {
                return {
                    fill: this.check_air ? '#F8ED75' : 'none',
                }
            },
            lock_protocol() {
                return {
                    locked: this.protocol_locked,
                }
            },
            isLightOn() {
                return {
                    on: this.workstation_is_on,
                }
            },
        },
        watch: {
            showControl() {
                if (this.showControl && this.showPressure) {
                    this.$store.dispatch('changePressureVisibility', false)
                }
            },
            showCalc() {
                if (this.showCalc && this.showPressure) {
                    this.$store.dispatch('changePressureVisibility', false)
                }
            },
        },
        beforeRouteLeave(to, from, next) {
            this.$store.dispatch('clearHighlightedRow');
            this.$store.dispatch('setSelectedRowButton', null);
            next();
        },
    }
</script>

<style lang='scss'>

    @import "../scss/style";

    .sticky-tooltips-container {
        left: -20%;
        font-size: 1.1rem;
    }


</style>