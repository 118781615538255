import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import workstation from './stations_modules/workstation'
import Reference from "./stations_modules/Reference";
import ManagerSettings from "./stations_modules/ManagerSettings";
import settingsLiquidDelivery from "./stations_modules/settings-LD"
import SystemSettings from "./stations_modules/SystemSettings";
import manager from "./stations_modules/manager"
import settingsPreset from "./stations_modules/settings-preset"

export default {
    namespaced: true,
    modules: {
        workstation,
        Reference,
        ManagerSettings,
        settingsLiquidDelivery,
        SystemSettings,
        manager,
        settingsPreset
    },
    state: {},
    mutations: {},
    actions: {}
}