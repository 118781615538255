<template>
    <div class="main">
        <img src="@/img/MainScreen-off.jpg" alt=""/>
    <div class="mode">
        <div class="header_work">
            <p class="Header-text">Начало работы</p>
        </div>
      <div class="disclamer">
        <p class="text-container" style="display: inline-block; text-align: left; margin: auto; width: 90%"> Материал подготовлен при поддержке компании Байер </p>
      </div>
        <div class="welcome-container">

            <div class="item__img">
                <img class="img-logo" src="@/img/components/homepage/homepageLogo.png" alt="">
            </div>
            <div style="margin: 0 10%;">
                <p class="text-container">
                    В данном разделе сайта вашему вниманию предлагается симуляционная программа по использованию MEDRAD® Stellant (инъекционной системы для внутривенного введения контрастного средства и физиологического раствора пациенту при проведении КТ исследований). Программа носит справочно-информационный характер и предназначена для специалистов здравоохранения. Убедительно просим не пересылать ссылку на данный раздел сайта и иным образом не передавать его содержание лицам, не являющимся специалистами здравоохранения.
                </p>
                <p class="text-container" style="margin-top: 30px;">
                    В случае расхождений между симуляционной программой, размещённой на данном разделе сайта, и технической (эксплуатационной) документацией MEDRAD® Stellant убедительно просим руководствоваться исключительно технической (эксплуатационной) документацией MEDRAD® Stellant. За актуальной информацией вы можете обратиться к уполномоченному представителю MEDRAD® Stellant в России компании АО «БАЙЕР».
                </p>
            </div>
            <v-btn
                color="#CE6C2B"
                depressed
                elevation="2"
                x-large
                class="btn-show"
                @click="redirectToMain"
                ><p class="text-btn">Начать</p>
            </v-btn>
        </div>
    </div>
</div>
</template>

<script>
    export default {

        name: 'WelcomePage',
        methods: {
    redirectToMain() {
      this.$router.push({ name: 'Main' });
    }
  }
    }
</script>

<style scoped lang="scss">
@import "../scss/style";
.main {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__mode {
        height: 25vh;
        background-color: rgba(255, 255, 255, 0.6);
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .mode {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        gap: 100px;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .header_work{
            width: 100%;
            padding: 20px 0px;
            justify-content: center;
            align-items: center;
            background: #FFF;

            .Header-text{
                color: #000;
                text-align: center;
                font-size: 48px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .disclamer{
          display: flex;
          background: rgb(255,255,255);
          width: 18%;
          margin: 6px auto 0%;
          height: 7%;
          align-items: center;
          position: fixed;
          right: 1%;
        }
        .welcome-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: rgba(255, 255, 255, 0.90);
            width: 75%;
            margin: 5% auto 5%;

            .item__img{
                display: flex;
                justify-content: center;
                margin: 30px auto;
                .img-logo{
                    width: 520px;
                }
            }
            .text-container{
                color: #000;
                font-family: Open Sans;
                font-size: 17px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        
        }
        .btn-show{
            width: 246px;
            height: 53px;
            background: #CE6C2B;
            margin: 60px auto;

            .text-btn{
                color: #FFF;
                text-align: center;
                font-family: Open Sans;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}
</style>