import { SequenceStepChunkDefinition } from '@/sequences/SequenceStepChunk/base_classes'

export class SequenceStepChunkWithText extends SequenceStepChunkDefinition {
    static COMPONENTS = [() => import('./ChunkWithText')]

    constructor({
        text: {
            text = '',
            style = ''
        } = {},
        ...restArgs
    } = {}) {
        super({
            ...restArgs,
            components: [...SequenceStepChunkWithText.COMPONENTS]
        })
        this.text = text
        this.style = style
    }
}