<template>
    <button :class="[isSelected, isDisabled, classInput]" class="pause" @pointerdown="enableButton">
        {{duration}}
    </button>
</template>

<script>

    import {mapGetters} from 'vuex'

    export default {
        name: "Pause",
        props: {
            duration: String,
            index: Number,
            wrapped: Boolean
        },

        data() {
            return {
                range: '00:01 - 15:00 (1) sec',
            }
        },

        methods: {
            enableButton(e) {
                // if (this.wrapped)
                //     this.$emit('focused', {
                //         event: e.target, f: () => this.$store.dispatch('enableCalc', {
                //             range: this.range,
                //             boolean: true,
                //         })
                //     });
                // else if (!this.isEducationModeEnabled) {
                    this.$emit('focused', e.target)
                    this.$store.dispatch('enableCalc', {
                        range: this.range,
                        boolean: true,
                    });
                // }
                this.$store.dispatch('setSelectedRowButton', 'pause');
            },
        },

        computed: {
            ...mapGetters([
                'getRows',
                'getEditableRow',
                'getSelectedRowButton',
            ]),
            classInput() {
                return {
                    inputed: this.getRows[this.index].input,
                }
            },
            isSelected() {
                return {
                    enabled: this.index === this.getEditableRow && this.getSelectedRowButton === 'pause',
                }
            },
            isDisabled() {
                return {
                    disabled: this.getSelectedRowButton !== null,
                }
            },
        }
    }
</script>

<style scoped lang="scss">

    @import "@/scss/variable";
    @import "@/scss/mixin";

    .pause {
        color: white;
        background: rgba(255, 255, 255, 0.1);
        width: 78px;
        height: 36px;
        font-size: 24px;
        font-weight: normal;
        cursor: pointer;
        }
    .inputed {
        background: #DEE9E2;
        color: #000;
    }

    .enabled {
        background: black !important;
        color: white !important;
        border: 2px solid black;
    }
    .disabled {
        pointer-events: none;
    }


</style>