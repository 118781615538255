<template>
    <div class="wrapper">

            <div class="modal">
                <p class="modal__information">
                    <slot name="information">

                    </slot>
                </p>
                <div class="modal__options options">
                    <SequenceChunkWrapper
                            v-slot="{processChunkAction: processChunkActionInjectionProcedureConfirmCheckForAir}"
                            sequence-id="injection_procedure"
                            step-id="confirm_check_for_air"
                            key="confirm_check_for_air"
                    >
                        <button class="options__button"
                                @click="() => processChunkActionInjectionProcedureConfirmCheckForAir(confirm, {enabledIfEducationIsInactive: true})">
                            Да
                        </button>
                    </SequenceChunkWrapper>
                    <button class="options__button"
                            @click="cancel">
                        Нет
                    </button>
                </div>

            </div>

    </div>
</template>

<script>
    export default {
        name: "ModalOptionsWorkStation",
        methods: {
            confirm() {
                this.$emit('confirm');
            },
            cancel() {
                this.$emit('cancel');
            },
        }
    }
</script>

<style scoped lang="scss">
    @import '../../scss/mixin';

    .wrapper {
        @include modal-container;
    }

    .modal {
        @include modal-work-station;
        &__options {
            display: flex;
            gap:25px;
        }

        .options {
            &__button {
                font-weight: unset;
                width: 120px;
                height: 30px;
                background: #598BD1;
                border-top: 2px solid #5bb7ff;
                border-left: 2px solid #5bb7ff;
                border-bottom: 2px solid #45699b;
                border-right: 2px solid #45699b;
                font-size: 14px;
                text-transform: capitalize;

                &:active {
                  border: 2px solid #598BD1;
                  font-size: 13px;
                }
            }
        }
    }
</style>