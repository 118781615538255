<template>
    <div class="container">
        <div class="computer">
            <div v-if="!workstation_is_on" key="off" class="screen-black"></div>
            <div v-else class="screen" key="enabled">
                <div class="header system-settings-header">
                    <img src="../img/infsystem.svg">
                    <span>Настройка информационной системы</span>
                </div>
                 <div class="screen-settings">
                        <div class="screen-settings-column first-column">
                            <left-button v-for="(value, name, index) in left_settings"
                                        :name="name"
                                        :value="value"
                                        :index="index"
                                        />
                        </div>
                        <div class="screen-settings-column second-column">

                        </div>
                        <div class="screen-settings-column third-column">
                            <right-button v-for="(value, name) in right_settings"
                                            :name="name"
                                            :value="value"
                                            />
                        </div>
                        <div class="screen-settings-column fourth-column">
                            <button class="btn screen-settings-btn"><span>По умолчанию</span></button>
                            <button class="btn screen-settings-btn confirm-btn" @pointerdown="done = true">
                                <router-link :to="{name: 'WorkStation'}">ОК</router-link>
                            </button>
                            <button class="btn screen-settings-btn"><router-link :to="{name: 'WorkStation'}">Отмена</router-link></button>
                        </div>
                    </div>
            </div>
            <div class="stand">
                <comp-lamp class="light" :class="isLightOn"/>
                <input type="checkbox" class="stand__power-button" @input="switchOnComp(true)">
            </div>
        </div>
    </div>
</template>
<script>

    import {mapGetters} from 'vuex';

    import CompLamp from '@/components/AppLamp'
    import LeftButton from '@/components/settingsbuttons/LeftButton';
    import RightButton from '@/components/settingsbuttons/RightButton'


    export default {
        components: {
            CompLamp,
            LeftButton,
            RightButton
        },

       data() {
            return {
                left_settings: {
                    'Информационная панель': 'Включение',
                    'Сорт. по умолч. раб. списка пациентов': 'Имя пациента',
                    'Тип фильтра по умолч. раб. спис. пац-тов': '',
                    'Знач. фил-ра по умолч. раб. спис. пац-тов': '',
                    'Место введения': '',
                },
                right_settings: {
                    'Диаметр катетера': '',
                    'ID оператора': '',
                    'Название станции': '',
                    'Источник жидкости А': 'UV 370 100 ml',
                    'Источник жидкости В': 'Физиологич. раствор',
                },
            }
        },
        methods: {
            switchOnComp() {
                if (!this.workstation_is_on) {
                    this.$router.push({name: 'WorkStation'});
                    this.$store.commit('switchStateOfWorkstation', {
                        state: true,
                        confirm: true,
                    });
                }
            },
        },

        computed: {
            ...mapGetters([
                'workstation_is_on',
             ]),
            isLightOn() {
                return {
                    on: this.workstation_is_on,
                }
            }
        },

    }
</script>

<style scoped lang='scss'>

    @import "../scss/style";
    @import "../scss/pages/system-settings";

</style>