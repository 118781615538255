<template>
    <div class="container">
        <div class="computer">
            <div v-if="!workstation_is_on" key="off" class="screen-black"></div>
            <div v-else class="screen" key="enabled">
                <div class="manager-header">
                    <div>Менеджер протоколов</div>
                    <SequenceChunkWrapper
                            v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_press_Exit_button_again}"
                            sequence-id="setup_protocol_manager"
                            step-id="press_Exit_button_again"
                            key="press_Exit_button_again"
                    >
                        <button class="exit-manager-btn" @click="processChunkAction_setup_protocol_manager_press_Exit_button_again(
                                        () => $router.push({name: 'WorkStation'}), {enabledIfEducationIsInactive: true})"
                        >выход
                        </button>
                    </SequenceChunkWrapper>
                </div>
                <div class="manager-content">
                    <div class="area">
                        <div class="content-header area-header">
                            <restart @showModalReset="!isEducationModeEnabled && showModalRestart()"/>
                            <span>область</span>
                        </div>
                        <div class="column area-content">
                            <div class="column-top">
                                <template v-for="(part, index) in globalBody">
                                    <SequenceChunkWrapper
                                            v-if="part === 'голова'"
                                            sequence-id="setup_protocol_manager"
                                            step-id="select_required_area_Head"
                                            key="select_required_area_Head"
                                            v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_select_required_area_Head}"
                                    >
                                        <head-button
                                                :part="part"
                                                :index="index"
                                                @select-part="processChunkAction_setup_protocol_manager_select_required_area_Head(() => selectPart($event), {enabledIfEducationIsInactive: true})"
                                                :selected="selected === part"
                                                :isHidden="hidden_body[index]"
                                                :key="index"
                                        />
                                    </SequenceChunkWrapper>
                                    <SequenceChunkWrapper
                                            v-else-if="part === 'грудная клетка'"
                                            sequence-id="setup_protocol_manager"
                                            step-id="select_other_area"
                                            key="select_other_area"
                                            v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_select_other_area}"
                                    >
                                        <head-button
                                                :part="part"
                                                :index="index"
                                                @select-part="(e) => {
                                                    processChunkAction_setup_protocol_manager_select_other_area(() => selectPart(e), {enabledIfEducationIsInactive: true})
                                                }"
                                                :selected="selected === part"
                                                :isHidden="hidden_body[index]"
                                                :key="index"
                                        />
                                    </SequenceChunkWrapper>
                                    <SequenceChunkWrapper
                                            v-else-if="part === 'таз'"
                                            v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_to_hide_area}"
                                            sequence-id="setup_protocol_manager"
                                            step-id="to_hide_area"
                                            key="to_hide_area"
                                    >
                                        <head-button
                                                :part="part"
                                                :index="index"
                                                @select-part="e => {
                                                    const fn = () => selectPart(e)
                                                    processChunkAction_setup_protocol_manager_to_hide_area(fn, {enabledIfEducationIsInactive: true})
                                                }"
                                                :selected="selected === part"
                                                :isHidden="hidden_body[index]"
                                                :key="index"
                                        />
                                    </SequenceChunkWrapper>
                                    <SequenceChunkWrapper
                                            v-else-if="mirror_body[index] === 'chest'"
                                            v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_to_remove_protocol_select_area}"
                                            sequence-id="setup_protocol_manager"
                                            step-id="to_remove_protocol_select_area"
                                            key="to_remove_protocol_select_area"
                                    >
                                        <head-button
                                                :part="part"
                                                :index="index"
                                                @select-part="e => {
                                                    const fn = () => selectPart(e)
                                                    processChunkAction_setup_protocol_manager_to_remove_protocol_select_area(fn, {enabledIfEducationIsInactive: true})
                                                }"
                                                :selected="selected === part"
                                                :isHidden="hidden_body[index]"
                                                :key="index"
                                        />
                                    </SequenceChunkWrapper>
                                    <head-button
                                            v-else
                                            :part="part"
                                            :index="index"
                                            @select-part="selectPart($event)"
                                            :selected="selected === part"
                                            :isHidden="hiddenBody[index]"
                                    />
                                </template>
                                <img class="area-background" src="../img/man.svg">
                            </div>
                            <div class="column-footer" v-show="!moving_process">
                                <div class="config-btn-container">
                                    <button class="btn area-btn config-btn"
                                    ><span>Конфиг. 1</span></button>
                                    <button class="btn area-btn config-btn"
                                    ><span>Конфиг. 2</span></button>
                                </div>
                                <div class="config-btn-container">
                                    <SequenceChunkWrapper
                                            sequence-id="setup_protocol_manager"
                                            step-id="to_rename_area"
                                            key="to_rename_area"
                                            v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_to_rename_area}"
                                    >
                                        <button class="btn area-btn" @pointerdown="processChunkAction_setup_protocol_manager_to_rename_area(showKeyBoard, {enabledIfEducationIsInactive: true})"
                                        ><span>Переименовать</span></button>
                                    </SequenceChunkWrapper>
                                    <button v-if="isHidden" class="btn area-btn"
                                            @pointerdown="showButton"
                                            :class="activeAll"
                                    ><span>Показать</span></button>
                                    <SequenceChunkWrapper
                                            v-else
                                            v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_press_Hide}"
                                            sequence-id="setup_protocol_manager"
                                            step-id="press_Hide"
                                            key="press_Hide"
                                    >
                                        <button class="btn area-btn"
                                                @pointerdown="processChunkAction_setup_protocol_manager_press_Hide(hideButton, {enabledIfEducationIsInactive: true})"
                                                :class="activeAll"
                                        ><span>Скрыть</span></button>
                                    </SequenceChunkWrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="protocols">
                        <div class="content-header protocols-header">протоколы</div>
                        <div class="column protocols-content">
                            <div class="column-top">
                                <template v-for="(name, index) in getTargetArea">
                                    <SequenceChunkWrapper
                                            v-if="getNameOfProtocolCreatedWithTutorial === name"
                                            v-slot="{processChunkAction: ProcessChuckAction_setup_protocol_manager_choose_protocol}"
                                            sequence-id="setup_protocol_manager"
                                            step-id="choose_protocol"
                                            key="choose_protocol"
                                    >
                                        <SequenceChunkWrapper
                                                v-slot="{processChunkAction: processChunkAction_protocol_manager_select_required_protocol}"
                                                sequence-id="protocol_manager"
                                                step-id="select_required_protocol"
                                                key="select_required_protocol"
                                        >
                                            <SequenceChunkWrapper
                                                    sequence-id="setup_protocol_manager"
                                                    step-id="select_required_protocol_to_move_it_to_other_area"
                                                    key="select_required_protocol_to_move_it_to_other_area"
                                                    v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_select_required_protocol_to_move_it_to_other_area}"
                                            >
                                                <SequenceChunkWrapper
                                                        sequence-id="setup_protocol_manager"
                                                        step-id="to_change_order_of_protocol_press_on_required_protocol"
                                                        key="to_change_order_of_protocol_press_on_required_protocol"
                                                        v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_to_change_order_of_protocol_press_on_required_protocol}"
                                                >
                                                    <protocol-button
                                                            :protocol_name="name"
                                                            :part_name="selected"
                                                            :selected="protocol_name === name"
                                                            :index="index"
                                                            @show-saved-protocol="(e) => {
                                                        const fn = () => {
                                                            showSavedProtocol(e);
                                                        }
                                                        ProcessChuckAction_setup_protocol_manager_choose_protocol(fn, {enabledIfEducationIsInactive: true})
                                                        processChunkAction_protocol_manager_select_required_protocol(fn)
                                                        processChunkAction_setup_protocol_manager_select_required_protocol_to_move_it_to_other_area(fn)
                                                        processChunkAction_setup_protocol_manager_to_change_order_of_protocol_press_on_required_protocol(fn)
                                                    }"
                                                    />
                                                </SequenceChunkWrapper>
                                            </SequenceChunkWrapper>
                                        </SequenceChunkWrapper>
                                    </SequenceChunkWrapper>
                                        <protocol-button
                                            v-else
                                            :protocol_name="name"
                                            :part_name="selected"
                                            :selected="protocol_name === name"
                                            :index="index"
                                            @show-saved-protocol="showSavedProtocol($event)"
                                    />
                                </template>
                            </div>
                            <div class="column-footer">
                                <SequenceChunkWrapper
                                        sequence-id="setup_protocol_manager"
                                        step-id="select_Change_area"
                                        key="select_Change_area"
                                        v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_select_Change_area}"
                                >
                                    <button class="protocol-btn change-button"
                                            @pointerdown="processChunkAction_setup_protocol_manager_select_Change_area(startMoving, {enabledIfEducationIsInactive: true})"
                                            :class="moving"
                                    >
                                        Изменить область
                                    </button>
                                </SequenceChunkWrapper>
                                <div v-if="moving_process" class="moving">
                                    <p class="moving">Выбрать другую область для перемещения протокола</p>
                                </div>
                                <template v-else>
                                    <SequenceChunkWrapper
                                            sequence-id="setup_protocol_manager"
                                            step-id="press_Up_to_change_position_of_protocol"
                                            key="press_Up_to_change_position_of_protocol"
                                            v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_press_Up_to_change_position_of_protocol}"
                                    >
                                        <button class="protocol-btn"
                                                @pointerdown="processChunkAction_setup_protocol_manager_press_Up_to_change_position_of_protocol(protocolUp, {enabledIfEducationIsInactive: true})">
                                            Вверх
                                        </button>
                                    </SequenceChunkWrapper>
                                    <button class="protocol-btn"
                                            @pointerdown="!isEducationModeEnabled && protocolDown()">Вниз
                                    </button>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="preview">
                        <div class="content-header preview-header">предпросмотр</div>
                        <div class="column preview-content">
                            <div v-if="protocol_name === 'Test'" :class="backgroundTop" class="column-top">
                                <div class="column-header">{{protocol_name}}</div>
                            </div>
                            <div v-else :class="backgroundTop" class="column-top">
                                <div class="column-header">{{protocol_name}}</div>
                                <div class="scripts">
                                    <manager-row class="manager-script" v-for="(row, index) in current_protocol"
                                                 :row="row"
                                                 :index="index"/>
                                </div>
                                <template v-if="current_protocol">
                                    <div class="total-section">
                                        <div class="pressure flex-overall">
                                            <p>Предел давл. (psi)</p>
                                            <div class="pressure__value overall">{{current_pressure}}</div>
                                        </div>
                                        <div class="total-values flex-overall">
                                            <p>Всего</p>
                                            <div class="contrast overall">{{totalContrast}}</div>
                                            <div class="saline overall">{{totalSaline}}</div>
                                            <div class="duration overall">{{totalDuration}}</div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div :class="backgroundFooter" class="column-footer">
                                <button
                                        v-if="protocol_name === 'Protocol*'"
                                        class="btn preview-btn save-btn"
                                        :class="nonActive"
                                        @pointerdown="showKeyboard">
                                    сохранить в {{selected}}
                                </button>
                                <SequenceChunkWrapper
                                        v-else-if="protocol_name !== 'Protocol*' && protocol_name"
                                        v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_press_Remove_button}"
                                        sequence-id="setup_protocol_manager"
                                        step-id="press_Remove_button"
                                        key="press_Remove_button">
                                    <button
                                            class="btn preview-btn save-btn"
                                            @pointerdown="processChunkAction_setup_protocol_manager_press_Remove_button(showDeleteModal, {enabledIfEducationIsInactive: true})"
                                    >
                                        Удалить
                                    </button>
                                </SequenceChunkWrapper>
                            </div>
                        </div>
                        <div class="preview-footer-block-btn">
                            <button class="preview-footer-btn"><a
                                    @click="!isEducationModeEnabled && $router.push({name: 'WorkStation'})">Отменить</a>
                            </button>
                            <button class="preview-footer-btn"><a
                                    @click="!isEducationModeEnabled && $router.push({name: 'WorkStation'})">ОК</a>
                            </button>
                        </div>
                    </div>
                </div>
                <app-keyboard v-show="getShowKeyboard"/>
                <modal-cancel v-if="modalShow"
                              :text="modalText"
                              @confirm="modalConfirm"
                              @cancel="closeModal"

                />
            </div>

            <div class="stand">
                <comp-lamp class="light" :class="isLightOn"/>
                <input type="checkbox" class="stand__power-button" @input="switchOnComp(true)">
            </div>
        </div>
        <router-modal/>
      <InfoButton style="width: 13.5%; min-width: 259px" @open-confirm="openModalTerm"/>
      <TermDefinition v-if="modalEx" style="z-index: 99999" @close-confirm="closeModalTerm"/>
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import ManagerRow from '@/components/ManagerRow'
    import HeadButton from '@/components/HeadButton'
    import ProtocolButton from '@/components/ProtocolButton'
    import AppKeyboard from '@/components/ScreenKeyboard'
    import ChevronButton from '@/components/ChevronBtn'
    import ModalCancel from "../components/ResetProtocolModal";
    import Restart from "../components/Restart";
    import CompLamp from '@/components/AppLamp'
    import RouterModal from '@/components/modals/RouterModal'
    import TermDefinition from "@/components/TermDefinition.vue";
    import InfoButton from "@/components/InfoButton.vue";


    export default {
        components: {
          InfoButton, TermDefinition,
            Restart,
            ModalCancel,
            HeadButton,
            ProtocolButton,
            ManagerRow,
            AppKeyboard,
            ChevronButton,
            CompLamp,
            RouterModal
        },

        data() {
            return {
                local_body: [
                    'голова',
                    'шея',
                    'грудная клетка',
                    'брюшная полость',
                    'таз',
                    'конечности',
                ],
                local_hidden_body: [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ],
              modalEx:false,
            }
        },

        methods: {
            selectPart(body) {
                this.$store.dispatch('station/ManagerSettings/changeSelected', body.part);
                this.$store.dispatch('station/ManagerSettings/changeMirror_selected', this.mirror_body[body.index]);
                this.$store.dispatch('station/ManagerSettings/changeIsHidden', body.isHidden);
                if (this.moving_process) {
                    this.$store.dispatch('station/ManagerSettings/changemodalShowVisibility', true);
                    this.$store.dispatch('station/ManagerSettings/changeModalText', `Вы действительно хотите переместить протокол "${this.protocol_name}" из ${this.from.toUpperCase()} в ${this.selected.toUpperCase()}?`);
                }
                this.$store.dispatch('setCurrentBodyIndex', body.index);
                this.$store.dispatch('setTargetArea', this.mirror_body[body.index]);

            },
            setCurrentProtocol() {
                this.$store.dispatch('station/ManagerSettings/changeCurrent_protocol', this.getRows)
                this.$store.dispatch('station/ManagerSettings/changeProtocol_name', 'Protocol*')
                this.$store.dispatch('station/ManagerSettings/changeCurrent_pressure', parseInt(this.getPressureLimit))
            },
            showKeyboard() {
                this.$store.dispatch('showKeyboard', true);
            },
            showSavedProtocol(item) {
                this.$store.dispatch('station/ManagerSettings/changeCurrent_protocol_index', item.index)
                if (item.name === 'Test') {
                    this.$store.dispatch('station/ManagerSettings/changeProtocol_name', 'Test');
                } else {
                    this.$store.dispatch('station/ManagerSettings/changeProtocol_name', item.name);
                    this.$store.dispatch('station/ManagerSettings/changeCurrent_protocol', Object.assign([], this.$store.getters[this.mirror_body[this.body.indexOf(item.part_name)]][item.name]))
                    this.$store.dispatch('station/ManagerSettings/changeCurrent_pressure', parseInt(this.getManagerPressure[item.name + this.mirror_body[this.body.indexOf(item.part_name)]]))
                }


            },
            setGlobalProtocol() {
                this.$store.dispatch('setGlobalProtocol', {
                    phases: this.current_protocol,
                    pressure: this.current_pressure,
                });
            },
            hideButton() {
                if (this.selected !== '...') {
                    this.$store.dispatch('hideButton');
                }

            },
            showButton() {
                if (this.selected !== '...') {
                    this.$store.dispatch('showButton');
                }

            },
            RZTShow() {
                this.showAll = false;
                this.showRZT = true;
                this.$store.dispatch('station/ManagerSettings/changeSelected', '...');
                this.$store.dispatch('station/ManagerSettings/changeMirror_selected', 'RZT');
            },
            showDeleteModal() {
                this.$store.dispatch('station/ManagerSettings/changemodalShowVisibility', true);
                this.$store.dispatch('station/ManagerSettings/changeModalText', `Вы хотите удалить протокол "${this.protocol_name}"?`);
            },
            modalConfirm() {

                if (this.moving_process) {
                    this.$store.dispatch('moveProtocol', {
                        from: this.mirror_body[this.body.indexOf(this.from)],
                        to: this.mirror_selected,
                        name: this.protocol_name,
                        index: this.current_protocol_index,
                    })
                    this.$store.dispatch('station/ManagerSettings/changeMoving_process', false);
                } else if (this.default) {
                    this.$store.dispatch('setDefaultBody', {
                        body: this.local_body,
                        hidden: this.local_hidden_body,
                    })
                    this.$store.dispatch('station/ManagerSettings/changeDefault', false);
                } else {
                    this.$store.dispatch('removeProtocol', {
                        areaName: this.mirror_selected,
                        protocolName: this.protocol_name,
                        index: this.current_protocol_index,
                    });
                    this.$store.dispatch('station/ManagerSettings/changeProtocol_name', '')

                }
                this.$store.dispatch('station/ManagerSettings/changemodalShowVisibility', false);
            },
            protocolUp() {
                this.$store.dispatch('protocolUp', {
                    areaName: this.mirror_selected,
                    index: this.current_protocol_index,
                })
            },
            protocolDown() {
                this.$store.dispatch('protocolDown', {
                    areaName: this.mirror_selected,
                    index: this.current_protocol_index,
                })
            },
            startMoving() {
                if (!this.protocol_name) {
                    return;
                } else {
                    this.$store.dispatch('station/ManagerSettings/changeMoving_process', !this.moving_process);
                    this.$store.dispatch('station/ManagerSettings/changeFrom', this.selected);
                }
            },
            showKeyBoard() {
                if (this.selected !== '...') {
                    this.$store.dispatch('showKeyboardManagerSettings', {
                        isVisible: true,
                        type: 'keyBoardManagerSettings',
                    });
                }
            },
            showModalRestart() {
                this.$store.dispatch('station/ManagerSettings/changemodalShowVisibility', true);
                this.$store.dispatch('station/ManagerSettings/changeDefault', true);
                this.$store.dispatch('station/ManagerSettings/changeModalText', 'Восстановить найстройки области по умолчанию?');
            },
            closeModal() {
                this.$store.dispatch('station/ManagerSettings/changemodalShowVisibility', false);
                this.$store.dispatch('station/ManagerSettings/changeMoving_process', false);
                this.$store.dispatch('station/ManagerSettings/changeDefault', false);
            },
            switchOnComp() {
                if (!this.workstation_is_on) {
                    this.$router.push({name: 'WorkStation'});
                    this.$store.commit('switchStateOfWorkstation', {
                        state: true,
                        confirm: true,
                    });
                }
            },
          openModalTerm() {
            this.modalEx = true;
          },
          closeModalTerm() {
            this.modalEx = false;
          },
        },
        computed: {
            ...mapGetters([
                'getRows',
                'getShowKeyboard',
                'getPressureLimit',
                'getManagerPressure',
                'getAllSavedProtocols',
                'body',
                'mirror_body',
                'hidden_body',
                'current_body_index',
                'workstation_is_on',
                'getNameOfProtocolCreatedWithTutorial'
            ]),
            ...mapGetters('station/ManagerSettings', {
                modalShow: 'modalShow',
                moving_process: 'moving_process',
                default: 'default',
                selected: 'selected',
                mirror_selected: 'mirror_selected',
                protocol_name: 'protocol_name',
                current_protocol: 'current_protocol',
                current_pressure: 'current_pressure',
                current_protocol_index: 'current_protocol_index',
                modalText: 'modalText',
                from: 'from',
                isHidden: 'isHidden',
            }),
            globalBody() {
                if (this.selected !== '...') {
                    this.$store.dispatch('station/ManagerSettings/changeSelected', this.body[this.current_body_index]);
                }
                return this.body;
            },
            hiddenBody() {
                if (this.selected !== '...') {
                    this.$store.dispatch('station/ManagerSettings/changeIsHidden', this.hidden_body[this.current_body_index])
                }
                return this.hidden_body;
            },
            getTargetArea() {
                if (this.showAll) {
                    return this.getAllSavedProtocols;
                } else {
                    if (this.protocol_name && !this.moving_process) {
                        this.$store.dispatch('station/ManagerSettings/changeCurrent_protocol_index', this.$store.getters[this.mirror_selected + 'Buttons'].indexOf(this.protocol_name))
                    }
                    return this.$store.getters[this.mirror_selected + 'Buttons'];
                }

            },
            protocolButton() {
                if (this.protocol_name === 'Protocol*') {
                    return {
                        'content-header': true,
                    }
                } else {
                    return {
                        'inactive-protocol': true,
                    }
                }
            },
            totalContrast() {
                let sum = 0;
                for (let volume of this.current_protocol) {
                    if (volume.type == 'A') {
                        sum += +volume.volume;
                    } else if (volume.type == 'dualFlow') {
                        sum += volume.volume * volume.contrast / 100;
                    }
                }

                return sum;
            },
            totalDuration() {
                let sum = '00:00';
                let total = 0;
                for (let phase of this.current_protocol) {
                    let min;
                    let sec;
                    let time;
                    if (phase.duration) {
                        min = phase.duration.slice(0, 2);
                        sec = phase.duration.slice(3);

                        if (min || sec) {
                            time = +min * 60 + +sec;
                        }

                        total += time;
                    }
                }

                if (total) {

                    let mins = Math.trunc(total / 60);
                    let secs = total % 60;
                    if (mins.toString().length === 1) {
                        mins = '0' + mins;
                    }

                    if (secs.toString().length === 1) {
                        secs = '0' + secs;
                    }

                    sum = `${mins}:${secs}`
                    return sum;
                } else {
                    return sum;
                }
            },
            totalSaline() {
                let sum = 0;
                for (let volume of this.current_protocol) {
                    if (volume.type == 'B') {
                        sum += +volume.volume;
                    } else if (volume.type == 'dualFlow') {
                        sum += volume.volume * volume.contrast / 100;
                    }
                }

                return sum;
            },
            backgroundTop() {
                return {
                    'active-protocol-top': !!this.protocol_name,
                }
            },
            backgroundFooter() {
                return {
                    'active-protocol-footer': !!this.protocol_name,
                }
            },
            nonActive() {
                if (this.selected !== '...') {
                    return {
                        'non-active': false,
                    }
                } else {
                    return {
                        'non-active': true,
                    }
                }

            },
            activeAll() {
                return {
                    'all-active': this.showAll,
                }
            },
            selectedButton() {
                return {
                    selected: this.showRZT && this.selected === '...' && !this.showAll,
                };
            },
            selectedRTZ() {
                return {
                    selected: this.protocol_name === 'RZT',
                }
            },
            moving() {
                return {
                    selected: this.moving_process,
                }
            },
            isLightOn() {
                return {
                    on: this.workstation_is_on
                }
            }
        },
        beforeDestroy() {
            this.$store.dispatch('station/ManagerSettings/setDefaultModule')
        },
    }
</script>

<style scoped lang="scss">

    @import "../scss/pages/manager-settings";

</style>