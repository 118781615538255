<template>
    <div id="resetProtocol" class="reset-protocol">
        <div class="reset-protocol-content">
            <span class="reset-protocol-header">{{text}}</span>
            <SequenceChunkWrapper
                    v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_confirm_your_choice_again, isActive: isActive_setup_protocol_manager_confirm_your_choice_again}"
                    sequence-id="setup_protocol_manager"
                    step-id="confirm_your_choice_again"
                    key="confirm_your_choice_again"
            >
                <SequenceChunkWrapper
                        v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_confirm_your_choice, isActive: isActive_setup_protocol_manager_confirm_your_choice}"
                        sequence-id="setup_protocol_manager"
                        step-id="setup_pm_confirm_your_choice"
                        key="setup_pm_confirm_your_choice">
                    <SequenceChunkWrapper
                            sequence-id="protocol_manager"
                            step-id="confirm_your_choice"
                            key="confirm_your_choice"
                            v-slot="{processChunkAction: processChunkAction_protocol_manager_confirm_your_choice, isActive: isActive_protocol_manager_confirm_your_choice}"
                    >
                        <div class="reset-protocol-block-button">
                            <button class="reset-protocol-button" @pointerdown="
                                                        () => {
                                                            const fn = () => confirm() || (link && $router.push({name: 'WorkStation'}))
                                                            processChunkAction_setup_protocol_manager_confirm_your_choice_again(fn, {enabledIfEducationIsInactive: true})
                                                            processChunkAction_setup_protocol_manager_confirm_your_choice(fn)
                                                            processChunkAction_protocol_manager_confirm_your_choice(fn)
                                                        }"
                            >
                                <span>да</span>
                            </button>
                            <button
                                    class="reset-protocol-button"
                                    @pointerdown="() => {
                                        if (!isEducationModeEnabled)
                                            cancel()
                                    }"
                            >
                                <span>нет</span>
                            </button>
                        </div>
                    </SequenceChunkWrapper>
                </SequenceChunkWrapper>
            </SequenceChunkWrapper>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ResetProtocolModal",
        props: {
            text: String,
            link: Boolean,
        },
        methods: {
            confirm() {
                this.$emit('confirm');
                if (this.text === 'Сбросить протокол?') {
                     this.$store.dispatch('resetProtocol');
                     this.$store.commit('changeStatusProtocolLock', false);
                }

            },
            cancel() {
                this.$emit('cancel');
            },
        }
    }
</script>

<style scoped lang="scss">

    @import "../scss/components/reset-protocol-modal-window";

</style>