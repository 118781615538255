var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"control"},[_c('control-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.getTestA),expression:"getTestA"}],staticClass:"green border",attrs:{"value":_vm.buttons.testA},on:{"action":_vm.contrast}}),_c('SequenceChunkWrapper',{key:"test_introduction_B",attrs:{"sequence-id":"protocol_creation","step-id":"test_introduction_B"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var test_introduction_B = ref.processChunkAction;
return [_c('control-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.getTestB),expression:"getTestB"}],staticClass:"blue border",attrs:{"value":_vm.buttons.testB},on:{"action":function () {
            var fn = function () {
                _vm.trial();
                _vm.$store.commit('stateControl', false);
                _vm.$store.dispatch('showRow', false);
                _vm.$store.dispatch('clearHighlightedRow')
            };
            test_introduction_B(fn, {enabledIfEducationIsInactive: true})
            }}})]}}])}),_c('control-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.getContrastShow),expression:"getContrastShow"}],staticClass:"green border",attrs:{"value":_vm.buttons.contrast},on:{"action":_vm.contrast}}),_c('SequenceChunkWrapper',{key:"choose_type_phase_Flushing_B",attrs:{"sequence-id":"protocol_creation","step-id":"choose_type_phase_Flushing_B"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var processChunkAction = ref.processChunkAction;
return [_c('control-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.getSalineShow),expression:"getSalineShow"}],staticClass:"blue border",attrs:{"value":_vm.buttons.flushing},on:{"action":function () {
            var fn = function () {
                _vm.saline();
                _vm.$store.commit('stateControl', false);
                _vm.$store.dispatch('showRow', false);
                _vm.$store.dispatch('clearHighlightedRow')
            };
            processChunkAction(fn, {enabledIfEducationIsInactive: true})
        }}})]}}])}),_c('SequenceChunkWrapper',{key:"choose_type_of_phase_AB",attrs:{"sequence-id":"protocol_creation","step-id":"choose_type_of_phase_AB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var processChunkAction = ref.processChunkAction;
return [_c('control-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.getDualShow),expression:"getDualShow"}],staticClass:"gradient border",attrs:{"value":_vm.buttons.percentage},on:{"action":function () {
            var fn = function () {
                _vm.dualflow();
                _vm.$store.commit('stateControl', false);
                _vm.$store.dispatch('showRow', false);
                _vm.$store.dispatch('clearHighlightedRow')
            };
            processChunkAction(fn, {enabledIfEducationIsInactive: true})}}})]}}])}),_c('control-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.getShowAwaiting),expression:"getShowAwaiting"}],staticClass:"dark-gray border",attrs:{"value":_vm.buttons.awaiting},on:{"action":_vm.awaiting}}),_c('SequenceChunkWrapper',{attrs:{"sequence-id":"protocol_creation","step-id":"select_phase_type_Pause"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var processChunkAction = ref.processChunkAction;
return [_c('control-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.getShowPause),expression:"getShowPause"}],staticClass:"dark-gray border",attrs:{"value":_vm.buttons.pause},on:{"action":function () {
            var fn = function () {
                _vm.pause();
                _vm.$store.commit('stateControl', false);
                _vm.$store.dispatch('showRow', false);
                _vm.$store.dispatch('clearHighlightedRow')
            };
            processChunkAction(fn, {enabledIfEducationIsInactive: true})
        }}})]}}])}),_c('SequenceChunkWrapper',{key:"choose_Delete",attrs:{"sequence-id":"protocol_creation","step-id":"choose_Delete"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var processChunkAction = ref.processChunkAction;
return [_c('control-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.getShowRemove),expression:"getShowRemove"}],key:"fasdf",staticClass:"gray border",attrs:{"value":_vm.buttons.remove},on:{"action":function () {
                    var fn = function () {
                        _vm.remove();
                        _vm.$store.commit('stateControl', false);
                        _vm.$store.dispatch('showRow', false);
                        _vm.$store.dispatch('clearHighlightedRow')
                    };
                    processChunkAction(fn, {enabledIfEducationIsInactive: true})
                }}})]}}])}),_c('control-btn',{staticClass:"gray border",attrs:{"value":_vm.buttons.cancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }