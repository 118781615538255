import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import settings from './modules/settings';
import mainScreen from './modules/main-screen'
import injector from './modules/injector'
import _sequences from '@/store/modules/_sequences'
import sequences from '@/store/modules/sequences'
import station from '@/store/modules/station/station'
import { cloneDeep } from 'lodash'

const initialState = {}

export const store = new Vuex.Store({
    plugins: [store => {
        const {sequences_store: aaa, ...restState} = store.state

        Object.assign(initialState, cloneDeep(restState))

    }],
    modules: {
        mainScreen,
        settings,
        injector,
        _sequences,
        station,
        sequences_store: sequences,
    },

    state: {
        workstation_is_on: false,
        showConfirmation: false,
        testPressed: false,
        showControl: false,
        editable_row: '',
        highlighted_row: null,
        selected_row_button: null,
        reminders_row: null,
        target_element: '',
        contrast: 50,
        previous_row: '',
        previous_row_duration: '',
        protocol_locked: false,
        current_phase: false,

        rows: [
            {
                flowRate: '1,0',
                volume: 10,
                type: 'A',
                duration: '00:10'
            },
            {
                type: 'Question',
            },
        ],
        changed_rows: [],
        reminders: [
            {
                time: '--',
                name: '--',
                show:false,
            },
            {
                time: '--',
                name: '--',
                show:false,
            },
            {
                time: '--',
                name: '--',
                show:false,
            },
            {
                time: '--',
                name: '--',
                show:false,
            },
            {
                time: '--',
                name: '--',
                show:false,
            },
        ],
        reminders_list: [],
        head: {
            Test: 'Test',
        },
        neck: {

        },

        chest: {

        },

        abdomen: {

        },

        pelvis: {

        },

        limbs: {

        },
        headButtons: [
            'Test',
        ],
        neckButtons: [

        ],

        chestButtons: [

        ],

        abdomenButtons: [

        ],

        pelvisButtons: [

        ],

        limbsButtons: [

        ],
        protocol_names: [
            'Test',
        ],
        RZTButtons: [
            'Test',
        ],
        body: [
            'голова',
            'шея',
            'грудная клетка',
            'брюшная полость',
            'таз',
            'конечности',
        ],
        mirror_body: [
            'head',
            'neck',
            'chest',
            'abdomen',
            'pelvis',
            'limbs'
        ],
        hidden_body: [
            false,
            false,
            false,
            false,
            false,
            false,
        ],
        protocol_names_addition: ' ',
        showCalc: false,
        range: '',
        relatedValue: '',
        input: 0,
        input_reminder: 0,
        input_reminderCancel: 0,
        showRow: false,
        showDual: false,
        previous_value: '',
        showKeyboard: false,
        reminder_name: "_",
        manager_name: "_",
        manager_settings_name: "_",
        keyboardType: '',
        pressure_limit: '325psi',
        reminderAmount: '',
        disableCalcEvents: false,
        target_area: '',
        manager_pressure: {},
        current_body_index: '',
        name_of_protocol_created_with_tutorial: undefined,
    },

    getters: {
        getRemindersRow(state) {
            return state.reminders_row;
        },
        getSelectedRowButton(state) {
            return state.selected_row_button;
        },
        current_phase(state) {
            return state.current_phase;
        },
        workstation_is_on(state) {
            return state.workstation_is_on;
        },
        showConfirmation(state) {
            return state.showConfirmation;
        },
        getRows(state) {
            return state.rows;
        },
        getChangedRows(state, getters, rootState, rootGetters) {

            state.changed_rows = JSON.parse(JSON.stringify(state.rows));

            let differenceA = getters.totalA - rootGetters['injector/fluids'].a;
            let differenceB = getters.totalB - rootGetters['injector/fluids'].b;
            if (differenceA > 0) {
                for (let i = state.changed_rows.length - 1; i >= 0 && differenceA > 0; i--) {
                    if (state.changed_rows[i].type === 'A') {
                        let local_volume = state.changed_rows[i].volume;
                        state.changed_rows[i].volume = state.changed_rows[i].volume - differenceA;
                        let duration = Math.round(state.changed_rows[i].volume / state.changed_rows[i].flowRate.replace(/,/, "."));
                        if (duration < 60) {

                            if (duration < 10) {
                                state.changed_rows[i].duration = `00:0${duration}`
                            } else {
                                state.changed_rows[i].duration = `00:${duration}`
                            }
                        } else {
                            let min = Math.trunc(duration / 60);
                            let sec = duration % 60;

                            if (min < 10) {
                                min = '0' + min;
                            }
                            if (sec < 10) {
                                sec = '0' + sec;
                            }

                            state.changed_rows[i].duration = `${min}:${sec}`;
                        }
                        differenceA -= local_volume;
                        if (state.changed_rows[i].volume <= 0) {
                            state.changed_rows.splice(i, 1);
                        }
                    }
                }
            }
            if (differenceB > 0) {
                for (let i = state.changed_rows.length - 1; i >= 0 && differenceB > 0; i--) {
                    if (state.changed_rows[i].type === 'B') {
                        let local_volume = state.changed_rows[i].volume;
                        state.changed_rows[i].volume = state.changed_rows[i].volume - differenceB;
                        let duration = Math.round(state.changed_rows[i].volume / state.changed_rows[i].flowRate.replace(/,/, "."));
                        if (duration < 60) {

                            if (duration < 10) {
                                state.changed_rows[i].duration = `00:0${duration}`
                            } else {
                                state.changed_rows[i].duration = `00:${duration}`
                            }
                        } else {
                            let min = Math.trunc(duration / 60);
                            let sec = duration % 60;

                            if (min < 10) {
                                min = '0' + min;
                            }
                            if (sec < 10) {
                                sec = '0' + sec;
                            }

                            state.changed_rows[i].duration = `${min}:${sec}`;
                        }
                        differenceB -= local_volume
                        if (state.changed_rows[i].volume <= 0) {
                            state.changed_rows.splice(i, 1);
                        }
                    }
                }
            }

            return state.changed_rows;
        },
        getManagerPressure(state) {
            return state.manager_pressure;
        },
        getReminders(state) {
            return state.reminders;
        },
        getEditableRow(state) {
            return state.editable_row
        },
        getHighlightedRow(state) {
            return state.highlighted_row
        },
        testPressed(state) {
            return state.testPressed;
        },
        showControl(state) {
            return state.showControl;
        },
        showCalc(state) {
            return state.showCalc;
        },
        totalA(state) {
            let sum = 0;
            for (let volume of state.rows) {
                if (volume.type == 'A') {
                    sum += +volume.volume;
                }

                else if (volume.type == 'dualFlow') {
                    sum += volume.volume * volume.contrast / 100;
                }
            }


            return Math.round(sum);
        },
        totalB(state) {
            let sum = 0;
            for (let volume of state.rows) {
                if (volume.type == 'B') {
                    sum += +volume.volume;
                }
                else if (volume.type == 'dualFlow') {
                    sum += volume.volume * volume.saline / 100;
                }
            }

            return Math.round(sum);
        },
        getRange(state) {
            return state.range;
        },

        getTarget(state) {
            return state.target_element;
        },
        getRelatedValue(state) {
            return state.relatedValue;
        },

        getSaline(state) {
            return 100 - state.contrast;
        },

        getContrast(state) {
            return state.contrast;
        },
        getShowDual(state) {
            return state.showDual;
        },
        getShowAwaiting(state) {
            if (state.editable_row == 0 || state.testPressed && state.editable_row == 2) {
                return false;
            } else {
                return true;
            }
        },
        getShowRemove(state) {
             if (state.editable_row == 0 && !state.testPressed && state.rows.length <= 2 || state.testPressed && state.editable_row == 2 && state.rows.length <=4 || state.rows[state.editable_row].type == 'Question') {
                return false;
            } else {
                return true;
            }
        },

        getTestA(state) {
            if (state.testPressed && state.editable_row == 0) {
                return true;
            } else {
                return false;
            }
        },

        getTestB(state) {
            if (state.editable_row == 0) {
                return true;
            } else {
                return false;
            }
        },
        getDualShow(state) {
          if (state.testPressed && state.editable_row == 0) {
              return false;
          } else {
              return true;
          }
        },
        getSalineShow(state) {
             if (state.testPressed && state.editable_row == 0) {
              return false;
          } else {
              return true;
          }
        },
        getContrastShow(state) {
             if (state.testPressed && state.editable_row == 0) {
              return false;
          } else {
              return true;
          }
        },
        getShowPause(state) {
            if (state.testPressed && state.editable_row == 0) {
                return false;
            } else {
                return true;
            }
        },
        getReminderInput(state) {
            return state.input_reminder;
        },
        getReminderCancel(state) {
            return state.input_reminderCancel
        },
        getShowKeyboard(state) {
            return state.showKeyboard;
        },
        getReminderName(state) {
            return state.reminder_name;
        },
        getManagerName(state) {
            return state.manager_name
        },
        getManagerSettingsName(state) {
            return state.manager_settings_name;
        },
        getKeyboardType(state) {
            return state.keyboardType;
        },
        getPressureLimit(state) {
            return state.pressure_limit;
        },
        getReminderAmount(state) {
            let amount = state.reminders.filter((item) => item.time !== '--').length;
            return amount ? amount : '';
        },
        getCalcEvents(state) {
            return state.disableCalcEvents;
        },
        getTargetArea(state) {
            return state[state.target_area];
        },
        getAllSavedProtocols(state) {
            return state.protocol_names;
        },
        head(state) {
            return state.head;
        },
        neck(state) {
            return state.neck;
        },
        chest(state) {
            return state.chest;
        },
        abdomen(state) {
            return state.abdomen;
        },
        pelvis(state) {
            return state.pelvis;
        },
        limbs(state) {
            return state.limbs;
        },
        headButtons(state) {
            return state.headButtons;
        },
        neckButtons(state) {
            return state.neckButtons;
        },
        chestButtons(state) {
            return state.chestButtons;
        },
        abdomenButtons(state) {
            return state.abdomenButtons;
        },
        pelvisButtons(state) {
            return state.pelvisButtons;
        },
        limbsButtons(state) {
            return state.limbsButtons;
        },
        RZTButtons(state) {
            return state.RZTButtons;
        },
        body(state) {
            return state.body;
        },
        mirror_body(state) {
            return state.mirror_body;
        },
        hidden_body(state) {
            return state.hidden_body
        },
        current_body_index(state) {
            return state.current_body_index;
        },
        protocol_locked(state) {
            return state.protocol_locked;
        },
        reminders_list(state) {
            return state.reminders_list;
        },
        
        getNameOfProtocolCreatedWithTutorial: state => state.name_of_protocol_created_with_tutorial
    },

    mutations: {
        setEditableRow(state, row) {

            if (row.index !== undefined) {
                state.editable_row = row.index;
            }
            state.target_element = row.target;
        },

        changeTest(state) {

            if (state.rows.length == 6 && state.rows[state.rows.length-1].type != 'Question') {
                state.testPressed = false;
            } else {
                state.testPressed = true;
            }
        },
        stateControl(state, payload) {
            state.showControl = payload;
        },
        showCalc(state, boolean) {
            state.showCalc = boolean;
        },
        showDual(state) {
            state.showDual = false;
        },

        setRange(state, range) {
            state.range = range;
        },

        validator(state, number) {

            if (state.target_element.className.includes('volume')) {
                if (number === '0') {
                    state.relatedValue = '';
                    return;
                }

                else if (number <= 200) {
                    state.rows[state.editable_row].volume = number;

                    state.relatedValue = number;
                }

                else {
                    let spliced = number.split('');
                    spliced.splice(-1, 1);
                    state.relatedValue = spliced.join('');
                }

            } else {
                let pattern = /,[^,]*,/;
                let test = 0;

                if (number !== 0) {
                    test = number.replace(/,/, ".");
                }



                if (number == ',' || number.length > 3 || pattern.test(number) || (number[0] == '0' && number[1] != ',' && number.length >= 2)) {
                    let spliced = number.split('');
                    spliced.splice(-1, 1);
                    state.relatedValue = spliced.join('');
                }


                else if (test <= 10) {

                    state.rows[state.editable_row].flowRate = number;

                    state.relatedValue = number;
                }

            }
        },

        setCurrentPhase(state, phase) {
            state.current_phase = phase;
        },

        validateDual(state, number) {
            if (number === '0') {
                    state.relatedValue = '';
                    return;
            }

            else if (number <= 95 && number % 5 == 0) {
                state.contrast = number;
                state.rows[state.editable_row].contrast = number;
                state.rows[state.editable_row].saline = 100 - number;

                state.relatedValue = number;
            }

            else if (number.toString().length == 1) {
                state.contrast = number;
                state.rows[state.editable_row].contrast = number;
                state.rows[state.editable_row].saline = 100 - number;

                state.relatedValue = number;
            }

            else {
                let spliced = number.split('');
                spliced.splice(-1, 1);
                state.relatedValue = spliced.join('');
            }
        },
        validatePause(state, number) {

            let min;
            let sec;

            if (number.toString().length === 1) {
                min = '00';
                sec = `0${number}`;

            } else if (number.toString().length === 2) {
                min = '00';
                sec = number;

            } else if (number.toString().length === 3) {
                min = `0${number.toString().slice(0,1)}`;
                sec = number.toString().slice(1);

            } else if (number.toString().length === 4) {
                min = number.toString().slice(0, 2);
                sec = number.toString().slice(2);
            } else if (number.toString().length > 4) {
                let spliced = number.split('');
                spliced.splice(-1, 1);
                number = spliced.join('');
                min = number.toString().slice(0, 2);
                sec = number.toString().slice(2);
            }

            if (!number) {
                state.rows[state.editable_row].duration = '00:00'
            } else {
                state.rows[state.editable_row].duration = `${min}:${sec}`;
                state.relatedValue = number;
            }
            state.relatedValue = number;

        },
        validateReminder(state, number) {
            let min;
            let sec;

            if (number.toString().length === 1) {
                min = '00';
                sec = `0${number}`;

            } else if (number.toString().length === 2) {
                min = '00';
                sec = number;

            } else if (number.toString().length === 3) {
                min = `0${number.toString().slice(0,1)}`;
                sec = number.toString().slice(1);

            } else if (number.toString().length === 4) {
                min = number.toString().slice(0, 2);
                sec = number.toString().slice(2);
            } else if (number.toString().length > 4) {
                let spliced = number.split('');
                spliced.splice(-1, 1);
                number = spliced.join('');
                min = number.toString().slice(0, 2);
                sec = number.toString().slice(2);
            }

            if (!number) {
                state.reminders[state.editable_row].time = '00:00'
            } else {
                state.reminders[state.editable_row].time = `${min}:${sec}`;
                state.relatedValue = number;
            }
            state.relatedValue = number;
        },

        calcDuration(state) {

            if (state.relatedValue == 0) {

                if (state.target_element.className.includes('volume')) {
                    state.rows[state.editable_row].volume = state.previous_value;
                } else {
                    state.rows[state.editable_row].flowRate = state.previous_value;
                }
            } else if (state.rows[state.editable_row].flowRate % 1 == 0) {
                state.rows[state.editable_row].flowRate = state.rows[state.editable_row].flowRate + ',0'
            }

            if(state.rows[state.editable_row].flowRate == '0,0' || state.rows[state.editable_row].volume == 0) {
                state.rows[state.editable_row].duration = '00:00';
            } else {
                let duration = Math.round(state.rows[state.editable_row].volume / state.rows[state.editable_row].flowRate.replace(/,/, "."));

                if (duration < 60) {

                    if (duration < 10) {
                        state.rows[state.editable_row].duration = `00:0${duration}`
                    } else {
                        state.rows[state.editable_row].duration = `00:${duration}`
                    }
                } else {
                    let min = Math.trunc(duration / 60);
                    let sec = duration % 60;

                    if (min < 10) {
                        min = '0' + min;
                    }
                    if (sec < 10) {
                        sec = '0' + sec;
                    }

                    state.rows[state.editable_row].duration = `${min}:${sec}`;
                }
            }

        },

        inputCancel(state) {
            if (state.target_element.className.includes('volume')) {
                state.rows[state.editable_row].volume = state.previous_value;

            } else {
                state.rows[state.editable_row].flowRate = state.previous_value;
            }
        },
        inputDualCancel(state) {

            if (state.previous_row.type != 'Question') {
                state.rows.splice(state.editable_row, 1, state.previous_row);
            } else {
                state.rows.splice(state.editable_row, 1);
            }

        },
        inputTimeCancel(state) {
            state.rows[state.editable_row].duration = state.previous_value;
        },
        inputReminderCancel(state) {
            state.reminders[state.editable_row].time = state.previous_value;
            state.relatedValue = '';
        },
        inputTime(state, number) {
            let min;
            let sec;

            if (number.toString().length === 1) {
                min = '00';
                sec = `0${number}`;

            } else if (number.toString().length === 2) {
                min = '00';
                sec = number;

            } else if (number.toString().length === 3) {
                min = `0${number.toString().slice(0,1)}`;
                sec = number.toString().slice(1);

            } else if (number.toString().length === 4) {
                min = number.toString().slice(0, 2);
                sec = number.toString().slice(2);
            } else if (number.toString().length > 4) {
                let spliced = number.split('');
                spliced.splice(-1, 1);
                number = spliced.join('');
                min = number.toString().slice(0, 2);
                sec = number.toString().slice(2);
            }

            if (!number) {
                state.rows[state.editable_row].duration = '00:00'
            } else {
                state.rows[state.editable_row].duration = `${min}:${sec}`;
            }

            state.rows[state.editable_row].input = true;
        },

        saveRow(state) {
            state.previous_row = state.rows[state.editable_row];
        },

        inputCount(state) {
            state.input++;
        },

        reminderCount(state) {
            state.input_reminder++;
        },
        reminderCountCancel(state) {
            state.input_reminderCancel++;
        },

        showRow(state, boolean) {
            state.showRow = boolean;
        },
        setRow(state, type) {

            if (type =='trial' && state.rows[1].type == 'Test') {
                state.rows.splice(state.editable_row, 1, {
                    flowRate: '4,0',
                    volume: 20,
                    type: 'B',
                    duration: '00:05'
                });
            } else if (type == 'trial' && state.rows.length <= 4) {
                state.rows.unshift({
                    type: 'Test',
                });

                state.rows.unshift({
                    flowRate: '4,0',
                    volume: 20,
                    type:'B',
                    duration: '00:05'
                });
            } else if (type == 'trial' && state.rows.length == 5 && state.rows[state.rows.length - 1].type == 'Question') {
                state.rows.pop();

                state.rows.unshift({
                    type: 'Test',
                });

                state.rows.unshift({
                    flowRate: '4,0',
                    volume: 20,
                    type:'B',
                    duration: '00:05'
                });

            } else if(type == 'trial' && state.rows.length == 6 && state.rows[state.rows.length - 1].type == 'Question') {
                state.rows.pop();

                state.rows.splice(state.editable_row, 1);
                state.rows.unshift({
                    type: 'Test',
                });

                state.rows.unshift({
                    flowRate: '4,0',
                    volume: 20,
                    type:'B',
                    duration: '00:05'
                });

            } else {
                if (type == 'dualFlowRow') {
                    state.rows.splice(state.editable_row, 1, {
                        flowRate: '0,0',
                        volume: 0,
                        contrast: 50,
                        saline: 50,
                        type: 'dualFlow',
                        duration: '00:00'
                    });
                } else if (type == 'contrast') {
                    state.rows.splice(state.editable_row, 1, {
                        flowRate: '1,0',
                        volume: 10,
                        type: 'A',
                        duration: '00:10'
                    });

                } else if (type == 'saline') {
                    state.rows.splice(state.editable_row, 1, {
                        flowRate: '4,0',
                        volume: 20,
                        type: 'B',
                        duration: '00:05'
                    });

                } else if (type == 'pause') {
                    state.rows.splice(state.editable_row, 1, {
                        flowRate: '0,0',
                        volume: 0,
                        type: 'Pause',
                        duration: '00:00',
                        input: false,
                    });
                } else if (type == 'awaiting') {
                    state.rows.splice(state.editable_row, 1, {
                        flowRate: '0,0',
                        volume: 0,
                        type: 'Awaiting',
                        duration: '00:00',
                    });
                }

                if (state.rows.length <= 5 && !state.rows[state.editable_row + 1]) {
                    state.rows.splice(state.editable_row + 1, 1, {
                        type: 'Question',
                    });

                }
            }
        },

        removeRow(state) {
            if (state.testPressed && state.editable_row == 0 && state.rows[state.rows.length - 1].type != 'Question') {
                state.rows.splice(0, 2);
                state.testPressed = false;
                state.rows.push({
                    type: 'Question',
                })

            } else if (state.testPressed && state.editable_row == 0) {
                state.rows.splice(0, 2);
                state.testPressed = false;
            }  else if (state.rows.length === 6 && state.rows[state.rows.length - 1].type != 'Question') {
                state.rows.splice(state.editable_row, 1);
                state.rows.push({
                        type: 'Question',
                });
            } else {
                state.rows.splice(state.editable_row, 1);
            }
        },
        setContrastDual(state) {
            state.contrast = 50;
        },
        setRelatedValue(state) {
            state.relatedValue = '';
        },
        savePreviousValue(state) {
            if (state.target_element.className.includes('volume')) {
                state.previous_value = state.rows[state.editable_row].volume;
            } else if (state.target_element.className.includes('flow-rate')) {
                state.previous_value = state.rows[state.editable_row].flowRate;
            } else if(state.target_element.className.includes('reminders-time enabled')) {
                state.previous_value = state.reminders[state.editable_row].name;
            } else if(state.target_element.className.includes('reminders-time')) {
                state.previous_value = state.reminders[state.editable_row].time;
            } else {
                state.previous_value = state.rows[state.editable_row].duration;
            }

        },
        changeStylePause(state) {
            state.rows[state.editable_row].input = false;
        },
        isVisibleKeyboard(state, visible) {
            state.showKeyboard = visible;
        },
        setKeyboardType(state, type) {
            state.keyboardType = type;
        },

        keyBoardReminder(state, name) {
            state.reminder_name = name;
        },
        keyboardReminderCancel(state) {
            state.reminders[state.editable_row].name = state.previous_value;
            state.reminder_name = '_';
        },
        keyboardReminderDone(state) {
            state.reminders[state.editable_row].name = state.reminder_name;
            state.reminder_name = '_';
        },
        showReminder(state, index) {
            state.reminders[index].show = true;
        },
        closeReminder(state, index) {
            state.reminders.splice(index, 1, {
                time: '--',
                name: '--',
                show:false,
            })
        },
        setPressure(state, pressure) {
            state.pressure_limit = pressure;
        },
        calcEvents(state, isEnabled) {
            state.disableCalcEvents = isEnabled;
        },
        resetRows(state) {
            state.rows.splice(0, state.rows.length,  {
                flowRate: '1,0',
                volume: 10,
                type: 'A',
                duration: '00:10'
            },
            {
                type: 'Question',
            },)
            state.pressure_limit = '325psi';
        },
        resetTestPressed(state) {
            state.testPressed = false;
        },
        keyBoardManager(state, name) {
            state.manager_name = name;
        },
        keyBoardManagerCancel(state) {
            state.manager_name = '_';
        },
        keyboardManagerDone(state, pushWithEducationModeEnabled) {

            let verified = false;

            for (let name of state.protocol_names) {
                if (name === state.manager_name) {
                    let nameOfProtocol = state.manager_name + state.protocol_names_addition;
                    state.protocol_names.push(nameOfProtocol);
                    state[state.target_area][nameOfProtocol] = Object.assign([], state.rows);
                    state.manager_pressure[nameOfProtocol + state.target_area] = state.pressure_limit;
                    state[state.target_area + 'Buttons'].push(nameOfProtocol);
                    state.protocol_names_addition += ' ';
                    verified = true;
                }
            }
            if (!verified) {
                if (pushWithEducationModeEnabled)
                    state.name_of_protocol_created_with_tutorial = state.manager_name
                state.protocol_names.push(state.manager_name);
                state[state.target_area][state.manager_name] = Object.assign([], state.rows);
                state.manager_pressure[state.manager_name + state.target_area] = state.pressure_limit;
                state[state.target_area + 'Buttons'].push(state.manager_name);
            }

            state.manager_name = '_';
        },
        keyBoardManagerSettings(state, name) {
            state.manager_settings_name = name;
        },
        keyBoardManagerSettingsCancel(state) {
              state.manager_settings_name = '_';
        },
        keyboardManagerSettingsDone(state) {
            state.body.splice(state.current_body_index, 1, state.manager_settings_name);
            state.manager_settings_name = '_';
        },
        setTargetArea(state, part) {
            state.target_area = part;
        },
        setGlobalProtocol(state, protocolSettings) {
            state.rows = Object.assign([], protocolSettings.phases);
            state.pressure_limit = protocolSettings.pressure + 'psi';
            for (let phase of protocolSettings.phases) {
                if (phase.type === 'Test') {
                    state.testPressed = true;
                }
            }
        },
        removeProtocol(state, payload) {
            delete state[payload.areaName][payload.protocolName];

            state[payload.areaName + 'Buttons'].splice(payload.index, 1);
            state.protocol_names = Object.assign([], state.headButtons, state.neckButtons, state.chestButtons, state.abdomenButtons, state.pelvisButtons, state.limbsButtons);
            if (payload.protocolName === 'Test') {
                state.RZTButtons.splice(0, 1);
            }

        },
        protocolUp(state, payload) {
            if (payload.index === 0) {
                return;
            } else {
                let current_protocol = state[payload.areaName + 'Buttons'][payload.index];
                let previous_protocol = state[payload.areaName + 'Buttons'][payload.index - 1];

                state[payload.areaName + 'Buttons'].splice(payload.index, 1);
                state[payload.areaName + 'Buttons'].splice(payload.index - 1, 1, current_protocol);
                state[payload.areaName + 'Buttons'].splice(payload.index, 0, previous_protocol);
                state.protocol_names = Object.assign([], state.headButtons, state.neckButtons, state.chestButtons, state.abdomenButtons, state.pelvisButtons, state.limbsButtons)
            }
        },
        protocolDown(state, payload) {
            if (payload.index === state[payload.areaName + 'Buttons'].length - 1) {
                return;
            } else {
                let next_protocol = state[payload.areaName + 'Buttons'][payload.index + 1];

                state[payload.areaName + 'Buttons'].splice(payload.index + 1, 1);
                state[payload.areaName + 'Buttons'].splice(payload.index, 0, next_protocol);
                state.protocol_names = Object.assign([], state.headButtons, state.neckButtons, state.chestButtons, state.abdomenButtons, state.pelvisButtons, state.limbsButtons)
            }
        },
        moveProtocol(state, payload) {
            state[payload.from + 'Buttons'].splice(payload.index, 1);
            state[payload.to + 'Buttons'].push(payload.name);

            let protocol = state[payload.from][payload.name];

            delete state[payload.from][payload.name];
            state[payload.to][payload.name] = protocol;
        },
        setCurrentBodyIndex(state, index) {
            state.current_body_index = index;
        },
        hideButton(state) {
            state.hidden_body.splice(state.current_body_index, 1, true);
        },
        showButton(state) {
            state.hidden_body.splice(state.current_body_index, 1, false);
        },
        setDefaultBody(state, payload) {
            state.body = Object.assign([], payload.body);
            state.hidden_body = Object.assign([], payload.hidden_body);
        },
        switchStateOfWorkstation(state, payload) {
            state.workstation_is_on = payload.state;
            state.showConfirmation = payload.confirm;
        },
        resetSystem(state) {
            state.workstation_is_on = false;
            state.showConfirmation = false;
            state.showControl = false;
            state.highlighted_row = null;
            state.selected_row_button = null;
            state.reminders_row = null;
            state.reminders = [
                {
                    time: '--',
                    name: '--',
                    show:false,
                },
                {
                    time: '--',
                    name: '--',
                    show:false,
                },
                {
                    time: '--',
                    name: '--',
                    show:false,
                },
                {
                    time: '--',
                    name: '--',
                    show:false,
                },
                {
                    time: '--',
                    name: '--',
                    show:false,
                },
            ];
            state.head = {
                Test: 'Test',
            };
            state.neck = {

            };

            state.chest = {

            };

            state.abdomen = {

            };

            state.pelvis = {

            };

            state.limbs = {

            };
            state.headButtons = [
                'Test',
            ];
            state.neckButtons = [

            ];

            state.chestButtons = [

            ];

            state.abdomenButtons = [

            ];

            state.pelvisButtons = [

            ];

            state.limbsButtons = [

            ];
            state.protocol_names = [
                'Test',
            ];
            state.RZTButtons = [
                'Test',
            ];
            state.body = [
                'голова',
                'шея',
                'грудная клетка',
                'брюшная полость',
                'таз',
                'конечности',
            ];
            state.mirror_body = [
                'head',
                'neck',
                'chest',
                'abdomen',
                'pelvis',
                'limbs'
            ];
            state.hidden_body = [
                false,
                false,
                false,
                false,
                false,
                false,
            ];
            state.protocol_names_addition = ' ';
            state.showCalc = false;
            state.range = '';
            state.relatedValue = '';
            state.input = 0;
            state.input_reminder = 0;
            state.input_reminderCancel = 0;
            state.showRow = false;
            state.showDual = false;
            state.previous_value = '';
            state.showKeyboard = false;
            state.keyboardType ='';
            state.reminderAmount = '';
            state.disableCalcEvents = false;
            state.target_area ='';
            state.manager_pressure = {};
            state.current_body_index = '';
        },
        changeStatusProtocolLock(state, status) {
            state.protocol_locked = status;
        },
        replaceRows(state) {
            state.rows = JSON.parse(JSON.stringify(state.changed_rows));
        },
        setReminderList(state) {
            state.reminders_list = JSON.parse(JSON.stringify(state.reminders));
        },
        clearHighlightedRow(state) {
            state.highlighted_row = null;
        },
        setHighlightedRow(state, index) {
            state.highlighted_row = index;
        },
        setSelectedRowButton(state, type) {
            state.selected_row_button = type;
        },
        setRemindersRow(state, index) {
            state.reminders_row = index;
        },
        CLEANUP_STORE_BEFORE_PROTOCOL_MANAGER_TUTORIAL(state) {
            state.rows = [{"flowRate":"4,0","volume":20,"type":"B","duration":"00:05"},{"type":"Test"},{"flowRate":"4,0","volume":"50","contrast":50,"saline":50,"type":"dualFlow","duration":"00:13"},{"flowRate":"0,0","volume":0,"type":"Pause","duration":"00:20","input":true},{"flowRate":"4,0","volume":20,"type":"B","duration":"00:05"},{"type":"Question"}]
        },
        CLEANUP_STORE_BEFORE_INJECTION_PROCEDURE_TUTORIAL(state) {
            state.rows = [{"flowRate":"4,0","volume":20,"type":"B","duration":"00:05"},{"type":"Test"},{"flowRate":"4,0","volume":"50","contrast":50,"saline":50,"type":"dualFlow","duration":"00:13"},{"flowRate":"0,0","volume":0,"type":"Pause","duration":"00:20","input":true},{"flowRate":"4,0","volume":20,"type":"B","duration":"00:05"}]
        },
        CLEANUP_STORE_BEFORE_SETUP_PROTOCOL_MANAGER_TUTORIAL(state) {
            state.rows = [{"flowRate":"4,0","volume":20,"type":"B","duration":"00:05"},{"type":"Test"},{"flowRate":"4,0","volume":"50","contrast":50,"saline":50,"type":"dualFlow","duration":"00:13"},{"flowRate":"0,0","volume":0,"type":"Pause","duration":"00:20","input":true},{"flowRate":"4,0","volume":20,"type":"B","duration":"00:05"},{"type":"Question"}]
            state.head = {"123":[{"flowRate":"4,0","volume":20,"type":"B","duration":"00:05"},{"type":"Test"},{"flowRate":"4,0","volume":"50","contrast":50,"saline":50,"type":"dualFlow","duration":"00:13"},{"flowRate":"0,0","volume":0,"type":"Pause","duration":"00:20","input":true},{"flowRate":"4,0","volume":20,"type":"B","duration":"00:05"},{"type":"Question"}],"Test":"Test"}
            state.protocol_names = ['Test', '123']
            state.headButtons = ["Test","123"]
            state.name_of_protocol_created_with_tutorial = '123'
        },
        FINALIZE_CLEANUP_STORE_BEFORE_TUTORIAL(state) {
            state.workstation_is_on = true
            state.showConfirmation = false
            state.mainScreen.educationIsChosen = true
        },


        RESET_STORE_TO_INITIAL_STATE__EXCEPT_SEQUENCES_MODULE: () => store.replaceState({
            ...cloneDeep(initialState),
            sequences_store: store.state.sequences_store
        })
    },

    actions: {
        enableCalc(store, payload) {
            store.commit('showCalc', payload.boolean);
            store.commit('setRange', payload.range);
            store.commit('savePreviousValue');
            store.commit('stateControl', false);
        },

        validate(store, number) {
            store.commit('validator', number);
        },

        validateDual(store, number) {
            store.commit('validateDual', number);
        },

        control(store, payload) {
            store.commit('stateControl', payload.control);
            store.commit('showCalc', payload.calc);
        },

        input(store) {
            store.commit('showCalc', false);
            store.commit('calcDuration');
            store.commit('inputCount');
        },

        inputCancel(store) {
            store.commit('showCalc', false);
            store.commit('inputCount');
            store.commit('inputCancel');
        },

        inputDual(store) {
          store.commit('showDual');
          store.commit('setContrastDual');
          store.commit('setRelatedValue');
        },

        inputDualCancel(store) {
            store.commit('showDual');
            store.commit('setContrastDual');
            store.commit('setRelatedValue');
            store.commit('showRow', false);
            store.commit('inputDualCancel');
        },

        inputTime(store, number) {
            store.commit('showCalc', false);
            store.commit('inputCount');
            store.commit('inputTime', number);
            store.commit('setRelatedValue');
        },

        inputTimeCancel(store) {
            store.commit('showCalc', false);
            store.commit('setRelatedValue');
            store.commit('inputTimeCancel');
            store.commit('inputCount');
        },

        inputReminder(store) {
            store.commit('reminderCount');
            store.commit('setKeyboardType', 'keyBoardReminder')
            store.commit('setRelatedValue');
            store.commit('isVisibleKeyboard', true);
            store.commit('savePreviousValue');


        },
        inputReminderCancel(store) {
            store.commit('setRelatedValue');
            store.commit('reminderCountCancel');
            store.commit('inputReminderCancel');

        },

        showRow(store, boolean) {
            store.commit('showRow', boolean);
        },
        setRow(store, type) {

            if (type == 'dualFlowRow') {
                store.commit('saveRow');
            }

            if (type == 'trial') {
                store.commit('changeTest');
            }

            store.commit('setRow', type);
            store.commit('setRange', false);
        },

        removeRow(store) {
            store.commit('removeRow');
        },

        validatePause(store, number) {
            store.commit('validatePause', number);
        },
        validateReminder(store, number) {
            store.commit('validateReminder', number);
        },
        changeStylePause(store) {
            store.commit('changeStylePause');
        },

        inputKeyboard(store, payload) {

            store.commit(payload.type, payload.value);
        },
        keyboardCancel(store, type) {

            if (type === 'keyBoardReminder') {
                store.commit('keyboardReminderCancel');
                store.commit('isVisibleKeyboard', false);
            }
            else if (type === 'keyBoardManager') {
                store.commit('keyBoardManagerCancel');
                store.commit('isVisibleKeyboard', false);
            } else if (type === 'keyBoardManagerSettings') {
                store.commit('keyBoardManagerSettingsCancel');
                store.commit('isVisibleKeyboard', false);
            }

        },

        keyboardDone(store, type) {
            if (type === 'keyBoardReminder') {
                store.commit('keyboardReminderDone');
                store.commit('isVisibleKeyboard', false);
            }
            else if (type === 'keyBoardManager') {
                store.commit('keyboardManagerDone');
                store.commit('isVisibleKeyboard', false);
            }
            else if (type === 'keyBoardManagerSettings') {
                store.commit('keyboardManagerSettingsDone');
                store.commit('isVisibleKeyboard', false);
            }
        },
        keyboardDoneWithEducationModeEnabled(store, type) {
            if (type === 'keyBoardReminder') {
                store.commit('keyboardReminderDone');
                store.commit('isVisibleKeyboard', false);
            }
            else if (type === 'keyBoardManager') {
                store.commit('keyboardManagerDone', true);
                store.commit('isVisibleKeyboard', false);
            }
            else if (type === 'keyBoardManagerSettings') {
                store.commit('keyboardManagerSettingsDone');
                store.commit('isVisibleKeyboard', false);
            }
        },

        showReminder(store, index) {
            store.commit('showReminder', index);

        },
        closeReminder(store, index) {
            store.commit('closeReminder', index);
        },
        choosePressure(store, pressure) {
            store.commit('setPressure', pressure);
        },
        calcEvents(store, isEnabled) {
            store.commit('calcEvents', isEnabled);
        },
        resetProtocol(store) {
            store.commit('showCalc', false);
            store.commit('stateControl', false);
            store.commit('resetRows');
            store.commit('resetTestPressed');

        },
        showKeyboard(store, isVisible) {
            store.commit('isVisibleKeyboard', isVisible);
            store.commit('setKeyboardType', 'keyBoardManager')
        },
        showKeyboardManagerSettings(store, payload) {
            store.commit('isVisibleKeyboard', payload.isVisible);
            store.commit('setKeyboardType', payload.type);
        },
        setTargetArea(store, part) {
            store.commit('setTargetArea', part)
        },
        setGlobalProtocol(store, protocolSettings) {
            store.commit('setGlobalProtocol', protocolSettings);
        },
        saveAllProtocols(store) {
            store.commit('saveAllProtocols')
        },
        removeProtocol(store, payload) {
            store.commit('removeProtocol', payload);
        },
        protocolUp(store, payload) {
            store.commit('protocolUp', payload);
        },
        protocolDown(store, payload) {
            store.commit('protocolDown', payload);
        },
        moveProtocol(store, payload) {
            store.commit('moveProtocol', payload);
        },
        setCurrentBodyIndex(store, index) {
            store.commit('setCurrentBodyIndex', index);
        },
        hideButton(store) {
            store.commit('hideButton');
        },
        showButton(store) {
            store.commit('showButton');
        },
        setDefaultBody(store, payload) {
            store.commit('setDefaultBody', payload)
        },
        resetStore(store) {
            store.commit('showCalc', false);
            store.commit('stateControl', false);
            store.commit('resetRows');
            store.commit('resetTestPressed');
            store.commit('resetSystem');
            store.commit('changeStatusProtocolLock', false);
        },
        setHighlightedRow({commit}, index) {
            commit('setHighlightedRow', index);
        },
        clearHighlightedRow({commit}) {
            commit('clearHighlightedRow');
        },
        setSelectedRowButton({commit}, type) {
            commit('setSelectedRowButton', type)
        },
        setRemindersRow({commit}, index) {
            commit('setRemindersRow', index);
        },

        cleanupStoreBeforeTutorial: ({dispatch, commit}) => {
            commit('RESET_STORE_TO_INITIAL_STATE__EXCEPT_SEQUENCES_MODULE')
        },
        cleanupStoreBeforeProtocolManagerTutorial: ({dispatch, commit}) => {
            dispatch('cleanupStoreBeforeTutorial')
            commit('CLEANUP_STORE_BEFORE_PROTOCOL_MANAGER_TUTORIAL')
            commit('FINALIZE_CLEANUP_STORE_BEFORE_TUTORIAL')
        },
        cleanupStoreBeforeSetupProtocolManagerTutorial: ({dispatch, commit}) => {
            dispatch('cleanupStoreBeforeTutorial')
            commit('CLEANUP_STORE_BEFORE_SETUP_PROTOCOL_MANAGER_TUTORIAL')
            commit('FINALIZE_CLEANUP_STORE_BEFORE_TUTORIAL')
        },
        cleanupStoreBeforeDefaultTutorial: ({dispatch, commit}) => {
            dispatch('cleanupStoreBeforeTutorial')
            commit('FINALIZE_CLEANUP_STORE_BEFORE_TUTORIAL')
        },
        cleanupStoreBeforeInjectionProcedureTutorial: ({dispatch , commit}) => {
            dispatch('cleanupStoreBeforeTutorial')
            dispatch('injector/changeDetailsForInjectionProcedureTutorial');
            commit('CLEANUP_STORE_BEFORE_INJECTION_PROCEDURE_TUTORIAL')
            commit('FINALIZE_CLEANUP_STORE_BEFORE_TUTORIAL')

        },
        cleanupStoreBeforeInjectorTutorial: async ({dispatch, commit, rootState}) => {
            await dispatch('cleanupStoreBeforeTutorial')
            commit('FINALIZE_CLEANUP_STORE_BEFORE_TUTORIAL')
        },

        prepareForInjectorHeadSetupMP1: ({rootState}) => {
            rootState.station.settingsLiquidDelivery.LD_settings_left['Тип LPCT'] = 'P8100 LPCT'
            rootState.station.settingsLiquidDelivery.LD_settings_second_page['Авт. заполн. объема д/удален. воздуха'] = 'Выключение'
        }
    }
})