import { SequenceStepChunkWithArrow } from '@/sequences/SequenceStepChunk/chunk_with_arrow'
import { SequenceStepChunkWithText } from '@/sequences/SequenceStepChunk/chunk_with_text'
import { mixer } from '@/sequences/mixer'
import { makeChunksVisibleOnlyWhileActive } from '@/sequences/SequenceStepChunk/chunk_mixin_visible_while_active'

export class SequenceStepChunkWithTextAndArrowVisibleWhileActive extends mixer(
    SequenceStepChunkWithArrow,
    SequenceStepChunkWithText
) {
    constructor({
        text = {},
        ...restArgs
    }) {
        super({
            text: {
                style: `
                    position: absolute;
                    font-size: 14px;
                    font-weight: 600;
                    max-width: 341px;
                    top: 180px;
                    left: 20px;
                    padding: 20px;
                    border: 1px orange solid;
                `,
                ...text
            },
            ...restArgs
        })
        this.components = makeChunksVisibleOnlyWhileActive({components: [...SequenceStepChunkWithArrow.COMPONENTS, ...SequenceStepChunkWithText.COMPONENTS]})
    }
}