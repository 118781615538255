<template>
    <div class="reminders">
        <div class="reminders-row" v-for="(item, index) in getReminders">
            <div class="reminders-number">
                {{index + 1}}
            </div>
            <div :tabindex="index + 1" :class="[index === getRemindersRow && 'enabled',
                                                index !== getRemindersRow && getRemindersRow !== null && 'disabled',
            ]"
                 class="reminders-time" @pointerdown="enableCalc($event.target, index)">
                {{item.time}}
            </div>
            <div class="reminders-name">
                {{item.name}}
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "Reminders",
        data() {
            return {
                current_target: '',
            }
        },
        methods: {
            enableCalc(target, index) {
                this.current_target = target;
                this.$store.commit('setEditableRow', {
                    index: index,
                    target: target,
                });
                this.$store.dispatch('enableCalc', {
                    boolean: true,
                    range: '00:01 - 20:00 (1) mm:ss'
                });
                this.$store.dispatch('calcEvents', false);
                this.$store.dispatch('setRemindersRow', index);
            },
        },
        computed: {
            ...mapGetters([
                'getReminders',
                'getReminderInput',
                'getRemindersRow'
            ]),
        },
        watch: {
            getReminderInput() {
                this.$store.commit('setEditableRow', {
                    target: this.current_target.nextElementSibling,
                })
            },
        },
        beforeDestroy() {
            this.$store.dispatch('setRemindersRow', null);
        },
    }
</script>

<style scoped lang="scss">

    @import "../../scss/components/reminders";

</style>