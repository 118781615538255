<template>
  <div class="injection-process-running-view">
    <InjectionProcessChart
        :max-pressure="maxPressure"
        :is-running="true"
        :expired-time="expiredTime"

        :is-paused="isPaused || currentProtocol.type === 'PAUSE'"

        :frames-per-second="CHART_UPDATING_FPS"
        :pixels-shift-per-frame="CHART_PIXELS_SHIFT_PER_FRAME"
        :timeline-labels-seconds-gap="CHART_TIMELINE_LABELS_SECONDS_GAP"

        :protocols-list="protocolsList"
    />

    <div class="injection-process-running-view__protocols info-block">
      <div class="info-block__header">Протокол</div>
      <div class="injection-process-running-view__protocols__list">
        <div
            v-for="protocol in protocolsList"
            class="injection-process-running-view__protocol"
            :class="currentProtocol && protocol.random === currentProtocol.random && 'injection-process-running-view__protocol--active'"
            v-if="protocol.initialData.type !== 'Question'"
        >
          <span class="type" :class="protocol.initialData.type">{{ types[protocol.initialData.type] }}</span>
          <template
              v-if="[ULTIMATE_PROTOCOLS_TYPES.A, ULTIMATE_PROTOCOLS_TYPES.B, ULTIMATE_PROTOCOLS_TYPES.DUAL_FLOW].includes(protocol.type)">
            <span :class="protocol.initialData.type">{{ protocol.initialData.flowRate }}</span>
            <span :class="protocol.initialData.type">{{ protocol.initialData.volume }}</span>
            <span :class="protocol.initialData.type">{{ protocol.initialData.duration }}</span>
            <span class="percents"
                  v-if="protocol.initialData.type === 'dualFlow'">{{ protocol.initialData.saline + '%' }}</span>
          </template>
        </div>
      </div>
    </div>
    <div class="info-block">
      <div class="info-block__header">
        {{ currentProtocol && BASE_PROTOCOLS_TYPES[currentProtocol.type].DISPLAY_TITLE }}
      </div>
      <div class="info-block__text">
        {{ currentProtocol && BASE_PROTOCOLS_TYPES[currentProtocol.type].DISPLAY_DESCRIPTION }}
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, onUnmounted, ref, watch} from '@vue/composition-api'

import InjectionProcessChart from '@/components/InjectionProcess/InjectionProcessChart'

export default {
  name: "InjectionProcessRunningView",
  components: {
    InjectionProcessChart,
  },
  props: {
    maxPressure: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      types: {
        A: 'A',
        B: 'B',
        Pause: 'Пауза',
        Awaiting: 'Ожидание',
        Test: 'Пробное введение',
        dualFlow: '%'

      }
    }
  },
  setup(props, {root, emit}) {
    /** BINDINGS **/

    onMounted(() => {
      root.standEventBus.$on('run', runButtonPressed)
      root.standEventBus.$on('stop', stopButtonPressed)
    })
    onUnmounted(() => {
      root.standEventBus.$off('run')
      root.standEventBus.$off('stop')
    })
    const runButtonPressed = () => {
      if ([ULTIMATE_PROTOCOLS_TYPES.TEST, ULTIMATE_PROTOCOLS_TYPES.AWAITING].includes(currentProtocol.value.type) && !currentProtocol.value.continued)
        currentProtocol.value.continued = true
      else if (!isTimeExpiring.value && expiredTime.value === 0) {
        startInjection()
      } else if (!isTimeExpiring.value) {
        startTimeExpiration()
      } else if (isTimeExpiring.value) {
        setTimeout(() => {
          isPaused.value = true
        }, 50)
        stopTimeExpiration()
      }
    }

    const stopButtonPressed = () => {
      if (expiredTime.value) {
        let countedTime = 0
        let completedProtocolsList = []

        for (let protocol of protocolsList.value) {
          if (countedTime + protocol.duration < expiredTime.value / 1000) {
            countedTime += protocol.duration
            completedProtocolsList.push({
              ...protocol,
              ...(isProtocolAnInjection(protocol) && {injected: parseInt(protocol.initialData.volume)})
            })
          } else if (isProtocolAnInjection(protocol)) {
            completedProtocolsList.push({
              ...protocol,
              completedDuration: Math.ceil(expiredTime.value / 1000 - countedTime),
              injected: parseFloat(((expiredTime.value / 1000 - countedTime) / protocol.duration * parseInt(protocol.initialData.volume)).toFixed(1))
            })
            return emit('injection-interrupted', completedProtocolsList)
          }
        }

      }
    }

    /** CONSTANTS **/
    const CHART_UPDATING_FPS = 50
    const CHART_PIXELS_SHIFT_PER_FRAME = 0.5
    const CHART_TIMELINE_LABELS_SECONDS_GAP = 2
    const BASE_PROTOCOLS_TYPES = {
      B: {
        UGLY_NAME: 'B',
        DISPLAY_TITLE: 'Введение B',
        DISPLAY_DESCRIPTION: 'Введение B описание'
      },
      A: {
        UGLY_NAME: 'A',
        DISPLAY_TITLE: 'Введение A',
        DISPLAY_DESCRIPTION: 'Введение A описание'
      },
      TEST: {
        UGLY_NAME: 'Test',
        DISPLAY_TITLE: 'Заряжен',
        DISPLAY_DESCRIPTION: 'Тестовое введение завершено. Нажмите кнопку Пуск, чтобы продолжить'
      },
      QUESTION: {
        UGLY_NAME: 'Question',
      },
      DUAL_FLOW: {
        UGLY_NAME: 'dualFlow',
        DISPLAY_TITLE: 'Введение dualFlow',
        DISPLAY_DESCRIPTION: 'Введение dualFlow описание'
      },
      PAUSE: {
        UGLY_NAME: 'Pause',
        DISPLAY_TITLE: 'Пауза',
        DISPLAY_DESCRIPTION: 'Пауза'
      },
      AWAITING: {
        UGLY_NAME: 'Awaiting',
        DISPLAY_TITLE: 'Ожидание',
        DISPLAY_DESCRIPTION: 'Нажмите Пуск, чтобы продолжить'
      }
    }
    const ULTIMATE_PROTOCOLS_TYPES = Object.fromEntries(
        Object.entries(BASE_PROTOCOLS_TYPES).map(([prettyName]) => [prettyName, prettyName])
    )
    const PRETTYFIED_PROTOCOLS_TYPES = Object.fromEntries(
        Object.entries(BASE_PROTOCOLS_TYPES).map(([prettyName, {UGLY_NAME}]) => [UGLY_NAME, prettyName])
    )
    const isProtocolAnInjection = protocol => [ULTIMATE_PROTOCOLS_TYPES.A, ULTIMATE_PROTOCOLS_TYPES.B, ULTIMATE_PROTOCOLS_TYPES.DUAL_FLOW].includes(protocol.type)
    const isProtocolAnInjectionFromUglified = protocol => [BASE_PROTOCOLS_TYPES.A.UGLY_NAME, BASE_PROTOCOLS_TYPES.B.UGLY_NAME, BASE_PROTOCOLS_TYPES.DUAL_FLOW.UGLY_NAME].includes(protocol.type)
    const constantsModule = {
      CHART_UPDATING_FPS,
      CHART_PIXELS_SHIFT_PER_FRAME,
      CHART_TIMELINE_LABELS_SECONDS_GAP,
      BASE_PROTOCOLS_TYPES,
      ULTIMATE_PROTOCOLS_TYPES
    }


    /** TIME EXPIRATION **/

    const startInjection = () => {
      startTimeExpiration()
      emit('injection-started')
    }

    const isTimeExpiring = ref(false)
    const startTimeExpiration = () => {
      isTimeExpiring.value = true
      expireTime()
    }
    const stopTimeExpiration = () => isTimeExpiring.value = false
    const expiredTime = ref(0)
    const timeExpirationStep = computed(() => 1000 / CHART_UPDATING_FPS)

    const fluids = computed(() => root.$store.getters['injector/fluids']);

    const expireTime = () => {
      expiredTime.value += timeExpirationStep.value
      if (isTimeExpiring.value)
        setTimeout(expireTime, timeExpirationStep.value)
    }
    const timeExpirationModule = {
      expiredTime,
      timeExpirationStep
    }


    /** PROTOCOLS PROCESSING**/
    const protocolsList = ref([])
    const calculateProtocolsList = () => {
      let injectionIndex = 1

      protocolsList.value = root.$store.getters.getRows.map((protocol, currentIndex, self) => {
        let duration, index

        if (protocol.duration && protocol.type !== BASE_PROTOCOLS_TYPES.AWAITING.UGLY_NAME) {
          const {groups: {minutes, seconds}} = /(?<minutes>\d\d):(?<seconds>\d\d)/.exec(protocol.duration)
          duration = parseInt(minutes) * 60 + parseInt(seconds)
        } else
          duration = 1


        if (isProtocolAnInjectionFromUglified(protocol) && !(currentIndex === 0 && self[currentIndex + 1]?.type === BASE_PROTOCOLS_TYPES.TEST.UGLY_NAME)) {
          index = injectionIndex
          injectionIndex += 1
        }

        return {
          ...(duration && {duration}),
          ...(index !== undefined && {index}),
          type: PRETTYFIED_PROTOCOLS_TYPES[protocol.type],
          random: Math.random() * 0.5 + 0.5,
          initialData: protocol
        }
      })
    }
    calculateProtocolsList()
    const protocolsProcessingModule = {
      protocolsList,
      calculateProtocolsList
    }


    /** CURRENT PROTOCOL HANDLING **/
    const currentProtocol = ref(undefined)
    const currentProtocolIndex = ref(undefined);
    const isPaused = ref(false)
    watch(currentProtocolIndex,
        () => {
          if (currentProtocolIndex.value && protocolsList.value.length) {
            if (protocolsList.value[currentProtocolIndex.value - 1].type === 'A' || protocolsList.value[currentProtocolIndex.value - 1].type === 'B') {
              root.$store.dispatch('injector/setFluids', {
                [protocolsList.value[currentProtocolIndex.value - 1].type.toLowerCase()]: fluids.value[protocolsList.value[currentProtocolIndex.value - 1].type.toLowerCase()] - protocolsList.value[currentProtocolIndex.value - 1].initialData.volume,
              })
            } else if (protocolsList.value[currentProtocolIndex.value - 1].type === 'DUAL_FLOW') {
                const contrast = protocolsList.value[currentProtocolIndex.value - 1].initialData.volume / 100 * protocolsList.value[currentProtocolIndex.value - 1].initialData.contrast;
                const saline = protocolsList.value[currentProtocolIndex.value - 1].initialData.volume / 100 * protocolsList.value[currentProtocolIndex.value - 1].initialData.saline;
                root.$store.dispatch('injector/setFluids', {
                  a: fluids.value.a - contrast,
                  b: fluids.value.b - saline,
                })
            }
          }
        }
    )
    watch(
        expiredTime,
        () => {
          let countedTime = 0
          for (let protocol of protocolsList.value) {
            if (countedTime + protocol.duration * 1000 >= expiredTime.value) {
              // if ([ULTIMATE_PROTOCOLS_TYPES.TEST, ULTIMATE_PROTOCOLS_TYPES.AWAITING].includes(protocol.type))
              if ([ULTIMATE_PROTOCOLS_TYPES.TEST, ULTIMATE_PROTOCOLS_TYPES.AWAITING].includes(protocol.type)
                  && countedTime + protocol.duration * 1000 < expiredTime.value + timeExpirationStep.value
                  && !protocol.continued) {
                protocol.duration += 1
                isPaused.value = true
              } else if (![ULTIMATE_PROTOCOLS_TYPES.TEST, ULTIMATE_PROTOCOLS_TYPES.AWAITING].includes(protocol.type)) {
                isPaused.value = false
              }

              currentProtocolIndex.value = protocolsList.value.findIndex(protocolLocal => protocol === protocolLocal);
              currentProtocol.value = protocol
              root.$store.commit('setCurrentPhase', currentProtocol.value.type);
              return
            } else
              countedTime += protocol.duration * 1000
          }
          stopTimeExpiration()
          currentProtocol.value = undefined
          emit('injection-ended', protocolsList.value)
        },
        {
          immediate: true
        }
    )
    const currentProtocolHandling = {
      currentProtocol,
      currentProtocolIndex,
      isPaused
    }


    return {
      ...constantsModule,
      ...timeExpirationModule,
      ...protocolsProcessingModule,
      ...currentProtocolHandling
    }
  },
}
</script>

<style lang="scss" scoped>
.injection-process-running-view {
  display: grid;
  grid-template-rows: 50% 30% 20%;
  grid-template-columns: 5fr 3fr;
  flex-grow: 1;
  grid-gap: 10px;
  position: relative;

  .info-block {
    border-radius: 10px;
    border: black 2px solid;

    display: flex;
    flex-direction: column;

    &__header {
      background-color: #36648b;
      color: white;
      font-weight: bold;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      font-size: 15px;
    }

    &__text {
      color: #fff;
      padding: 5px;
    }
  }

  &__protocols {
    grid-row: 2/4;
    margin-left: 33px;

    &__list {
      display: flex;
      flex-direction: column;


      .injection-process-running-view__protocol {
        display: flex;
        flex: 1 1 16.666%;
        justify-content: space-around;
        align-items: center;
        border-bottom: 2px solid #fff;
        position: relative;
        font-weight: 700;

        &:last-child {
          border: none;
        }


        &--active {
          font-weight: 700;
          transform: scale(1.1);

          .type {

            position: relative;
            z-index: 1;
            background: #FFFF99;

          }

          .B {
            &:after {
              content: '';
              position: absolute;
              border: 11.9px solid #61C6F1;
              border-left: 14px solid #FFFF99;
              top: 0.5px;
              left: 46px;
              z-index: 2;
            }
          }

          .A {
            &:after {
              content: '';
              position: absolute;
              border: 11.9px solid #9DC76C;
              border-left: 14px solid #FFFF99;
              top: 0.5px;
              left: 46px;
              z-index: 2;
            }
          }

          .dualFlow {
            &:after {
              content: '';
              position: absolute;
              border: 12px solid #9DC76C;
              border-left: 14px solid #FFFF99;
              border-left: 14px solid #FFFF99;
              top: 0px;
              left: 45px;
              z-index: 2;
            }
          }

          .Test:before {
            display: none;
          }

          .Test:after {
            display: none;
          }

          .Awaiting, .Pause, .Test {
            background: #D0D0BB !important;
            border: 1px solid black;

            &:after {
              display: none;
            }
          }
        }
      }

      .A {
        background: #9DC76C;
        width: 25%;
        padding: 5px;
      }

      .B {
        width: 25%;
        padding: 5px;
        background-color: #61C6F1;
      }

      .dualFlow {
        width: 25%;
        padding: 5px;
        background-image: linear-gradient(180deg, #9DC76C 50%, #61C6F1 50%);
      }

      .percents {
        position: absolute;
        top: 6.5px;
        left: 32px;
        font-size: 12px;
        z-index: 5;
      }

      .Test {
        color: #fff;
        font-weight: 700;
        padding: 5px;
        position: relative;
        background: rgba(255, 255, 255, 0.1) !important;
        width: 100%;
        text-align: center;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 10px;
          height: 2px;
          width: 52px;
          background: #fff;
        }

        &:after {
          content: '';
          position: absolute;
          top: 7px;
          left: 5px;
          height: 2px;
          width: 11px;
          background: #fff;
          transform: rotate(90deg);
        }
      }


      .Awaiting {
        font-weight: 700;
        padding: 5px;
        background: rgba(255, 255, 255, 0.1) !important;
        width: 100%;
        text-align: center;
      }

      .Pause {
        font-weight: 700;
        padding: 5px;
        background: rgba(255, 255, 255, 0.1) !important;
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>