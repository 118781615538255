<template>
    <div class="mode-header">
        <div class="mode-header__item item" style="width: 24%">
            <div class="item__img" >
              <img src="@/img/components/homepage/homepageLogo.png" alt="" style="width: 100%" >
            </div>
        </div>
        <div class="mode__item item buttons">
            <div class="item__button button"
                 v-for="button in img"
                 :class="button.class"
                 @click="button.description === 'Выйти из режима' ? exitMode() : false"
                 :style="button.padding"
                 data-enable-pointer-events="true"
            >
                <img class="button__img" :src=button.url alt="">
                <div class="button__text">
                    {{button.description}}
                </div>
            </div>
        </div>
        <div class="mode__item item list">
            <div class="item__description">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                educationImg: [
                    {
                        url: require('@/img/components/homepage/education.svg'),
                        description: 'Обучающий режим',
                        class: 'selected',
                        padding: {
                            padding: '5px 32.5px 0'
                        }
                    },
                    {
                        url: require('@/img/components/homepage/back.svg'),
                        description: 'Выйти из режима',
                        padding: {
                            padding: '5px 30px 0'
                        }
                    },
                ],
                emulationImg: [
                    {
                        url: require('@/img/components/homepage/emulation.svg'),
                        description: 'Режим эмуляции',
                        class: 'selected',
                        padding: {
                            padding: '5px 32.5px 0'
                        }
                    },
                    {
                        url: require('@/img/components/homepage/back.svg'),
                        description: 'Выйти из режима',
                        padding: {
                            padding: '5px 30px 0'
                        }
                    },
                ],
            }
        },
        methods: {
            exitMode() {
                this.$store.dispatch('mainScreen/changeShowModalOptions', true);
            },
        },
        computed: {
            img() {
                if (this.isEducationModeEnabled) {
                    return this.educationImg;
                } else return this.emulationImg;
            },
        },
    }
</script>

<style scoped lang="scss">
    .mode-header {
        height: 25vh;
        background-color: rgba(255, 255, 255, 0.6);
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;

        .item {
            &__button {
                border: 2px solid orange;
                height: 100%;
                padding: 5px 25px 0;
                transition: background-color 0.5s;
            }

            &__button:hover {
                background-color: rgba(255, 128, 0, 0.4);
            }

            .selected {
                background-color: rgba(255, 128, 0, 0.4);
            }

            &__description {
                li {
                    font-size: 10px;
                    font-weight: bold;
                    line-height: 12px;
                    margin-bottom: 3px;
                }
            }

            .button {
                &__img {
                    height: 30%;
                    cursor: pointer;
                }

                img {
                    min-height: 100%;
                    min-width: 100%;
                    object-fit: contain;
                    padding: 0 10px 20px;
                }

                &__text {
                    font-size: 12px;
                    margin-top: -21%;
                    text-align: center;
                    width: 100%;
                }
            }
        }

        .buttons {
            display: flex;
            gap: 10px;
            height: 70%;
        }

        .list {
            width: 20%;
            @media only screen and (max-width: 1000px) {
                width: 30%;
            }
            @media only screen and (max-width: 780px) {
                width: auto;
            }
        }
    }
</style>