export default {
    namespaced: true,
    state: {
        educationIsChosen: false,
        emulationIsChosen: false,
        showModalOptions: false,
        showModalNotification: false,
        sticky_tooltips_for_main_page: [
            'Кликните по объекту, чтобы работать с ним',
            'Чтобы включить прибор, необходимо сначала включить рабочую станцию'
        ]
    },
    getters: {
        getEducationIsChosen: state => state.educationIsChosen,
        getEmulationIsChosen: state => state.emulationIsChosen,
        getShowModalOptions: state => state.showModalOptions,
        getShowModalNotification: state => state.showModalNotification,
        getStickyTooltipsForMainPage(state) {
            return state.sticky_tooltips_for_main_page;
        },
    },
    mutations: {
        selectMode(state, type) {
            if (type.includes('Обучающий')) {
                state.educationIsChosen = true;
                state.emulationIsChosen = false
            } else if (type.includes('эмуляции')) {
                state.emulationIsChosen = true;
                state.educationIsChosen = false
            } else {
                state.educationIsChosen = false;
                state.emulationIsChosen = false;
            }
        },
        changeShowModalOptions(state, status) {
            state.showModalOptions = status;
        },
        changeShowModalNotification(state, status) {
            state.showModalNotification = status;
        },
    },
    actions: {
        changeShowModalOptions({commit}, payload) {
            commit('changeShowModalOptions', payload)
        },
        changeShowModalNotification({commit}, payload) {
            commit('changeShowModalNotification', payload)
        },
        selectMode({commit}, payload) {
            commit('selectMode', payload);
        },
    },
}