import { assignInstanceConstructor } from '@/sequences/abc_sequence_element'
import { SequenceStepDefinition, SequenceStepInstance } from '@/sequences/SequenceStep/base_classes'

class SequenceStepWithSequenceAlertModalInstance extends SequenceStepInstance {
    processChunkActionExecution(chunkId) {
        return this.parent.nextStep(chunkId)
    }
}

export class SequenceStepWithSequenceAlertModalDefinition extends assignInstanceConstructor(SequenceStepWithSequenceAlertModalInstance, SequenceStepDefinition) {
    constructor({
        text = '',
        buttons = [],
        ...restArgs
    } = {}) {
        super({id: Math.random().toString(16), ...restArgs})
        this.text = text
        this.buttons = buttons

    }
}
