import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        selected: '...',
        mirror_selected: '',
        protocol_name: '',
        current_protocol: '',
        current_pressure: '',
        showAll: false,
        showRZT: false,
        changePage: true,
        showModalNotification: false,
    },
    getters: {
        showModalNotification(state) {
            return state.showModalNotification
        },
        selected(state) {
            return state.selected;
        },
        mirror_selected(state) {
            return state.mirror_selected;
        },
        protocol_name(state) {
            return state.protocol_name;
        },
        current_protocol(state) {
            return state.current_protocol;
        },
        current_pressure(state) {
            return state.current_pressure;
        },
        showAll(state) {
            return state.showAll;
        },
        showRZT(state) {
            return state.showRZT;
        },
        changePage(state) {
            return state.changePage;
        },
    },
    mutations: {
        changeSelected(state, payload) {
            state.selected = payload;
        },
        changeMirrorSelected(state, payload) {
            state.mirror_selected = payload;
        },
        changeProtocolName(state, name) {
            state.protocol_name = name;
        },
        changeCurrentProtocol(state, name) {
            state.current_protocol = name;
        },
        changeCurrentPressure(state, name) {
            state.current_pressure = name;
        },
        changeShowAll(state, status) {
            state.showAll = status;
        },
        changeShowRZT(state, status) {
            state.showRZT = status;
        },
        changePageStatus(state, status) {
            state.changePage = status;
        },
        setDefaultModule(state) {
            state.selected = '...';
            state.mirror_selected = '';
            state.protocol_name = '';
            state.current_protocol = '';
            state.current_pressure = '';
            state.showAll = false;
            state.showRZT = false;
            state.changePage = true;
            state.showModalNotification = false;
        },
        changeModalNotificationStatus(state, status) {
            state.showModalNotification = status;
        }
    },
    actions: {
        changeSelected({commit}, payload) {
            commit('changeSelected', payload);
        },
        changeMirrorSelected({commit}, payload) {
            commit('changeMirrorSelected', payload);
        },
        changeProtocolName({commit}, payload) {
            commit('changeProtocolName', payload);
        },
        changeCurrentProtocol({commit}, payload) {
            commit('changeCurrentProtocol', payload);
        },
        changeCurrentPressure({commit}, payload) {
            commit('changeCurrentPressure', payload);
        },
        changeShowAll({commit}, payload) {
            commit('changeShowAll', payload);
        },
        changeShowRZT({commit}, payload) {
            commit('changeShowRZT', payload);
        },
        changePageStatus({commit}, payload) {
            commit('changePageStatus', payload);
        },
        setDefaultModule({commit}) {
            commit('setDefaultModule')
        },
        changeModalNotificationStatus({commit}, status) {
            commit('changeModalNotificationStatus', status);
        }
    }
}