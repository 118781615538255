<template>
    <div class="container" style="z-index: 9999">
        <div class="modal">
            <p class="modal__information">
                <slot name="information_1">

                </slot>
            </p>
            <p class="modal__information">
                <slot name="information_2">

                </slot>
            </p>
            <p class="modal__information">
                <slot name="information_3">

                </slot>
            </p>
            <p class="modal__information center">
                <slot name="information_center">

                </slot>
            </p>
            <div :class="img_style">
                <img :src="img_src" alt="">
            </div>
            <div class="modal__button button">
                <button class="button__confirm" @click="confirm">
                    <slot name="button_confirm">

                    </slot>
                </button>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        props: {
            img_src: String,
        },
        methods: {
            confirm() {
                this.$emit('confirm');
            }
        },
        computed: {
            img_style() {
                return {
                    'modal__image': !!this.img_src,
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../../scss/mixin";
    .container {
        @include modal-container;


        .modal {
            @include modal;

            &__information {

                font-weight: 500;
                line-height: 20px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }

            .center {
                text-align: center;
            }

            &__image {
                height: auto;
                width: 62%;
                margin: 20px auto;
                img {
                    max-width: 100%;
                }
            }

            &__button {
                margin-top: 20px;
                display: flex;
                justify-content: center;

            }

            .button {
                &__confirm {
                    @include button-modal;
                }
            }
        }
    }
</style>