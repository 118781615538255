var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"script-item",class:_vm.stylePause},[(_vm.row.type == 'A')?[_c('SequenceChunkWrapper',{key:"to_delete_phase_choose_necessary_arrow",attrs:{"sequence-id":"protocol_creation","step-id":"to_delete_phase_choose_necessary_arrow"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate_to_delete_phase_choose_necessary_arrow = ref.processChunkAction;
return [_c('SequenceChunkWrapper',{key:"create_or_choose_protocol",attrs:{"sequence-id":"protocol_creation","step-id":"create_or_choose_protocol"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate_create_or_choose_protocol = ref.processChunkAction;
return [_c('script-button',{class:_vm.highlightRowFirstButton,attrs:{"letter":_vm.row.type},on:{"change-color":function (e) {
                        var fn = function () {
                            _vm.startCreatingRow(e);
                            _vm.$store.dispatch('showRow', false);
                        };
                        validate_to_delete_phase_choose_necessary_arrow(fn, {enabledIfEducationIsInactive: true})
                        validate_create_or_choose_protocol(fn)
                    }}})]}}],null,true)})]}}],null,false,2324996323)}),_c('SequenceChunkWrapper',{key:"choose_Flow_phase_parameter_Contrast_A",attrs:{"sequence-id":"protocol_creation","step-id":"choose_Flow_phase_parameter_Contrast_A"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var processChunkAction = ref.processChunkAction;
return [_c('flow-button',{class:_vm.highlightRow,attrs:{"index":_vm.index,"flowRate":_vm.row.flowRate},on:{"focused":function (ref) {
                             var event = ref.event;
                             var f = ref.f;

                             var fn = function () {
                                 f()
                                 _vm.enableButton(event)
                             }
                             processChunkAction(fn, {enabledIfEducationIsInactive: true})
                         }}})]}}],null,false,2725256265)}),_c('SequenceChunkWrapper',{key:"select_Volume_phase_parameter_Contrast_A",attrs:{"sequence-id":"protocol_creation","step-id":"select_Volume_phase_parameter_Contrast_A"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var processChunkAction = ref.processChunkAction;
return [_c('volume-button',{class:_vm.highlightRow,attrs:{"index":_vm.index,"volume":_vm.row.volume},on:{"focused":function (ref) {
                             var event = ref.event;
                             var f = ref.f;

                             var fn = function () {
                                 f()
                                 _vm.enableButton(event)
                             }
                             processChunkAction(fn, {enabledIfEducationIsInactive: true})
                         }}})]}}],null,false,519084051)}),_c('duration-button',{key:_vm.row.type,class:_vm.highlightRow,attrs:{"duration":_vm.row.duration}})]:(_vm.row.type=='B')?[_c('script-button',{staticClass:"b b-script",class:_vm.highlightRowFirstButton,attrs:{"letter":_vm.row.type},on:{"change-color":function($event){return _vm.startCreatingRow($event)}}}),(_vm.index === 5)?_c('SequenceChunkWrapper',{key:"choose_Flow_phase_parameter_Contrast_B",attrs:{"sequence-id":"protocol_creation","step-id":"choose_Flow_phase_parameter_Contrast_B"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var processChunkAction = ref.processChunkAction;
return [_c('flow-button',{staticClass:"b",class:_vm.highlightRow,attrs:{"index":_vm.index,"flowRate":_vm.row.flowRate},on:{"focused":function (ref) {
                             var event = ref.event;
                             var f = ref.f;

                             var fn = function () {
                                 f()
                                 _vm.enableButton(event)
                             }
                             processChunkAction(fn, {enabledIfEducationIsInactive: true})
                         }}})]}}],null,false,1775440043)}):_c('flow-button',{staticClass:"b",class:_vm.highlightRow,attrs:{"index":_vm.index,"flowRate":_vm.row.flowRate},on:{"focused":function (ref) {
                         var event = ref.event;
                         var f = ref.f;

                         f()
                         _vm.enableButton(event)
                     }}}),(_vm.index === 5)?_c('SequenceChunkWrapper',{key:"select_Volume_phase_parameter_Contrast_B",attrs:{"sequence-id":"protocol_creation","step-id":"select_Volume_phase_parameter_Contrast_B"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var processChunkAction = ref.processChunkAction;
return [_c('volume-button',{staticClass:"b",class:_vm.highlightRow,attrs:{"index":_vm.index,"volume":_vm.row.volume},on:{"focused":function (ref) {
                             var event = ref.event;
                             var f = ref.f;

                             var fn = function () {
                                 f()
                                 _vm.enableButton(event)
                             }
                             processChunkAction(fn, {enabledIfEducationIsInactive: true})
                         }}})]}}],null,false,3797371633)}):_c('volume-button',{staticClass:"b",class:_vm.highlightRow,attrs:{"index":_vm.index,"volume":_vm.row.volume},on:{"focused":function (ref) {
                         var event = ref.event;
                         var f = ref.f;

                         f()
                         _vm.enableButton(event)
                     }}}),_c('duration-button',{key:_vm.row.type,class:_vm.highlightRow,attrs:{"duration":_vm.row.duration}})]:(_vm.row.type =='Test')?[_c('div',{staticClass:"script-text test"},[_vm._v("Пробное введение")])]:(_vm.row.type == 'Question')?[_c('SequenceChunkWrapper',{key:"press_arrow_question_test_B",attrs:{"sequence-id":"protocol_creation","step-id":"press_arrow_question_test_B"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var validate_press_arrow_question_test_B = ref.processChunkAction;
return [_c('SequenceChunkWrapper',{key:"press_arrow_question_Pause",attrs:{"sequence-id":"protocol_creation","step-id":"press_arrow_question_Pause"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var validate_press_arrow_question_Pause = ref.processChunkAction;
return [_c('SequenceChunkWrapper',{key:"press_arrow_question_AB",attrs:{"sequence-id":"protocol_creation","step-id":"press_arrow_question_AB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var validate_press_arrow_question_AB = ref.processChunkAction;
return [_c('script-test',{class:_vm.highlightRowFirstButton,attrs:{"letter":"?","index":_vm.index},on:{"change-color":function($event) {
                      var fn = function () { return _vm.startCreatingRow($event); }
                        validate_press_arrow_question_test_B(fn, {enabledIfEducationIsInactive: true})
                        validate_press_arrow_question_Pause(fn)
                        validate_press_arrow_question_AB(fn)
                    },"original-color":function($event){return _vm.originalColor($event)}}})]}}],null,true)})]}}],null,true)})]}}])}),(_vm.showRow)?[_c('flow-button',{class:_vm.highlightRow,attrs:{"index":_vm.index,"disabled":true,"flowRate":_vm.row.flowRate}}),_c('volume-button',{class:_vm.highlightRow,attrs:{"index":_vm.index,"disabled":true,"volume":_vm.row.volume}}),_c('duration-button',{key:_vm.row.type,class:_vm.highlightRow,attrs:{"duration":_vm.row.duration}})]:_vm._e()]:(_vm.row.type == 'dualFlow')?[_c('script-button',{staticClass:"dual dual-script",class:_vm.highlightRowFirstButton,attrs:{"letter":"%"},on:{"change-color":function($event){return _vm.startCreatingRow($event)}}}),_c('SequenceChunkWrapper',{key:"choose_Flow_phase_parameter_AB",attrs:{"sequence-id":"protocol_creation","step-id":"choose_Flow_phase_parameter_AB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var processChunkAction = ref.processChunkAction;
return [_c('flow-button',{staticClass:"dualflow",class:_vm.highlightRow,attrs:{"index":_vm.index,"flowRate":_vm.row.flowRate},on:{"focused":function (ref) {
                             var event = ref.event;
                             var f = ref.f;

                             var fn = function () {
                                 f()
                                 _vm.enableButton(event)
                             }
                             processChunkAction(fn, {enabledIfEducationIsInactive: true})
                         }}})]}}])}),_c('SequenceChunkWrapper',{key:"select_Volume_phase_parameter_AB",attrs:{"sequence-id":"protocol_creation","step-id":"select_Volume_phase_parameter_AB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var processChunkAction = ref.processChunkAction;
return [_c('volume-button',{staticClass:"dualflow",class:_vm.highlightRow,attrs:{"index":_vm.index,"volume":_vm.row.volume,"contrast":_vm.row.contrast,"saline":_vm.row.saline},on:{"focused":function (ref) {
                             var event = ref.event;
                             var f = ref.f;

                             var fn = function () {
                                 f()
                                 _vm.enableButton(event)
                             }
                             processChunkAction(fn, {enabledIfEducationIsInactive: true})
                         }}})]}}])}),_c('duration-button',{key:_vm.row.type,class:_vm.highlightRow,attrs:{"duration":_vm.row.duration}})]:(_vm.row.type == 'Pause')?[_c('script-test',{class:_vm.highlightRowFirstButton,attrs:{"index":_vm.index},on:{"change-color":function($event){return _vm.startCreatingRow($event)},"original-color":function($event){return _vm.originalColor($event)}}}),_c('div',{staticClass:"script-text"},[_vm._v("Пауза")]),_c('SequenceChunkWrapper',{key:"select_parameter_value_Duration_Pause",attrs:{"sequence-id":"protocol_creation","step-id":"select_parameter_value_Duration_Pause"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var processChunkAction = ref.processChunkAction;
return [_c('pause-button',{key:_vm.row.type,class:_vm.highlightRow,attrs:{"duration":_vm.row.duration,"index":_vm.index},on:{"focused":function(e) {
                              processChunkAction(function () { return _vm.enableButton(e); }, {enabledIfEducationIsInactive: true})
                          }}})]}}])})]:(_vm.row.type == 'Awaiting')?[_c('script-test',{class:_vm.highlightRowFirstButton,attrs:{"index":_vm.index},on:{"change-color":function($event){return _vm.startCreatingRow($event)},"original-color":function($event){return _vm.originalColor($event)}}}),_c('div',{staticClass:"script-text"},[_vm._v("Ожидание")]),_c('awaiting-button',{key:_vm.row.type,class:_vm.highlightRow,attrs:{"duration":_vm.row.duration}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }