<template>
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="50px" height="191px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
viewBox="0 0 2.59 9.89"
 xmlns:xlink="http://www.w3.org/1999/xlink"
 xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
 <defs>
  <font id="FontID0" horiz-adv-x="665" font-variant="normal" style="fill-rule:nonzero" font-style="normal" font-weight="400">
	<font-face
		font-family="Arial">
		<font-face-src>
			<font-face-name name="Arial"/>
		</font-face-src>
	</font-face>
   <missing-glyph><path d="M0 0z"/></missing-glyph>
   <glyph unicode="0" horiz-adv-x="555" d="M41.9331 352.776c0,84.3615 8.65549,152.591 25.9665,204.17 17.4525,51.7443 43.2539,91.5076 77.5456,119.314 34.1031,27.7825 77.1919,41.5794 129.125,41.5794 38.254,0 71.8854,-7.64136 100.989,-23.1128 28.9617,-15.4714 52.9235,-37.7823 71.5552,-66.9091 18.6317,-28.9381 33.2776,-64.3855 43.9378,-106.154 10.8017,-41.768 16.1318,-98.182 16.1318,-168.888 0,-83.8663 -8.65549,-151.766 -25.7778,-203.345 -17.1459,-51.7443 -42.9473,-91.5312 -77.2155,-119.479 -34.1267,-27.9476 -77.3805,-41.9331 -129.62,-41.9331 -68.7251,0 -122.804,24.4571 -162.072,73.5599 -47.0981,59.9045 -70.5646,156.907 -70.5646,291.197zm89.8567 -0.330182c0,-117.663 13.6554,-195.869 41.1077,-234.476 27.4523,-38.7728 61.3903,-58.0649 101.673,-58.0649 40.2586,0 74.1966,19.4572 101.649,58.23 27.4759,38.9379 41.1077,116.979 41.1077,234.311 0,117.639 -13.6318,195.845 -41.1077,234.453 -27.2872,38.7728 -61.5554,58.0649 -102.663,58.0649 -40.2586,0 -72.5457,-17.1459 -96.6726,-51.249 -30.1173,-43.419 -45.0934,-123.96 -45.0934,-241.269z"/>
   <glyph unicode="1" horiz-adv-x="555" d="M371.408 0l-87.852 0 0 559.446c-21.1317,-20.3062 -48.9377,-40.4473 -83.2059,-60.5884 -34.2918,-20.1175 -65.2346,-35.2587 -92.5218,-45.2585l0 84.8804c49.0792,22.9477 92.0265,50.9188 128.795,83.8663 36.7681,32.7824 62.8997,64.5506 78.206,95.4934l56.5791 0 0 -717.84z"/>
  </font>
 </defs>
 <g id="med-station">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="fluidB">
    <path class="fil0" d="M1.4 9.89l-0.21 0c-0.05,0 -0.09,-0.04 -0.09,-0.09l0 -0.31 -0.87 -0.62 0 -8.63 -0.11 0c-0.07,0 -0.12,-0.05 -0.12,-0.12 0,-0.07 0.05,-0.12 0.12,-0.12l2.35 0c0.06,0 0.12,0.05 0.12,0.12 0,0.07 -0.06,0.12 -0.12,0.12l-0.11 0 0 8.63 -0.87 0.62 0 0.31c0,0.05 -0.05,0.09 -0.09,0.09zm-1.11 -1.05l0.87 0.62 0 0.34c0,0.02 0.01,0.03 0.03,0.03l0.21 0c0.01,0 0.03,-0.01 0.03,-0.03l0 -0.34 0.87 -0.62 0 -8.65 0.17 0c0.03,0 0.06,-0.03 0.06,-0.06 0,-0.04 -0.03,-0.06 -0.06,-0.06l-2.34 0c-0.04,0 -0.06,0.03 -0.06,0.06 0,0.03 0.03,0.06 0.06,0.06l0.16 0 0 8.65 0 0z"/>
    <path class="fil1" d="M2.47 0.06l-2.34 0c-0.04,0 -0.06,0.03 -0.06,0.06 0,0.04 0.03,0.06 0.06,0.06l0.16 0 0 8.66 0.88 0.62 0 0.34c0,0.02 0.01,0.03 0.03,0.03l0.2 0c0.02,0 0.03,-0.01 0.03,-0.03l0 -0.34 0.88 -0.62 0 -8.65 0.16 0c0.04,0 0.06,-0.03 0.06,-0.06 0,-0.04 -0.03,-0.07 -0.06,-0.07z"/>
    <path class="fil2" :style="fluids_level" d="M2.47 0.06l-2.34 0c-0.04,0 -0.06,0.03 -0.06,0.06 0,0.04 0.03,0.06 0.06,0.06l0.16 0 0 8.66 0.88 0.62 0 0.34c0,0.02 0.01,0.03 0.03,0.03l0.2 0c0.02,0 0.03,-0.01 0.03,-0.03l0 -0.34 0.88 -0.62 0 -8.65 0.16 0c0.04,0 0.06,-0.03 0.06,-0.06 0,-0.04 -0.03,-0.07 -0.06,-0.07z"/>
    <path class="fil0" d="M0.97 2.76l0.26 0c0.12,0 0.21,0.02 0.26,0.05 0.05,0.03 0.08,0.09 0.08,0.16 0,0.05 -0.01,0.09 -0.03,0.12 -0.03,0.04 -0.06,0.05 -0.1,0.06l0 0.01c0.06,0.01 0.09,0.03 0.12,0.06 0.02,0.03 0.03,0.08 0.03,0.13 0,0.08 -0.03,0.14 -0.08,0.18 -0.06,0.04 -0.13,0.06 -0.22,0.06l-0.32 0 0 -0.83zm0.18 0.33l0.1 0c0.05,0 0.09,-0.01 0.11,-0.02 0.02,-0.02 0.03,-0.04 0.03,-0.08 0,-0.03 -0.01,-0.05 -0.04,-0.06 -0.02,-0.02 -0.06,-0.02 -0.11,-0.02l-0.09 0 0 0.18zm0 0.14l0 0.22 0.12 0c0.04,0 0.08,-0.01 0.1,-0.03 0.03,-0.02 0.04,-0.05 0.04,-0.09 0,-0.07 -0.05,-0.1 -0.15,-0.1l-0.11 0z"/>
    <path class="fil0" d="M1.28 6.08l-0.1 0 0 -0.2c0,-0.02 0,-0.04 -0.01,-0.06 -0.01,-0.01 -0.02,-0.01 -0.04,-0.01 -0.03,0 -0.04,0 -0.05,0.02 -0.02,0.02 -0.02,0.05 -0.02,0.09l0 0.16 -0.1 0 0 -0.34 0.08 0 0.01 0.04 0 0c0.01,-0.02 0.03,-0.03 0.05,-0.04 0.01,-0.01 0.03,-0.01 0.06,-0.01 0.05,0 0.08,0.02 0.1,0.05l0.01 0c0.01,-0.02 0.02,-0.03 0.04,-0.04 0.02,-0.01 0.04,-0.01 0.06,-0.01 0.04,0 0.07,0.01 0.09,0.03 0.02,0.02 0.03,0.05 0.03,0.1l0 0.22 -0.09 0 0 -0.2c0,-0.02 -0.01,-0.04 -0.02,-0.06 0,-0.01 -0.02,-0.01 -0.03,-0.01 -0.03,0 -0.05,0 -0.06,0.02 -0.01,0.02 -0.01,0.04 -0.01,0.08l0 0.17zm0.41 0l-0.1 0 0 -0.48 0.1 0 0 0.48z"/>
  <text v-if="!fluids.b" x="1" y="5.5"  class="fil3 fnt0">0</text>
  <text v-else-if="fluids.b < 100" x="0.8" y="5.5"  class="fil3 fnt0">{{fluids.b}}</text>
  <text v-else x="0.45" y="5.35"  class="fil3 fnt0">{{fluids.b}}</text>
  </g>
 </g>
</svg>
</template>

<script>

    import {mapGetters} from 'vuex';

    export default {
        name: "FluidB",
        computed: {
            ...mapGetters('injector', {
                fluids: 'fluids',
            }),
            fluids_level() {
                return {
                    'clip-path': `inset(${100 - (this.fluids.b / 205 * 100)}% 0% 0% 0%)`
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    @font-face { font-family:"Arial";font-variant:normal;font-style:normal;font-weight:normal;src:url("#FontID0") format(svg)}
    .fil3 {fill:#2B2A29}
    .fil0 {fill:#36393B;fill-rule:nonzero}
    .fil2 {fill:#61C6F1;fill-rule:nonzero}
    .fil1 {fill:white;fill-rule:nonzero}
    .fnt0 {font-weight:normal;font-size:0.96px;font-family:'Arial'}
</style>