<template>
   <div class="menu-modal second-level">
       <button class="btn settings-btn"><img src="../../img/components/settings_icons/settings.svg">
           <SequenceChunkWrapper
                   v-slot="{processChunkAction: processChunkAction_setup_system_select_section_system}"
                   sequence-id="setup_system"
                   step-id="select_section_system"
                   key="select_section_system"
           >
               <a @click="processChunkAction_setup_system_select_section_system(() => $router.push({name: 'SystemSettings'}), {enabledIfEducationIsInactive: true})"><span
                       class="name-btn">Система</span><span class="value">Настройка</span></a>
           </SequenceChunkWrapper>
       </button>
       <button class="btn settings-btn"><img src="../../img/components/settings_icons/P3T.svg"><a @click="!isEducationModeEnabled && $router.push({name: 'SettingsPreset'})"><span class="name-btn">Предустановка</span><span class="value">Настройка</span></a></button>
       <button class="btn settings-btn"><img src="../../img/components/settings_icons/protocol-manage.svg">
           <SequenceChunkWrapper
                   sequence-id="setup_protocol_manager"
                   step-id="select_section_protocol_manager"
                   key="select_section_protocol_manager"
                   v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_select_section_protocol_manager}"
           >
               <a @click="() => processChunkAction_setup_protocol_manager_select_section_protocol_manager(
                           () => $router.push({name: 'ManagerSettings'}), {enabledIfEducationIsInactive: true})">
                   <span class="name-btn">Менеджер протоколов</span>
                   <span class="value">Настройка</span>
               </a>
           </SequenceChunkWrapper>
       </button>
       <button class="btn settings-btn"><img src="../../img/components/settings_icons/delivery.svg">
           <SequenceChunkWrapper
                   v-slot="{processChunkAction: processChunkAction_liquid_delivery_select_section_liquid_delivery}"
                   sequence-id="liquid_delivery"
                   step-id="select_section_liquid_delivery"
                   key="select_section_liquid_delivery"
           >
               <a @click="processChunkAction_liquid_delivery_select_section_liquid_delivery(() => $router.push({name: 'LiquidDelivery'}), {enabledIfEducationIsInactive: true})"><span class="name-btn">Доставка жидкости</span><span class="value">Настройка</span></a>
           </SequenceChunkWrapper>
       </button>
       <button class="btn settings-btn"><img src="../../img/components/settings_icons/info-sys.svg"><a @click="!isEducationModeEnabled && $router.push({name: 'InformationSystem'})"><span class="name-btn">Информационная система</span><span class="value">Настройка</span></a></button>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped lang="scss">

@import "../../scss/components/menu-modal";

    .menu-modal {
        left: 215px;

        .settings-btn {
            font-size: 10px;
        }
    }

</style>