<template>
    <div class="injection-process-chart">
        <div class="injection-process-chart__pressure-axis">
            <span style="top: 0">psi</span>
            <span class="injection-process-chart__pressure-axis__value" :style="{top: `${HEADER_HEIGHT}px`}">{{Math.round(maxPressure * 2)}}</span>
            <span class="injection-process-chart__pressure-axis__value" :style="{top: `${CHART_HEIGHT / 4 + HEADER_HEIGHT - 7}px`}">{{Math.round(maxPressure * 1.5)}}</span>
            <span class="injection-process-chart__pressure-axis__value" :style="{top: `${CHART_HEIGHT / 2 + HEADER_HEIGHT - 13}px`}">{{Math.round(maxPressure)}}</span>
            <span class="injection-process-chart__pressure-axis__value" :style="{top: `${CHART_HEIGHT * 3 / 4 + HEADER_HEIGHT- 20}px`}">{{Math.round(maxPressure * 0.5)}}</span>
            <span class="injection-process-chart__pressure-axis__value" :style="{top: `${CHART_HEIGHT + HEADER_HEIGHT - 25}px`}">0</span>
        </div>
        <div id="injection_process_chart_container">
            <canvas id="injection_process_chart_canvas"/>
        </div>
        <div v-if="isPauseSignVisible" class="injection-process-chart__pause-sign">

        </div>
    </div>
</template>

<script>
    import { computed, onMounted, ref, watch } from '@vue/composition-api'
    import Vue from 'vue'

    export default {
        name: "InjectionProcessChart",
        props: {
            maxPressure: {
                type: Number,
                required: true
            },
            isRunning: {
                type: Boolean,
                required: true
            },
            expiredTime: Number, // at milliseconds

            isPaused: Boolean,

            framesPerSecond: {
                type: Number,
                required: true
            },
            pixelsShiftPerFrame: {
                type: Number,
                required: true
            },
            timelineLabelsSecondsGap: {
                type: Number,
                required: true
            },

            protocolsList: {
                type: Array,
                required: true
            }
        },
        setup(props, {root}) {
            /** CONSTANTS **/
            const HEADER_HEIGHT = 30 //px
            const HEADER_BACKGROUND_COLOR = '#d3d3d3'

            const TIMELINE_BACKGROUND_COLOR = '#dfdfdf'
            const TIMELINE_TEXT_COLOR = '#8d8d8d'
            const TIMELINE_HEIGHT = 20 //px
            const TIMELINE_RULER_STROKE_HEIGHT = 5 //px
            const TIMELINE_RULER_TEXT_MARGIN_TOP = 3 //px

            const CANVAS_HEIGHT = 230 //px
            const CHART_HEIGHT = CANVAS_HEIGHT - HEADER_HEIGHT - TIMELINE_HEIGHT
            const CHART_BACKGROUND_COLOR = '#d3d3d3'
            const CHART_PADDING_X = 20  //px

            const PAUSE_TOGGLING_INTERVAL = 1000 // ms

            const GRAPH_ASC_COEFFICIENT = 2.5
            const GRAPH_DESC_COEFFICIENT = -3

            const GRAPH_NUMBER_ARROW_WIDTH = 6
            const GRAPH_NUMBER_ARROW_HEIGHT = 20
            const GRAPH_NUMBER_SQUARE_SIDE_LENGTH = 14

            const EXPOSE_CONSTANTS = {
                HEADER_HEIGHT, TIMELINE_HEIGHT, CHART_HEIGHT
            }


            /** CANVAS SIZING **/
            const canvasHeight = ref(0)
            const _canvasWidth = ref(0)
            const maxViewportHeight = computed(() => document.getElementById('injection_process_chart_container').offsetHeight - 1)
            const maxViewportWidth = computed(() => document.getElementById('injection_process_chart_container').offsetWidth)
            const canvasWidth = ref(0)
            const resizeCanvas = () => {
                canvasHeight.value = maxViewportHeight.value

                if (!props.isRunning)
                    _canvasWidth.value = props.framesPerSecond * props.pixelsShiftPerFrame * (props.expiredTime / 1000) + CHART_PADDING_X * 2
                else
                    _canvasWidth.value += props.pixelsShiftPerFrame
                canvasWidth.value = Math.max(_canvasWidth.value, maxViewportWidth.value)
                canvas.width = canvasWidth.value
                canvas.height = canvasHeight.value
            }
            const canvasSizingModule = {maxViewportWidth, canvasHeight, canvasWidth, maxViewportHeight}


            /** CTX DEFINITION **/
            let canvas, ctx
            onMounted(() => {
                canvas = document.getElementById('injection_process_chart_canvas')
                ctx = canvas.getContext('2d')

                resizeCanvas()
                drawBackground()
                drawTimeline()
                drawMaxPressureLine()
                drawGraphs()
            })


            /** CTX UTILS **/
            const drawRect = (x, y, width, height, color) => {
                ctx.fillStyle = color
                ctx.fillRect(x, y, width, height)
            }
            const drawLine = (fromX, fromY, toX, toY, color, width, lineDash) => {
                ctx.beginPath()
                if (lineDash)
                    ctx.setLineDash(lineDash)
                ctx.strokeStyle = color
                ctx.lineWidth = width
                ctx.moveTo(fromX, fromY)
                ctx.lineTo(toX, toY)
                ctx.stroke()
                ctx.closePath()
            }
            const drawText = (text, x, y, align, baseline, color, font) => {
                ctx.font = font
                ctx.textAlign = align
                ctx.textBaseline = baseline
                ctx.fillStyle = color
                ctx.fillText(text, x, y)
            }


            /** CHART METHODS **/
            const drawBackground = () => drawRect(0, 0, canvasWidth.value, canvasHeight.value, CHART_BACKGROUND_COLOR)

            let timelineStartLeftIndent
            const timelineRulerPixelsGap = props.framesPerSecond * props.pixelsShiftPerFrame * props.timelineLabelsSecondsGap
            const drawTimeline = () => {
                drawRect(0, canvasHeight.value - TIMELINE_HEIGHT, canvasWidth.value, TIMELINE_HEIGHT, TIMELINE_BACKGROUND_COLOR)

                let leftIndent
                for (let timelineGapIndex = 0; timelineGapIndex <= (props.expiredTime / 1000) / props.timelineLabelsSecondsGap; timelineGapIndex++) {
                    if (props.isRunning)
                        leftIndent = canvasWidth.value - (timelineGapIndex + (props.expiredTime / 1000) % props.timelineLabelsSecondsGap * (1 / props.timelineLabelsSecondsGap)) * timelineRulerPixelsGap
                    else
                        leftIndent = canvasWidth.value - CHART_PADDING_X - (timelineGapIndex + (props.expiredTime / 1000) % props.timelineLabelsSecondsGap * (1 / props.timelineLabelsSecondsGap)) * timelineRulerPixelsGap
                    drawLine(
                        leftIndent,
                        canvasHeight.value - TIMELINE_HEIGHT,
                        leftIndent,
                        canvasHeight.value - (TIMELINE_HEIGHT - TIMELINE_RULER_STROKE_HEIGHT),
                        TIMELINE_TEXT_COLOR,
                        1
                    )
                    drawText(
                        ((Math.floor((props.expiredTime / 1000) / props.timelineLabelsSecondsGap) - timelineGapIndex) * props.timelineLabelsSecondsGap).toLocaleString(),
                        leftIndent,
                        canvasHeight.value - (TIMELINE_HEIGHT - TIMELINE_RULER_STROKE_HEIGHT - TIMELINE_RULER_TEXT_MARGIN_TOP),
                        'center',
                        'top',
                        TIMELINE_TEXT_COLOR,
                        '10px'
                    )
                }

                timelineStartLeftIndent = leftIndent
            }

            const drawHeader = () => drawRect(0, 0, canvasWidth.value, HEADER_HEIGHT, HEADER_BACKGROUND_COLOR)
            const drawHeaderMaxPressure  = () => drawText(
                `Предел давления ${props.maxPressure} psi`,
                canvasWidth.value - 30, HEADER_HEIGHT / 2,
                'end', 'middle', 'black', 'bold 15px sans-serif'
            )

            const drawMaxPressureLine = () => drawLine(
                0, (canvasHeight.value - HEADER_HEIGHT - TIMELINE_HEIGHT) / 2 + HEADER_HEIGHT,
                canvasWidth.value, (canvasHeight.value - HEADER_HEIGHT - TIMELINE_HEIGHT) / 2 + HEADER_HEIGHT,
                'red', 1, [2, 2])

            const drawGraphIndex = (x, number, color) => {
                drawLine(x, HEADER_HEIGHT, x, canvasHeight.value - TIMELINE_HEIGHT, '#bfbfbf', 1)
                ctx.beginPath()
                ctx.fillStyle = 'black'
                ctx.strokeStyle = ''
                ctx.lineWidth = 1
                ctx.moveTo(x, canvasHeight.value - TIMELINE_HEIGHT)
                ctx.lineTo(x - GRAPH_NUMBER_ARROW_WIDTH / 2, canvasHeight.value - TIMELINE_HEIGHT - GRAPH_NUMBER_ARROW_HEIGHT)
                ctx.lineTo(x + GRAPH_NUMBER_ARROW_WIDTH / 2, canvasHeight.value - TIMELINE_HEIGHT - GRAPH_NUMBER_ARROW_HEIGHT)
                ctx.lineTo(x, canvasHeight.value - TIMELINE_HEIGHT)
                ctx.fill()
                ctx.fillRect(x - GRAPH_NUMBER_SQUARE_SIDE_LENGTH / 2, canvasHeight.value - TIMELINE_HEIGHT - GRAPH_NUMBER_ARROW_HEIGHT - GRAPH_NUMBER_SQUARE_SIDE_LENGTH, GRAPH_NUMBER_SQUARE_SIDE_LENGTH, GRAPH_NUMBER_SQUARE_SIDE_LENGTH)
                ctx.fillStyle = color
                ctx.fillRect(x - GRAPH_NUMBER_SQUARE_SIDE_LENGTH / 2 + 1, canvasHeight.value - TIMELINE_HEIGHT - GRAPH_NUMBER_ARROW_HEIGHT - GRAPH_NUMBER_SQUARE_SIDE_LENGTH + 1, GRAPH_NUMBER_SQUARE_SIDE_LENGTH - 2, GRAPH_NUMBER_SQUARE_SIDE_LENGTH - 2)
                drawText(number, x, canvasHeight.value - TIMELINE_HEIGHT - GRAPH_NUMBER_ARROW_HEIGHT - GRAPH_NUMBER_SQUARE_SIDE_LENGTH / 2, 'center', 'middle', 'black', 'bold 11px sans-serif')
            }

            const drawGraphs = () => {
                let graphIndex, graph, color
                for ([graphIndex, graph] of props.protocolsList.entries()) {
                    let pixelsDuration = graph.duration * props.framesPerSecond * props.pixelsShiftPerFrame
                    let randomMaxPressure = (canvasHeight.value - HEADER_HEIGHT - TIMELINE_HEIGHT) / 2 * graph.random
                    let randomUpK = GRAPH_ASC_COEFFICIENT * graph.random
                    let randomDownK = GRAPH_DESC_COEFFICIENT * graph.random
                    if (graph.type === 'B') {
                        color = 'blue'
                    } else if (graph.type === 'A' || graph.type === 'dualFlow') {
                        color = 'green';
                    } else {
                        color = 'white';
                    }
                    if (graph.hasOwnProperty('index'))
                        drawGraphIndex(timelineStartLeftIndent, graph.index, color)

                    ctx.beginPath()
                    ctx.strokeStyle = 'black'
                    ctx.setLineDash([])
                    ctx.lineWidth = 1

                    ctx.moveTo(timelineStartLeftIndent, canvasHeight.value - TIMELINE_HEIGHT)

                    if (graph.type === 'TEST' || graph.type === 'AWAITING' || graph.type === 'PAUSE') {
                        ctx.lineTo(timelineStartLeftIndent + pixelsDuration, canvasHeight.value - TIMELINE_HEIGHT)
                    } else {

                        const xCrossing = -1 * (pixelsDuration * randomDownK) / (randomUpK - randomDownK)
                        const yCrossing = randomUpK * xCrossing

                        let y = Math.min(randomMaxPressure, yCrossing),
                            x = Math.min(y / randomUpK, xCrossing)

                        ctx.bezierCurveTo(
                            timelineStartLeftIndent + 10, canvasHeight.value - TIMELINE_HEIGHT,
                            timelineStartLeftIndent + x / 3, canvasHeight.value - TIMELINE_HEIGHT - y,
                            timelineStartLeftIndent + x, canvasHeight.value - TIMELINE_HEIGHT - y
                        )

                        x = pixelsDuration + y / GRAPH_DESC_COEFFICIENT
                        ctx.lineTo(timelineStartLeftIndent + x, canvasHeight.value - TIMELINE_HEIGHT - y)

                        ctx.bezierCurveTo(
                            timelineStartLeftIndent + x +(pixelsDuration - x) * 0.75, canvasHeight.value - TIMELINE_HEIGHT - y * 0.9,
                            timelineStartLeftIndent + pixelsDuration - 5, canvasHeight.value - TIMELINE_HEIGHT,
                            timelineStartLeftIndent + pixelsDuration, canvasHeight.value - TIMELINE_HEIGHT
                        )
                    }


                    ctx.stroke()
                    ctx.closePath()

                    timelineStartLeftIndent += pixelsDuration

                    if (timelineStartLeftIndent >= canvasWidth.value)
                        return
                }
            }

            const drawFrame = () => {
                drawBackground()
                drawTimeline()
                drawMaxPressureLine()
                drawGraphs()

                drawHeader()
                drawHeaderMaxPressure()
            }

            watch(() => props.expiredTime, () => {
                resizeCanvas()
                drawFrame()
            })


            /** PAUSE HANDLING **/
            const isPauseSignVisible = ref(false)
            const togglePauseSignVisibility = () => {
                if (props.isPaused) {
                    isPauseSignVisible.value = !isPauseSignVisible.value
                    setTimeout(togglePauseSignVisibility, PAUSE_TOGGLING_INTERVAL)
                }
            }
            watch(
                () => props.isPaused,
                () => {
                    if (props.isPaused)
                        togglePauseSignVisibility()
                    else
                        isPauseSignVisible.value = false
                },
                {immediate: true}
            )
            const pauseHandlingModule = {isPauseSignVisible}


            /** CHART SCROLLING **/
            const scrollChart = scrollLength =>
                document.getElementById('injection_process_chart_container').scrollBy({left: scrollLength, behavior: 'smooth'})


            return {
                ...canvasSizingModule,
                ...EXPOSE_CONSTANTS,
                ...pauseHandlingModule,
                ctx,
                canvasHeight,
                scrollChart
            }
        }
    }
</script>

<style lang="scss" scoped>
    .injection-process-chart {
        grid-column: 1 / 3;
        display: flex;
        min-width: 0;
        height: 100%;
        position: relative;

        &__pressure-axis {
            position: relative;
            color: white;
            width: 25px;
            flex-shrink: 0;

            span {
                position: absolute;
                right: -2px;
                font-size: 13px;

                &.injection-process-chart__pressure-axis__value {
                    margin-top: -6px;
                }
            }
        }

        #injection_process_chart_container {
            flex-grow: 1;
            overflow-x: scroll;
            overflow-y: hidden;
            direction: rtl;
            margin-left: 10px;

            &::-webkit-scrollbar {
                display: none;
            }

            canvas {
                direction: ltr;
            }
        }

        &__pause-sign {
            width: 50px;
            height: 50px;
            position: absolute;
            background-color: yellow;
            left: 50%;
            top: calc(50% - 30px);

            &::before, &::after {
                content: '';
                width: 10px;
                height: 40px;
                position: absolute;
                background-color: black;
            }

            &::before {
                top: 5px;
                left: 10px;
            }

            &::after {
                top: 5px;
                right: 10px;
            }
        }

    }
</style>