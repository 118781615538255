<template>
    <button :class="isSelected" class="btn area-head-btn" @pointerdown="onClick">{{name}}</button>
</template>

<script>
    export default {
        props: {
            name: String,
            selected: Boolean,
        },
        methods: {
            onClick() {
                this.$emit('click-area-button', this.name)
            }
        },
        computed: {
            isSelected() {
                return {
                    selected: this.selected,
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../scss/mixin";
    @import "../../scss/variable";
    .area-head-btn {
        @include btn;
        border-radius: 0;
        font-size: 10px;
        flex: 1;
        background: $secondary-background;
        @include shadows-btn;
        text-transform: none;
    }
    .selected {
      background: $yellow !important;
      box-shadow: none !important;
    }
</style>