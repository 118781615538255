import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        showAboutProgram: true,
    },
    getters: {
        showAboutProgram(state) {
            return state.showAboutProgram
        }
    },
    mutations: {
        changeAboutProgramVisibility(state, payload) {
            state.showAboutProgram = payload
        },
    },
    actions: {
        changeAboutProgramVisibility({commit}, payload) {
            commit('changeAboutProgramVisibility', payload)
        },
    }
}