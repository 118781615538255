<template>
    <div class="main">
        <img v-if="workstation_is_on" src="@/img/MainScreen.jpg" alt="">
        <img v-else src="@/img/MainScreen-off.jpg" alt=""/>
        <MainPageHeader
                v-if="educationIsChosen || emulationIsChosen"
        />
        <MainPageWrapper
                v-else
        />
        <div v-if="isEducationModeEnabled" class="sticky-tooltips-container">
            <div v-for="(text, index) in stickyTooltipsForMainPage" class="sticky-tooltip" :key="index"
            >{{text}}
            </div>
        </div>
        <a class="route-station" style="cursor: pointer" @click="$router.push({name: 'WorkStation'})">
            <div v-if="isEducationModeEnabled" class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-bottom chunk-with-text-attached-to-arrow--align-left" style="top:100%; display: flex; flex-direction: column; gap: 5px;">
              <span style="font-weight: 700">Шаг 1</span>
              <span>Рабочая станция Certegra</span></div>
        </a>
        <div @click="navigateToInjector()" class="route-injector">
            <div v-if="isEducationModeEnabled"
                 class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-bottom chunk-with-text-attached-to-arrow--align-left"
                 style="top:100%; display: flex; flex-direction: column; gap: 5px;">
                <span style="font-weight: 700">Шаг 2</span>
                <span>Голова инъектора с пьедесталом</span>
            </div>
        </div>
        <modal-options @confirm="exitMode"
                       @cancel="$store.dispatch('mainScreen/changeShowModalOptions', false)"
                       v-if="showModalOptions"
        >
            <template v-slot:header>
                Внимание!
            </template>
            <template v-slot:information_1>
                При выходе из текущего режима все данные и настройки будут обнулены!
            </template>
            <template v-slot:button-confirm>
                Выйти из режима
            </template>
            <template v-slot:button-cancel>
                Отменить выход
            </template>
        </modal-options>
        <modal-notification v-show="showModalNotification"
                            @confirm="changeShowModalNotification(false)"
        >
            <template v-slot:information_center>
                Сначала нужно включить рабочую станцию!
            </template>
            <template v-slot:button_confirm>
                ОК
            </template>
        </modal-notification>
    </div>
</template>

<script>
    import ModalOptions from '@/components/modals/ModalOptions'
    import ModalNotification from '@/components/modals/ModalNotification'
    import MainPageWrapper from "../components/mainPage/MainPageWrapper";
    import MainPageHeader from "../components/mainPage/MainPageHeader";

    import {mapState, mapGetters} from 'vuex'

    export default {
        components: {
            ModalOptions,
            ModalNotification,
            MainPageWrapper,
            MainPageHeader,
        },
        computed: {
            ...mapState([
                'workstation_is_on',
            ]),
            ...mapGetters('mainScreen', {
                educationIsChosen: 'getEducationIsChosen',
                emulationIsChosen: 'getEmulationIsChosen',
                showModalOptions: 'getShowModalOptions',
                showModalNotification: 'getShowModalNotification',
                stickyTooltipsForMainPage: 'getStickyTooltipsForMainPage'
            }),
            ...mapGetters('injector', {
                getInstallationStatus: 'getInstallationStatus',
            }),
        },
        methods: {
            exitMode() {
                // this.$store.dispatch('resetStore');
                // if (this.getInstallationStatus) {
                //     this.$store.dispatch('injector/reset_installation', false)
                // }
                // this.$store.commit('mainScreen/selectMode', 'Выйти');
                this.$store.dispatch('mainScreen/changeShowModalOptions', false);
                location.reload();
            },
            navigateToInjector() {
                if (this.workstation_is_on) {
                    this.$router.push({name: 'Injector'});
                    return true
                } else {
                    this.$store.dispatch('mainScreen/changeShowModalNotification', true);
                    return false
                }
            },
            changeShowModalNotification(status) {
                this.$store.dispatch('mainScreen/changeShowModalNotification', status);
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "../scss/style";
    .main {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &__mode {
            height: 25vh;
            background-color: rgba(255, 255, 255, 0.6);
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .route-station {
            position: absolute;
            height: 21%;
            width: 18%;
            top: 64%;
            left: 47%;
        }

        .route-injector {
            position: absolute;
            right: 30%;
            top: 28%;
            width: 6%;
            height: 26%;
            cursor: pointer;
        }
    }


</style>