import { SequenceStepChunkDefinition } from '@/sequences/SequenceStepChunk/base_classes'
import { isObject } from 'lodash'
import { makeChunksVisibleOnlyWhileActive } from '@/sequences/SequenceStepChunk/chunk_mixin_visible_while_active'

class SequenceStepChunkTextAttachedToArrow_Addon {
    constructor({
        inheritStyle = true,
        content = '',
        style = {}
    }) {
        this.inheritStyle = inheritStyle
        this.content = content

        style = {...(inheritStyle && ({
            border: 'inherit',
            'background-color': 'inherit',
            padding: 'inherit'
        })), ...style}

        this.style = Object.entries(style).map(([k, v]) => `${k}:${v}`).join(';')
    }

    genElement() {
        return `<div style="${this.style}">${this.content}</div>`
    }
}

class SequenceStepChunkTextAttachedToArrow_TextBottomAddon extends SequenceStepChunkTextAttachedToArrow_Addon {
    constructor({text}) {
        super({
            content: text,
            style: {
                position: 'absolute',
                width: '100%',
                left: 0,
                top: '100%',
                'margin-top': '20px'
            }
        })
    }
}

export class SequenceStepChunkTextAttachedToArrow extends SequenceStepChunkDefinition {
    static COMPONENTS = [() => import('./ChunkTextAttachedToArrow')]

    static POSITIONS = {
        TOP: Symbol('TOP'),
        RIGHT: Symbol('RIGHT'),
        BOTTOM: Symbol('BOTTOM'),
        LEFT: Symbol('LEFT')
    }

    static ALIGNMENT = {
        TOP: Symbol('TOP'),
        RIGHT: Symbol('RIGHT'),
        BOTTOM: Symbol('BOTTOM'),
        LEFT: Symbol('LEFT')
    }

    static ADDON = (...args) => new SequenceStepChunkTextAttachedToArrow_Addon(...args)

    static TEXT_BOTTOM_ADDON = (...args) => new SequenceStepChunkTextAttachedToArrow_TextBottomAddon(...args)

    constructor({
        text_arrow: {
            position = SequenceStepChunkTextAttachedToArrow.POSITIONS.TOP,
            align = SequenceStepChunkTextAttachedToArrow.ALIGNMENT.RIGHT,
            mainText = '',
            addons = [],
            withoutArrow = false
        } = {},
        ...restArgs
    } = {}) {
        super({
            ...restArgs,
            components: makeChunksVisibleOnlyWhileActive({components: [...SequenceStepChunkTextAttachedToArrow.COMPONENTS]})
        })
        this.position = position
        this.align = align

        this.mainText = mainText
        this.addons = addons
        this.withoutArrow = withoutArrow

        this.#validateAfterInitialization()
    }

    #validateAfterInitialization() {
        if (!Object.values(SequenceStepChunkTextAttachedToArrow.POSITIONS).some(position => position === this.position))
            this.throwInitializationError(`Position must be one of: ${Object.keys(SequenceStepChunkTextAttachedToArrow.POSITIONS).join(', ')}`)

        if (!Object.values(SequenceStepChunkTextAttachedToArrow.ALIGNMENT).some(align => align === this.align))
            this.throwInitializationError(`Align must be one of: ${Object.keys(SequenceStepChunkTextAttachedToArrow.ALIGNMENT).join(', ')}`)

        if (
            ([SequenceStepChunkTextAttachedToArrow.POSITIONS.TOP, SequenceStepChunkTextAttachedToArrow.POSITIONS.BOTTOM].includes(this.position)
                && [SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP, SequenceStepChunkTextAttachedToArrow.ALIGNMENT.BOTTOM].includes(this.align))
            ||
            ([SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT, SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT].includes(this.position)
                && [SequenceStepChunkTextAttachedToArrow.ALIGNMENT.RIGHT, SequenceStepChunkTextAttachedToArrow.ALIGNMENT.LEFT].includes(this.align))
        ) {
            this.throwInitializationError(`Allowed pairs (POSITION-ALIGN): top-right, top-left, bottom-right, bottom-left, right-top, right-bottom, left-top, left-bottom`)
        }

    }
}