<template>
    <button class="modal-button" data-enable-pointer-events="true" @click="$store.dispatch('sequences_store/endAllSequences'); $router.push({name: 'Main'})">
        В аппаратную
    </button>
</template>

<script>
    export default {

    }
</script>

<style scoped lang="scss">
    @import "../../scss/variable";
    .modal-button {
        z-index: 6;
        background-color: $modal-color;
        border-radius: 3px;
        padding: 10px;
        width: 200px;
        color: #fff;
        position: absolute;
        bottom: 7%;
        left: 2%;
        font-size: 14px;
    }
</style>