import {SequenceStepChunkDefinition} from '@/sequences/SequenceStepChunk/base_classes'
import {defineComponent} from '@vue/composition-api'

export const makeChunksVisibleOnlyWhileActive = ({components}) => {
    return [defineComponent({
        name: "ChunkVisibleWhileActive",
        functional: true,
        props: {
            sequenceId: {
                type: String,
                required: true
            },
            stepId: {
                type: String,
                required: true
            },
            chunkId: {
                type: String,
                default: SequenceStepChunkDefinition.DEFAULT_ID
            },
            anchorSelector: {
                type: String,
                required: true
            }
        },
        render(createElement, ctx) {
            if (ctx.props.sequenceId === ctx.parent.$store.getters['sequences_store/getActiveSequence']?.definition?.id &&
                ctx.props.stepId === ctx.parent.$store.getters['sequences_store/getActiveStep']?.definition?.id
                && ctx.parent.$store.getters['sequences_store/getActiveChunks']
                    ?.some(({definition: {id}}) => id === ctx.props.chunkId)) {
                return components
                    .map(component => createElement(
                        component,
                        {props: ctx.data.props}
                        )
                    )
            }
            return []
        }
    })]


}