<template>
    <div class="wrapper">
        <div class="modal-fluids">
            <h3 class="modal-fluids__header">Недостаточный объем</h3>
            <div class="modal-fluids__fluids fluids">
                <p v-show="totalA - fluids.a > 0">A <span class="fluids__contrast substance">33</span>ml</p>
                <p v-show="totalB - fluids.b > 0">B <span class="fluids__saline substance">113</span>ml</p>
            </div>
            <div class="modal-fluids__protocols protocols">
                <div class="protocols__pre-defined-protocol pre-defined-protocol">
                    <h4 class="pre-defined-protocol__header">Запрограммир. протокол</h4>
                    <div class="pre-defined-protocol__phases">
                        <manager-row class="manager-script" v-for="(row, index) in getRows"
                                                :row="row"
                                                :index="index"/>
                    </div>
                </div>
                <div class="protocols__changed-protocol changed-protocol">
                    <h4 class="changed-protocol__header">Измененный протокол</h4>
                    <div class="changed-protocol__phases">
                        <manager-row class="manager-script" v-for="(row, index) in getChangedRows"
                                                :row="row"
                                                :index="index"/>
                    </div>
                </div>
            </div>
            <div class="modal-fluids__footer footer">
                <h4 class="footer__header">
                    Принять изменения в протоколе?
                </h4>
                <div class="footer__buttons">
                    <button @click="confirm">Да</button>
                    <button @click="cancel">Нет</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex';

    import ManagerRow from '@/components/ManagerRow'

    export default {
        components: {
            ManagerRow
        },
        methods: {
            cancel() {
                this.$emit('cancel');
            },
            confirm() {
                this.$store.commit('replaceRows')
                this.$emit('confirm');
            }
        },
        computed: {
            ...mapGetters([
                'getRows',
                'totalA',
                'totalB',
                'getChangedRows',
            ]),
            ...mapGetters('injector', {
                fluids: 'fluids',
            })
        }
    }
</script>

<style lang="scss">
    @import '../../scss/mixin';
    .wrapper {
        @include modal-container;
    }

    .modal-fluids {
        @include modal-work-station;
        width: 550px;
        gap: 10px;

        &__header {
            font-size: 18px;
            font-weight: 700;
        }

        &__fluids {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .fluids {
            &__contrast {
                @include contrast-box;
            }

            &__saline {
                @include saline-box;
            }

            .substance {
                @include common-box-substance-style;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        &__protocols {
            display: flex;
            gap: 35px;
        }

        .protocols {
            &__pre-defined-protocol, .changed-protocol {
                padding: 5px 10px;
                border-radius: 5px;
                min-height: 180px;

                .manager-script {
                    pointer-events: none;
                    display: flex;
                    align-items: center;
                    padding-left: 5px;
                    gap: 5px;

                    .awaiting {
                        border: 1px solid black;
                        border-radius: 5px;
                    }
                }
            }

            &__pre-defined-protocol {
                background-color: #DEE9E2;
            }

            &__changed-protocol {
                background-color: #fff;
            }

            .pre-defined-protocol {
                &__phases {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .script-text {
                        color: #fff;
                    }
                }
            }

            .changed-protocol {
                &__phases {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }
            }
        }

        &__footer {

        }

        .footer {
            &__buttons {
                display: flex;
                gap: 35px;
                justify-content: center;

                button {
                    font-weight: unset;
                    width: 120px;
                    height: 30px;
                    background: #598BD1;
                    border-top: 2px solid #5bb7ff;
                    border-left: 2px solid #5bb7ff;
                    border-bottom: 2px solid #45699b;
                    border-right: 2px solid #45699b;
                    font-size: 14px;
                    text-transform: capitalize;

                    &:active {
                      border: 2px solid #598BD1;
                      font-size: 13px;
                    }
                }
            }
        }

        h4 {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;
        }
    }
</style>