<template>
    <button class="total-btn" @pointerdown="showModalReset">
        <img src="../img/components/restart.svg">
    </button>
</template>

<script>
    export default {
        name: "Restart",

        methods: {
            showModalReset() {
                this.$emit('showModalReset')
            }
        }
    }
</script>

<style scoped lang="scss">

    @import "../scss/variable";
    @import "../scss/mixin";

    .total-btn {
       @include total;
        width: 56px;
        background: #8EACBD;
        cursor: pointer;
    }
    .disabled {
        pointer-events: none;
    }

</style>