var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[(_vm.isEducationModeEnabled && _vm.$store.getters.getActiveSequenceId === 'liquid_delivery')?_c('span',{staticStyle:{"margin-top":"20px","position":"absolute","z-index":"1000","font-weight":"700","color":"blue"}},[_vm._v("Чтобы получить информацию о функциях наведите указатель на интересующую кнопку. При нажатии на кнопку отобразятся её параметры")]):_vm._e(),_c('div',{staticClass:"computer"},[(!_vm.workstation_is_on)?_c('div',{key:"off",staticClass:"screen-black"}):_c('div',{key:"enabled",staticClass:"screen"},[_vm._m(0),(_vm.showFirstPage)?_c('div',{staticClass:"screen-settings"},[_c('div',{staticClass:"screen-settings-column first-column"},[_vm._l((_vm.LD_settings_left),function(value,name){return [_c('v-tooltip',{attrs:{"content-class":"education-mode-ld-settings-tooltip","attach":".container","absolute":"","disabled":!_vm.isEducationModeEnabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('left-button',_vm._g({attrs:{"data-enable-pointer-events":true,"name":name,"value":value,"isSelected":_vm.selectedButton === name},on:{"select-button":function($event){return _vm.selectButton(name)}}},on))]}},(_vm.isEducationModeEnabled)?{key:"default",fn:function(){return [_vm._v(_vm._s(_vm.getTooltipTextForButton(name))+" ")]},proxy:true}:null],null,true)})]})],2),_c('div',{staticClass:"screen-settings-column second-column"},[_vm._l((_vm.test_injection_speed_2),function(value){return _c('option-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedButton === 'Скорость тестового введения' && !_vm.showFirstOptions),expression:"selectedButton === 'Скорость тестового введения' && !showFirstOptions"}],attrs:{"value":value},on:{"select-option":function($event){return _vm.selectOption(value)}}})}),_vm._l((_vm.options),function(value){return _c('option-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFirstOptions),expression:"showFirstOptions"}],attrs:{"value":value},on:{"select-option":function($event){return _vm.selectOption(value)}}})}),(_vm.selectedButton === 'Скорость тестового введения')?_c('chevron-button',{staticClass:"arrow-language",attrs:{"page":_vm.pageProps},on:{"change-page":function($event){return _vm.changeShowFirstOptions(!_vm.showFirstOptions)}}}):_vm._e()],2),_c('div',{staticClass:"screen-settings-column third-column"},[_vm._l((_vm.LD_settings_right),function(value,name){return [_c('v-tooltip',{attrs:{"content-class":"education-mode-ld-settings-tooltip","absolute":"","attach":".container","disabled":!_vm.isEducationModeEnabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('right-button',_vm._g({attrs:{"data-enable-pointer-events":true,"name":name,"value":value,"isSelected":_vm.selectedButton === name},on:{"select-button":function($event){return _vm.selectButton(name)}}},on))]}},(_vm.isEducationModeEnabled)?{key:"default",fn:function(){return [_vm._v(_vm._s(_vm.getTooltipTextForButton(name))+" ")]},proxy:true}:null],null,true)})]})],2),_c('div',{staticClass:"screen-settings-column fourth-column"},[_c('button',{staticClass:"btn screen-settings-btn",on:{"pointerdown":_vm.setDefaultSettings}},[_c('span',[_vm._v("По умолчанию")])]),_c('button',{staticClass:"btn screen-settings-btn confirm-btn",on:{"pointerdown":function($event){return _vm.changeDone(true)}}},[_c('SequenceChunkWrapper',{key:"press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup",attrs:{"sequence-id":"liquid_delivery","step-id":"press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup","chunk-id":"press_Ok_button_quiet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_liquid_delivery_press_Ok_button_quiet = ref.processChunkAction;
return [_c('a',{on:{"click":function($event){processChunkAction_liquid_delivery_press_Ok_button_quiet(function () { return _vm.$router.push({name: 'WorkStation'}); }, {enabledIfEducationIsInactive: true})}}},[_vm._v("ОК")])]}}],null,false,2171073758)})],1),_c('SequenceChunkWrapper',{key:"press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup",attrs:{"sequence-id":"liquid_delivery","step-id":"press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup","chunk-id":"press_Cancel_button_quiet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_liquid_delivery_press_Cancel_button_quiet = ref.processChunkAction;
return [_c('button',{staticClass:"btn screen-settings-btn",on:{"pointerdown":function($event){processChunkAction_liquid_delivery_press_Cancel_button_quiet(function () { return _vm.LD_cancelSettings(); }, {enabledIfEducationIsInactive: true})}}},[_c('a',[_vm._v("Отмена")])])]}}],null,false,2062547846)})],1)]):_c('div',{staticClass:"screen-settings"},[_c('div',{staticClass:"screen-settings-column first-column"},[_vm._l((_vm.LD_settings_second_page),function(value,name){return [_c('v-tooltip',{attrs:{"content-class":"education-mode-ld-settings-tooltip","attach":".container","absolute":"","disabled":!_vm.isEducationModeEnabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('left-button',_vm._g({attrs:{"data-enable-pointer-events":true,"name":name,"value":value,"isSelected":_vm.selectedButton === name},on:{"select-button":function($event){return _vm.selectButton(name)}}},on))]}},(_vm.isEducationModeEnabled)?{key:"default",fn:function(){return [_vm._v(" "+_vm._s(_vm.getTooltipTextForButton(name))+" ")]},proxy:true}:null],null,true)})]})],2),_c('div',{staticClass:"screen-settings-column second-column"},_vm._l((_vm.options),function(value){return _c('option-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFirstOptions),expression:"showFirstOptions"}],attrs:{"value":value},on:{"select-option":function($event){return _vm.selectOption(value)}}})}),1),_c('div',{staticClass:"screen-settings-column third-column"}),_c('div',{staticClass:"screen-settings-column fourth-column"},[_c('button',{staticClass:"btn screen-settings-btn",on:{"pointerdown":_vm.setDefaultSettings}},[_c('span',[_vm._v("По умолчанию")])]),_c('button',{staticClass:"btn screen-settings-btn confirm-btn",on:{"pointerdown":function($event){return _vm.changeDone(true)}}},[_c('SequenceChunkWrapper',{key:"press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup",attrs:{"sequence-id":"liquid_delivery","step-id":"press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup","chunk-id":"press_Ok_button_quiet_second_page"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_liquid_delivery_press_Ok_button_quiet = ref.processChunkAction;
return [_c('a',{on:{"click":function($event){processChunkAction_liquid_delivery_press_Ok_button_quiet(function () { return _vm.$router.push({name: 'WorkStation'}); }, {enabledIfEducationIsInactive: true})}}},[_vm._v("ОК")])]}}])})],1),_c('SequenceChunkWrapper',{key:"press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup",attrs:{"sequence-id":"liquid_delivery","step-id":"press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup","chunk-id":"press_Cancel_button_quiet_second_page"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_liquid_delivery_press_Cancel_button_quiet = ref.processChunkAction;
return [_c('button',{staticClass:"btn screen-settings-btn",on:{"pointerdown":function($event){processChunkAction_liquid_delivery_press_Cancel_button_quiet(function () { return _vm.LD_cancelSettings(); }, {enabledIfEducationIsInactive: true})}}},[_c('a',[_vm._v("Отмена")])])]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedButton === 'Типы контрастного вещества'),expression:"selectedButton === 'Типы контрастного вещества'"}],staticClass:"types-contrast-agent"},[_vm._m(1),_c('div',{staticClass:"types-contrast-agent__content"},[_vm._m(2),_c('div',{staticClass:"right-menu"},[_c('button',{staticClass:"btn",attrs:{"data-enable-pointer-events":true},on:{"click":_vm.clearSelectedButton}},[_c('span',[_vm._v("По умолчанию")])]),_c('button',{staticClass:"btn",attrs:{"data-enable-pointer-events":true},on:{"click":_vm.clearSelectedButton}},[_c('span',[_vm._v("ОК")])]),_c('button',{staticClass:"btn",attrs:{"data-enable-pointer-events":true},on:{"click":_vm.clearSelectedButton}},[_vm._v("Отмена")])])])])]),_c('div',{staticClass:"footer-btn"},[_c('button',{staticClass:"btn show-more",attrs:{"data-enable-pointer-events":true},on:{"pointerdown":_vm.goToPage}},[_c('i',{class:_vm.page})])])]),(_vm.done && _vm.isChanged)?_c('modal-cancel',{attrs:{"text":_vm.modalText,"link":true},on:{"cancel":_vm.LD_cancelSettings,"confirm":_vm.handleConfirm}}):_vm._e(),_c('div',{staticClass:"stand"},[_c('comp-lamp',{staticClass:"light",class:_vm.isLightOn}),_c('input',{staticClass:"stand__power-button",attrs:{"type":"checkbox"},on:{"input":function($event){return _vm.switchOnComp(true)}}})],1)],1)]),_c('router-modal'),_c('InfoButton',{staticStyle:{"width":"13.5%","min-width":"259px"},on:{"open-confirm":_vm.openModalTerm}}),(_vm.modalEx)?_c('TermDefinition',{staticStyle:{"z-index":"99999"},on:{"close-confirm":_vm.closeModalTerm}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header system-settings-header"},[_c('img',{attrs:{"src":require("../img/LiquidDelivery.svg")}}),_c('span',[_vm._v("Доставка жидкости")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header system-settings-header"},[_c('span',[_vm._v("Конфигурация контрастного вещества")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-menu"},[_c('div',{staticClass:"left-menu__btns"},[_c('button',{staticClass:"btn"},[_c('span',[_vm._v(" - 300 — ml ")])]),_c('button',{staticClass:"btn"},[_c('span',[_vm._v("- 350 — ml ")])]),_c('button',{staticClass:"btn"},[_c('span',[_vm._v("UV 370 100 ml")])]),_c('button',{staticClass:"btn"},[_c('span',[_vm._v("UV 300 200 ml")])])]),_c('div',{staticClass:"left-menu__space"})])}]

export { render, staticRenderFns }