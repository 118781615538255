<template>
    <button @pointerdown="setDate" class="system-btn">Установить</button>
</template>

<script>
    export default {
        name: "SystemBtn",
        methods: {
            setDate() {
                this.$emit('set-date');
            }
        }
    }
</script>

<style scoped lang="scss">

    @import "../scss/mixin";
    @import "../scss/variable";

    .system-btn {
        @include btn;
        @include shadows-btn;
        border-radius: 0;
        background: $primary-background;
        width: 150px;
    }

</style>