<template>
    <div class="wrapper">
        <div class="modal">
            <div class="modal__information information">
                <p class="information__first">
                    <slot name="information_1">
                        Шприц с контрастным веществом отсутствует
                    </slot>
                </p>
                <p class="information__second">
                    <slot name="information_2">
                        Шприц с физиологическим раствором отсутствует
                    </slot>
                </p>
            </div>
            <div class="modal__options options">
                <button class="options__button" @click="confirm">ОК</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            confirm() {
                this.$emit('confirm');
            }
        },

    }
</script>

<style scoped lang="scss">
    @import '../../scss/mixin';

    .wrapper {
        @include modal-container;
    }

    .modal {
        @include modal-work-station;
        padding: 20px 5px 15px;
        width: 410px;
        &__options {
            display: flex;
            gap:25px;
            justify-content: center;
        }

        .information {
            &__second {
                margin-top: 15px;
            }
        }

        .options {
            &__button {
                font-weight: unset;
                width: 120px;
                height: 30px;
                background: #598BD1;
                border-top: 2px solid #5bb7ff;
                border-left: 2px solid #5bb7ff;
                border-bottom: 2px solid #45699b;
                border-right: 2px solid #45699b;
                font-size: 14px;
                text-transform: capitalize;

                &:active {
                  border: 2px solid #598BD1;
                  font-size: 13px;
                }
            }
        }
    }
</style>