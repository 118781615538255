<template>
    <div class="menu-modal">
        <SequenceChunkWrapper
                v-slot="{processChunkAction: processChunkAction_liquid_delivery_select_section_settings}"
                sequence-id="liquid_delivery"
                step-id="select_section_settings"
                key="select_section_settings"
        >
            <SequenceChunkWrapper
                    v-slot="{processChunkAction: processChunkAction_setup_system_select_section_settings}"
                    sequence-id="setup_system"
                    step-id="select_section_settings"
                    key="select_section_settings">
                <SequenceChunkWrapper
                        sequence-id="setup_protocol_manager"
                        step-id="select_section_settings"
                        key="select_section_settings"
                        v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_setup_system_select_section_settings}"
                >
                    <button :class="selected" class="btn settings-btn" @pointerdown="
                () => {
                    processChunkAction_liquid_delivery_select_section_settings(() => showSettings(), {enabledIfEducationIsInactive: true})
                    processChunkAction_setup_system_select_section_settings(() => showSettings())
                    processChunkAction_setup_protocol_manager_setup_system_select_section_settings(() => showSettings())
                }"
                    >
                        <img src="../../img/components/settings_icons/settings.svg">
                        <span>настройка</span>
                    </button>
                </SequenceChunkWrapper>
            </SequenceChunkWrapper>
        </SequenceChunkWrapper>
        <button class="btn settings-btn"><a @click="!isEducationModeEnabled && $router.push({name: 'VirtualCare'})"><img
                src="../../img/components/settings_icons/Virtual-Care.svg"><span>Virtual Care</span></a></button>
        <button class="btn settings-btn"><a
                @click="!isEducationModeEnabled && $router.push({name: 'LicenseManager'})"><img
                src="../../img/components/settings_icons/manager-lic.svg"><span>Менеджер лицензий</span></a></button>
        <button class="btn settings-btn"><a @click="!isEducationModeEnabled && $router.push({name: 'Reference'})"><img
                src="../../img/components/settings_icons/ref.svg"><span>Справка</span></a></button>
        <button class="btn settings-btn" @click="showShutdown"><img
                src="../../img/components/settings_icons/off.svg"><span>Выключение</span></button>
    </div>
</template>

<script>

    export default {
        props: {
            isSelected: Boolean,
        },
        methods: {
            showSettings() {
                this.$emit('show-settings')
            },
            showShutdown() {
                if (!this.isEducationModeEnabled)
                    this.$emit('show-shutdown');
            }
        },
        computed: {
            selected() {
                return {
                    selected: this.isSelected,
                }
            }
        }
    }
</script>

<style scoped lang="scss">


@import "../../scss/components/menu-modal";

</style>