<template>
    <div class="injection-process-ended-view">
        <div class="injection-process-ended-view__info-container">
            <InjectionProcessBrief
                    v-if="activeMode === modes.brief"
                    :max-pressure="maxPressure"
                    :completed-protocols-list="completedProtocolsList"
                    :total-duration="totalDuration"
            />
            <InjectionProcessStaticChart
                    v-else-if="activeMode === modes.chart"
                    :max-pressure="maxPressure"
                    :completed-protocols-list="completedProtocolsList"
                    :expired-time="expiredTime"
            />
        </div>
        <div class="injection-process-ended-view__navigation">
            <div class="injection-process-ended-view__navigation__mode-toggler">
                <div class="injection-process-ended-view__btn" @click="setActiveModeBrief">
                    Сводка
                </div>
                <SequenceChunkWrapper
                        v-slot="{processChunkAction: processChunkActionInjectionProcedureChooseGraph}"
                        sequence-id="injection_procedure"
                        step-id="choose_Graph"
                        key="choose_Graph"
                >
                    <div class="injection-process-ended-view__btn" @click="() => processChunkActionInjectionProcedureChooseGraph(setActiveModeChart, {enabledIfEducationIsInactive: true})">
                        График
                    </div>
                </SequenceChunkWrapper>
            </div>
            <div class="injection-process-ended-view__navigation__notifications">
                <div class="injection-process-ended-view__navigation__notifications-header">Напоминания</div>
                <div class="injection-process-ended-view__navigation__notifications__list">
                    <div class="item" v-for="(reminder, index) in reminders_list" v-if="reminder.time !== '--'">
                        <span>{{index + 1}}</span>
                        <span>{{reminder.time}}</span>
                    </div>
                </div>
            </div>
            <div class="injection-process-ended-view__navigation__following-actions">
                <span>Следующая инъекция</span>

                <div @click="injectionDone" class="injection-process-ended-view__btn">Тот же пациент</div>
                <SequenceChunkWrapper
                        v-slot="{processChunkAction: processChunkActionInjectionProcedurePressNewPatientButton}"
                        sequence-id="injection_procedure"
                        step-id="press_New_patient_button"
                        key="press_New_patient_button"
                >
                    <div @click="() => {
                        processChunkActionInjectionProcedurePressNewPatientButton(injectionDone, {enabledIfEducationIsInactive: true})
                    }"
                         class="injection-process-ended-view__btn injection-process-ended-view__btn--active">Новый
                        пациент
                    </div>
                </SequenceChunkWrapper>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from '@vue/composition-api'
    import { computed } from '@vue/composition-api/dist/vue-composition-api'
    import {mapGetters} from 'vuex';
    import InjectionProcessBrief from '@/components/InjectionProcess/InjectionProcessEnded/InjectionProcessBrief'
    import InjectionProcessStaticChart
        from '@/components/InjectionProcess/InjectionProcessEnded/InjectionProcessStaticChart'

    export default {
        name: "InjectionProcessEndedView",
        components: {
            InjectionProcessBrief,
            InjectionProcessStaticChart,
        },
        props: {
            completedProtocolsList: {
                type: Array,
                required: true
            },
            maxPressure: {
                type: Number,
                required: true
            }
        },
        computed: {
            ...mapGetters([
                'reminders_list'
            ])
        },
        methods: {
            injectionDone() {
                this.$store.commit('changeStatusProtocolLock', false);
                this.$store.dispatch('injector/afterInjection', {
                    status: false,
                    phases: this.completedProtocolsList,
                })
            },
        },
        setup(props) {
            /** MODES TOGGLING **/
            const activeMode = ref()
            const modes = {
                brief: 'brief',
                chart: 'chart'
            }
            const setActiveModeBrief = () => activeMode.value = modes.brief
            const setActiveModeChart = () => activeMode.value = modes.chart
            const modesTogglingModule = {activeMode, modes, setActiveModeBrief, setActiveModeChart}


            const expiredTime = computed(() => props.completedProtocolsList.reduce((total, {duration, completedDuration}) => total + (completedDuration ? completedDuration : duration), 0))
            const totalDuration = computed(() => {
                const addZeroIfNeed = num => num < 10 ? ('0' + num) : num

                const hours = addZeroIfNeed(Math.floor(expiredTime.value / 3600))
                const minutes = addZeroIfNeed(Math.floor((expiredTime.value % 3600) / 60))
                const seconds = addZeroIfNeed(expiredTime.value % 60)

                return `${hours}:${minutes}:${seconds}`
            })

            setActiveModeBrief()

            return {
                ...modesTogglingModule,
                expiredTime,
                totalDuration
            }
        }
    }
</script>

<style lang="scss" scoped>
    .injection-process-ended-view {
        display: flex;
        flex-grow: 1;
        padding: 5px;
        column-gap: 10px;
        min-width: 0;
        height: 450px;

        &__info-container {
            flex-grow: 1;
            background-color: #c7cfc6;
            min-width: 0;
        }

        &__navigation {
            flex: 0 0 130px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            &__mode-toggler{

            }

            &__notifications, &__following-actions {
                padding: 15px;
                color: white;
                background-color: #5d86a0;
                border-radius: 10px;
                border: #41525f 1px solid;
            }

            &__following-actions {
                padding: 15px 0px 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-grow: 1;
                span {
                    text-align: center;
                    margin-bottom: 20px;
                    font-size: 10px;
                    font-weight: 700;
                }

            }

            .injection-process-ended-view__btn {
                font-size: 10px;
                width: 90%;
            }

            &__notifications {
                flex-grow: 2;
                font-weight: 700;
                font-size: 12px;

                &__list {
                    margin-top: 10px;
                    .item {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 5px;
                    }

                }
            }
        }

        &__btn {
            padding: 10px 10px;
            background-color: #9ab4c7;
            text-align: center;
            color: black;
            font-weight: 700;
            font-size: 12px;
            box-shadow: 1px 5px 5px #6E818F;
            border-bottom: 1px solid black;
            cursor: pointer;

            &--active {
                background-color: #ffdf9a;
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
</style>