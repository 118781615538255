<template>
    <button class="total-btn">
        <div class="total-btn__name">Всего B</div>
        <div class="total-btn__data">
            <div class="">ml</div>
            <div>{{totalB}}</div>
        </div>
    </button>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: "TotalA",

        computed: mapGetters([
            'totalB'
        ])
    }
</script>

<style scoped lang="scss">

    @import "../scss/variable";
    @import "../scss/mixin";

    .total-btn {
       @include total;
        background: $secondary;
    }

</style>