<template>
    <button class="control__btn" @click="passEvent">{{ value }}</button>
</template>

<script>
    export default {
        name: 'ControlBtn',
        props: {
            value: String,
        },

        methods: {
            passEvent() {
                this.$emit('action')
                this.$store.commit('stateControl', false);
                this.$store.dispatch('showRow', false);
                this.$store.dispatch('clearHighlightedRow')
            }
        }
    }
</script>

<style scoped lang="scss">

    .control__btn {
        height: 37px;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;

    }

</style>