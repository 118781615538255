<template>
    <div :class="[isSelected, isDisabled]" ref="btn" class="volume" @click="e => !disabled && enableCalc(e)">
        {{volume}}
        <template v-if='contrast'>
            <div :class="managerSize" class="dual-flow contrast">{{contrast + '%'}}</div>
            <div :class="managerSize" class="dual-flow saline">{{saline + '%'}}</div>
        </template>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "Volume",
        props: {
            value: [String, Number],
            contrast: [Number, String],
            saline: Number,
            volume: [String, Number],
            manager: Boolean,
            wrapped: Boolean,
            disabled: {
                type: Boolean,
                default: false,
            },
            index: {
                type: Number,
            }
        },

        data() {
            return {
                range: '1-200 (1) ml',
            }
        },

        methods: {

            enableCalc() {
                this.$emit('focused', {event: this.$refs.btn, f: async () => {
                    await this.$store.dispatch('clearHighlightedRow')
                    await this.$store.dispatch('setSelectedRowButton', 'volume');

                    return this.$store.dispatch('enableCalc', {
                        range: this.range,
                        boolean: true,
                    });
                }})
            }
        },
        computed: {
            ...mapGetters([
                'getEditableRow',
                'getSelectedRowButton'
            ]),
            managerSize() {
                return {
                    'manager-size': this.manager,
                }
            },
            isSelected() {
                return {
                    enabled: this.index === this.getEditableRow && this.getSelectedRowButton === 'volume',
                }
            },
            isDisabled() {
                return {
                    disabled: this.getSelectedRowButton !== null,
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .enabled {
        background: black !important;
        color: white;
        border: 2px solid black;
    }

    .disabled {
        pointer-events: none;
    }

    @import "@/scss/variable";
    @import "@/scss/mixin";

    .volume {
        @include scripts;
        position: relative;
        cursor: pointer;
        z-index: 1;
    }

    .dual-flow {
        text-align: left;
        padding-left: 2px;
        font-size: 9px;
        font-weight: 400;
        z-index: -1;
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .contrast {
        background-color: transparent;
        top: 0;
        left: 0;
    }

    .saline {
        background-color: transparent;
        bottom: 0;
        left: 0;
    }

    .manager-size {
        font-size: 8px;
    }

</style>