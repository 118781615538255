<template>
    <div id="DualFlow" class="dual-flow">
        <div class="dual-flow-content">
            <div class="dual-flow-content-header">DualFlow</div>
            <div class="dual-flow-content-content">
                <div class="dual-flow-info-display">
                    <div class="parameter-range">
                        <div class="parameter-range-header">Диапазон параметра:</div>
                        <div class="parameter-range-limit">5% - 95%</div>
                        <div class="parameter-range-increment">Приращивание: 5%</div>
                    </div>
                    <div class="contrast-agent">
                        <div class="contrast-agent-header">Контрастное вещество %</div>
                        <button class="dual-flow-info-value contrast-agent-value focused" v-if="getContrast">{{getContrast}}</button>
                        <button class="dual-flow-info-value contrast-agent-value focused" v-else>-</button>
                    </div>
                    <div class="saline">
                        <div class="saline-header">Физиологич. раствор %</div>
                        <div class="dual-flow-info-value saline-value" v-if="getContrast">{{getSaline}}</div>
                        <div class="dual-flow-info-value saline-value" v-else>-</div>
                    </div>
                </div>
                <app-calc class="dual-flow-input"></app-calc>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import AppCalc from '@/components/Calc'

    export default {
        name: "DualFlow",

        components: {
            AppCalc
        },

        data() {
            return {
                show: false,
            }
        },

        computed: mapGetters([
            'getContrast',
            'getSaline',
        ])


    }
</script>

<style scoped lang="scss">

    @import "../../scss/components/dual-flow";

</style>