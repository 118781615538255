import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        RZTPreview: false,
        selected: '',
        areaButton: '',
        RZTPage: 1,
        selectedButton: '',
        preview: 'Предпросмотр',

    },
    getters: {
        RZTPreview(state) {
            return state.RZTPreview;
        },
        selected(state) {
            return state.selected;
        },
        areaButton(state) {
            return state.areaButton;
        },
        RZTPage(state) {
            return state.RZTPage;
        },
        selectedButton(state) {
            return state.selectedButton;
        },
        preview(state) {
            return state.preview;
        },
    },
    mutations: {
        changeRZTPreviewStatus(state, status) {
            state.RZTPreview = status;
        },
        changeSelected(state, payload) {
            state.selected = payload;
        },
        changeAreaButtonName(state, buttonName) {
            state.areaButton = buttonName;
        },
        changeRZTPage(state, number) {
            state.RZTPage = number;
        },
        changeSelectedButton(state, name) {
           state.selectedButton = name;
        },
        changePreview(state, name) {
            state.preview = name;
        }
    },
    actions: {
        changeRZTPreviewStatus({commit}, payload) {
            commit('changeRZTPreviewStatus', payload);
        },
        changeSelected({commit}, payload) {
            commit('changeSelected', payload)
        },
        changeAreaButtonName({commit}, payload) {
            commit('changeAreaButtonName', payload)
        },
        changeRZTPage({commit}, payload) {
            commit('changeRZTPage', payload);
        },
        changeSelectedButton({commit}, payload) {
            commit('changeSelectedButton', payload);
        },
        changePreview({commit}, payload) {
            commit('changePreview', payload);
        }
    }
}