<template>
    <div class="container">
        <div class="computer">
            <div v-if="!workstation_is_on" key="off" class="screen-black"></div>
            <div v-else class="screen settings-preset" key="enabled">
                <div class="manager-header">
                    <div class="header-container">
                        <img src="../img/components/settings_icons/P3T.svg">
                        <span>Настройка предустановки</span>
                    </div>
                    <router-link :to="{name: 'WorkStation'}"
                                 tag="button"
                                 class="exit-manager-btn"

                    >выход
                    </router-link>
                </div>
                <div class="manager-content">
                    <div class="area">
                        <div class="content-header area-header">
                            <area-head-button v-for="name in areaButtons"
                                              :name="name"
                                              :selected="name === areaButton"
                                              @click-area-button="selectAreaButton($event)"
                            />
                        </div>
                        <div class="column area-content">
                            <div class="column-top">
                                <head-button v-for="(part, index) in body"
                                             v-if="areaButton === 'Изм. предустан'"
                                             :part="part"
                                             :index="index"
                                             @select-part="selectPart($event)"
                                             :selected="selected === part"
                                             :isHidden="hidden_body[index]"
                                >
                                </head-button>
                                <img class="area-background" src="../img/man.svg">
                            </div>
                            <div class="column-footer">
                                <button class="btn area-btn show-all"
                                ><span>отобразить все</span></button>
                            </div>
                        </div>
                    </div>
                    <div class="protocols">
                        <div class="content-header protocols-header">предустановки</div>
                        <div class="column protocols-content">
                            <div class="column-top">
                                <button v-if="selected === 'голова' && areaButton === 'Изм. предустан'"
                                        class="protocol-btn btn"
                                        @pointerdown="showRZTPreview"
                                        :class="isSelected"
                                >
                                    <div class="data-protocol-btn">
                                        <div class="data-protocol__header">
                                            <span class="protocol-name">test</span>
                                            <span class="part-name">Голова</span>
                                        </div>
                                        <div class="data-protocol__image">
                                            <img src="../img/components/P3T.svg" alt="">
                                        </div>
                                    </div>
                                </button>
                                <rzt-button v-for="name in RZTButtons"
                                            v-else-if="areaButton === 'Созд. нов.пред.'"
                                            :name="name"
                                            :isSelected="name === selectedButton"
                                            @select-button="selectButton($event)"
                                >
                                    <div class="data-protocol-btn">
                                        <div class="data-protocol__header">
                                            <span class="protocol-name">РЗТ Cardiac</span>
                                            <span class="part-name"></span>
                                        </div>
                                        <div class="data-protocol__image">
                                            <img src="../img/components/P3T.svg" alt="">
                                        </div>
                                    </div>
                                </rzt-button>
                            </div>
                            <div class="column-footer">
                                <button class="btn advanced-customization">
                                    <router-link :to="{name: 'AdvancedSettings'}">Расширенная настройка</router-link>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="preview">
                        <div class="content-header preview-header">{{preview}}</div>
                        <div class="column preview-content">
                            <template v-if="RZTPreview">
                                <div class="column-top active-protocol-top" v-if="RZTPage === 1">
                                    <div class="p3t-item">
                                        <div class="item-name">Марка контр. в-ва</div>
                                        <div class="inline-wrapper">
                                            <span class="units"></span>
                                            <div class="value">--</div>
                                        </div>

                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Концентрация контр. вещества</div>
                                        <div class="inline-wrapper">
                                            <span class="units">mg/ml</span>
                                            <div class="value">300</div>
                                        </div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Конт. в-во: разм. емк</div>
                                        <div class="inline-wrapper">
                                            <span class="units">ml</span>
                                            <div class="value">--</div>
                                        </div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Тестовое введение</div>
                                        <div class="inline-wrapper">
                                            <span class="units"></span>
                                            <div class="value">да</div>
                                        </div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Жидк. тест. введения</div>
                                        <div class="inline-wrapper">
                                            <span class="units"></span>
                                            <div class="value">Физ. Раствор</div>
                                        </div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Метод тест. введения</div>
                                        <div class="inline-wrapper">
                                            <span class="units"></span>
                                            <div class="value">Обьем</div>
                                        </div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Объём тест. введения</div>
                                        <div class="inline-wrapper">
                                            <span class="units">ml</span>
                                            <div class="value">10</div>
                                        </div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Прод-ть тест. введения</div>
                                        <div class="inline-wrapper">
                                            <span class="units">mm:ss</span>
                                            <div class="value">00:05</div>
                                        </div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Болюсное введение</div>
                                        <div class="inline-wrapper">
                                            <span class="units"></span>
                                            <div class="value">да</div>
                                        </div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">V ввод болюс. к .в-ва</div>
                                        <div class="inline-wrapper">
                                            <span class="units">ml</span>
                                            <div class="value">ml</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="column-top active-protocol-top" v-else-if="RZTPage === 2">
                                    <div class="p3t-item">
                                        <div class="item-name">DualFLow</div>
                                        <span class="units"></span>
                                        <div class="value">да</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">V в. болюс. физ. р-ра</div>
                                        <span class="units">ml</span>
                                        <div class="value">40</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Диапазоны веса</div>
                                        <span class="units">kg</span>
                                        <div class="value">по ум.</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Макс. расход</div>
                                        <span class="units">ml/s</span>
                                        <div class="value">6,0</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Корректир. длительн.</div>
                                        <span class="units">mm:ss</span>
                                        <div class="value">00:04</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Весовые коэффициенты</div>
                                        <span class="units">gl/kg</span>
                                        <div class="value">по ум.</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Мин. длит. введения</div>
                                        <span class="units">mm:ss</span>
                                        <div class="value">00:16</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Объем пром. ф. р-ром</div>
                                        <span class="units">ml</span>
                                        <div class="value">30</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Корр. сокр. протокола</div>
                                        <span class="units">mm:ss</span>
                                        <div class="value">00:03</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Макс. загрузка йода</div>
                                        <span class="units">gl</span>
                                        <div class="value">38</div>
                                    </div>
                                </div>
                                <div class="column-top active-protocol-top active-protocol-top--last" v-else>
                                    <div class="p3t-item">
                                        <div class="item-name">Мин. загрузка йода</div>
                                        <span class="units">gl</span>
                                        <div class="value">20</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Макс. диагн. V к. в-ва</div>
                                        <span class="units">ml</span>
                                        <div class="value">194</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Макс. диагн. V ф. в-ва</div>
                                        <span class="units">ml</span>
                                        <div class="value">194</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Предел давления</div>
                                        <span class="units">psi</span>
                                        <div class="value">325</div>
                                    </div>
                                    <div class="p3t-item">
                                        <div class="item-name">Продолжит. сканир-ия</div>
                                        <span class="units">mm:ss</span>
                                        <div class="value">00:10</div>
                                    </div>
                                    <div class="p3t-item bordered">
                                        <div class="item-name">Метод ввода массы</div>
                                        <span class="units"></span>
                                        <div class="value">Диап. мас.</div>
                                    </div>
                                </div>
                                <div class="column-footer active-protocol-footer rzt-footer">
                                    <chevron-button @change-page="changeRZTPage(1)" v-if="RZTPage === 2" :page="2"/>
                                    <chevron-button class="main-chevron" @change-page="changePage" :page="page"/>
                                    <button class="btn preview-btn save-btn rzt-button">
                                        <router-link :to="{name: 'WorkStation'}">ок</router-link>
                                    </button>
                                </div>
                            </template>
                            <template v-else>
                                <div class="column-top">
                                    <p>Доп. инф. см. в руководстве по эксплуатации РЗТ</p>
                                </div>
                                <div class="column-footer">

                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="stand">
                <comp-lamp class="light" :class="isLightOn"/>
                <input type="checkbox" class="stand__power-button" @input="switchOnComp(true)">
            </div>
        </div>
        <router-modal/>
    </div>
</template>

<script>

    import {mapGetters, mapState} from 'vuex';

    import HeadButton from '@/components/HeadButton'
    import AreaHeadButton from '@/components/presetbuttons/AreaHeadButton'
    import ChevronButton from '@/components/ChevronBtn';
    import RztButton from '@/components/presetbuttons/RZTButton'
    import CompLamp from '@/components/AppLamp'
    import RouterModal from '@/components/modals/RouterModal'

    export default {
        components: {
            HeadButton,
            AreaHeadButton,
            ChevronButton,
            RztButton,
            CompLamp,
            RouterModal
        },
        data() {
            return {
                areaButtons: [
                    'Изм. предустан',
                    'Созд. нов.пред.',
                ],
                RZTButtons: [
                    'РЗТ Cardiac',
                    'РЗТ Abdomen',
                    'РЗТ РА',
                ],
            }
        },
        methods: {
            selectPart(body) {
                this.$store.dispatch('station/settingsPreset/changeSelected', body.part)
                this.$store.dispatch('station/settingsPreset/changeRZTPreviewStatus', false)
                this.$store.dispatch('station/settingsPreset/changeRZTPage', 1);
            },
            selectAreaButton(buttonName) {
                this.$store.dispatch('station/settingsPreset/changeAreaButtonName', buttonName)
                this.$store.dispatch('station/settingsPreset/changeSelected', '');
                this.$store.dispatch('station/settingsPreset/changeRZTPreviewStatus', false)
                this.$store.dispatch('station/settingsPreset/changeRZTPage', 1);
                this.$store.dispatch('station/settingsPreset/changeSelectedButton', '');
            },
            showRZTPreview() {
                this.$store.dispatch('station/settingsPreset/changeRZTPreviewStatus', true)
                this.$store.dispatch('station/settingsPreset/changePreview', 'МОДУЛЬ: РЗТ Cardiac')
            },
            changePage() {
                if (this.RZTPage === 1 || this.RZTPage === 3) {
                    this.$store.dispatch('station/settingsPreset/changeRZTPage', 2)
                } else if (this.RZTPage === 2) {
                    this.$store.dispatch('station/settingsPreset/changeRZTPage', 3)
                }
            },
            changeRZTPage(number) {
                this.$store.dispatch('station/settingsPreset/changeRZTPage', number);
            },
            selectButton(name) {
                this.$store.dispatch('station/settingsPreset/changeSelectedButton', name);
            },
            switchOnComp() {
                if (!this.workstation_is_on) {
                    this.$router.push({name: 'WorkStation'});
                    this.$store.commit('switchStateOfWorkstation', {
                        state: true,
                        confirm: true,
                    });
                }
            },
        },
        computed: {
            ...mapGetters([
                'body',
                'hidden_body',
                'workstation_is_on'
            ]),
            isSelected() {
                return {
                    selected: this.RZTPreview
                }
            },
            page() {
                if (this.RZTPage === 1 || this.RZTPage === 2) {
                    return 1;
                } else {
                    return 2;
                }
            },
            isLightOn() {
                return {
                    on: this.workstation_is_on,
                }
            },
            ...mapGetters('station/settingsPreset', [
                'RZTPreview',
                'selected',
                'areaButton',
                'RZTPage',
                'selectedButton',
                'preview',
            ])
        },
    }
</script>

<style scoped lang="scss">

    @import "../scss/pages/settings-preset";

</style>