<template>
    <div>
        <div class="container">
            <span v-if="isEducationModeEnabled && $store.getters.getActiveSequenceId === 'liquid_delivery'"
                  style="margin-top: 20px;position: absolute; z-index: 1000; font-weight: 700; color: blue">Чтобы получить информацию о функциях наведите указатель на интересующую кнопку. При нажатии на кнопку отобразятся её параметры</span>
            <div class="computer">
                <div v-if="!workstation_is_on" key="off" class="screen-black"></div>
                <div v-else class="screen" key="enabled">
                    <div class="header system-settings-header">
                        <img src="../img/LiquidDelivery.svg">
                        <span>Доставка жидкости</span>
                    </div>
                    <div v-if="showFirstPage" class="screen-settings">
                        <div class="screen-settings-column first-column">
                            <!-- левое меню настроек -->
                            <template v-for="(value, name) in LD_settings_left">
                                <v-tooltip
                                        content-class="education-mode-ld-settings-tooltip"
                                        attach=".container"
                                        absolute
                                        :disabled="!isEducationModeEnabled"
                                >
                                    <template #activator="{on}">
                                        <left-button
                                                v-on="on"
                                                :data-enable-pointer-events="true"
                                                :name="name"
                                                :value="value"
                                                :isSelected="selectedButton === name"
                                                @select-button="selectButton(name)"/>
                                    </template>
                                    <template v-if="isEducationModeEnabled" #default>{{getTooltipTextForButton(name)}}
                                    </template>
                                </v-tooltip>
                            </template>
                        </div>
                        <div class="screen-settings-column second-column">
                            <option-button
                                    v-show="selectedButton === 'Скорость тестового введения' && !showFirstOptions"
                                    v-for="value in test_injection_speed_2"
                                    :value="value"
                                    @select-option="selectOption(value)"/>
                            <option-button v-show="showFirstOptions"
                                           v-for="value in options"
                                           :value="value"
                                           @select-option="selectOption(value)"
                            />
                            <chevron-button class="arrow-language"
                                            v-if="selectedButton === 'Скорость тестового введения'"
                                            @change-page="changeShowFirstOptions(!showFirstOptions)"
                                            :page="pageProps"
                            />
                        </div>
                        <!-- правое меню настроек на первой странице -->
                        <div class="screen-settings-column third-column">
                            <template v-for="(value, name) in LD_settings_right">
                                <v-tooltip
                                        content-class="education-mode-ld-settings-tooltip"
                                        absolute
                                        attach=".container"
                                        :disabled="!isEducationModeEnabled"
                                >
                                    <template #activator="{on}">
                                        <right-button
                                                v-on="on"
                                                :data-enable-pointer-events="true"
                                                :name="name"
                                                :value="value"
                                                :isSelected="selectedButton === name"
                                                @select-button="selectButton(name)"/>
                                    </template>
                                    <template v-if="isEducationModeEnabled" #default>{{getTooltipTextForButton(name)}}
                                    </template>
                                </v-tooltip>
                            </template>
                        </div>
                        <!-- просто кнопки (отмена, ок, по умолчанию) -->
                        <div class="screen-settings-column fourth-column">
                            <button class="btn screen-settings-btn" @pointerdown="setDefaultSettings">
                                <span>По умолчанию</span></button>
                            <button class="btn screen-settings-btn confirm-btn" @pointerdown="changeDone(true)">
                                <SequenceChunkWrapper
                                        v-slot="{processChunkAction: processChunkAction_liquid_delivery_press_Ok_button_quiet}"
                                        sequence-id="liquid_delivery"
                                        step-id="press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup"
                                        chunk-id="press_Ok_button_quiet"
                                        key="press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup"
                                >
                                    <a @click="processChunkAction_liquid_delivery_press_Ok_button_quiet(() => $router.push({name: 'WorkStation'}), {enabledIfEducationIsInactive: true})">ОК</a>
                            </SequenceChunkWrapper>
                            </button>
                            <SequenceChunkWrapper
                                    v-slot="{processChunkAction: processChunkAction_liquid_delivery_press_Cancel_button_quiet}"
                                    sequence-id="liquid_delivery"
                                    step-id="press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup"
                                    chunk-id="press_Cancel_button_quiet"
                                    key="press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup"
                            >
                                <button class="btn screen-settings-btn"
                                        @pointerdown="processChunkAction_liquid_delivery_press_Cancel_button_quiet(() => LD_cancelSettings(), {enabledIfEducationIsInactive: true})">
                                    <a>Отмена</a></button>
                            </SequenceChunkWrapper>
                        </div>
                    </div>
                    <!-- вторая страница -->
                    <div v-else class="screen-settings">
                        <!-- левое меню настроек -->
                        <div class="screen-settings-column first-column">
                            <template v-for="(value, name) in LD_settings_second_page">
                                <v-tooltip
                                        content-class="education-mode-ld-settings-tooltip"
                                        attach=".container"
                                        absolute
                                        :disabled="!isEducationModeEnabled"
                                >
                                    <template #activator="{on}">
                                        <left-button
                                                v-on="on"
                                                :data-enable-pointer-events="true"
                                                :name="name"
                                                :value="value"
                                                :isSelected="selectedButton === name"
                                                @select-button="selectButton(name)"/>
                                    </template>
                                    <template v-if="isEducationModeEnabled" #default>
                                        {{getTooltipTextForButton(name)}}
                                    </template>
                                </v-tooltip>
                            </template>
                        </div>
                        <div class="screen-settings-column second-column">
                            <option-button v-show="showFirstOptions"
                                           v-for="value in options"
                                           :value="value"
                                           @select-option="selectOption(value)"
                            />
                        </div>
                        <div class="screen-settings-column third-column"></div>
                        <div class="screen-settings-column fourth-column">
                            <button class="btn screen-settings-btn" @pointerdown="setDefaultSettings">
                                <span>По умолчанию</span></button>
                            <button class="btn screen-settings-btn confirm-btn" @pointerdown="changeDone(true)">
                                <SequenceChunkWrapper
                                        v-slot="{processChunkAction: processChunkAction_liquid_delivery_press_Ok_button_quiet}"
                                        sequence-id="liquid_delivery"
                                        step-id="press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup"
                                        chunk-id="press_Ok_button_quiet_second_page"
                                        key="press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup"
                                >
                                    <a @click="processChunkAction_liquid_delivery_press_Ok_button_quiet(() => $router.push({name: 'WorkStation'}), {enabledIfEducationIsInactive: true})">ОК</a>
                                </SequenceChunkWrapper>
                            </button>
                            <SequenceChunkWrapper
                                    v-slot="{processChunkAction: processChunkAction_liquid_delivery_press_Cancel_button_quiet}"
                                    sequence-id="liquid_delivery"
                                    step-id="press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup"
                                    chunk-id="press_Cancel_button_quiet_second_page"
                                    key="press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup"
                            >
                                <button class="btn screen-settings-btn"
                                        @pointerdown="processChunkAction_liquid_delivery_press_Cancel_button_quiet(() => LD_cancelSettings(), {enabledIfEducationIsInactive: true})">
                                    <a>Отмена</a></button>
                            </SequenceChunkWrapper>
                        </div>
                        <div class="types-contrast-agent"
                             v-show="selectedButton === 'Типы контрастного вещества'"
                        >
                            <div class="header system-settings-header">

                                <span>Конфигурация контрастного вещества</span>
                            </div>
                            <div class="types-contrast-agent__content">
                                <div class="left-menu">
                                    <div class="left-menu__btns">
                                        <button class="btn"><span> - 300 — ml </span></button>
                                        <button class="btn"><span>- 350 — ml </span></button>
                                        <button class="btn"><span>UV 370 100 ml</span></button>
                                        <button class="btn"><span>UV 300 200 ml</span></button>
                                    </div>
                                    <div class="left-menu__space">
                                    </div>
                                </div>
                                <div class="right-menu">
                                    <button :data-enable-pointer-events="true" class="btn" @click="clearSelectedButton"><span>По умолчанию</span></button>
                                    <button class="btn"
                                            @click="clearSelectedButton"
                                            :data-enable-pointer-events="true"
                                    >
                                        <span>ОК</span>
                                    </button>
                                    <button :data-enable-pointer-events="true" class="btn" @click="clearSelectedButton">Отмена</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-btn">
                        <button :data-enable-pointer-events="true" class="btn show-more" @pointerdown="goToPage"><i :class="page"></i></button>
                    </div>
                </div>
                <modal-cancel v-if="done && isChanged"
                              :text="modalText"
                              :link="true"
                              @cancel="LD_cancelSettings"
                              @confirm="handleConfirm"
                />
                <div class="stand">
                    <comp-lamp class="light" :class="isLightOn"/>
                    <input type="checkbox" class="stand__power-button" @input="switchOnComp(true)">
                </div>
            </div>
        </div>
        <router-modal/>
      <InfoButton style="width: 13.5%; min-width: 259px" @open-confirm="openModalTerm"/>
      <TermDefinition v-if="modalEx" style="z-index: 99999" @close-confirm="closeModalTerm"/>
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';

    import LeftButton from '@/components/settingsbuttons/LeftButton';
    import RightButton from '@/components/settingsbuttons/RightButton'
    import OptionButton from '@/components/settingsbuttons/OptionButton'
    import ChevronButton from '@/components/ChevronBtn'
    import SystemButton from '@/components/SystemBtn'
    import ModalCancel from '@/components/ResetProtocolModal'
    import CompLamp from '@/components/AppLamp'
    import RouterModal from '@/components/modals/RouterModal'
    import TermDefinition from "@/components/TermDefinition.vue";
    import InfoButton from "@/components/InfoButton.vue";

    export default {
        data() {
            return {
                modalEx: false,
                selectedPeriod: '',
                system_options: {
                    //данные для LiquidDelivery page
                    'Режим заполнения': 'filling_mode',
                    'Источник заполнения': 'filling_source',
                    'Тип LPCT': 'type_of_LPCT',
                    'Источник тестового введения': 'test_injection_source',
                    'Объем тестового введения': 'test_injection_volume',
                    'Скорость тестового введения': 'test_injection_speed',
                    'Режим прогр. протокола': 'protocol_programming_mode',
                    'Отображение суммарного объема': 'total_volume_display',
                    'Автоматический отвод': 'automatic_retraction',
                    'Автоматическое продвижение': 'automatic_promotion',
                    'Авт. заполн. объема д/удален. воздуха': 'aut_fill_volume_for_removed_air',
                    'Управление жидкостью': 'fluid_management',
                    'Типы контрастного вещества': 'types_contrast_agent',
                    'Сброс протокола после введения': 'reset_protocol_after_injection',

                },
                modalText: 'Параметры конфигурации были изменены. Сохранить?',
                cashed_settings_left: {},
                cashed_settings_right: {},
                cashed_settings_second_page: {},

            }
        },
        components: {
          InfoButton, TermDefinition,
            LeftButton,
            RightButton,
            OptionButton,
            ChevronButton,
            SystemButton,
            ModalCancel,
            CompLamp,
            RouterModal,
        },
        computed: {
            ...mapGetters('station/settingsLiquidDelivery', [
                'LD_settings_tooltips',
                'LD_settings_left',
                'LD_settings_right',
                'LD_settings_second_page',
                'test_injection_speed_2',
                'selectedButton',
                'showFirstOptions',
                'isChanged',
                'done',
                'showFirstPage',
            ]),
            ...mapGetters('sequences_store', ['getActiveSequenceId']),
            ...mapGetters(['workstation_is_on']),
            page() {
                if (this.showFirstPage) {
                    return {
                        'fas fa-chevron-right': true,
                    }
                } else {
                    return {
                        'fas fa-chevron-left': true,
                    }
                }
            },
            isLightOn() {
                return {
                    on: this.workstation_is_on,
                }
            },
            options() {
                return this.$store.getters[`station/settingsLiquidDelivery/${this.system_options[this.selectedButton]}`];
            },
            pageProps() {
                if (this.showFirstOptions) {
                    return 1;
                } else {
                    return 2;
                }
            }
        },
        methods: {
            getTooltipTextForButton(name) {
                 return this.getActiveSequenceId === 'liquid_delivery' ? this.LD_settings_tooltips[name] : '';
            },
            handleConfirm() {
                this.$store.dispatch('station/settingsLiquidDelivery/changeIsChanged', false);
                this.$store.dispatch('station/settingsLiquidDelivery/changeDone', false);

            },
            selectButton(name) {
                this.$store.dispatch('station/settingsLiquidDelivery/assignSelectedButton', name)
                this.$store.dispatch('station/settingsLiquidDelivery/changeShowFirstOptions', true);
                this.selectedPeriod = '';
            },
            clearSelectedButton() {
                this.$store.dispatch('station/settingsLiquidDelivery/clearSelectedButton')
            },
            selectOption(option) {
                this.$store.dispatch('station/settingsLiquidDelivery/LD_setNewValue', {
                    name: this.selectedButton,
                    value: option,
                })
                this.$store.dispatch('station/settingsLiquidDelivery/clearSelectedButton')
                this.$store.dispatch('station/settingsLiquidDelivery/changeShowFirstOptions', true);
                this.$store.dispatch('station/settingsLiquidDelivery/changeIsChanged', true);
            },
            goToPage() {
                this.$store.dispatch('station/settingsLiquidDelivery/changePage');
                this.$store.dispatch('station/settingsLiquidDelivery/clearSelectedButton');
            },
            setDefaultSettings() {
                this.$store.dispatch('station/settingsLiquidDelivery/defaultSettings');
            },
            LD_cancelSettings() {
                this.$store.dispatch('station/settingsLiquidDelivery/LD_cancelSettings', {
                    left: this.cashed_settings_left,
                    right: this.cashed_settings_right,
                    second_page: this.cashed_settings_second_page,
                });
                this.$store.dispatch('station/settingsLiquidDelivery/changeDone', false);
                this.$store.dispatch('station/settingsLiquidDelivery/changeIsChanged', false);
                this.$router.push({name: 'WorkStation'})
            },
            switchOnComp() {
                if (!this.workstation_is_on) {
                    this.$router.push({name: 'WorkStation'});
                    this.$store.commit('switchStateOfWorkstation', {
                        state: true,
                        confirm: true,
                    });
                }
            },
            changeShowFirstOptions(status) {
                this.$store.dispatch('station/settingsLiquidDelivery/changeShowFirstOptions', status);
            },
            changeDone(status) {
                this.$store.dispatch('station/settingsLiquidDelivery/changeDone', status);
            },
          openModalTerm() {
            this.modalEx = true;
          },
          closeModalTerm() {
            this.modalEx = false;
          },

        },
        created() {
            this.cashed_settings_left = Object.assign({}, this.LD_settings_left);
            this.cashed_settings_right = Object.assign({}, this.LD_settings_right);
            this.cashed_settings_second_page = Object.assign({}, this.LD_settings_second_page);
        },
        beforeDestroy() {
            this.$store.dispatch('station/settingsLiquidDelivery/setDefaultModule');
        },
    }
</script>

<style scoped lang="scss">

    @import "../scss/style";
    @import "../scss/pages/system-settings";
    @import "../scss/pages/liquid-delivery";

</style>