import { store } from '@/store'
import Vue from 'vue'
import router from '@/router/router'



export default {
    state: {
        sequences: {
            workstation_general_info: {
                prettyName: 'Общая информация',
                cleanup: () => store.dispatch('cleanupStoreBeforeDefaultTutorial'),
                items: [
                    {
                        id: 'main',
                        text: ''
                    }
                ]
            },
            protocol_creation: {
                prettyName: 'Создание протокола',
                cleanup: () => store.dispatch('cleanupStoreBeforeDefaultTutorial'),
                end_alert_text: 'Пример создания протокола завершен',
                items: [
                    {
                        id: 'create_or_choose_protocol',
                        text: 'Выберите стрелку, чтобы создать или изменить протокол'
                    },
                    {
                        id: 'test_introduction_B',
                        text: 'Выберите тип фазы – «Тестовое введение В»'
                    },
                    {
                        id: 'to_change_type_of_phase',
                        text: 'Чтобы изменить тип фазы (при необходимости) нажмите стрелку'
                    },
                    
                    /** AB **/
                    {
                        id: 'choose_type_of_phase_AB',
                        text: 'Выберите тип фазы – «А%В%» (DualFlow)'
                    },
                    {
                        id: 'enter_contrast_substance_AB',
                        text: 'Введите процентное содержание контрастного вещества, которое будет вводиться в фазе DualFlow. Система автоматически определит процентное содержание физиологического раствора',
                        left: true
                    },
                    {
                        id: 'choose_Flow_phase_parameter_AB',
                        text: 'Выберите параметр фазы «Расход»'
                    },
                    {
                        id: 'enter_parameter_value_Flow_AB',
                        text: 'Введите значение параметра',
                        left: true
                    },
                    {
                        id: 'select_Volume_phase_parameter_AB',
                        text: 'Выберите параметр фазы «Объем»'
                    },
                    {
                        id: 'enter_parameter_value_Volume_AB',
                        text: 'Введите значение параметра',
                        left: true
                    },
                    
                    /** PAUSE **/
                    {
                        id: 'press_arrow_question_Pause',
                        text: 'Нажмите на стрелку со знаком вопроса, чтобы создать новую фазу'
                    },
                    {
                        id: 'select_phase_type_Pause',
                        text: 'Выберите тип фазы – «Пауза»'
                    },
                    {
                        id: 'select_parameter_value_Duration_Pause',
                        text: 'Выберите параметр паузы «Длит»:'
                    },
                    {
                        id: 'enter_parameter_value_Duration_Pause',
                        text: 'Введите значение параметра',
                        left: true
                    },

                    /** CONTRAST A **/
                    {
                        id: 'press_arrow_question_Contrast_A',
                        text: 'Нажмите на стрелку со знаком вопроса, чтобы создать новую фазу'
                    },
                    {
                        id: 'choose_type_phase_Contrast_A',
                        text: 'Выберите тип фазы – «Контрастное в-во А»:'
                    },
                    {
                        id: 'choose_Flow_phase_parameter_Contrast_A',
                        text: 'Выберите параметр фазы «Расход»:'
                    },
                    {
                        id: 'enter_parameter_value_Flow_Contrast_A',
                        text: 'Введите значение параметра',
                        left: true
                    },
                    {
                        id: 'select_Volume_phase_parameter_Contrast_A',
                        text: 'Выберите параметр фазы «Объем»'
                    },
                    {
                        id: 'enter_parameter_value_Volume_Contrast_A',
                        text: 'Введите значение параметра',
                        left: true
                    },

                    /** FLUSHING A **/
                    {
                        id: 'press_arrow_question_Flushing_B',
                        text: 'Нажмите на стрелку со знаком вопроса, чтобы создать новую фазу'
                    },
                    {
                        id: 'choose_type_phase_Flushing_B',
                        text: 'Выберите тип фазы – «Промывка В»'
                    },

                    /** DELETING **/
                    {
                        id: 'to_delete_phase_choose_necessary_arrow',
                        text: 'Чтобы удалить фазу, выберите нужную стрелку, например нажмите на стрелку «А»'
                    },
                    {
                        id: 'choose_Delete',
                        text: 'Выберите «Удалить»'
                    }
                ]
            },
            protocol_manager: {
                prettyName: 'Менеджер протоколов',
                cleanup: () => store.dispatch('cleanupStoreBeforeProtocolManagerTutorial'),
                end_alert_text: 'Обучение основам работы с менеджером протоколов завершено',
                items: [
                    {
                        id: 'to_save_or_upload_select_protocol_manager',
                        text: 'Чтобы сохранять или загружать протоколы выберите «Менеджер протоколов»'
                    },
                    {
                        id: 'press_current_protocol',
                        text: 'Нажмите «Текущий протокол»'
                    },
                    {
                        id: 'select_area_to_save',
                        text: 'Выберите область, в которую хотите сохранить протокол, например «Грудная клетка»'
                    },
                    {
                        id: 'press_button_Save_into_Chest',
                        text: 'Нажмите на кнопку «Сохранить в грудная клетка»'
                    },
                    {
                        id: 'type_name_of_protocol',
                        text: 'Введите название протокола'
                    },
                    {
                        id: 'protocol_saved_press_Exit',
                        text: 'Протокол сохранён, нажмите «Выход»'
                    },
                    {
                        id: 'press_reset_protocol_button',
                        text: 'Нажмите на кнопку сбросить протокол «R»'
                    },
                    {
                        id: 'confirm_your_choice',
                        text: 'Подтвердите выбор'
                    },
                    {
                        id: 'to_upload_existing_protocol_choose_protocol_manager',
                        text: 'Чтобы загрузить ранее сохранённый протокол, выберите «Менеджер протоколов»'
                    },
                    {
                        id: 'choose_the_area_where_you_saved_the_protocol_Chest',
                        text: 'Выберите область в которую сохранили протокол',
                        multiple_dependency_one_of: [10]
                    },
                    {
                        id: 'choose_the_area_where_you_saved_the_protocol_Show_all',
                        text: 'Или нажмите «Отобразить всё»'
                    },
                    {
                        id: 'select_required_protocol',
                        text: 'Выберите нужный протокол'
                    },
                    {
                        id: 'protocol_will_be_displayed_press_Ok',
                        text: 'Протокол отобразится справа, нажмите кнопку «Ок»'
                    }
                ]
            },
            setup_protocol_manager: {
                prettyName: 'Настройка менеджера протоколов',
                cleanup: () => store.dispatch('cleanupStoreBeforeSetupProtocolManagerTutorial'),
                end_alert_text: 'Обучение основам работы с настройками менеджера протокола завершено',
                items: [
                    {
                        id: 'press_Menu_button',
                        text: 'Нажмите кнопку «Меню»'
                    },
                    {
                        id: 'select_section_settings',
                        text: 'Выберите раздел «Настройка»'
                    },
                    {
                        id: 'select_section_protocol_manager',
                        text: 'Выберите раздел «Менеджер протоколов»'
                    },
                    {
                        id: 'select_required_area_Head',
                        text: 'Выберите интересующую область, например, «Голова»'
                    },
                    {
                        id: 'to_change_order_of_protocol_press_on_required_protocol',
                        text: 'Чтобы изменить последовательность отображения протоколов нажмите на интересующий протокол'
                    },
                    {
                        id: 'press_Up_to_change_position_of_protocol',
                        text: 'Нажмите «вверх», чтобы изменить позицию протокола'
                    },
                    {
                        id: 'select_required_protocol_to_move_it_to_other_area',
                        text: 'Чтобы переместить протокол в другую область, выберите интересующий протокол'
                    },
                    {
                        id: 'select_Change_area',
                        text: 'Выберите «Изменить область»'
                    },
                    {
                        id: 'select_other_area',
                        text: 'Выберите другую область, например, «Грудная клетка»'
                    },
                    {
                        id: 'confirm_your_choice',
                        text: 'Подтвердите выбор'
                    },
                    {
                        id: 'to_rename_area',
                        text: 'Для того чтобы переименовать область (при необходимости) выберите нужную область, например «Грудная клетка» и нажмите «Переименовать»'
                    },
                    {
                        id: 'type_new_name_of_area',
                        text: 'Введите название'
                    },
                    {
                        id: 'to_hide_area',
                        text: 'Для того чтобы скрыть область (при необходимости) выберите нужную область, например «Таз»'
                    },
                    {
                        id: 'press_Hide',
                        text: 'Нажмите «Скрыть»'
                    },
                    {
                        id: 'to_remove_protocol_select_area',
                        text: 'Чтобы удалить протокол из Менеджера протоколов выберите область в которой сохранён протокол'
                    },
                    {
                        id: 'choose_protocol',
                        text: 'Выберите протокол'
                    },
                    {
                        id: 'press_Remove_button',
                        text: 'Нажмите «Удалить»'
                    },
                    {
                        id: 'confirm_your_choice_again',
                        text: 'Подтвердите выбор'
                    },
                    {
                        id: 'press_Exit_button_again',
                        text: 'Нажмите «Выход», чтобы вернуться на главный экран'
                    }
                ]
            },
            setup_system: {
                prettyName: 'Настройка системы',
                cleanup: () => store.dispatch('cleanupStoreBeforeDefaultTutorial'),
                items: [
                    {
                        id: 'press_Menu_button',
                        text: 'Нажмите на кнопку «Меню»'
                    },
                    {
                        id: 'select_section_settings',
                        text: 'Выберите раздел «Настройка»'
                    },
                    {
                        id: 'select_section_system',
                        text: 'Выберите раздел «Система»'
                    },
                    {
                        id: 'press_Ok_button_quiet',
                        text: '',
                        without_popup: true,
                        multiple_dependency_one_of: [4]
                    },
                    {
                        id: 'press_Cancel_button_quiet',
                        text: '',
                        without_popup: true
                    }
                ]
            },
            liquid_delivery: {
                prettyName: 'Доставка жидкости',
                cleanup: () => store.dispatch('cleanupStoreBeforeDefaultTutorial'),
                items: [
                    {
                        id: 'press_Menu_button',
                        text: 'Нажмите на кнопку «Меню»'
                    },
                    {
                        id: 'select_section_settings',
                        text: 'Выберите раздел «Настройка»'
                    },
                    {
                        id: 'select_section_liquid_delivery',
                        text: 'Выберите раздел «Доставка жидкости»'
                    },
                    {
                        id: 'press_Ok_button_quiet',
                        text: '',
                        without_popup: true,
                        multiple_dependency_one_of: [4]
                    },
                    {
                        id: 'press_Cancel_button_quiet',
                        text: '',
                        without_popup: true
                    }
                ]
            },
            injector_general_info: {
                prettyName: 'Общая информация',
                cleanup: () => store.dispatch('cleanupStoreBeforeDefaultTutorial'),
                items: [
                    {
                        id: 'main',
                        text: ''
                    }
                ]
            },
            SPK_injector: {
                prettyName: 'Работа с головой инъектора',
                items: [
                    {
                        id: 'start_disclaimer',
                        text: '',
                        without_popup: true
                    },
                    {
                        id: 'enter_SPK',
                        text: 'Выберите "Одноразовый набор SDS-CTP-SPK"'
                    },
                    {
                        id: 'disclaimer',
                        text: '',
                        without_popup: true
                    },
                    {
                        id: 'set_flasks',
                        text: 'Шаг 1. Установите шприцы'
                    },
                    {
                        id: 'set_needles_to_flasks',
                        text: 'Шаг 2. Установите иглы на шприцы'
                    },
                    {
                        id: 'set_flacons',
                        text: 'Шаг 3. Установите емкости с жидкостями'
                    },
                    {
                        id: 'press_autoload_button',
                        text: 'Шаг 4. Для забора жидкостей в режиме автозаполнения нажмите кнопку "Autoload"'
                    },
                    {
                        id: 'press_fillB_button',
                        text: 'Нажмите кнопку Fill B для забора физраствора',
                        multiple_dependency: [8],
                        left: true
                    },
                    {
                        id: 'press_fillA_button',
                        text: 'Нажмите кнопку Fill A для забора контрастного средства'
                    },
                    {
                        id: 'take_off_flacons',
                        text: 'Шаг 5. Снимите емкости с растворами'
                    },
                    {
                        id: 'take_off_needles',
                        text: 'Шаг 6. Снимите иглы со шприцев'
                    },
                    {
                        id: 'set_heaters',
                        text: 'Шаг 7. Установите подогреватели шприцев'
                    },
                    {
                        id: 'set_tube',
                        text: 'Шаг 8: Установите Т-образную трубку'
                    },
                    {
                        id: 'set_bleed_tube',
                        text: 'Шаг 9: Установите трубку для прокачки воздуха в системе'
                    },
                    {
                        id: 'press_Prime_button',
                        text: 'Прокачайте воздух в системе в автоматическом режиме. Для этого нажмите кнопку «Prime»'
                    },
                    {
                        id: 'turn_down_injector_head',
                        text: 'Поверните голову инъектора шприцами вниз'
                    },
                    {
                        id: 'take_off_bleed_tube',
                        text: 'Снимите трубку для прокачки воздуха'
                    },
                    {
                        id: 'connect_system_with_pactient',
                        text: 'Подключите систему к пациенту'
                    },
                    {
                        id: 'system_connected_with_patient_pause',
                        without_popup: true,
                        text: ''
                    },
                    {
                        id: 'press_Check_For_Air_button',
                        text: 'Нажмите кнпоку Check For Air (Проверка на наличие воздуха)'
                    },
                    {
                        id: 'press_arm_button',
                        text: 'Нажмите кнопку «Arm» - Активация'
                    },
                    {
                        id: 'press_run_button',
                        text: 'Нажмите желтую кнопку ПУСК для запуска инъекции'
                    },
                    {
                        id: 'injection_started_confirmation',
                        text: '',
                        without_popup: true
                    }
                ],
                on_end: () => {
                    store.dispatch('injector/reset_installation', {status: false})
                    store.commit('mainScreen/selectMode', 'Режим эмуляции')
                    router.push({name: 'Main'})
                }
            }
        },
        current_sequence_id: undefined,

        current_initial_items_indexes: [],
        current_items_indexes: [],
        current_wrapped_elements_boundings: [],

        temporary_sequence_data: {}
    },
    mutations: {
        SET_CURRENT_SEQUENCE_ID: (state, id) => state.current_sequence_id = id,

        SEQUENCE_ENDED: state => {
            state.current_sequence_id = undefined
            state.current_initial_items_indexes = []
            state.current_items_indexes = []
            state.current_wrapped_elements_boundings = []
        },

        SET_CURRENT_INITIAL_ITEMS_INDEXES: (state, indexes) => {
            state.current_initial_items_indexes = indexes
            state.current_items_indexes = indexes
        },
        SET_CURRENT_ITEMS_INDEXES: (state, indexes) => state.current_items_indexes = indexes,
        SET_CURRENT_WRAPPED_ELEMENTS_BOUNDINGS: (state, boundings) => state.current_wrapped_elements_boundings = boundings,

        SET_TEMPORARY_SEQUENCE_DATA: (state, data) => state.temporary_sequence_data = data
    },
    actions: {
        startSequence: ({commit, getters}, sequenceId) => {
            commit('SEQUENCE_ENDED')
            commit('SET_CURRENT_SEQUENCE_ID', sequenceId)
            commit('SET_CURRENT_INITIAL_ITEMS_INDEXES', [0])
        },
        endAllSequences: ({commit}) => {
            commit('SEQUENCE_ENDED')
        },

        sequenceNextStep: ({commit, getters}, {id}) => {
            let passedIndex = getters.getCurrentSequence.items.findIndex(item => item.id === id)
            commit('SET_CURRENT_ITEMS_INDEXES', getters.getCurrentSequenceItemsIndexes
                .filter(index => index !== passedIndex))

            if (getters.getCurrentSequenceInitialItemsIndexes.some(index => getters.getCurrentSequence.items[index].multiple_dependency_one_of))
                commit('SET_CURRENT_ITEMS_INDEXES', [])

            if (!getters.getCurrentSequenceItemsIndexes.length) {
                let nextItem = getters.getCurrentSequence.items[Math.max(...getters.getCurrentSequenceInitialItemsIndexes) + 1]
                if (!nextItem) {
                    if (getters.getCurrentSequence.on_end)
                        getters.getCurrentSequence.on_end()
                    sequencesEventBus.$emit('sequence-ended', {id: getters.getCurrentSequenceId, sequence: getters.getSequences[getters.getCurrentSequenceId]})
                    return commit('SEQUENCE_ENDED')
                }
                commit('SET_CURRENT_INITIAL_ITEMS_INDEXES', [
                    Math.max(...getters.getCurrentSequenceInitialItemsIndexes) + 1,
                    ...(nextItem.multiple_dependency ? nextItem.multiple_dependency : []),
                    ...(nextItem.multiple_dependency_one_of ? nextItem.multiple_dependency_one_of : [])
                ])
                commit('SET_CURRENT_WRAPPED_ELEMENTS_BOUNDINGS', [])
            } else
                commit('SET_CURRENT_WRAPPED_ELEMENTS_BOUNDINGS', getters.getCurrentWrappedElementsBoundings
                    .filter(({id: bId}) => bId !== id))
        },
        setCurrentSequenceStep: ({commit, getters}, id) => {
            commit('SET_CURRENT_WRAPPED_ELEMENTS_BOUNDINGS', [])
            commit('SET_CURRENT_INITIAL_ITEMS_INDEXES', [getters.getCurrentSequence.items.findIndex(item => item.id === id)])
        },
        setWrappedElementBounding: ({commit, getters}, {id, bounding}) => {
            if (!getters.getCurrentWrappedElementsBoundings.some(({id: bId}) => bId === id))
                commit('SET_CURRENT_WRAPPED_ELEMENTS_BOUNDINGS', [...getters.getCurrentWrappedElementsBoundings, {
                    id: id, bounding: bounding, text: getters.getCurrentSequence.items.find(item => item.id === id).text,
                    left: getters.getCurrentSequence.items.find(item => item.id === id).left,
                    top: getters.getCurrentSequence.items.find(item => item.id === id).top,
                }])
        },
        unsetWrappedElementBounding: ({commit, getters}, id) => {
            commit('SET_CURRENT_WRAPPED_ELEMENTS_BOUNDINGS', getters.getCurrentWrappedElementsBoundings.filter(b => b.id !== id))
        },

        setStaticWrappedElementBounding: ({commit, getters}, {
            width, hash, text, bounding, left, top
        }) => {
            if (!getters.getCurrentWrappedElementsBoundings.some(({text: bText, hash: bHash}) => bText === text && bHash === hash))
                commit('SET_CURRENT_WRAPPED_ELEMENTS_BOUNDINGS', [...getters.getCurrentWrappedElementsBoundings, {
                    text: text, bounding: bounding,
                    left, top, width
                }])
        },
        unsetStaticWrappedElementBounding: ({commit, getters}, {hash, text}) => {
            commit('SET_CURRENT_WRAPPED_ELEMENTS_BOUNDINGS', getters.getCurrentWrappedElementsBoundings.filter(({text: bText, hash: bHash}) => bText !== text && ((hash && bHash !== hash) || !hash)))
        },
        cleanWrappedElementBounding: ({commit}) => {
            commit('SET_CURRENT_WRAPPED_ELEMENTS_BOUNDINGS', [])
        },

        addTemporarySequenceData: ({commit, getters}, data) => {
            commit('SET_TEMPORARY_SEQUENCE_DATA', {...getters.getTemporarySequenceData, ...data})
        },
        removeTemporarySequenceData: ({commit, getters}, data) => {
            const d = getters.getTemporarySequenceData
            data.forEach(key => delete d[key])
            commit('SET_TEMPORARY_SEQUENCE_DATA', d)
        }
    },
    getters: {
        getSequences: state => state.sequences,
        getCurrentSequenceId: state => state.current_sequence_id,
        getCurrentSequence: state => state.sequences[state.current_sequence_id],

        getCurrentSequenceInitialItemsIndexes: state => state.current_initial_items_indexes,
        getCurrentSequenceItemsIndexes: state => state.current_items_indexes,
        getCurrentSequenceItems: (state, getters) => getters.getCurrentSequence?.items
            .filter((item, index) => getters.getCurrentSequenceItemsIndexes.includes(index)),
        getCurrentSequenceItemsIds: (state, getters) => getters.getCurrentSequenceItems?.map(item => item.id),
        getCurrentWrappedElementsBoundings: state => state.current_wrapped_elements_boundings,

        getTemporarySequenceData: state => state.temporary_sequence_data
    }
}