<template>
    <div class="mode">
      <div class="disclamer">
        <p class="text-container" style="display: inline-block; text-align: left; margin: auto; width: 90%"> Материал подготовлен при поддержке компании Байер </p>
      </div>
       <TermDefinition v-if="modalEx" style="z-index: 2" @close-confirm="closeModal"/>
        <div class="mode__item item" style="text-align: center">
            <div class="item__img">
                <img src="@/img/components/homepage/homepageLogo.png" alt="">
            </div>
          <InfoButton style="width:93%;margin-left: 3%;margin-bottom: 25px;z-index: auto; position: relative" @open-confirm="openModal"/>
        </div>
        <div class="mode__item item">
            <div class="item__wrapper main-screen-item-wrapper"
                 v-for="button in defaultImgs"
            >
                <div class="main-screen-item-wrapper__button">
                    <div class="main-screen-item-button"
                         @click="selectMode(button.description)">
                        <div class="main-screen-item-button__img">
                            <img :src=button.url alt="">
                        </div>
                        <div class="main-screen-item-button__text">
                            {{button.description}}
                        </div>
                    </div>
                    <p>{{button.details}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import InfoButton from "@/components/InfoButton.vue";
     import TermDefinition from "@/components/TermDefinition.vue";
    export default {
        name: 'MainPageWrapper',
        components: {InfoButton,TermDefinition,},
        data() {
            return {
                modalEx:false,
                defaultImgs: [
                    {
                        url: require('@/img/components/homepage/education.svg'),
                        description: 'Обучающий режим',
                        details: 'Ознакомление с основным функционалом и настройками прибора на конкретных примерах. Процесс работы\n' +
                            ' сопровождается подсказками.',

                    },
                    {
                        url: require('@/img/components/homepage/emulation.svg'),
                        description: 'Режим эмуляции',
                        details: 'Полнофункциональная эмуляция работы с прибора. Доступен весь функционал прибора. Процесс работы сопровождается уведомлениями о критических ошибках.',
                    },
                ]
            }
        },
        computed: {
            ...mapGetters('injector', {
                getInstallationStatus: 'getInstallationStatus',
            }),
        },
        methods: {
            selectMode(type) {
                this.$store.dispatch('resetStore');
                if (this.getInstallationStatus) {
                    this.$store.dispatch('injector/reset_installation', false)
                }
                this.$store.dispatch('mainScreen/selectMode', type);
            },
            openModal() {
              this.modalEx = true;
            },
          closeModal() {
            this.modalEx = false;
          }
        },
    }
</script>

<style scoped lang="scss">
    .mode {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        display: flex;
        gap: 100px;
        align-items: center;
        justify-content: center;
        z-index: 1;
       .disclamer{
         display: flex;
         background: rgb(255,255,255);
         width: 18%;
         margin: 6px auto 0%;
         height: 7%;
         align-items: center;
         position: fixed;
         right: 1%;
         top:2%
       }

        .item {
            &__img {
                width: 300px;
                margin-bottom: 80px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &__wrapper {
                display: flex;
                max-width: 600px;

                .main-screen-item-wrapper {

                    &__button {
                        display: flex;
                        gap: 40px;
                        align-items: center;
                        margin-bottom: 20px;

                        .main-screen-item-button:hover {
                            background-color: rgba(255, 128, 0, 0.4);
                        }

                        .main-screen-item-button {
                            border: 2px solid orange;
                            height: 100%;
                            width: auto;
                            padding: 5px 25px 0;
                            cursor: pointer;
                            transition: background-color 0.5s;
                            max-width: 150px;

                            &__img {
                                height: 67%;
                            }

                            img {
                                min-height: 100%;
                                min-width: 100%;
                                object-fit: contain;
                            }

                            &__text {
                                font-size: 12px;
                                margin-top: 15%;
                                text-align: center;

                            }
                        }

                        p {
                            display: block;
                            line-height: 1.2rem;
                        }

                    }
                }

            }
        }

    }
</style>