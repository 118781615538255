import {
    ABCSequenceElementDefinition,
    ABCSequenceElementInstance,
    assignInstanceConstructor
} from '@/sequences/abc_sequence_element'

export class SequenceStepChunkInstance extends ABCSequenceElementInstance {}

export class SequenceStepChunkDefinition extends assignInstanceConstructor(SequenceStepChunkInstance, ABCSequenceElementDefinition) {
    static DEFAULT_ID = 'default'

    constructor({
        id = SequenceStepChunkDefinition.DEFAULT_ID,
        components
    } = {}) {
        super()

        this.id = id
        this.components = components
    }
}