<template>
    <button class="volume" @click="showControl" @focus="isVisible">
        {{letter}}
    </button>
</template>

<script>

    export default {
        props: {
            letter: String,
            wrapped: Boolean
        },
        name: "ScriptTest",

        methods: {
            showControl(e) {
                // if (this.wrapped)
                //     this.$emit('change-color', {
                //         event: e.target, f: () => {
                //             if (this.letter) {
                //                 this.$store.dispatch('showRow', true);
                //             }
                //         }
                //     });
                // else if (!this.isEducationModeEnabled)
                    this.$emit('change-color', e.target)
                if (this.letter) {
                                this.$store.dispatch('showRow', true);
                            }
            },
            isVisible() {
                if (!this.isEducationModeEnabled && this.letter)
                    this.$store.dispatch('showRow', true);
            },
        },
    }
</script>

<style scoped lang="scss">

    @import "@/scss/variable";
    @import "@/scss/mixin";

    .volume {
        @include scripts;
        font-size: 24px;
        position: relative;
        width: 34px;
        background: #C1D2E1;
        border: 2px solid #C1D2E1;

        &::after {
            content: '';
            position: absolute;
            border: 18px solid transparent;
            border-left: 14px solid #C1D2E1;
            top: -2px;
            left: 32px;
        }
    }
</style>