export const eventBusMixin = {
    created() {

        if (this.arm && this.check_air && this.protocol_locked) {
            this.$store.commit('injector/setInjectionStatus', true);
        }

        this.injectorEventBus.$on('startAutoload', this.changeStatusAutoload);
        this.injectorEventBus.$on('changeStatusManualLoad', this.changeStatusManualLoad);
        this.injectorEventBus.$on('autoFill', (type) => this.autoFillFluid(type));
        this.injectorEventBus.$on('manualFill', ({type, operation}) => this.SetFluidsManually(type, operation));
        this.injectorEventBus.$on('pauseManualFilling', this.pauseManualFilling)
        this.injectorEventBus.$on('autoPumping', this.autoPumping)
        this.injectorEventBus.$on('enableAir', this.enableAir);
        this.injectorEventBus.$on('enableArm', this.enableArm);
    },
    beforeDestroy() {
        this.injectorEventBus.$off('startAutoload', this.changeStatusAutoload);
        this.injectorEventBus.$off('changeStatusManualLoad', this.changeStatusManualLoad);
        this.injectorEventBus.$off('autoFill', (type) => this.autoFillFluid(type));
        this.injectorEventBus.$off('manualFill', ({type, operation}) => this.SetFluidsManually(type, operation));
        this.injectorEventBus.$off('pauseManualFilling', this.pauseManualFilling)
        this.injectorEventBus.$off('autoPumping', this.autoPumping)
        this.injectorEventBus.$off('enableAir', this.enableAir);
        this.injectorEventBus.$off('enableArm', this.enableArm);
    }
}