<template>
    <button class="volume" @focus="showControl">
        {{letter}}
    </button>
</template>

<script>
    export default {
        name: "ScriptA",
        props: {
            letter: String,
            wrapped: Boolean
        },

        methods: {
            showControl(e) {
                // if (this.wrapped) {
                //     this.$emit('change-color', {event: e.target, f: () => })
                // } else if (!this.isEducationModeEnabled) {
                    this.$emit('change-color', e.target)
                    // this.$store.dispatch('showRow', false)
                // }
            },
        }
    }
</script>

<style scoped lang="scss">

    @import "@/scss/variable";
    @import "@/scss/mixin";

    .volume {
       @include scripts;
        font-size: 24px;
        position: relative;
        width: 34px;
        border: 2px solid $primary;

        &::after {
            content: '';
            position: absolute;
            border: 18px solid transparent;
            border-left: 14px solid $primary;
            top: -2px;
            left: 32px;
        }

    }

    #color-clip {
        fill: #61C6F1;
        clip-path: inset(50% 0% 0% 0%);
    }

    .margin {
        margin-top: 5px;
    }
</style>