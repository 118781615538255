<template>
    <div>
        <div class="container">
            <div class="computer">
                <div v-if="!workstation_is_on" key="off" class="screen-black"></div>
                <div v-else class="screen" key="enabled">
                    <div class="header system-settings-header">
                        <img src="../img/components/settings_icons/settings.svg">
                        <span>РЗТ Расширенная настройка</span>
                    </div>
                    <div class="screen-settings">
                        <div class="screen-settings-column first-column">
                            <left-button v-for="(value, name) in advanced_settings"
                                        :name="name"
                                        :value="value"
                                        :isSelected="selectedButton === name"
                                        @select-button="selectButton(name)"/>
                        </div>

                        <div class="screen-settings-column second-column">
                            <option-button  v-for="value in options"
                                            :value="value"
                                            @select-option="selectOption(value)"/>
                        </div>
                        <div class="screen-settings-column third-column">
                            <button class="btn screen-settings-btn confirm-btn" @pointerdown="done = true">
                                <router-link :to="{name: 'SettingsPreset'}">ОК</router-link>
                            </button>
                        </div>
                    </div>

                </div>
                <div class="stand">
                    <comp-lamp class="light" :class="isLightOn"/>
                    <input type="checkbox" class="stand__power-button" @input="switchOnComp(true)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    import LeftButton from '@/components/settingsbuttons/LeftButton'
    import OptionButton from '@/components/settingsbuttons/OptionButton'
    import CompLamp from '@/components/AppLamp'

    export default {
        components: {
            LeftButton,
            OptionButton,
            CompLamp
        },
        data() {
            return {
                selectedButton: '',
                'Режим просмотра': [
                    'Простой вид',
                    'Расширенный вид',
                ],
                'Задержка напоминания автом. сканир.': [
                    'Выключение',
                    'Включение',
                ]
            }
        },
        methods: {
            selectButton(name) {
                this.selectedButton = name;
            },
            selectOption(value) {
                this.$store.dispatch('settings/setAdvancedSetting', {
                    name: this.selectedButton,
                    value: value,
                })
                this.selectedButton = '';
            },
             switchOnComp() {
                if (!this.workstation_is_on) {
                    this.$router.push({name: 'WorkStation'});
                    this.$store.commit('switchStateOfWorkstation', {
                        state: true,
                        confirm: true,
                    });
                }
             },
        },
        computed: {
            ...mapGetters('settings', {
                advanced_settings: 'advanced_settings',
            }),
            ...mapGetters([
               'workstation_is_on'
            ]),
            options() {
                return this[this.selectedButton];
            },
            isLightOn() {
                return {
                    on: this.workstation_is_on,
                }
            }
        },

    }
</script>

<style scoped lang="scss">

    @import "../scss/style";
    @import "../scss/pages/advanced-settings";

</style>