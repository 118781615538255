<template>
        <span class="light"></span>
</template>

<script>
    export default {

    }
</script>

<style scoped lang="scss">
    .light{

        box-shadow:none;
        background:radial-gradient(circle at 50% 40%,white 20%,gray 50%);
        display:inline-block;
        vertical-align:middle;
        width:10px;
        height:10px;
        border-radius:20px;
    }

    .on {
        background:radial-gradient(circle at 50% 40%,white 20%,green 50%);
        box-shadow:0 0 10px 2px #5EEA8C;
    }
</style>