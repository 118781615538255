import { SequenceStepChunkDefinition } from '@/sequences/SequenceStepChunk/base_classes'

export class SequenceStepChunkWithArrow extends SequenceStepChunkDefinition {
    static COMPONENTS = [() => import('./ChunkWithArrow')]

    static POSITIONS = {
        TOP: Symbol('TOP'),
        RIGHT: Symbol('RIGHT'),
        BOTTOM: Symbol('BOTTOM'),
        LEFT: Symbol('LEFT')
    }

    constructor({
        arrow: {
            position = SequenceStepChunkWithArrow.POSITIONS.TOP,
            hidden = false
        } = {},
        ...restArgs
    } = {}) {
        super({
            ...restArgs,
            components: [...SequenceStepChunkWithArrow.COMPONENTS]
        })
        this.position = position
        this.arrowHidden = hidden

        this.#validateAfterInitialization()
    }

    #validateAfterInitialization() {
        if (!Object.values(SequenceStepChunkWithArrow.POSITIONS).some(position => position === this.position))
            this.throwInitializationError(`Position must be one of: ${Object.keys(SequenceStepChunkWithArrow.POSITIONS).join(', ')}`)
    }
}