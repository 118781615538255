<template>
    <div class="sidebar">
        <div>
            <div :class="getActiveStepId === 'main' ? 'blurred' : ''">
                <div class="sidebar__element element">
                    <div class="element__header shadow">
                        <div class="human">
                            <img src="../img/body.svg" alt="">
                        </div>
                    </div>
                    <div class="element__parameters">
                        <p>Patient ID: </p>
                        <p>DOB:</p>
                        <p>Weight:</p>
                    </div>
                </div>
                <div class="sidebar__element element">
                    <div class="element__header shadow">Процедура</div>
                    <div class="element__parameters">
                        <p>Accession:</p>
                    </div>
                </div>
                <div class="sidebar__element element">
                    <div class="element__header shadow">Жидкости</div>
                    <div class="element__parameters">
                        <p class="fluid-a">Fluid A:</p>
                        <p class="fluid-b">Fluid B: </p>
                    </div>
                </div>
                <div class="sidebar__element element">
                    <div class="element__header shadow">События</div>
                    <div class="element__parameters empty"></div>
                </div>
            </div>
            <div v-if="getActiveSequenceId === 'workstation_general_info'" class="chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-left chunk-with-text-attached-to-arrow--align-bottom"
                 style="top:50%; right: 110%; width: 350px; font-weight: normal;">
                <span>Информационная панель, где могут быть отображены данные о пациенте. Ее мы пока не рассматриваем. В будущем она позволит автоматически через соединение с RIS подгружать данные о пациенте и процедуре и сохранять их вместе с данными исследования на сервере больницы.</span>
            </div>
        </div>
        <div class="sidebar__element element">
            <div class="element__header shadow small">Рабочий список пациентов</div>
        </div>
        <div class="sidebar__element element flex">
            <SequenceChunkWrapper
                    v-slot="{processChunkAction: processChunkAction_liquid_delivery_press_Menu_button}"
                    sequence-id="liquid_delivery"
                    step-id="press_Menu_button"
                    key="liquid_delivery_press_Menu_button"
            >
                <SequenceChunkWrapper
                        v-slot="{processChunkAction: processChunkAction_setup_system_press_Menu_button}"
                        sequence-id="setup_system"
                        step-id="press_Menu_button"
                        key="setup_system_press_Menu_button"
                >
                    <SequenceChunkWrapper
                            sequence-id="setup_protocol_manager"
                            step-id="press_Menu_button"
                            key="setup_protocol_manager_press_Menu_button"
                            v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_press_Menu_button}"
                    >
                            <div class="element__icons shadow"
                                 @pointerdown="() => {
                             const fn = () => {
                                 showMenu();
                             }
                             processChunkAction_liquid_delivery_press_Menu_button(fn, {enabledIfEducationIsInactive: true})
                             processChunkAction_setup_protocol_manager_press_Menu_button(fn)
                             processChunkAction_setup_system_press_Menu_button(fn)
                         }"
                            >
                                <img src="../img/icons.svg" alt="">
                            </div>

                    </SequenceChunkWrapper>
                </SequenceChunkWrapper>
            </SequenceChunkWrapper>
            <div class="element__status shadow" @click="openLiquidModal">
                Жидк. А.
            </div>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        computed: {
            ...mapGetters('sequences_store', ['getActiveSequenceId', 'getActiveStepId'])
        },
        methods: {
            showMenu() {
                this.$emit('show-menu');
            },
            openLiquidModal() {
                if (!this.isEducationModeEnabled)
                    this.$emit('open-liquid-modal');
            }
        }
    }
</script>

<style scoped lang="scss">
    .sidebar {
        display: flex;
        flex-direction: column;
        gap: 2px;
        font-weight: bold;
        color: #fff;

        .element {
            width: 100%;

            &__header {
                padding-left: 10px;
                display: flex;
                align-items: center;
                z-index: 2;
                position: relative;
                font-size: 13px;
                min-height: 35px;
                color: #000;


                .human {
                    width: 10px;
                    height: 13px;

                    img {
                        min-width: 100%;
                        min-height: 100%;
                    }
                }
            }

            &__parameters {
                padding: 13px 11px 5px;
                border-radius: 5px;
                border: 1px solid #fff;
                position: relative;
                z-index: 1;
                bottom: 5px;
                font-size: 12px;


                p {
                    display: block;
                    margin-bottom: 6px;


                }

                .fluid-a, .fluid-b {
                    padding-left: 13px;
                }

                .fluid-a:before {
                    position: absolute;
                    content: '';
                    top: 13px;
                    left: 6px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: #5EEA8C;
                }

                .fluid-b:before {
                    position: absolute;
                    content: '';
                    top: 29px;
                    left: 6px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: #369AFF;
                }
            }

            &__icons {
                flex: 0 0 49.5%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                min-height: 34px;
                cursor: pointer;
            }

            &__status {
                flex: 0 0 49.5%;
                font-size: 13px;
                min-height: 34px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000;
                cursor: pointer;
            }

            .empty {
                height: 58px;
            }
        }

        .flex {
            display: flex;
            gap: 1%;

        }

        .shadow {
            border-image: linear-gradient(90deg, #DEEAE2 -0.06%, #A1B29B 100.08%);
            border-image-slice: 1;
            background: linear-gradient(90deg, #DEE9E2 -0.04%, #DDEDE6 100.12%);
            border-radius: 5px;
            border: 1px solid #fff;
        }

        .small {
            font-size: 13px;
            background: rgba(255, 255, 255, 0.1);
            color: #fff;

        }

    }
    .blurred {
        position: relative;

        &:after {
            content: "";
            background-color: #959494;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 5000;
            opacity: 0.55;
        }
        border: 2px solid orange;
    }
</style>