<template>
    <div class="block-btn" @pointerdown="selectButton" v-on="$listeners">
        <button :class="selected" :style="inactive" class="btn screen-settings-btn"><span>{{name}}</span></button>
        <div class="input-btn" :class="isEmpty">{{value}}</div>
    </div>
</template>

<script>
    export default {
        props: {
            name: String,
            value: [String, Number],
            isSelected: Boolean,
            index: [Number],
        },
        methods: {
            selectButton() {
                this.$emit('select-button');
            }
        },
        computed: {
            selected() {
                return {
                    selected: this.isSelected,
                }
            },
            isEmpty() {
                if (!this.value) {
                    return {
                        empty: true,
                    }
                }
            },
            inactive() {
                if (this.index <= 3 && this.index > 0) {
                    return {
                        border: 'none',
                        'box-shadow': 'none',
                        'background-color': '#E0DDD8',
                        cursor: 'default'
                    }
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "../../scss/variable";
    @import "../../scss/mixin";
    .screen-settings-btn {
        @include btn;
        border-radius: 0;
        width: 100%;
        position: relative;
    }

    .block-btn {
        position: relative;
        .screen-settings-btn {
          @include btn;
          border-radius: 0;
          text-align: left;
          padding-left: 10px;
          justify-content: flex-start;
          box-shadow: inset 0px 2px #fff,
                            2px 0px #fff inset,
                            0px -3px #ADB7AF inset,
                            -3px 0px #ADB7AF inset;
        }

        .input-btn {
          background: $light-background;
          margin-left: 15px;
          font-weight: normal;
          width: 75%;
          padding: 0 3px 5px;
        }
        .empty {
            height: 20px;
        }
    }
    .selected {
        background: $yellow !important;
        border-color: $yellow !important;
        box-shadow: none !important;

        &::after {
            content: "";
            position: absolute;
            border: 19px solid transparent;
            border-left: 20px solid $yellow;
            left: 190px;
        }
    }
    .btn {
        background: $primary-background;
        text-transform: none !important;
    }
</style>