<template>
    <div class="pressure">
        <button class="pressure__btn" v-for="item in pressure" @pointerdown="choosePressure(item)">{{item}}</button>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                pressure: [
                    '50psi',
                    '100psi',
                    '150psi',
                    '200psi',
                    '225psi',
                    '250psi',
                    '300psi',
                    '325psi',
                    'Отменить'
                ]
            }
        },
        methods: {
            choosePressure(item) {
                if (item === 'Отменить') {
                    this.$emit('closePressure');
                } else {
                    this.$store.dispatch('choosePressure', item)
                    this.$emit('closePressure');
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .pressure {
        display: flex;
        align-items: center;
        border: 1px solid black;
        border-radius: 5px;
        padding: 10px 0;
        gap: 7px;
        flex-direction: column;

        &__btn {
            background: #DDEDE6;
            box-shadow: 0 -3px #C7D5CF inset;
            border-radius: 3px;
            border: 1px solid #fff;
            border-bottom: none;
            height: 37px;
            width: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
        }
    }
</style>