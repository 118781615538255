<template>
    <div>
        <SequenceChunkWrapper
                sequence-id="setup_protocol_manager"
                step-id="type_new_name_of_area"
                key="type_new_name_of_area"
                v-slot="{processChunkAction: processChunkAction_setup_protocol_manager_type_new_name_of_area, isActive: isActive_type_new_name_of_area}"
        >
            <SequenceChunkWrapper
                    sequence-id="protocol_manager"
                    step-id="type_name_of_protocol"
                    key="type_name_of_protocol"
                    v-slot="{processChunkAction: processChunkAction_type_name_of_protocol, isActive: isActive_type_name_of_protocol}"
            >
                <div
                        class="screen-keyboard-modal"
                        @pointerdown="(e) => {
                            if (isEducationModeEnabled) {
                                processChunkAction_setup_protocol_manager_type_new_name_of_area(() => enterName(e.target, {
                                        educationActive:isActive_type_new_name_of_area,
                                        shouldMatch: isActive_type_new_name_of_area && 'QWERTY'
                                    }), {enabledIfEducationIsInactive: true, mustReturnTrue: true});
                                processChunkAction_type_name_of_protocol(
                                    () => enterName(e.target, {
                                        educationActive:isActive_type_name_of_protocol,
                                        shouldMatch: isActive_type_name_of_protocol && '123'
                                    }),
                                    {mustReturnTrue: true})
                            } else {
                                enterName(e.target, {})
                            }
                        }"
                >
                    <div class="screen-keyboard">
                        <div class="entry-field">
                            <template v-if="getKeyboardType === 'keyBoardReminder'">
                                <div class="entry-field-screen">{{getReminderName}}</div>
                                <div class="entry-field-heading">Введите имя напоминания</div>
                            </template>
                            <template v-else-if="getKeyboardType === 'keyBoardManager'">
                                <div class="entry-field-screen">{{getManagerName}}</div>
                                <div class="entry-field-heading">Введите имя протокола</div>
                            </template>
                            <template v-else-if="getKeyboardType === 'keyBoardManagerSettings'">
                                <div class="entry-field-screen">{{getManagerSettingsName}}</div>
                                <div class="entry-field-heading">Введите имя области</div>
                            </template>


                        </div>
                        <div class="keys">
                            <div class="keys-row">
                                <div class="key">1</div>
                                <div class="key">2</div>
                                <div class="key">3</div>
                                <div class="key">4</div>
                                <div class="key">5</div>
                                <div class="key">6</div>
                                <div class="key">7</div>
                                <div class="key">8</div>
                                <div class="key">9</div>
                                <div class="key">0</div>
                                <div class="key fas fa-backspace"></div>
                            </div>
                            <div class="keys-row">
                                <div class="key">Q</div>
                                <div class="key">W</div>
                                <div class="key">E</div>
                                <div class="key">R</div>
                                <div class="key">T</div>
                                <div class="key">Y</div>
                                <div class="key">U</div>
                                <div class="key">I</div>
                                <div class="key">O</div>
                                <div class="key">P</div>
                                <div class="key">_</div>
                            </div>
                            <div class="keys-row">
                                <div class="key">A</div>
                                <div class="key">S</div>
                                <div class="key">D</div>
                                <div class="key">F</div>
                                <div class="key">G</div>
                                <div class="key">H</div>
                                <div class="key">J</div>
                                <div class="key">K</div>
                                <div class="key">L</div>
                                <div class="key">-</div>
                            </div>
                            <div class="keys-row">
                                <div class="key clear">
                                    <svg class="clear" width="21" height="21" viewBox="0 0 21 21" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.05039 11.494H9.57857C10.0962 11.494 10.4782 11.4077 10.7247 11.2351C10.9712 11.0626 11.0945 10.7915 11.0945 10.4217C11.0945 10.0555 10.9677 9.79496 10.7142 9.64003C10.4642 9.4851 10.0751 9.40763 9.54688 9.40763H9.05039V11.494ZM9.05039 12.825V15.7881H7.41302V8.06604H9.66308C10.7124 8.06604 11.4888 8.25795 11.9924 8.64176C12.4959 9.02205 12.7477 9.6013 12.7477 10.3795C12.7477 10.8337 12.6227 11.2387 12.3727 11.5943C12.1227 11.9464 11.7688 12.2228 11.311 12.4236C12.473 14.1595 13.2301 15.281 13.5822 15.7881H11.7653L9.92189 12.825H9.05039Z"
                                              fill="#369AFF"/>
                                        <path d="M10.2 0.300049C4.59998 0.300049 0.0999756 4.80006 0.0999756 10.4001C0.0999756 14.2001 2.29998 17.7001 5.59998 19.4001L3.99997 20.0001L5.29996 20.6001L7.49997 19.7001L6.59998 17.5001L5.29996 16.9001L5.89996 18.4001C2.89996 16.8001 0.999969 13.8001 0.999969 10.4001C0.999969 5.40005 5.09998 1.30005 10.1 1.30005C15.1 1.30005 19.2 5.40005 19.2 10.4001C19.2 14.1001 17 17.4 13.5 18.8L13.9 19.7001C17.7 18.1001 20.2 14.5001 20.2 10.4001C20.2 4.80006 15.7 0.300049 10.2 0.300049Z"
                                              fill="#369AFF"/>
                                    </svg>
                                </div>
                                <div class="key">Z</div>
                                <div class="key">X</div>
                                <div class="key">C</div>
                                <div class="key">V</div>
                                <div class="key">B</div>
                                <div class="key">N</div>
                                <div class="key">M</div>
                                <div class="key">,</div>
                                <div class="key">.</div>
                            </div>
                            <div class="keys-row keys-row__last-row">
                                <div class="key key__cancel">Отменить</div>
                                <div class="key key__space">space</div>
                                <div class="key key__enter">Ввод</div>
                            </div>
                        </div>
                    </div>
                </div>
            </SequenceChunkWrapper>
        </SequenceChunkWrapper>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex';

    export default {
        name: "ScreenKeyboard",
        data() {
            return {
                localValue: '',
            }
        },
        methods: {
            enterName(target, {educationActive, shouldMatch}) {
                let regexMask
                    if (shouldMatch) {
                        regexMask = new RegExp(`^${Array.from(shouldMatch).reverse().reduce((a, e) => `(${e}${a})?`, '')}$`, 'gm')
                    }

                if (!target.closest('.key')) {
                    return;
                }

                if (target.classList.contains('fas')) {
                    let spliced = this.localValue.split('');
                    spliced.splice(-1, 1);
                    this.localValue = spliced.join('');
                    if (this.localValue.toString().length == 0) {
                        this.$store.dispatch('inputKeyboard', {
                        value: '_',
                        type: this.getKeyboardType
                    });
                    } else {
                        this.$store.dispatch('inputKeyboard', {
                            value: this.localValue,
                            type: this.getKeyboardType
                        });
                    }

                }

                else if (target.innerHTML === 'Отменить') {
                    if (educationActive)
                        return
                    this.$store.dispatch('keyboardCancel', this.getKeyboardType);
                    this.localValue = '';
                    if (this.getKeyboardType === 'keyBoardReminder') {
                        this.$store.dispatch('calcEvents', true);
                    }
                    this.$store.dispatch('setRemindersRow', null);
                }
                else if (target.innerHTML === 'Ввод') {
                    if (educationActive && regexMask)
                        if (shouldMatch !== this.localValue)
                            return

                    if (educationActive)
                        this.$store.dispatch('keyboardDoneWithEducationModeEnabled', this.getKeyboardType)
                    else
                        this.$store.dispatch('keyboardDone', this.getKeyboardType);
                    this.localValue = '';
                    if (this.getKeyboardType === 'keyBoardReminder') {
                        this.$store.dispatch('calcEvents', true);
                    }
                    if (educationActive)
                        return true
                }
                else if (target.classList.contains('clear') || target.className.animVal === 'clear') {
                    this.localValue = '';
                    this.$store.dispatch('inputKeyboard', {
                        value: '_',
                        type: this.getKeyboardType
                    });
                } else if (target.classList.contains('key__space')) {
                    if (educationActive && regexMask)
                        if (!regexMask.test(this.localValue + ' '))
                            return
                    this.localValue += ' ';
                    this.$store.dispatch('inputKeyboard', {
                        value: this.localValue,
                        type: this.getKeyboardType
                    });
                }

                else {
                    if (educationActive && regexMask)
                        if (!regexMask.test(this.localValue + String(target.innerHTML)))
                            return

                    this.localValue += String(target.innerHTML);
                    this.$store.dispatch('inputKeyboard', {
                        value: this.localValue,
                        type: this.getKeyboardType
                    });
                }

                }
        },
        computed: {
            ...mapGetters([
                'getReminderName',
                'getKeyboardType',
                'getManagerName',
                'getManagerSettingsName'
            ])
        },
    }
</script>

<style scoped lang="scss">

    @import "../scss/components/screen-keyboard";

</style>