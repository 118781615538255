<template>
    <button @pointerdown="selectOption" class="btn screen-settings-btn"><span>{{value}}</span></button>
</template>

<script>
    export default {
        props: {
            value: [String, Number],
        },
        methods: {
            selectOption() {
                this.$emit('select-option');
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "../../scss/variable";
    @import "../../scss/mixin";

    .screen-settings-btn {
        @include btn;
        border-radius: 0;
        position: relative;
        width: 80% !important;
        padding: 20px 0;
        border-top-color: $light-border !important;
        border-left-color: $light-border !important;
        border-bottom-color: $dark-border !important;
        border-right-color: $dark-border !important;
    }

</style>