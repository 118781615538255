<template>
    <div class="container">
        <div class="computer">
            <div v-if="!workstation_is_on" key="off" class="screen-black"></div>
            <div v-else class="screen" key="enabled">
                <div class="virtual-care">
                    <div class="header virtual-care__element title">
                        <p>Для обслуживания обратитесь по адресу "www.radiology.bayer.com/contact"</p>
                        <router-link :to="{name: 'WorkStation'}"
                                     tag="button"
                                     class="header__btn"
                        >Выполнено
                        </router-link>
                    </div>
                    <div class="main">
                        <div class="left-side virtual-care__element">
                            <div class="virtual-care__element_cell title">
                                <p>Настройка сети</p>
                                <button class="main__btn">Изменить</button>
                            </div>
                            <div class="virtual-care__element_cell"></div>
                            <div class="virtual-care__element_cell title"><p>Доступ к интренету</p></div>
                            <div class="virtual-care__element_cell">
                                <p>Назначение IP-адреса:</p>
                                <p>Автоматический</p>
                            </div>
                            <div class="virtual-care__element_cell"><p>IP-адрес:</p></div>
                            <div class="virtual-care__element_cell"><p>Маска сети:</p></div>
                            <div class="virtual-care__element_cell"><p>Шлюз по умолчанию:</p></div>
                            <div class="virtual-care__element_cell">
                                <p>DNS №1:</p>
                                <p>-</p>
                            </div>
                            <div class="virtual-care__element_cell">
                                <p>DNS №2:</p>
                                <p>-</p>
                            </div>
                            <div class="virtual-care__element_cell title"><p>Прокси-сервер</p></div>
                            <div class="virtual-care__element_cell">
                                <p>Тип прокси-сервера</p>
                                <p>Нет</p>
                            </div>
                            <div class="virtual-care__element_cell">
                                <p>Адрес:</p>
                                <p>-</p>
                            </div>
                            <div class="virtual-care__element_cell">
                                <p>Порт прокси сервера:</p>
                                <p>-</p>
                            </div>
                            <div class="virtual-care__element_cell">
                                <p>Имя пользователя:</p>
                                <p>-</p>
                            </div>
                            <div class="virtual-care__element_cell">
                                <p>Распознование сценария</p>
                                <p>-</p>
                            </div>
                        </div>
                        <div class="right-side">
                            <div class="virtual-care__element">
                                <div class="virtual-care__element_cell title">
                                    <p>Запросить звонок из службы поддержки</p>
                                </div>
                                <div class="virtual-care__element_cell">
                                    <button class="main__btn">Сервис</button>
                                    <button class="main__btn">Другие</button>
                                </div>
                            </div>
                            <div class="virtual-care__element">
                                <div class="virtual-care__element_cell title">
                                    <p>Состояние соединения </p>
                                </div>
                                <div class="virtual-care__element_cell">
                                    <p>Подключение к сети отсутствует. Убедитесь в том, что сетевой кабель надежно
                                        присоединен к дисплею. Для обновления состояния соединения может
                                        потребоваться до 30 секунд.</p>
                                </div>
                            </div>
                            <div class="virtual-care__element">
                                <div class="virtual-care__element_cell">
                                    <p>Свойства оборудования</p>
                                </div>
                                <div class="virtual-care__element_cell">
                                    <p>Серийный номер:</p>
                                    <p>303111</p>
                                </div>
                                <div class="virtual-care__element_cell">
                                    <p>Имя компьютера:</p>
                                    <p>M84216682-30311</p>
                                </div>
                                <div class="virtual-care__element_cell">
                                    <p>Версия операционной системы:</p>
                                    <p>DN-233889 Ren H</p>
                                </div>
                                <div class="virtual-care__element_cell">
                                    <p>Версия системы Certega:</p>
                                    <div>
                                        <p>4.80.21054.1320</p>
                                        <p>4.80.21054.1320</p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-btns">
                                <button class="main__btn"><img src="../img/Maintenance.svg" alt=""></button>
                                <button class="main__btn"><img src="../img/Monitor.svg" alt=""></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="stand">
                <comp-lamp class="light" :class="isLightOn"/>
                <input type="checkbox" class="stand__power-button" @input="switchOnComp(true)">
            </div>
        </div>
        <router-modal/>
    </div>
</template>

<script>

    import {mapGetters, mapState} from 'vuex';
    import CompLamp from '@/components/AppLamp'
    import RouterModal from '@/components/modals/RouterModal'

    export default {
        computed: {
            ...mapGetters([
                'workstation_is_on'
            ]),
            isLightOn() {
                return {
                    on: this.workstation_is_on,
                }
            },
        },
        components: {
            CompLamp,
            RouterModal
        },

        methods: {
            switchOnComp() {
                if (!this.workstation_is_on) {
                    this.$router.push({name: 'WorkStation'});
                    this.$store.commit('switchStateOfWorkstation', {
                        state: true,
                        confirm: true,
                    });
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "../scss/pages/virtual-care";
    @import "../scss/style";
    @import "../scss/pages/system-settings";
</style>