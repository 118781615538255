<template>
    <button class="total-btn">
        <div class="total-btn__name">Всего A</div>
        <div class="total-btn__data">
            <div class="">ml</div>
            <div>{{totalA}}</div>
        </div>
    </button>
</template>

<script>

    import {mapGetters} from 'vuex'

    export default {
        name: "TotalB",

        computed: mapGetters([
            'totalA'
        ])
    }
</script>

<style scoped lang="scss">

    @import "../scss/variable";
    @import "../scss/mixin";

    .total-btn {
       @include total;
        background: $primary;
    }

</style>