<template>
    <div :class="[isSelected, isDisabled]" class="flow-rate" @click="e => !disabled && enableCalc(e)">
        {{ flowRate }}
    </div>
</template>

<script>

    import {mapGetters} from 'vuex';

    export default {
        name: "FlowRate",
        props: {
            value: [String, Number],
            flowRate: String,
            wrapped: Boolean,
            disabled: {
                type: Boolean,
                default: false,
            },
            index: {
                type: Number,
            },
        },

        data() {
            return {
                range: '0.1 - 10 (0.1) ml/s',
            }
        },

        methods: {
            enableCalc(e) {
                this.$emit('focused', {event: e.target, f: async () => {
                    await this.$store.dispatch('clearHighlightedRow')
                    await this.$store.dispatch('setSelectedRowButton', 'flowRate');

                    return this.$store.dispatch('enableCalc', {
                        range: this.range,
                        boolean: true,
                    });
                }})
            }
        },
        computed: {
            ...mapGetters([
                'getRows',
                'getEditableRow',
                'getSelectedRowButton',
            ]),
            isSelected() {
                return {
                    enabled: this.index === this.getEditableRow && this.getSelectedRowButton === 'flowRate',
                }
            },
            isDisabled() {
                return {
                    disabled: this.getSelectedRowButton !== null,
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .enabled {
        background: black !important;
        color: white;
        border: 2px solid black;
    }

    @import "@/scss/variable";
    @import "@/scss/mixin";

    .flow-rate {
        @include scripts;
        margin-left: 13px;
        cursor: pointer;
    }
    .disabled {
        pointer-events: none;
    }
</style>