import { assignInstanceConstructor } from '@/sequences/abc_sequence_element'
import { SequenceStepDefinition, SequenceStepInstance } from '@/sequences/SequenceStep/base_classes'

class SequenceStepOneOfMultipleChunksInstance extends SequenceStepInstance {
    processChunkActionExecution(chunkId) {
        this.passedChunks.value = this.remainingChunks.value
        this.remainingChunks.value = []
        return this.parent.nextStep(chunkId)
    }
}

export class SequenceStepOneOfMultipleChunksDefinition extends assignInstanceConstructor(SequenceStepOneOfMultipleChunksInstance, SequenceStepDefinition) {}
