<template>
    <div class="window-remainders" v-if="show">
        <div class="window-remainders-content">
            <i class="far fa-clock"></i>
            <span class="name-window-remainders">{{name}}</span>
            <span class="time-window-remainders">{{time}}</span>
            <button class="button-window-remainders" @pointerdown="closeReminder" >ok</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
          time: String,
          name: String,
          index: Number,
          show: Boolean,
          confirmed: Number,

        },

        methods: {
            closeReminder() {
                this.$store.dispatch('closeReminder', this.index);
            }
        },

        name: "ModalWindowRemainders",
        watch: {
            confirmed() {
                if (this.time !== '--') {
                    let ms = this.time.slice(0,2) * 60000 + this.time.slice(3) * 1000;
                    setTimeout(() => {
                        this.$store.dispatch('showReminder', this.index)
                    }, ms)
                }

            }
        }

    }
</script>

<style scoped lang="scss">

    @import "../../scss/components/modal-window-reminders";

</style>