<template>
    <button :class="isSelected" class="protocol-btn btn" @pointerdown="showSavedProtocol">
        <div class="data-protocol-btn">
            <div class="data-protocol__header">
                <span class="protocol-name">{{protocol_name}}</span>
                <span class="part-name">{{setPartName}}</span>
            </div>
             <div v-show="this.protocol_name === 'Test'" class="data-protocol__image">
                <img src="../img/components/P3T.svg" alt="">
            </div>
        </div>
    </button>
</template>

<script>

    import {mapGetters} from 'vuex';

    export default {
        name: "ProtocolButton",
        props: {
            protocol_name: String,
            part_name: String,
            selected: Boolean,
            index: Number,
            wrapped: Boolean
        },
        data() {
            return {
                local_part_name: '',
            }
        },
        methods: {

            showSavedProtocol() {
                this.$emit('show-saved-protocol', {
                    name: this.protocol_name,
                    part_name: this.local_part_name,
                    index: this.index,
                });
            }
        },
         computed: {
            ...mapGetters([
                'head',
                'neck',
                'chest',
                'abdomen',
                'pelvis',
                'limbs',
                'body',
            ]),
            isSelected() {
                return {
                    selected: this.selected,
                }
            },
            setPartName() {
                if (this.part_name !== '...') {
                    this.local_part_name = this.part_name;
                    return this.local_part_name;
                } else {

                    for (let protocol of Object.values(this.head)) {
                        if (protocol === this.head[this.protocol_name]) {
                            this.local_part_name = this.body[0];
                            return this.local_part_name;
                        }
                    }
                    for (let protocol of Object.values(this.neck)) {
                        if (protocol === this.neck[this.protocol_name]) {
                            this.local_part_name = this.body[1];;
                            return this.local_part_name;
                        }
                    }
                    for (let protocol of Object.values(this.chest)) {
                        if (protocol === this.chest[this.protocol_name]) {
                            this.local_part_name = this.body[2];;
                            return this.local_part_name;
                        }
                    }
                    for (let protocol of Object.values(this.abdomen)) {
                        if (protocol === this.abdomen[this.protocol_name]) {
                            this.local_part_name = this.body[3];;
                            return this.local_part_name;
                        }
                    }
                    for (let protocol of Object.values(this.pelvis)) {
                        if (protocol === this.pelvis[this.protocol_name]) {
                            this.local_part_name = this.body[4];;
                            return this.local_part_name;
                        }
                    }
                    for (let protocol of Object.values(this.limbs)) {
                        if (protocol === this.limbs[this.protocol_name]) {
                            this.local_part_name = this.body[5];;
                            return this.local_part_name;
                        }
                    }
                }
            },
        }
    }
</script>

<style scoped lang="scss">

    @import "../scss/mixin";
    @import "../scss/variable";

    .protocol-btn {
      @include btn;
      background: $secondary-background;
      width: 50%;
      border-radius: 0;
      display: block;

      .data-protocol-btn {
          display: flex;
          justify-content: space-between;
          padding: 0 10px;

          .data-protocol__header {
              display: flex;
              flex-direction: column;
              text-align: left;

              .part-name {
                  font-weight: 400;
                  opacity: 0.3;
              }
          }

          .data-protocol__image {
              width: 35px;
              display: flex;
              align-items: center;

              img {
                  max-width: 100%;
                }
          }
      }
    }
    .selected {
        background: $yellow !important;
    }

</style>