<template>
    <div>
        <h1>MAIN MODULE</h1>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>

</style>