<template>
    <div class="container">
        <div class="modal">
            <h2 class="modal__header">
                <slot name="header">

                </slot>
            </h2>
            <p class="modal__information">
                <slot name="information_1">

                </slot>
            </p>
            <p class="modal__information">
                <slot name="information_2">

                </slot>
            </p>
            <div class="modal__buttons">
                <button class="modal__buttons-confirm" @click="confirm">
                    <slot name="button-confirm">

                    </slot>
                </button>
                <button class="modal__buttons-cancel" @click="cancel">
                    <slot name="button-cancel">

                    </slot>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            confirm() {
                this.$emit('confirm');
            },
            cancel() {
                this.$emit('cancel');
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "../../scss/mixin";
    .container {
        @include modal-container;


        .modal {
            @include modal;



            &__header {
                font-weight: bold;
                text-transform: uppercase;
                font-size: 22px;
                margin-bottom: 25px;
            }

            &__information {
                line-height: 20px;
            }

            &__buttons {
                margin-top: 50px;
                display: flex;
                justify-content: space-around;

                button {
                    padding: 5px;
                    border-radius: 5px;
                }

                &-confirm {
                    background: transparent;
                    border: 1px solid #fff;
                    color: #fff;
                }

                &-cancel {
                    color: #E17000;
                    background-color: #fff;
                }
            }
        }
    }
</style>