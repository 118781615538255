<template>
    <button
            class="protocol-btn btn"
            @pointerdown="selectButton"
            :class="selected"
    >
        <div class="data-protocol-btn">
            <div class="data-protocol__header">
                <span class="protocol-name">{{name}}</span>
                <span class="part-name"></span>
            </div>
            <div class="data-protocol__image">
                <img src="../../img/components/P3T.svg" alt="">
            </div>
        </div>
    </button>
</template>

<script>
    export default {
        name: 'RZTButton',
        props: {
            name: String,
            isSelected: Boolean,
        },
        methods: {
            selectButton() {
                this.$emit('select-button', this.name);
            },
        },
        computed: {
            selected() {
                return {
                    selected: this.isSelected,
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "../../scss/variable";
    @import "../../scss/mixin";

      .data-protocol-btn {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 10px;

        .data-protocol__header {
          display: flex;
          flex-direction: column;
          text-align: left;

          .part-name {
            font-weight: 400;
            opacity: 0.3;
          }
        }

        .data-protocol__image {
          width: 35px;
          display: flex;
          align-items: center;

          img {
            max-width: 100%;
          }
        }
      }
    .selected {
        background: $yellow;
    }
</style>