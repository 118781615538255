import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from "../pages/Main";
import MainModule from "../pages/MainModule";
import Injector from "../pages/Injector";
import WorkStation from "../pages/WorkStation";
import Manager from "../pages/Manager"
import SystemSettings from "../pages/SystemSettings";
import SettingsPreset from "../pages/SettingsPreset";
import ManagerSettings from "../pages/ManagerSettings"
import AdvancedSettings from "../pages/AdvancedSettings";
import VirtualCare from "../pages/VirtualCare";
import InformationSystem from '../pages/InformationSystem';
import LicenseManager from "../pages/LicenseManager";
import Reference from "../pages/Reference";
import LiquidDelivery from "../pages/LiquidDelivery";
import WelcomePage from "../pages/WelcomePage.vue"
Vue.use(VueRouter);

const routes = [
  {
    path:'/',
    name: 'WelcomePage',
    component: WelcomePage,
  },
  {
    path:'/main',
    name: 'Main',
    component: Main,
  },
  {
    path: '/mainmodule',
    name: 'MainModule',
    component: MainModule,
  },
  {
    path: '/injector',
    name: 'Injector',
    component: Injector
  },
  {
    path: '/workstation',
    name: 'WorkStation',
    component: WorkStation,
  },
  {
    path: '/workstation/manager',
    name: 'Manager',
    component: Manager,
  },
  {
    path: '/workstation/systemsettings',
    name: 'SystemSettings',
    component: SystemSettings,
  },
  {
    path: '/workstation/settingspreset',
    name: 'SettingsPreset',
    component: SettingsPreset,
  },
  {
    path: '/workstation/managersettings',
    name: 'ManagerSettings',
    component: ManagerSettings,
  },
  {
    path: '/workstation/settingspreset/advanced',
    name: 'AdvancedSettings',
    component: AdvancedSettings,
  },
  {
    path: '/workstation/virtualcare',
    name: 'VirtualCare',
    component: VirtualCare,
  },
  {
    path: '/workstation/licensemanager',
    name: 'LicenseManager',
    component: LicenseManager,
  },
  {
    path: '/workstation/infsystem',
    name: 'InformationSystem',
    component: InformationSystem,
  },
  {
    path: '/workstation/reference',
    name: 'Reference',
    component: Reference,
  },
    {
    path: '/workstation/liquiddelivery',
    name: 'LiquidDelivery',
    component: LiquidDelivery,
  }
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})