export const mixer = (baseClass, ...mixins) => {
    class MixedClass extends baseClass {
        constructor (...args) {
            super(...args)

            mixins.forEach(mixin => {
                copyProps(this, (new mixin(...args)))
            })
        }
    }

    let copyProps = (target, source) => {
        Object.getOwnPropertyNames(source)
              .concat(Object.getOwnPropertySymbols(source))
              .forEach(prop => {
                 if (!prop.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/))
                    Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(source, prop))
               })
    }

    mixins.forEach(mixin => {
        copyProps(MixedClass.prototype, mixin.prototype)
        copyProps(MixedClass, mixin)
    })

    return MixedClass
}