import {gsap} from "gsap";
import {mapGetters} from "vuex";

export const injectorsMixin = {
    data() {
        return {
            lock_animation: '',
            flashing_fill_a_button: '',
            flashing_fill_b_button: '',
            piston_first_animation: '',
            timeout_manual_filling: '',
            manual_filling_is_on: false,
            button_check_air_animation: '',
            numbers_animation: '',
            panel_animation: '',
            disable_manual_filling_button: null,
        }
    },
    methods: {
        startInjection() {
            this.$router.push({name: 'WorkStation'});
        },
        parentMethod() {
            this.flashing_fill_b_button.pause();
            gsap.to('.fill-b-off', {opacity: 1});
            this.flashing_fill_a_button.pause();
            gsap.to('.fill-a-off', {opacity: 1});
            gsap.to(`.background-b`, {y: 0, duration: 0})
            gsap.to(`.background-a`, {y: 0, duration: 0})
        },
        removeHeaters() {
            this.$store.dispatch('injector/removeHeaters');
        },
        decreaseFluids(type) {
            this.$store.dispatch('injector/decreaseFluids', {
                type: type,
                value: 5,
            })
        },
        increaseFluids(type) {
            this.$store.dispatch('injector/increaseFluids', {
                type: type,
                value: 5,
            })
        },
        changeStatusManualLoad() {
            this.injectorEventBus.$emit('manualLoadAnimation');
            if (this.injector[1].set) {
                gsap.to('.move-off', {opacity: this.manualload_is_chosen ? 1 : 0,});
                this.$store.commit('injector/setManualLoadStatus', !this.manualload_is_chosen);
            } else if (this.manualload_is_chosen) {
                gsap.to('.move-off', {
                    opacity: 1,
                })
                this.$store.dispatch('injector/setManualLoadStatus', {
                    status: false,
                    a: this.manual_load_started.a ? this.fluids.a : 0,
                    b: this.manual_load_started.b ? this.fluids.b : 0,
                })
            } else {
                this.$store.commit('injector/setAutoloadStatus', false);
                this.flashing_fill_b_button.pause();
                this.flashing_fill_a_button.pause();
                gsap.to('.fill-a-off', {opacity: 1,});
                gsap.to('.fill-b-off', {opacity: 1,});
                gsap.to('.move-off', {opacity: 0,});

                this.$store.dispatch('injector/setManualLoadStatus', {
                    status: true,
                    a: this.manual_load_started.a ? this.fluids.a : 0,
                    b: this.manual_load_started.b ? this.fluids.b : 0,
                })
            }

        },
        SetFluidsManually(type, operation) {
            if (this.injector[1].set) {
                if (operation === 'decrease') {
                    let tl = gsap.timeline({defaults: {duration: 1, delay: 0.2}})

                    if (type === 'a' && this.manual_pumping.a || type === 'b' && this.manual_pumping.b || type === 'a' && !this.fluids.a || type === 'b' && !this.fluids.b) {
                        return;
                    } else {
                        tl.to(`.flask-${type}`, {y: this.flasks_translateY[type] - 0.68}, 'pumping')
                            .to(this.fluids, {
                                [type]: this.fluids[type] - 4, ease: 'steps(4)', onComplete: () => {
                                    this.$store.dispatch('injector/setManualPumping', {
                                        type: type,
                                        translate: this.flasks_translateY[type] - 0.68,
                                        status: true,
                                    })
                                }
                            }, 'pumping')
                    }
                }
            } else {
                clearTimeout(this.disable_manual_filling_button);
                this.disable_manual_filling_button = null;
                if (!this.manual_filling_is_on) {
                    this.manual_filling_is_on = true;
                    if (operation === 'decrease') {
                        this.timeout_manual_filling = setInterval(() => {
                            let tl = gsap.timeline({defaults: {duration: 0}});
                            tl.to(this.flasks_translateY, {[type]: this.flasks_translateY[type] - 0.185 < 0 ? 0 : this.flasks_translateY[type] - 0.185})
                                .to(this.fluids, {
                                    [type]: this.fluids[type] === 0 ? 0 : this.fluids[type] - 1,
                                    ease: 'steps(1)'
                                }, 'filling')
                                .to(`.background-${type}`, {y: 2}, 'filling')
                                .to(`.flask-${type}`, {y: this.flasks_translateY[type],}, 'filling')
                        }, 100)
                    } else {
                        this.timeout_manual_filling = setInterval(() => {
                            let tl = gsap.timeline({defaults: {duration: 0}});
                            tl.to(this.flasks_translateY, {[type]: this.flasks_translateY[type] + 0.185 > 38 ? 38 : this.flasks_translateY[type] + 0.185})
                                .to(this.fluids, {
                                    [type]: this.fluids[type] === 205 ? 205 : this.fluids[type] + 1,
                                    ease: 'steps(1)'
                                }, 'filling')
                                .to(`.background-${type}`, {y: 2}, 'filling')
                                .to(`.flask-${type}`, {y: this.flasks_translateY[type],}, 'filling')
                        }, 100)
                    }
                    this.$store.dispatch('injector/startManualLoad', {
                        type: type,
                        status: true,
                    });
                }
            }
        },
        autoFillFluid(type) {
            this.injectorEventBus.$emit('autoFillFluidAnimation', type);
            this.$store.commit('injector/changePistonStatus', true);

            let fluid = this.fluids[type];
            let translate = 0.185 * fluid;

            if (type === 'a') {
                this.flashing_fill_a_button.pause();
            } else {
                this.flashing_fill_b_button.pause();
            }

            gsap.to(`.fill-${type}-off`, {
                duration: 0.1,
                opacity: 1,
            });

            let tl = gsap.timeline({defaults: {duration: 2}});
            tl.to(`.piston-${type}`, {y: (20 / 205 * 100) / 3, ease: "back.out(2)"}, 'down')
                .fromTo(this.fluids, {[type]: 0, duration: 0,}, {[type]: 20, ease: "steps(20)",}, 'down')
                .to(`.piston-${type}`, {y: 0,}, 'up')
                .to(this.fluids, {[type]: 0, ease: "steps(20)",}, 'up')
                .to(`.background-${type}`, {y: 2, duration: 3, delay: 0.5}, 'filling')
                .to(`.flask-${type}`, {y: translate > 38 ? 38 : translate, duration: 3, delay: 0.5}, 'filling')
                .to(this.fluids,
                    {
                        [type]: fluid, duration: 3, delay: 0.5, ease: `steps(${fluid})`, onComplete: () => {
                            this.$store.commit('injector/changePistonStatus', false);
                            this.$store.commit('injector/setFlasksTranslateY', {
                                type,
                                translate,
                            })
                            if (type === 'a') {
                                this.$store.dispatch('injector/startFilling', {
                                    status: true,
                                    type: 'autoload_filling_a_started',
                                    fluids_type: type,
                                });
                            } else {
                                this.$store.dispatch('injector/startFilling', {
                                    status: true,
                                    type: 'autoload_filling_b_started',
                                    fluids_type: type,
                                });
                            }
                        }
                    }, 'filling');

        },
        pauseManualFilling() {
            setTimeout(() => {
                clearInterval(this.timeout_manual_filling);
                this.manual_filling_is_on = false;
                clearTimeout(this.disable_manual_filling_button);
                this.disable_manual_filling_button = setTimeout(() => {
                    if (!this.manual_filling_is_on) {
                        gsap.to('.move-off', {opacity: 1})
                        this.injectorEventBus.$emit('disableMoveButton');
                        this.$store.dispatch('injector/setManualLoadStatus', {
                            status: false,
                        })
                    }
                }, 10000);
            }, 50);

        },
        autoPumping() {
            this.injectorEventBus.$emit('autoPumpingAnimation');
            this.$store.commit('injector/setManualLoadStatus', false);
            this.$store.dispatch('injector/setPumpingStatus', true);
            gsap.to('.move-off', {opacity: 1,})
            let tl = gsap.timeline({defaults: {duration: 1, delay: 0.2}})
            tl.to('.prime-off', {opacity: 0, duration: 0})

            if (this.fluids.a) {
                tl.to('.flask-a', {y: this.flasks_translateY.a - 0.68}, 'pumping')
                    .to(this.fluids, {
                        a: this.fluids.a - 4, ease: 'steps(4)', onComplete: () => {
                            this.$store.commit('injector/setFlasksTranslateY', {
                                type: 'a',
                                translate: this.flasks_translateY.a - 0.68,
                            })
                            this.$store.commit('injector/setShowDropStatus', true);
                        }
                    }, 'pumping')
                    .to('.prime-off', {opacity: 1, duration: 0})
            }

            if (this.fluids.b) {
                tl.to('.flask-b', {y: this.flasks_translateY.b - 0.68}, 'pumping')
                    .to(this.fluids, {
                        b: this.fluids.b - 4, ease: 'steps(4)', onComplete: () => {
                            this.$store.commit('injector/setFlasksTranslateY', {
                                type: 'b',
                                translate: this.flasks_translateY.b - 0.68,
                            })
                            this.$store.commit('injector/setShowDropStatus', true);
                        }
                    }, 'pumping')
                    .to('.prime-off', {opacity: 1, duration: 0})
            }
        },
        enableArm() {
            if (this.protocol_locked || this.getEducationIsChosen) {
                this.injectorEventBus.$emit('enableArmAnimation')
                gsap.to('.arm-off', {
                    opacity: this.arm ? 1 : 0,
                })

                this.$store.commit('injector/setArmStatus', !this.arm);

                this.lock_animation = gsap.to('.lock', {
                    fill: '#00AEFF',
                    duration: 1,
                    yoyo: true,
                    repeat: -1,
                    paused: true,
                })

                if (this.arm) {
                    if (!this.check_air) {
                        this.button_check_air_animation.restart();
                    }
                    if (!this.protocol_locked) {
                        this.lock_animation.restart();
                    }
                    if (this.totalA - this.fluids.a > 0 || this.totalB - this.fluids.b > 0) {
                        this.numbers_animation.restart();
                    }

                    if (this.check_air && this.protocol_locked && !(this.totalA - this.fluids.a > 0 || this.totalB - this.fluids.b > 0)) {
                        this.$store.commit('injector/setInjectionStatus', true);
                        this.panel_animation.restart();
                    }
                } else {
                    this.numbers_animation.pause()
                    gsap.to('.numbers', {
                        opacity: 1,
                    })
                    if (!this.protocol_locked) {
                        this.lock_animation.pause();
                        gsap.to('.lock', {
                            fill: 'black',
                        })
                    }

                    if (!this.check_air) {
                        this.button_check_air_animation.pause()
                        gsap.to('.check-off', {
                            opacity: 1,
                        })
                    }
                    if (this.injection_started) {
                        this.$store.commit('injector/setInjectionStatus', false);
                        this.panel_animation.pause();
                        gsap.to('.left_panel', {
                            fill: 'url(#paint243_linear_2_2)'
                        })
                        gsap.to('.right_panel', {
                            fill: 'url(#paint243_linear_2_2)'
                        })
                    }
                }
            } else {
                this.$emit('lock-request')
            }
        },

        enableAir() {
            this.injectorEventBus.$emit('enableAirAnimation')
            this.button_check_air_animation.pause();
            gsap.to('.check-off', {
                opacity: this.check_air ? 1 : 0,
            })
            this.$store.commit('injector/setCheckAirStatus', !this.check_air);
            if (this.arm && this.protocol_locked) {
                this.$store.commit('injector/setInjectionStatus', true);
            }
        }

    },
    computed: {
        ...mapGetters('injector', {
            injector: 'selected_kit',
            current_id: 'current_id',
            fluids: 'fluids',
            autoload_is_chosen: 'autoload_is_chosen',
            autoload_filling_a_started: 'autoload_filling_a_started',
            autoload_filling_b_started: 'autoload_filling_b_started',
            heaters: 'heaters',
            manualload_is_chosen: 'manualload_is_chosen',
            flasks_translateY: 'flasks_translateY',
            manual_load_started: 'manual_load_started',
            pistons_in_move: 'pistons_in_move',
            pumping: 'pumping',
            manual_pumping: 'manual_pumping',
            injector_inverted: 'injector_inverted',
            connected_to_patient: 'connected_to_patient',
            check_air: 'check_air',
            arm: 'arm',
            getShowDrop: 'getShowDrop',
            filling_started: 'filling_started',
            getEnlargedPanelState: 'getEnlargedPanelState',
            showHuman: 'showHuman',
            getShortInjectorType: 'getShortInjectorType',
            injection_started: 'injection_started',
            disposable_tube: 'tube-SPD250',
            remaining_fluids: 'remaining_fluids',
            allowReuse: 'allowReuse',
        }),
        ...mapGetters([
            'totalA',
            'totalB',
            'protocol_locked',
        ]),
        ...mapGetters('mainScreen', ['getEducationIsChosen']),
        ...mapGetters('sequences_store', ['getActiveStep']),
        is_protocol_locked() {
            return {
                fill: this.protocol_locked ? '#00AEFF' : 'black'
            }
        },
        transform_injector() {
            return {
                inverted: this.injector_inverted,
            }
        },
        state_air_button() {
            return {
                active: this.injector_inverted,
            }
        },
        state_arm_button() {
            return {
                active: this.injector_inverted && this.check_air,
            }
        },
        state_start_injection_button() {
            return {
                active: this.connected_to_patient,
            }
        },
        state_loupe_button() {
            return {
                'active-loupe': this.getEnlargedPanelState,
            }
        },
    },
    watch: {
        state_arm_button() {
            this.$store.dispatch('injector/enlarged_panel/setButtonState', {armButtonActive: this.injector_inverted && this.check_air});
        },
        state_air_button() {
            this.$store.dispatch('injector/enlarged_panel/setButtonState', {checkAirButtonActive: this.injector_inverted});
        },
        state_start_injection_button() {
            this.$store.dispatch('injector/enlarged_panel/setButtonState', {startInjectionButton: this.connected_to_patient});
        },
        autoload_is_chosen(v) {
            if (!v && this.getEducationIsChosen) {
                this.flashing_fill_a_button.pause();
                this.flashing_fill_b_button.pause();
            }
        },
        arm(v) {
            if (!v && this.getEducationIsChosen && (this.getActiveStep.definition.id === 'mp1_press_arm_button' || this.getActiveStep.definition.id === 'spk_press_arm_button')) {
                this.lock_animation.pause()
            }
        }
    },
}