<template>
    <div>
        <SequencesAppWrapper>
            <router-view/>
        </SequencesAppWrapper>
        <div :id="SEQUENCES_CONTROL_ID.slice(1)">
            <div
                    v-if="getActiveSequence && getActiveSequence.definition && getActiveSequence.definition.persistentHeader"
                    v-html="getActiveSequence.definition.persistentHeader"
                    style="position: absolute; top: 20px; left: 20px; font-size: 20px; font-weight: 600; max-width: 350px; z-index: 10;"
            ></div>

            <SequenceItemPopup/>
            <SequencesAlertModal/>
            <WorkstationTutorialSelectionMenu v-if="showWorkstationTutorialMenu"/>
            <InjectorTutorialSelectionMenu v-if="showInjectorTutorialMenu"/>
            <ActiveSequenceStepBack v-if="showActiveSequenceStepBack"/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        components: {
            SequencesAppWrapper: () => import('@/components/EducationMode/SequencesAppWrapper'),
            ActiveSequenceStepBack: () => import('@/sequences/ActiveSequenceStepBack'),
            SequenceItemPopup: () => import('@/components/EducationMode/WrapSequence/SequenceItemPopup'),
            WorkstationTutorialSelectionMenu: () => import('@/components/EducationMode/WorkstationTutorialSelectionMenu'),
            InjectorTutorialSelectionMenu: () => import('@/components/EducationMode/InjectorTutorialSelectionMenu'),
            SequencesAlertModal: () => import('@/components/EducationMode/SequencesAlertModal')
        },
        computed: {
            ...mapGetters(['workstation_is_on', 'showConfirmation']),
            ...mapGetters('sequences_store', ['getActiveSequenceId', 'getActiveSequence']),
            showWorkstationTutorialMenu() {
                return this.isEducationModeEnabled && this.workstation_is_on && !this.showConfirmation &&
                    !['Main', 'MainModule', 'Injector'].includes(this.$route.name)
            },
            showInjectorTutorialMenu() {
                return this.isEducationModeEnabled && this.workstation_is_on && this.$route.name === 'Injector'
            },
            showActiveSequenceStepBack() {
                return this.isEducationModeEnabled && this.workstation_is_on && this.getActiveSequenceId;
            }
        },
        mounted() {
            if (this.$router.currentRoute.name !== 'WelcomePage')
                this.$router.push({name: 'WelcomePage'});
        },
    }
</script>

<style lang='scss'>

    .tooltip {
        z-index: 999999999999;
        margin-bottom: 20px;
    }

    .hover-tooltip {
        position: absolute;
        bottom: 100%;
        background-color: white;
        font-size: 12px;
        font-weight: 400;
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 5px;
        width: 300px;
    }

    * {
        padding: 0;
        margin: 0;
        border: 0;
    }

    *, *:before, *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    :focus, :active {
        outline: none;
    }

    a:focus, a:active {
        outline: none;
    }

    nav, footer, header, aside {
        display: block;
    }

    html, body {
        height: 100%;
        width: 100%;
        font-size: 100%;
        font-size: 14px;
        -ms-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;

        //#app.v-application {
            font-family: 'Open Sans', sans-serif;
            line-height: 1;

            //.container {
            //    max-width: unset;
            //}
        //}
    }

    //.v-application .v-application--wrap p {
    //    margin-bottom: 0;
    //}

    input, button, textarea {
        font-family: inherit;
    }

    input::-ms-clear {
        display: none;
    }

    button {
        cursor: pointer;
    }

    button::-moz-focus-inner {
        padding: 0;
        border: 0;
    }

    a, a:visited {
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }

    ul li {
        list-style: none;
    }

    img {
        vertical-align: top;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: inherit;
        font-weight: 400;
    }

    i {
        font-family: fontawesome !important;
    }

    .chunk-with-text-attached-to-arrow {
        color: #000;
        position: absolute;
        background-color: white;
        padding: 10px 20px;
        border: 1px orange solid;

        max-width: 400px;

        $arrow-width: 10px;
        $offset: $arrow-width + 5px;
        $arrow-color: orange;

        &:not(&--without-arrow) {
            &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
            }
        }

        &--position {
            &-top {
                margin-bottom: $offset;

                &::before {
                    top: 100%;
                    border-width: $arrow-width $arrow-width 0 $arrow-width;
                    border-color: $arrow-color transparent transparent transparent;
                }
            }

            &-right {
                margin-left: $offset;

                &::before {
                    right: 100%;
                    border-width: $arrow-width $arrow-width $arrow-width 0;
                    border-color: transparent $arrow-color transparent transparent;
                }
            }

            &-bottom {
                margin-top: $offset;

                &::before {
                    bottom: 100%;
                    border-width: 0 $arrow-width $arrow-width $arrow-width;
                    border-color: transparent transparent $arrow-color transparent;
                }
            }

            &-left {
                margin-right: $offset;

                &::before {
                    left: 100%;
                    border-width: $arrow-width 0 $arrow-width $arrow-width;
                    border-color: transparent transparent transparent $arrow-color;
                }
            }
        }

        &--align {
            &-top::before {
                top: 0;
            }

            &-right::before {
                right: 0;
            }

            &-bottom::before {
                bottom: calc(50% - #{$arrow-width});
            }

            &-left::before {
                left: 0;
            }
        }

    }

    @import url("https://netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");

</style>