<template>
    <div class="container">
        <div class="computer">
            <div v-if="!workstation_is_on" key="off" class="screen-black"></div>
            <div v-else class="screen reference" key="enabled">
                <div class="reference__header">
                    <img src="../img/components/reference.svg" alt="">
                    <h1 class="header__title">О программе</h1>
                    <router-link :to="{name: 'WorkStation'}" tag="button">Выход</router-link>
                </div>
                <div class="reference__main main">
                    <div class="main__navigation navigation">
                        <button class="navigation__button"
                                @pointerdown="$store.dispatch('station/Reference/changeAboutProgramVisibility',
                                true)"
                                :class="selectedProgram"
                        >
                            О программе
                        </button>
                        <button class="navigation__button"
                                @pointerdown="$store.dispatch('station/Reference/changeAboutProgramVisibility', false)"
                                :class="selectedGuide"
                        >
                            Руководства по эксплуатации
                        </button>
                    </div>
                    <div v-if="showAboutProgram" class="main__information information">
                        <div class="information__patent-section">
                            <h2>Применимые патенты</h2>
                            <p>Информацию о патенте можно найти на www.ripatents.com</p>
                        </div>
                        <div class="information__additional additional">
                            <div class="additional__license">
                                <h2>Лицензированные функции</h2>
                                <ul>
                                    <li>Cardiac 1.x</li>
                                    <li>Abdomen 1.x</li>
                                    <li>Pulmonary Angiography 1.x</li>
                                </ul>
                            </div>
                            <div class="additional__versions">
                                <h2>Версии по</h2>
                                <ul>
                                    <li>S2-CRU-100.70-C1.06_WIN_VnV</li>
                                    <li>S2-SRU-100.46-C1.06_SH_VnV</li>
                                    <li>POD-APPL-1.05_HU</li>
                                    <li>---</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div v-else class="main__guide guide">
                        <p>Bayer HealthCare по-прежнему уделяет повышенное внимание защите окружающей среды и стремится к рациональному
                            использованию ресурсов. Мы ищем экологически безопасные решения для людей и нашей планеты.
                            На компакт-диске, который входит в комплект доставки, имеется электронная версия руководства по эксплуатации
                            нашего продукта. Распространяя этот документ в таком виде, мы пытаемся сохранить окружающую среду на нашей
                            планете. Bayer HealthCare рекомендует загрузить руководство по эксплуатации с компакт-диска, распечатать его
                            (при необходимости), изучить и предоставить оператору для работы с нашими продуктами.
                        </p>
                        <h2>Обслуживание</h2>
                        <p>Если вам нужна помощь в обслуживании, пожалуйста, см. руководство по эксплуатации или обратитесь по адресу в интернете
                            : www.radiology.bayer.com/contact
                        </p>
                    </div>
                </div>
            </div>
            <div class="stand">
                <comp-lamp class="light" :class="isLightOn"/>
                <input type="checkbox" class="stand__power-button" @input="switchOnComp(true)">
            </div>
        </div>
        <router-modal/>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex';
    import CompLamp from '@/components/AppLamp'
    import RouterModal from '@/components/modals/RouterModal'

    export default {
        computed: {
            ...mapGetters([
                'workstation_is_on'
            ]),
            ...mapGetters('station/Reference', {
                showAboutProgram: 'showAboutProgram'
            }),
            selectedProgram() {
                return {
                    selected: this.showAboutProgram
                }
            },
            selectedGuide() {
                return {
                    selected: !this.showAboutProgram
                }
            },
            isLightOn() {
                return {
                    on: this.workstation_is_on,
                }
            }
        },
        components: {
            CompLamp,
            RouterModal
        },

        methods: {
            switchOnComp() {
                if (!this.workstation_is_on) {
                    this.$router.push({name: 'WorkStation'});
                    this.$store.commit('switchStateOfWorkstation', {
                        state: true,
                        confirm: true,
                    });
                }
            },
        },
    }
</script>

<style scoped lang="scss">

    @import "../scss/style";
    @import "../scss/pages/reference";
</style>