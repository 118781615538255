<template>
  <div class="modal">
    <div class="modal-content">
      <div class="modal-header">Расшифровка терминов и сокращений</div>
      <div class="modal-body">
        <div class="column">
          <div class="column-text" v-for="text in this.textone" key="text">
           {{text}}
          </div>
        </div>
        <div class="column">
          <div class="column-text"  v-for="text in this.texttwo" key="text">
            {{text }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
            data-enable-pointer-events="true"
            color="#CE6C2B"
            @click="closeModal"
            depressed
            elevation="2"
            x-large
            class="btn-show"
        ><p class="text-btn">Закрыть</p>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      textone:[
          'Patient ID – Идентификатор пациента',
          'DOB - Дата рождения',
          'Weight – вес',
          'Accession – Учет',
          'Fluid A (B)  – жидкость А (B)',
          'Volume - объем',
          'ml – миллилитры',
          'Protocol – протокол',
      ],
      texttwo:[
        'Lb – международный фунт',
        'Kg – килограмм',
        'ISI - Система синхронизации со сканнером',
        'Legasy ISI -Система синхронизации со сканнером',
        'XDS - Система для датчика экстравазации (не представлена в РФ)',
        'Autoload - Автозаполнение',
        'Move piston – Активировать движение поршня',
      ],

    }
  },
  methods: {
    closeModal() {
      this.$emit('close-confirm');
    }
  }
}
</script>
<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  border: 2px solid #CE6C2B;
  padding: 20px;
  max-width: 80%;
  width: 1040px;
  height: 50%;
}

.modal-header {
  font-weight: bold;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 60px;
  color: #CE6C2B;
  text-align: center;
}

.modal-body {
  display: flex;
}

.column {
  flex: 1;
  width: 40%;
}
.column-text {
  margin-bottom: 5px;
  margin-left: 19%;
}
.modal-footer {
  text-align: center;
  margin-top: 10px;
}
.btn-show{
  width: 246px;
  height: 53px;
  background: #CE6C2B;
  margin: 60px auto;

  .text-btn{
    color: #FFF;
    text-align: center;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>