import { assignInstanceConstructor } from '@/sequences/abc_sequence_element'
import { SequenceStepDefinition, SequenceStepInstance } from '@/sequences/SequenceStep/base_classes'

class SequenceStepAllOfMultipleChunksInstance extends SequenceStepInstance {
    processChunkActionExecution(chunkId) {
        if (this.remainingChunks.value.some(({definition: {id}}) => id === chunkId))
            this.remainingChunks.value = this.remainingChunks.value.filter(chunk => {
                if (chunk.definition.id === chunkId) {
                    this.passedChunks.value.push(chunk)
                    return false
                }
                return true
            })

        if (!this.remainingChunks.value.length)
            return this.parent.nextStep(chunkId)
    }
}

export class SequenceStepAllOfMultipleChunksDefinition extends assignInstanceConstructor(SequenceStepAllOfMultipleChunksInstance, SequenceStepDefinition) {}