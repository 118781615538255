export default {
    namespaced: true,
    state: {
        LD_settings_left: {
            'Режим заполнения': 'Одновременно',
            'Источник заполнения': 'B',
            'Тип LPCT': 'Стандартный LPCT',
            'Источник тестового введения': 'B',
            'Объем тестового введения': '10 ml',
        },
        LD_settings_right: {
            'Скорость тестового введения': '4 ml/s',
            'Режим прогр. протокола': 'Расчет продолжительности',
            'Отображение суммарного объема': 'A, B',
            'Автоматический отвод': 'Включение',
            'Автоматическое продвижение': 'Включение',
        },
        LD_settings_second_page: {
            'Авт. заполн. объема д/удален. воздуха': '10 ml',
            'Управление жидкостью': 'Высокий',
            'Типы контрастного вещества': 'A, B',
            'Сброс протокола после введения': 'Выключение',
        },

        LD_settings_tooltips: {
            'Режим заполнения': 'Устанавливает инъектор на заправку линии для пациента из обоих шприцев одновременно или поочередно.\n' +
                'Одновременный режим заправки управляет прохождением жидкости через общий разъем, одновременно подавая как физиологический раствор, так и контрастное средство в течение процесса заправки.\n' +
                'Последовательный режим заправки управляет прохождением жидкости через каждую из ветвей общего разъема, подавая поочередно физиологический раствор и контрастное средство в течение всего процесса заправки\n',
            'Источник заполнения': 'Устанавливает шприц для заправки системы трубок. Оба шприца могут использоваться для заправки. Обычно выбирают шприц с физиологическим раствором в качестве источника жидкости для заправки для экономии контрастного средства, а также для создания возможности провести тестовое введние с физиологическим раствором',
            'Тип LPCT': 'Устанавливает тип используемых расходных материалов: для одноразовых комплектов производства Bayer используется стандартный LPCT, для многоразовых комплектов производства Bayer – P8100 LPCT',
            'Источник тестового введения': 'Устанавливает шприц по умолчанию в качестве источника для пробного введения',
            'Объем тестового введения': 'Устанавливает объем тестового введения по умолчанию',
            'Скорость тестового введения': 'Устанавливает скорость тестового введения по умолчанию',
            'Режим прогр. протокола': 'Система рассчитывает значения для отображаемого параметра. Устанавливает режим программирования, позволяющий системе рассчитывать длительность, скорость введения или объем на основании 2-х других показателей.\n' +
                'Доступны следующие режимы программирования.\n' +
                '• Расчет скорости введения, основываясь на объеме и длительности.\n' +
                '• Расчет объема, основываясь на скорости и длительности.\n' +
                '• Расчет длительности, основываясь на скорости и объеме.\n',
            'Отображение суммарного объема': 'Устанавливает отображение полного объема жидкости по отдельности в шприце A и шприце B или полный совместный объем в шприце A и B.',
            'Автоматический отвод': 'Включает или выключает функцию автоматического втягивания поршня после удаления шприца',
            'Автоматическое продвижение': 'Включает или выключает функцию автоматического продвижения после установки шприца',
            'Авт. заполн. объема д/удален. воздуха': 'При использовании устройств для заполнения компании Bayer выберите один из четырех объемов, основываясь на следующих принципах.\n' +
                '• 30мл для трубки быстрого наполнения;\n' +
                '• 20мл для коротких переходников (58 см);\n' +
                '• 10мл при заполнении только через иглу;\n' +
                '• 0мл (ВЫКЛ.) при заполнении через устройство для заполнения с обратным клапаном (при использовании многоразовых расходных материалов MP-Kit\n' +
                'Для расходных материалов Байер обычно используется следующие параметры: 10, 30 или выключено\n',
            'Управление жидкостью': 'Устанавливает уровень сопротивления для механических  ручек управления внизу головы инъектора.',
            'Типы контрастного вещества': 'Установка или добавление контрастного вещества',
            'Сброс протокола после введения': 'Устанавливает сброс протокола к заводским настройкам по умолчанию после завершения инъекции.',
        },

        filling_mode: ['Одновременно', 'Последовательно'],
        filling_source: ['A', 'B'],
        type_of_LPCT: ['Стандартный LPCT', 'Другой тип LPCT', 'P8100 LPCT'],
        test_injection_source: ['A', 'B'],
        test_injection_volume: ['10 ml', '15 ml', '20 ml'],
        test_injection_speed: ['1 ml/s', '2 ml/s', '3 ml/s', '4 ml/s', '5 ml/s',],
        test_injection_speed_2: ['6 ml/s', '7 ml/s', '8 ml/s', '9 ml/s', '10 ml/s'],
        protocol_programming_mode: ['Отключение', 'Расчет продолжительности', 'Расчет расхода', 'Расчет объема'],
        total_volume_display: ['A, B', 'A + B'],
        automatic_retraction: ['Включение', 'Выключение'],
        automatic_promotion: ['Включение', 'Выключение'],
        aut_fill_volume_for_removed_air: ['10 ml', '20 ml', '30 ml', 'Выключение'],
        fluid_management: ['Высокий', 'Средний', 'Низкий', 'Выключение'],
        types_contrast_agent: [],

        reset_protocol_after_injection: ['Включение', 'Выключение'],

        selectedButton: '',
        showFirstOptions: true,
        isChanged: false,
        done: false,
        showFirstPage: true,
    },
    getters: {
        LD_settings_left(state) {
            return state.LD_settings_left;
        },
        LD_settings_right(state) {
            return state.LD_settings_right;
        },
        LD_settings_second_page(state) {
            return state.LD_settings_second_page;
        },
        LD_settings_tooltips(state) {
            return state.LD_settings_tooltips;
        },
        filling_mode(state) {
            return state.filling_mode;
        },
        filling_source(state) {
            return state.filling_source;
        },
        type_of_LPCT(state) {
            return state.type_of_LPCT;
        },
        test_injection_source(state) {
            return state.test_injection_source;
        },
        test_injection_volume(state) {
            return state.test_injection_volume;
        },
        test_injection_speed(state) {
            return state.test_injection_speed;
        },
        test_injection_speed_2(state) {
            return state.test_injection_speed_2;
        },
        protocol_programming_mode(state) {
            return state.protocol_programming_mode;
        },
        total_volume_display(state) {
            return state.total_volume_display;
        },
        automatic_retraction(state) {
            return state.automatic_retraction;
        },
        automatic_promotion(state) {
            return state.automatic_promotion;
        },
        aut_fill_volume_for_removed_air(state) {
            return state.aut_fill_volume_for_removed_air;
        },
        fluid_management(state) {
            return state.fluid_management;
        },
        types_contrast_agent(state) {
            return state.types_contrast_agent;
        },
        reset_protocol_after_injection(state) {
            return state.reset_protocol_after_injection;
        },
        type_lcpt(state) {
            return state.LD_settings_left['Тип LPCT'];
        },
        air_removal_volume(state) {
            return state.LD_settings_second_page['Авт. заполн. объема д/удален. воздуха'];
        },
        selectedButton(state) {
            return state.selectedButton;
        },
        showFirstOptions(state) {
            return state.showFirstOptions;
        },
        isChanged(state) {
            return state.isChanged;
        },
        done(state) {
            return state.done;
        },
        showFirstPage(state) {
            return state.showFirstPage;
        },
    },
    mutations: {
        setNewValue(state, payload) {
            if (state.settings_left.hasOwnProperty(payload.name)) {
                state.settings_left[payload.name] = payload.value;
            } else if (state.settings_right.hasOwnProperty(payload.name)) {
                state.settings_right[payload.name] = payload.value;
            } else {
                state.settings_second_page[payload.name] = payload.value;
            }
        },
        defaultSettings(state) {
            //данные для LiquidDelivery page
            state.LD_settings_left = Object.assign({}, {
                'Режим заполнения': 'Одновременно',
                'Источник заполнения': 'B',
                'Тип LPCT': 'Станадартный LPCT',
                'Источник тестового введения': 'B',
                'Объем тестового введения': '10 ml',
            })
            state.LD_settings_right = Object.assign({}, {
                'Скорость тестового введения': '4 ml/s',
                'Режим прогр. протокола': 'Расчет продолжительности',
                'Отображение суммарного объема': 'A, B',
                'Автоматический отвод': 'Включение',
                'Автоматическое продвижение': 'Включение',
            })
            state.LD_settings_second_page = Object.assign({}, {
                'Авт. заполн. объема д/удален. воздуха': '20 ml',
                'Управление жидкостью': 'Высокий',
                'Типы контрастного вещества': 'A, B',
                'Сброс протокола после введения': 'Выключение',
            })
        },
        //данные для LiquidDelivery page
        LD_setNewValue(state, payload) {
            if (state.LD_settings_left.hasOwnProperty(payload.name)) {
                state.LD_settings_left[payload.name] = payload.value;
            } else if (state.LD_settings_right.hasOwnProperty(payload.name)) {
                state.LD_settings_right[payload.name] = payload.value;
            } else {
                state.LD_settings_second_page[payload.name] = payload.value;
            }
        },
        LD_cancelSettings(state, payload) {
            state.LD_settings_left = Object.assign({}, payload.left);
            state.LD_settings_right = Object.assign({}, payload.right);
            state.LD_settings_second_page = Object.assign({}, payload.second_page);
        },
        clearSelectedButton(state) {
            state.selectedButton = '';
        },
        assignSelectedButton(state, name) {
            state.selectedButton = name;
        },
        changeShowFirstOptions(state, status) {
            state.showFirstOptions = status;
        },
        changeIsChanged(state, status) {
            state.isChanged = status;
        },
        changeDone(state, status) {
            state.done = status;
        },
        changePage(state) {
            state.showFirstPage = !state.showFirstPage;
        },
        setDefaultModule(state) {
            state.selectedButton = '';
            state.showFirstOptions = true;
            state.isChanged = false;
            state.done = false;
            state.showFirstPage = true;
        },
    },
    actions: {
        defaultSettings({commit}) {
            commit('defaultSettings');
        },
        LD_setNewValue({commit}, payload) {
            commit('LD_setNewValue', payload);
        },
        LD_cancelSettings({commit}, payload) {
            commit('LD_cancelSettings', payload);
        },
        clearSelectedButton({commit}) {
            commit('clearSelectedButton');
        },
        assignSelectedButton({commit}, payload) {
            commit('assignSelectedButton', payload)
        },
        changeShowFirstOptions({commit}, payload) {
            commit('changeShowFirstOptions', payload)
        },
        changeIsChanged({commit}, payload) {
            commit('changeIsChanged', payload)
        },
        changeDone({commit}, payload) {
            commit('changeDone', payload)
        },
        changePage({commit}) {
            commit('changePage');
        },
        setDefaultModule({commit}) {
            commit('setDefaultModule');
        },
    }
}