<template>
    <div
            class="container-injector-page"
            @click="() => {
                if (isEducationModeEnabled) {
                     if ($store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable') {
                         if ($store.getters['sequences_store/getActiveStep'].state.value !== '')
                             $store.getters['sequences_store/getActiveStep'].state.value = ''
                     }
                 }
            }"
    >
        <div @click.stop class="head-zone-common-information"
             v-if="$store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable' && $store.getters['sequences_store/getActiveStep'].state.value.startsWith('head_zoom')">
            <!--            <div class="head-zone-common-information-title">-->
            <!--                <h3>Общая информация:</h3>-->
            <!--                <span>В данном разделе показывается назначение расходных<br/>материалов и частей прибора</span>-->
            <!--            </div>-->
            <div class="head-zone-common-information-instruction">
                Панель управления головы инъектора. Кликните на элемент панели, чтобы увидеть его назначение
            </div>
        </div>
        <EnlargedForEducation/>
        <EnlargedPanel v-if="getEnlargedPanelState" @wrong-click="Mp1ShowNotification"></EnlargedPanel>
        <div class="injector">
            <component ref="injector" :is="injector"
                       @wrong-click="Mp1ShowNotification"
                       @lock-request="showLockNotification"
            >

            </component>
        </div>
        <div class="details">
            <h1 class="details__header">{{header}}</h1>
            <template v-if="installation_started">
                <div class="section-details">
                    <h2 class="section-details__header">{{chosen_details_type.slice(0,18)}}<strong>{{chosen_details_type.slice(18)}}</strong>
                    </h2>
                    <div class="section-details__row wrap">
                        <div class="section-details__item-alone" v-for="(detail, index) in details"
                             :class="detail.classes">
                            <SequenceChunkWrapper
                                    v-if="detail.id === 1"
                                    v-slot="{processChunkAction: processChunkAction_spk}"
                                    sequence-id="injector_head_setup"
                                    step-id="spk_set_flasks"
                            >
                                <SequenceChunkWrapper
                                        v-slot="{processChunkAction: processChunkAction_mp1}"
                                        sequence-id="injector_head_setup"
                                        step-id="mp1_set_flasks"
                                >
                                    <img v-show="!detail.set"
                                         :src="detail.src"
                                         @click="() => {
                                             const fn = () => setDetail(detail, index)
                                             processChunkAction_spk(fn, {enabledIfEducationIsInactive: true, mustReturnTrue: true})
                                             processChunkAction_mp1(fn, {mustReturnTrue: true})
                                         }"
                                         alt="">
                                </SequenceChunkWrapper>
                            </SequenceChunkWrapper>
                            <SequenceChunkWrapper
                                    v-else-if="detail.id === 2"
                                    v-slot="{processChunkAction: processChunkAction_spk_set_needles_to_flasks}"
                                    sequence-id="injector_head_setup"
                                    step-id="spk_set_needles_to_flasks"
                            >
                                <SequenceChunkWrapper
                                        v-slot="{processChunkAction: processChunkAction_mp1_set_flacons}"
                                        sequence-id="injector_head_setup"
                                        step-id="mp1_set_flacons"
                                >
                                    <img v-show="!detail.set"
                                         :src="detail.src"
                                         @click="() => {
                                             const fn = () => setDetail(detail, index)
                                             processChunkAction_spk_set_needles_to_flasks(fn, {enabledIfEducationIsInactive: true, mustReturnTrue: true})
                                             processChunkAction_mp1_set_flacons(fn, {mustReturnTrue: true})
                                         }"
                                         alt="">
                                </SequenceChunkWrapper>
                            </SequenceChunkWrapper>
                            <SequenceChunkWrapper
                                    v-else-if="detail.id === 3"
                                    v-slot="{processChunkAction: processChunkAction_spk_set_flacons}"
                                    sequence-id="injector_head_setup"
                                    step-id="spk_set_flacons"
                                    key="spk_set_flacons"
                            >
                                <SequenceChunkWrapper
                                        v-slot="{processChunkAction: processChunkAction_mp1_set_tube_rolled_up}"
                                        sequence-id="injector_head_setup"
                                        step-id="mp1_set_tube_rolled_up"
                                        key="mp1_set_tube_rolled_up"
                                >
                                    <img v-show="!detail.set"
                                         :src="detail.src"
                                         @click="() => {
                                         const fn = () => setDetail(detail, index)
                                         processChunkAction_spk_set_flacons(fn, {enabledIfEducationIsInactive: true, mustReturnTrue: true})
                                         processChunkAction_mp1_set_tube_rolled_up(fn, {mustReturnTrue: true})
                                     }"
                                         alt="">
                                </SequenceChunkWrapper>
                            </SequenceChunkWrapper>
                            <SequenceChunkWrapper
                                    v-else-if="detail.id === 4"
                                    v-slot="{processChunkAction: processChunkAction_spk_set_tube}"
                                    sequence-id="injector_head_setup"
                                    step-id="spk_set_tube"
                                    key="spk_set_tube"
                            >
                                <SequenceChunkWrapper
                                        v-slot="{processChunkAction: processChunkAction_mp1_set_bleed_tube}"
                                        sequence-id="injector_head_setup"
                                        step-id="mp1_set_bleed_tube"
                                        key="mp1_set_bleed_tube"
                                >
                                    <img v-show="!detail.set"
                                         :src="detail.src"
                                         @click="() => {
                                             const fn = () => setDetail(detail, index)
                                             processChunkAction_spk_set_tube(fn, {enabledIfEducationIsInactive: true, mustReturnTrue: true})
                                             processChunkAction_mp1_set_bleed_tube(fn, {mustReturnTrue: true})
                                         }"
                                         alt="">
                                </SequenceChunkWrapper>
                            </SequenceChunkWrapper>
                            <SequenceChunkWrapper
                                    v-else-if="detail.id === 5"
                                    v-slot="{processChunkAction: processChunkAction_spk_set_bleed_tube}"
                                    sequence-id="injector_head_setup"
                                    step-id="spk_set_bleed_tube"
                                    key="spk_set_bleed_tube"
                            >
                                <SequenceChunkWrapper
                                        v-slot="{processChunkAction: processChunkAction_mp1_set_card}"
                                        sequence-id="injector_head_setup"
                                        step-id="mp1_set_card"
                                        key="mp1_set_card"
                                >
                                    <img v-show="!detail.set"
                                         :src="detail.src"
                                         @click="() => {
                                             const fn = () => setDetail(detail, index)

                                             processChunkAction_spk_set_bleed_tube(fn, {enabledIfEducationIsInactive: true, mustReturnTrue: true})
                                             processChunkAction_mp1_set_card(fn, {mustReturnTrue: true})
                                         }"
                                         alt="">
                                </SequenceChunkWrapper>
                            </SequenceChunkWrapper>
                            <img v-else v-show="!detail.set"
                                 :src="detail.src"
                                 @click="setDetail(detail, index)"
                                 alt="">
                        </div>
                    </div>
                </div>
                <div class="details__footer footer">
                    <div v-show="!tube.set" class="footer__item tube" :class="invisible">
                        <h2>Одноразовая трубка <strong>SPD 250</strong></h2>
                        <SequenceChunkWrapper
                                v-slot="{processChunkAction}"
                                sequence-id="injector_head_setup"
                                step-id="mp1_set_tube_SDP250"
                                key="mp1_set_tube_SDP250"
                        >
                            <img :src="tube.src"
                                 @click="() => {
                                     processChunkAction(() => setDetail(tube), {enabledIfEducationIsInactive: true, mustReturnTrue: true})
                                 }"
                                 alt=""
                            >
                        </SequenceChunkWrapper>
                    </div>
                    <div class="footer__item" v-show="!heaters.set">
                        <SequenceChunkWrapper
                                v-slot="{processChunkAction: processChunkAction_spk_set_heaters}"
                                sequence-id="injector_head_setup"
                                step-id="spk_set_heaters"
                                key="spk_set_heaters"
                        >
                            <SequenceChunkWrapper
                                    v-slot="{processChunkAction: processChunkAction_mp1_set_heaters}"
                                    sequence-id="injector_head_setup"
                                    step-id="mp1_set_heaters"
                                    key="mp1_set_heaters"
                            >
                                <img :src="heaters.src"
                                     @click="() => {
                                         const fn = () => setDetail(heaters)
                                         processChunkAction_spk_set_heaters(fn, {enabledIfEducationIsInactive: true, mustReturnTrue: true})
                                         processChunkAction_mp1_set_heaters(fn, {mustReturnTrue: true})
                                     }"
                                     alt="">
                            </SequenceChunkWrapper>
                        </SequenceChunkWrapper>
                    </div>
                </div>
            </template>

            <template
                    v-else
                    v-for="section in materials"
            >
                <SequenceChunkWrapper
                        v-if="['Одноразовый набор SDS-CTP-SPK'].includes(section.name)"
                        v-slot="{processChunkAction: processChunkAction_select_SPK_kit}"
                        sequence-id="injector_head_setup"
                        step-id="select_injection_kit"
                        chunk-id="select_SPK_kit"
                        key="select_injection_kit_select_SPK_kit"
                >
                    <div
                            :class="[
                                'section-details',
                                $store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable' && $store.getters['sequences_store/getActiveStep'].state.value === section.sequence_step_path && 'section-details--active'
                            ]"
                            :style="(isEducationModeEnabled && !$store.getters['sequences_store/getActiveSequenceId']) && {pointerEvents: 'none'}"
                            @click.stop="() => {
                                if (isEducationModeEnabled) {
                                    const fn = () => $store.dispatch('injector/start_installation', {
                                        status: true,
                                        type: section.name
                                    })

                                    processChunkAction_select_SPK_kit(fn)

                                    if ($store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable') {
                                        if ($store.getters['sequences_store/getActiveStep'].state.value !== section.sequence_step_path)
                                            $store.getters['sequences_store/getActiveStep'].state.value = section.sequence_step_path
                                    }
                                } else {
                                    chooseSet(section.name)
                                }
                            }"
                    >
                        <h2 class="section-details__header">
                            {{ section.name.slice(0, 18) }}<strong>{{ section.name.slice(18) }}</strong></h2>
                        <div class="section-details__row">
                            <div class="section-details__item" v-for="(img, i) in section.details"
                                 :key="section.name + img.text">
                                <img :src="img.src" alt="">
                                <div v-if="$store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable' && $store.getters['sequences_store/getActiveStep'].state.value === section.sequence_step_path">
                                    {{ i + 1 }}
                                </div>
                            </div>
                        </div>

                        <div
                                v-if="$store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable' && $store.getters['sequences_store/getActiveStep'].state.value === section.sequence_step_path"
                                :style="section.huge_popup.style"
                                v-html="section.huge_popup.content"
                                data-aaa-sequence-huge-popup
                        ></div>
                    </div>
                </SequenceChunkWrapper>
                <SequenceChunkWrapper
                        v-else-if="['Многоразовый набор SDS MP1 (+SPD 250)'].includes(section.name)"
                        v-slot="{processChunkAction: processChunkAction_select_MP1_kit}"
                        sequence-id="injector_head_setup"
                        step-id="select_injection_kit"
                        chunk-id="select_MP1_kit"
                        key="select_injection_kit_select_MP1_kit"
                >
                    <div
                            :class="[
                                'section-details',
                                $store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable' && $store.getters['sequences_store/getActiveStep'].state.value === section.sequence_step_path && 'section-details--active'
                            ]"
                            :style="(isEducationModeEnabled && !$store.getters['sequences_store/getActiveSequenceId']) && {pointerEvents: 'none'}"
                            @click.stop="() => {
                                if (isEducationModeEnabled) {
                                    const fn = () => $store.dispatch('injector/start_installation', {
                                        status: true,
                                        type: section.name
                                    })

                                    processChunkAction_select_MP1_kit(fn)

                                    if ($store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable') {
                                        if ($store.getters['sequences_store/getActiveStep'].state.value !== section.sequence_step_path)
                                            $store.getters['sequences_store/getActiveStep'].state.value = section.sequence_step_path
                                    }
                                } else {
                                    chooseSet(section.name)
                                }
                            }"
                    >
                        <h2 class="section-details__header">
                            {{ section.name.slice(0, 18) }}<strong>{{ section.name.slice(18) }}</strong></h2>
                        <div class="section-details__row">
                            <div class="section-details__item" v-for="(img, i) in section.details"
                                 :key="section.name + img.text">
                                <img :src="img.src" alt="">
                                <div v-if="$store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable' && $store.getters['sequences_store/getActiveStep'].state.value === section.sequence_step_path">
                                    {{ i + 1 }}
                                </div>
                            </div>
                        </div>

                        <div
                                v-if="$store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable' && $store.getters['sequences_store/getActiveStep'].state.value === section.sequence_step_path"
                                :style="section.huge_popup.style"
                                v-html="section.huge_popup.content"
                                data-aaa-sequence-huge-popup
                        ></div>
                    </div>
                </SequenceChunkWrapper>
                <div v-else :class="[
                                'section-details',
                                $store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable' && $store.getters['sequences_store/getActiveStep'].state.value === section.sequence_step_path && 'section-details--active'
                            ]"
                     :style="{...(isEducationModeEnabled && {cursor: 'default'}), ...($store.getters['sequences_store/getActiveSequenceId'] === 'injector_head_setup' && {opacity: '0.3'}), ...((isEducationModeEnabled && !$store.getters['sequences_store/getActiveSequenceId']) && {pointerEvents: 'none'})}"
                     @click.stop="() => {
                         if (isEducationModeEnabled) {
                             if ($store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable') {
                                 if ($store.getters['sequences_store/getActiveStep'].state.value !== section.sequence_step_path)
                                     $store.getters['sequences_store/getActiveStep'].state.value = section.sequence_step_path
                             }
                         } else {
                             chooseSet(section.name)
                         }
                         // isEducationModeEnabled ? (section.is_tutorial_exist && chooseSet(section.name)) : chooseSet(section.name)
                     }"
                >
                    <h2 class="section-details__header">
                        {{ section.name.slice(0, 18) }}<strong>{{ section.name.slice(18) }}</strong></h2>
                    <div class="section-details__row">
                        <div class="section-details__item" v-for="(img, i) in section.details"
                             :key="section.name + img.text">
                            <img :src="img.src" alt="">
                            <div v-if="$store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable' && $store.getters['sequences_store/getActiveStep'].state.value === section.sequence_step_path">
                                {{ i + 1 }}
                            </div>
                        </div>
                    </div>

                    <div
                            v-if="$store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable' && $store.getters['sequences_store/getActiveStep'].state.value === section.sequence_step_path"
                            :style="section.huge_popup.style"
                            v-html="section.huge_popup.content"
                            data-aaa-sequence-huge-popup
                    ></div>
                </div>
            </template>
        </div>
        <modal-notification
                v-show="showModalNotification"
                data-enable-pointer-events="true"
                :img_src="modal_img"
                @confirm="$store.dispatch('injector/changeModalNotificationVisibility', false)"
        >
            <template v-slot:information_1>
                {{modal_first_p}}
            </template>
            <template v-slot:information_2>
                {{modal_second_p}}
            </template>
            <template #information_3>
                {{modal_third_p}}
            </template>
            <template v-slot:information_center>
                {{modal_centered_p}}
            </template>
            <template v-slot:button_confirm>
                {{modal_button_text}}
            </template>
        </modal-notification>
        <modal-options v-show="showModalOptions"
                       @cancel="$store.dispatch('injector/changeModalOptionsVisibility', false)"
                       @confirm="resetMaterials"
        >
            <template v-slot:information_1>
                {{modal_first_p}}
            </template>
            <template v-slot:information_2>
                {{modal_second_p}}
            </template>
            <template v-slot:button-confirm>
                {{modal_button_text}}
            </template>
            <template v-slot:button-cancel>
                {{modal_button_text_cancel}}
            </template>
        </modal-options>
        <div class="controls">
            <div class="controls__top" v-show="installation_started">
                <SequenceChunkWrapper
                        v-slot="{processChunkAction: processChunkAction_spk_turn_down_injector_head}"
                        sequence-id="injector_head_setup"
                        step-id="spk_turn_down_injector_head"
                        key="spk_turn_down_injector_head"
                >
                    <SequenceChunkWrapper
                            v-slot="{processChunkAction: processChunkAction_mp1_turn_down_injector_head}"
                            sequence-id="injector_head_setup"
                            step-id="mp1_turn_down_injector_head"
                            key="mp1_turn_down_injector_head"
                    >
                        <button
                                :class="transform_button"
                                @click="() => {
                                    processChunkAction_spk_turn_down_injector_head(invertInjector, {enabledIfEducationIsInactive: true})
                                    processChunkAction_mp1_turn_down_injector_head(invertInjector)
                                }"
                                class="controls__top-button"
                        ><img src="../img/injector/icons/icon-rolled-up.svg" alt="">Повернуть
                            голову инъектора
                        </button>
                    </SequenceChunkWrapper>
                </SequenceChunkWrapper>
                <SequenceChunkWrapper
                        v-slot="{processChunkAction: processChunkAction_spk_connect_system_with_patient, isActive: isActive_spk_connect_system_with_patient}"
                        sequence-id="injector_head_setup"
                        step-id="spk_connect_system_with_patient"
                        key="spk_connect_system_with_patient"
                >
                    <SequenceChunkWrapper
                            v-slot="{processChunkAction: processChunkAction_mp1_connect_system_with_patient, isActive: isActive_mp1_connect_system_with_patient}"
                            sequence-id="injector_head_setup"
                            step-id="mp1_connect_system_with_patient"
                            key="mp1_connect_system_with_patient"
                    >
                        <button
                                :class="connect_button"
                                @click="() => {

                                    if (isActive_spk_connect_system_with_patient || isActive_mp1_connect_system_with_patient) {
                                        const fn = () => {
                                            $store.dispatch('injector/connectToPatient', true);
                                            $store.dispatch('injector/changeHumanStatus', true);
                                        }

                                        processChunkAction_spk_connect_system_with_patient(fn)
                                        processChunkAction_mp1_connect_system_with_patient(fn)

                                    } else {
                                        showNotificationConnect()
                                    }
                                }"
                                class="controls__top-button"
                        >
                            <img src="../img/injector/icons/plug-icon.svg" alt="">Подключить к пациенту
                        </button>
                    </SequenceChunkWrapper>
                </SequenceChunkWrapper>
            </div>
            <div class="controls__bottom">
                <template v-if="installation_started">
                    <button class="controls__bottom-reset transparent"
                            :style="{...(isEducationModeEnabled && getActiveSequenceId !== 'SPK_injector' && {cursor: 'default'}), ...(isEducationModeEnabled && {transform: 'scale(0)'})}"
                            @click="isEducationModeEnabled && getActiveSequenceId === 'SPK_injector' ? $store.dispatch('injector/reset_installation', {
                                status: true
                            }) && $store.dispatch('setCurrentSequenceStep', 'set_flasks') : isEducationModeEnabled ? null : resetModal()"
                    >Сброс
                    </button>
                    <button class="controls__bottom-back transparent"
                            data-enable-pointer-events="true"
                            @click="() => {
                                if (isEducationModeEnabled) {
                                    this.$store.dispatch('sequences_store/startSequence', {sequenceId: 'injector_head_setup'})
                                } else {
                                    backToChoicesModal()
                                }
                            }"
                    >К выбору расх материалов
                    </button>
                </template>
                <router-modal class="controls__bottom-router"/>
            </div>
        </div>
      <TermDefinition v-if="modalEx" style="z-index: 99999" @close-confirm="closeModal"/>
      <InfoButton style="width: 13.5%; position: fixed;right: 2%;bottom: 6% ;z-index: 3" @open-confirm="openModal"/>
    </div>
</template>

<script>

    import {mapState, mapGetters} from 'vuex';
    import RouterModal from '@/components/modals/RouterModal'

    import EmptyInjector from '@/components/injectors/Empty'
    import MP1 from '@/components/injectors/MP1'
    import QFT from '@/components/injectors/QFT'
    import SPK from '@/components/injectors/SPK'
    import EnlargedPanel from "@/components/injectors/EnlargedPanel/EnlargedPanel";

    import ModalNotification from '@/components/modals/ModalNotification'
    import ModalOptions from '@/components/modals/ModalOptions'
    import InfoButton from "@/components/InfoButton.vue";
    import TermDefinition from "@/components/TermDefinition.vue";

    export default {
        components: {
            RouterModal,
            EmptyInjector,
            ModalNotification,
            ModalOptions,
            MP1,
            QFT,
            SPK,
            EnlargedPanel,
            EnlargedForEducation: () => import('@/components/injectors/EnlargedForEducation.vue'),
            InfoButton,
            TermDefinition,
        },
        data() {
            return {
                header: 'Выберите расходные материалы',
                modal_first_p: '',
                modal_second_p: '',
                modal_third_p: '',
                modal_centered_p: '',
                modal_button_text: '',
                modal_button_text_cancel: '',
                modal_img: '',
                modalEx:false,
                materials: [
                    {
                        name: 'Одноразовый набор SDS-CTP-SPK',
                        sequence_step_path: 'spk',
                        is_tutorial_exist: true,
                        huge_popup: {
                            style: {
                                top: '166%',
                                left: '-24%'
                            },
                            content: '1.\tШприцы объемом 200 мл<br>' +
                                '2.\tT-образная соединительная трубка низкого давления для подсоединения к колбам<br>' +
                                '3.\tТрубочка для прокачки воздуха<br>' +
                                '4.\tИглы для заполнения шприцев'
                        },
                        details: [
                            {
                                src: require('../img/injector/flasks.svg'),
                                text: 'Шприцы объемом 200 мл',
                                width: 110
                            },
                            {
                                src: require('../img/injector/tube.svg'),
                                text: 'T-образная соединительная трубка низкого давления для подсоединения к колбам'
                            },
                            {
                                src: require('../img/injector/bleed-tube.svg'),
                                text: 'Трубочка для прокачки'
                            },
                            {
                                src: require('../img/injector/needles.svg'),
                                text: 'Иглы для заполнения шприцев'
                            }
                        ]
                    },
                    {
                        name: 'Одноразовый набор SDS-CTP-QFT',
                        sequence_step_path: 'qft',
                        huge_popup: {
                            style: {
                                bottom: '125%',
                                left: '-24%'
                            },
                            content: '1.\tШприцы объемом 200 мл<br>' +
                                '2.\tT-образная соединительная трубка низкого давления для подсоединения к колбам<br>' +
                                '3.\tТрубочка для прокачки воздуха<br>' +
                                '4.\tТрубочка быстрого заполнения шприцев'
                        },
                        details: [
                            {
                                src: require('../img/injector/flasks.svg'),
                                text: 'Шприцы объемом 200 мл',
                                width: 110
                            },
                            {
                                src: require('../img/injector/tube.svg'),
                                text: 'T-образная соединительная трубка низкого давления для подсоединения к колбам'
                            },
                            {
                                src: require('../img/injector/bleed-tube.svg'),
                                text: 'Трубочка для прокачки'
                            },
                            {
                                src: require('../img/injector/quick-fill-tube.svg'),
                                text: 'a'
                            },
                        ],
                    },
                    {
                        name: 'Многоразовый набор SDS MP1 (+SPD 250)',
                        sequence_step_path: 'mp1',
                        huge_popup: {
                            style: {
                                bottom: '135%',
                                left: '-30%'
                            },
                            content: '1.\tШприцы объемом 200 мл<br>' +
                                '2.\tТрубки соединительная для нескольких пациентов с обратными клапанами <br>' +
                                '3.\tSPD250 – это отдельная линия пациента, которая не входит в комплект MP1. \n' +
                                'Одноразовый компонент SPD 250 - трубка для одного пациента длинной 2,5 метра. Этот элемент системы подлежит замене после каждого пациента.\n<br>' +
                                '4.\tЛинии для заполнения шприц-колб <br>' +
                                '5.\tИнформационная карточка, на которой указывается время начала работы с компонентом и конец (через 12 часов) <br>'
                        },
                        details: [
                            {
                                src: require('../img/injector/flasks.svg'),
                                text: 'Шприцы объемом 200 мл',
                                width: 110
                            },
                            {
                                src: require('../img/injector/tube-reusable.svg'),
                                text: 'Трубки соединительная для нескольких пациентов с обратными клапанами ',
                                width: 100
                            },
                            {
                                src: require('../img/injector/tube-SPD250.svg'),
                                text: 'Одноразовый компонент SPD 250 - трубка для одного пациента длинной 2,5 метра. Этот элемент системы подлежит замене после каждого пациента.',
                                width: 100
                            },
                            {
                                src: require('../img/injector/tube-rolled-up.svg'),
                                text: 'Линии для заполнения шприц-колб ',
                                width: 100
                            },
                            {
                                src: require('../img/injector/card-info.svg'),
                                text: 'Информационная карточка, на которой указывается время начала работы с компонентом и конец (через 12 часов) ',
                                width: 100,
                                class: 'reduce'
                            }
                        ],
                    },
                ],

                connectNotificationShown: false
            }
        },
        created() {
            this.sequencesEventBus.$on('cleanup-before-injector-tutorial', this.resetMaterials)
        },
        beforeDestroy() {
            this.sequencesEventBus.$off('cleanup-before-injector-tutorial', this.resetMaterials)
        },
        methods: {
            Mp1ShowNotification() {
                this.modal_first_p = '';
                this.modal_second_p = '';
                this.modal_third_p = ''
                this.modal_centered_p = 'Многоразовый комплект рекомендуется установить полностью, а затем приступить к заполнению системы';
                this.modal_button_text = 'Понятно';
                this.modal_img = null;
                this.$store.dispatch('injector/changeModalNotificationVisibility', true);
            },
            showLockNotification() {
                this.modal_first_p = '';
                this.modal_second_p = '';
                this.modal_third_p = ''
                this.modal_centered_p = 'Необходимо сначала заблокировать протокол на рабочей станции';
                this.modal_button_text = 'Понятно';
                this.modal_img = null;
                this.$store.dispatch('injector/changeModalNotificationVisibility', true);
            },
            chooseSet(name) {
                if (
                    name.includes('SDS-CTP-SPK') && this.details_type === 'Стандартный LPCT' && this.air_removal_volume === '10 ml' ||
                    name.includes('SDS-CTP-QFT') && this.details_type === 'Стандартный LPCT' && this.air_removal_volume === '30 ml' ||
                    name.includes('SDS MP1 (+SPD 250)') && this.details_type === 'P8100 LPCT' && this.air_removal_volume === 'Выключение'
                ) {
                    this.modal_first_p = 'Клик на расходном материале устанавливает его на голову инъектора.';
                    this.modal_second_p = 'Клик на установленном - снимает его с головы инъектора.';
                    this.modal_third_p = 'Есть возможность увеличить панель, кликнув на кнопку с изображением лупы на голове инъектора.'
                    this.modal_centered_p = '';
                    this.modal_button_text = 'Понятно';
                    this.modal_img = null;
                    this.$store.dispatch('injector/changeModalNotificationVisibility', true);
                    this.header = 'Установка расходных материалов';
                    this.$store.dispatch('injector/start_installation', {
                        status: true,
                        type: name,
                    });

                } else {
                    this.modal_first_p = 'Настройки доставки жидкости не соответствуют типу выбранного расходного материала.';
                    this.modal_second_p = 'Необходимо изменить настройки рабочей станции (Настройки/Доставка жидкости).';
                    this.modal_third_p = '';
                    this.modal_centered_p = '';
                    this.modal_button_text = 'Понятно';
                    this.modal_img = null;
                    this.$store.dispatch('injector/changeModalNotificationVisibility', true);
                }
            },
            backToChoicesModal() {
                this.modal_first_p = 'Возврат к выбору комлпектов расходных материалов.';
                this.modal_second_p = 'Все установленные расходные материалы будут сняты.';
                this.modal_third_p = '';
                this.modal_button_text = 'Возврат к выбору';
                this.modal_button_text_cancel = 'Отмена';
                this.$store.dispatch('injector/changeModalOptionsVisibility', true);
            },

            resetModal() {
                this.modal_first_p = 'Возврат к началу работы с комплектом расходных материалов.';
                this.modal_second_p = 'Все установленные расходные материалы будут сняты.';
                this.modal_third_p = '';
                this.modal_centered_p = '';
                this.modal_button_text = 'Сброс';
                this.modal_button_text_cancel = 'Отмена';
                this.$store.dispatch('injector/changeModalOptionsVisibility', true);
            },

            resetMaterials() {
                this.$store.dispatch('injector/changeModalOptionsVisibility', false);

                this.$store.dispatch('injector/reset_installation', {
                    status: this.modal_button_text === 'Сброс',
                })

                if (this.modal_button_text !== 'Сброс') {
                    this.header = 'Выберите расходные материалы';
                    this.$store.commit('injector/SET_TYPE', 'EmptyInjector');
                } else {
                    this.$refs.injector.parentMethod();
                }
            },
            setDetail({id, name}, index) {
                if (id - this.current_id > 1 ||
                this.injector === 'SPK' && (!this.selected_kit[0].fulfil || !this.selected_kit[3].fulfil) && (id > 3 || id === '>3') ||
                this.injector === 'QFT' && !this.selected_kit[0].left_fulfil && id === 4 ||
                this.injector === 'QFT' && !this.selected_kit[0].right_fulfil && (id > 4 || id === '>3') ||
                this.injector === 'MP1' && (!this.filling_started.a || !this.filling_started) && id === '>3') {
                    this.modal_first_p = '';
                    this.modal_second_p = '';
                    this.modal_third_p = '';
                    this.modal_centered_p = 'Неверный порядок установки!';
                    this.modal_button_text = 'ОК';
                    this.$store.dispatch('injector/changeModalNotificationVisibility', true);
                    this.modal_img = null;
                    return false
                } else {
                    this.$store.dispatch('injector/setDetail', {
                        index: index,
                        name: name,
                    });
                    return true
                }
            },
            invertInjector() {
                this.$store.dispatch('injector/invertInjector');
                if (this.injector === 'MP1') {
                    this.$store.commit('injector/setKitStatus', true);
                }
            },
            showNotificationConnect() {
                this.modal_img = require('@/img/injector/connected-to-patient(drop to drop).svg');
                this.modal_first_p = '';
                this.modal_second_p = '';
                this.modal_third_p = '';
                this.modal_centered_p = 'Система подключена к пациенту.';
                this.modal_button_text = 'Понятно';
                this.connectNotificationShown = true;
                this.$store.dispatch('injector/changeModalNotificationVisibility', true);
                this.$store.dispatch('injector/connectToPatient', true);
                this.$store.dispatch('injector/changeHumanStatus', true);
            },
            openModal() {
                this.modalEx = true;
            },
            closeModal() {
                this.modalEx = false;
            }
        },
        computed: {
            ...mapGetters('injector', {
                pumping: 'pumping',
                manual_pumping: 'manual_pumping',
                fluids: 'fluids',
                injector_inverted: 'injector_inverted',
                kit_installed: 'kit_installed',
                connected_to_patient: 'connected_to_patient',
                filling_started: 'filling_started',
                getEnlargedPanelState: 'getEnlargedPanelState',
                injector_may_be_inverted: 'injector_may_be_inverted',
                check_air: 'check_air',
                arm: 'arm'
            }),
            ...mapGetters('sequences_store', ['getActiveSequenceId', 'getActiveChunksIds']),
            ...mapGetters('station/settingsLiquidDelivery', {
                details_type: 'type_lcpt',
                air_removal_volume: 'air_removal_volume',
            }),
            ...mapState('injector', {
                installation_started: 'installation_started',
                chosen_details_type: 'chosen_details_type',
                heaters: 'heaters',
                tube: 'tube-SPD250',
                current_id: 'current_id',
                selected_kit: 'selected_kit',
                showModalNotification: 'showModalNotification',
                showModalOptions: 'showModalOptions',
            }),
            details() {
                return this.$store.getters[`injector/selected_kit`];
            },
            invisible() {
                return {
                    invisible: this.chosen_details_type !== 'Многоразовый набор SDS MP1 (+SPD 250)'
                }
            },
            injector() {
                if (this.chosen_details_type === 'EmptyInjector') {
                    return 'EmptyInjector';
                } else {
                    return this.chosen_details_type.match(/QFT|SPK|MP1/i)[0];
                }
            },
            transform_button() {
                return {
                    active: this.injector_may_be_inverted,
                    orange: this.injector_may_be_inverted,
                }
            },
            connect_button() {
                return {
                    orange: this.check_air && this.arm,
                    active: this.check_air && this.arm,
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "../scss/variable";

    .container-injector-page {
        padding: 20px 30px;
        display: flex;
        gap: 110px;
        height: 100%;
        background: #EFEFEF;

        @media screen and (max-width: 820px) {
            flex-direction: column;
        }

        .head-zone-common-information {
            position: absolute;
            top: 135px;
            left: 20px;
            z-index: 6;

            &-title {
                margin-bottom: 15px;

                * {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            &-instruction {
                font-size: 14px;
                font-weight: 600;
                max-width: 341px;
                padding: 20px;
                border: 1px orange solid;
            }
        }

        .controls {
            position: absolute;
            bottom: 7%;
            left: 2%;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            gap: 70px;

            @media screen and (max-width: 820px) {
                bottom: 40%;
            }

            button {
                padding: 10px;
                width: 200px;

                @media screen and (max-width: 1279px) {
                    width: 150px;
                    font-size: 12px;
                }
                @media screen and (max-width: 1150px) {
                    width: 130px;
                    font-size: 12px;
                }
            }

            &__top {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &-button {
                    display: flex;
                    background: grey;
                    color: #b3b3b3;
                    text-align: left;
                    gap: 10px;
                    align-items: center;
                    cursor: default;
                    pointer-events: none;

                    img {
                        width: 25px;
                        height: auto;
                    }
                }

                .orange {
                    color: #fff;
                    background: $modal-color !important;
                    cursor: pointer;
                }

                .active {
                    pointer-events: all;
                }
            }

            &__bottom {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .transparent {
                    background: transparent;
                    border: 2px solid $modal-color !important;
                    color: $modal-color;
                }

                &-router {
                    position: relative;
                    bottom: 0;
                    left: 0;
                }
            }

        }
    }

    .injector {
        flex: 1 0 calc(42% - 150px);
        margin-left: 150px;
        height: 94vh;
    }

    .details {
        flex: 0 2 58%;

        &__header {
            font-size: 28px;
            margin: 10px 0px;
        }

        .section-details {
            border: 2px solid rgba(0, 0, 0, 0.3);
            padding: 10px 15px 10px 80px;
            margin-bottom: 20px;
            cursor: pointer;
            position: relative;

            div[data-aaa-sequence-huge-popup] {
                position: absolute;
                background-color: white;
                padding: 30px 25px;
                font-size: 16px;
                left: 0;
                width: 100%;
                border: 2px orange solid;
                z-index: 20;
            }

            &--active {
                border: 2px dashed red !important;
            }

            &:hover {
                border: 2px solid #E17000;
            }

            &__row {
                display: flex;
                align-items: center;
                gap: 30px;

                .first-line {
                    flex: 0 1 calc(50% - 30px);

                    img {
                        width: 80%;
                        height: auto;
                        max-height: 103px;
                    }
                }

                .flacon {
                    flex: 0 1 calc(15% - 30px);
                }

                .card {
                    img {
                        width: 30%;
                    }
                }
            }

            .wrap {
                flex-wrap: wrap;
            }

            &__header {
                margin-bottom: 20px;
                font-size: 20px;
            }

            &__item {
                flex: 0 1 13%;
                position: relative;

                div {
                    content: '';
                    position: absolute;
                    top: 100%;
                    font-size: 18px;
                    background-color: white;
                    border: 1px solid orange;
                    color: black;
                    padding: 7px 10px;
                    z-index: 20;
                    margin-top: 10px;

                    &:before {
                        content: '';
                        position: absolute;
                        top: -10%;
                        left: 6.7%;
                        width: 0;
                        height: 0;
                        border-left: 20px solid orange;
                        border-right: 20px solid transparent;
                        border-bottom: 20px solid transparent;
                        transform: rotate(45deg);
                    }
                }


                @media screen and (max-width: 820px) {
                    flex: 0 1 25%;
                }

                img {
                    width: 100%;
                    height: auto;
                    max-height: 103px;
                }

                .reduce {
                    height: 45px;
                }
            }

            &__item-alone {
                flex: 0 1 calc(25% - 30px);
                border: 2px solid transparent;
                padding: 3px;

                img {
                    width: 100%;
                    height: auto;
                    padding: 5px;

                    &:hover {
                        border: 2px solid #E17000;
                        padding: 3px;
                    }
                }
            }
        }

        &__footer {
            display: flex;
            gap: 30px;

        }

        .footer {
            &__item {
                flex: 0 1 50%;
                border: 2px solid rgba(0, 0, 0, 0.3);
                display: flex;
                align-items: center;
                padding: 20px;
                cursor: pointer;

                &:hover {
                    border: 2px solid #E17000;
                }

                img {
                    width: 100%;
                    height: auto;
                    max-height: 103px;
                }
            }

            .tube {
                flex-direction: column;
                justify-content: space-between;
                gap: 90px;

                h2 {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            .invisible {
                opacity: 0;
                pointer-events: none;
            }
        }


    }

    @media screen and (max-width: 1279px) {
        .injector {
            flex: 1 1 calc(50% - 20px);
            margin-left: 20px;
        }

        .details {
            flex: 1 2 50%;
        }
    }
</style>