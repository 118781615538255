<template>
    <div class="date-time">dd/mm/yyyy hh:mm:ss PM</div>
</template>

<script>
    export default {
        name: "DateTime"
    }
</script>

<style scoped lang="scss">

    @import "../scss/variable";
    @import "../scss/mixin";

    .date-time {
        @include border;
        color: white;
       height: 36px;
    }

</style>