import Vue from 'vue'

import enlarged_panel from './injector_modules/enlarged_panel'

export default {
    namespaced: true,
    modules: {
        enlarged_panel,
    },
    state: {
        isEnlargedPanelOpened: false,
        current_id: 0,
        chosen_details_type: 'EmptyInjector',
        short_type: '',
        installation_started: false,
        autoload_is_chosen: false,
        manualload_is_chosen: false,
        injection_started: false,
        injector_may_be_inverted: false,
        filling_started: {
            a: false,
            b: false,
        },
        manual_load_started: {
            a: false,
            b: false,
        },
        autoload_filling_a_started: false,
        autoload_filling_b_started: false,
        pistons_in_move: false,
        pumping: false,
        injector_inverted: false,
        connected_to_patient: false,
        arm: false,
        check_air: false,
        kit_installed: false,
        allowReuse: false,
        showDrop: false,
        manual_pumping: {
            a: false,
            b: false,
        },
        remaining_fluids: {
            a: 0,
            b: 0,
        },
        flasks_translateY: {
            a: 0,
            b: 0,
        },
        fluids: {
            b: 0,
            a: 0,
        },
        selected_kit: [],
        'SDS-CTP-SPK': [
            {
                src: require('@/img/injector/needles.svg'),
                id: 2,
                set: false,
                fulfil: false,
                classes: {
                    'first-line': true,
                }
            },
            {
                src: require('@/img/injector/bleed-tube.svg'),
                id: 5,
                set: false,
                fulfil: false,
                classes: {
                    'first-line': true,
                }
            },
            {
                src: require('@/img/injector/flasks.svg'),
                id: 1,
                set: false,
                animated: false,
            },
            {
                src: require('@/img/injector/flacons.svg'),
                id: 3,
                set: false,
                fulfil: false,
            },
            {
                src: require('@/img/injector/tube.svg'),
                id: 4,
                set: false,
                fulfil: false,
            },
        ],
        'SDS-CTP-QFT': [
            {
                src: require('@/img/injector/quick-fill-tube.svg'),
                id: 2,
                set: false,
                left_fulfil: false,
                right_fulfil: false,
                classes: {
                    'first-line': true,
                }
            },
            {
                src: require('@/img/injector/bleed-tube.svg'),
                id: 6,
                set: false,
                fulfil: false,
                classes: {
                    'first-line': true,
                }
            },
            {
                src: require('@/img/injector/flasks.svg'),
                id: 1,
                set: false,
                animated: false,
            },
            {
                src: require('@/img/injector/blue-flacon.svg'),
                id: 3,
                set: false,
                fulfil: false,
                classes: {
                    flacon: true,
                },
            },
            {
                src: require('@/img/injector/green-flacon.svg'),
                id: 4,
                set: false,
                fulfil: false,
                classes: {
                    flacon: true,
                },
            },
            {
                src: require('@/img/injector/tube.svg'),
                id: 5,
                set: false,
            },
        ],
        'SDS MP1 (+SPD 250)': [
            {
                src: require('@/img/injector/tube-rolled-up.svg'),
                id: 3,
                set: false,
                classes: {
                    'first-line': true,
                }
            },
            {
                src: require('@/img/injector/card-info.svg'),
                id: 5,
                set: false,
                classes: {
                    'first-line': true,
                    'card': true,
                }
            },
            {
                src: require('@/img/injector/flasks.svg'),
                id: 1,
                set: false,
            },
            {
                src: require('@/img/injector/flacons.svg'),
                id: 2,
                set: false,
            },
            {
                src: require('@/img/injector/tube-reusable.svg'),
                id: 4,
                set: false,
            },
        ],
        heaters: {
            src: require('@/img/injector/heaters.svg'),
            id: '>3',
            set: false,
            name: 'heaters'
        },
        'tube-SPD250': {
            src: require('@/img/injector/tube-SPD250.svg'),
            id: 6,
            set: false,
            fulfil: false,
            name: 'tube-SPD250',
        },
        showModalNotification: false,
        showModalOptions: false,
        showHuman: false,
    },
    getters: {
        injector_may_be_inverted(state) {
            return state.injector_may_be_inverted;
        },
        getShortInjectorType(state) {
            return state.short_type;
        },
        getEnlargedPanelState(state) {
            return state.isEnlargedPanelOpened;
        },
        'SDS-CTP-SPK'(state) {
            return state['SDS-CTP-SPK'];
        },
        'SDS-CTP-QFT'(state) {
            return state['SDS-CTP-QFT'];
        },
        'SDS MP1 (+SPD 250)'(state) {
            return state['SDS MP1 (+SPD 250)'];
        },
        current_id(state) {
            return state.current_id;
        },
        fluids(state) {
            return state.fluids;
        },
        selected_kit(state) {
            return state.selected_kit;
        },
        autoload_is_chosen(state) {
            return state.autoload_is_chosen;
        },
        manualload_is_chosen(state) {
            return state.manualload_is_chosen
        },
        autoload_filling_a_started(state) {
            return state.autoload_filling_a_started;
        },
        autoload_filling_b_started(state) {
            return state.autoload_filling_b_started;
        },
        heaters(state) {
            return state.heaters;
        },
        'tube-SPD250'(state) {
            return state['tube-SPD250'];
        },
        flasks_translateY(state) {
            return state.flasks_translateY;
        },
        manual_load_started(state) {
            return state.manual_load_started;
        },
        pistons_in_move(state) {
            return state.pistons_in_move;
        },
        pumping(state) {
            return state.pumping;
        },
        manual_pumping(state) {
            return state.manual_pumping;
        },
        injector_inverted(state) {
            return state.injector_inverted;
        },
        connected_to_patient(state) {
            return state.connected_to_patient;
        },
        kit_installed(state) {
            return state.kit_installed;
        },
        check_air(state) {
            return state.check_air;
        },
        arm(state) {
            return state.arm;
        },
        filling_started(state) {
            return state.filling_started;
        },
        injection_started(state) {
            return state.injection_started;
        },
        getShowDrop(state) {
            return state.showDrop;
        },
        remaining_fluids(state) {
            return state.remaining_fluids;
        },
        allowReuse(state) {
            return state.allowReuse;
        },
        getInstallationStatus(state) {
            return state.installation_started;
        },
        showModalNotification(state) {
            return state.showModalNotification;
        },
        showModalOptions(state) {
            return state.showModalOptions;
        },
        showHuman(state) {
            return state.showHuman;
        }

    },
    mutations: {
        change_installation_status(state, status) {
            state.installation_started = status;
        },
        SET_TYPE(state, type) {
            state.chosen_details_type = type;
            state.short_type = type.slice(18).trim()
            if (type === 'EmptyInjector') {
                this.selected_kit = [];
            } else {
                state.selected_kit = Object.assign([], state[state.short_type]);
            }

        },
        CHANGE_DETAILS_SET_STATUS_FOR_INJECTION_PROCEDURE_TUTORIAL(state) {
            state.selected_kit.forEach(item => item.set = true);
            state.selected_kit[0].fulfil = true;
            state.selected_kit[3].fulfil = true;
        },
        RESET_DETAILS(state) {
            state.selected_kit.forEach(item => item.set = false);
            state.selected_kit.forEach(item => item.animated ? item.animated = false : item);
            state.selected_kit.forEach(item => item.fulfil = false);
            if (state.selected_kit[0]?.right_fulfil || state.selected_kit[0]?.left_fulfil) {
                state.selected_kit[0].right_fulfil = false;
                state.selected_kit[0].left_fulfil = false;
            }
            state.injector_may_be_inverted = false;
            state.heaters.set = false;
            state['tube-SPD250'].set = false;
            state['tube-SPD250'].fulfil = false;
            state.current_id = 0;
            state.autoload_is_chosen = false;
            state.filling_started.a = false;
            state.filling_started.b = false;
            state.fluids.a = 0;
            state.fluids.b = 0;
            state.manualload_is_chosen = false;
            state.autoload_filling_a_started = false;
            state.autoload_filling_b_started = false;
            state.flasks_translateY.a = 0;
            state.flasks_translateY.b = 0;
            state.manual_pumping.a = false;
            state.manual_pumping.b = false;
            state.pumping = false;
            state.injector_inverted = false;
            state.kit_installed = false;
            state.connected_to_patient = false;
            state.manual_load_started.a = false;
            state.manual_load_started.b = false;
            state.injection_started = false;
            state.arm = false;
            state.check_air = false;
            state.remaining_fluids.a = 0;
            state.remaining_fluids.b = 0;
            state.allowReuse = false;
            state.isEnlargedPanelOpened = false;
            state.showHuman = false;
        },
        setDetail(state, payload) {
            if (payload.name) {
                state[payload.name].set = true;
                if (payload.name === 'tube-SPD250') {
                    state.current_id++;
                }
            } else {
                state.selected_kit[payload.index].set = true;
                state.current_id++;
            }
        },
        removeDetail(state, index) {
            state.selected_kit[index].set = false;
            state.selected_kit[index].animated = false;
            state.current_id--;

            if (index === 2) {
                state.fluids.a = 0;
                state.fluids.b = 0;
            }
        },
        changeAnimateDetailStatus(state, index) {
            state.selected_kit[index].animated = true;
        },
        setAutoloadStatus(state, status) {
            state.autoload_is_chosen = status;
        },
        setManualLoadStatus(state, status) {
            state.manualload_is_chosen = status;
        },
        setFluids(state, fluids) {
            state.fluids.b = typeof fluids.b === 'undefined' ? state.fluids.b : fluids.b;
            state.fluids.a = typeof fluids.a === 'undefined' ? state.fluids.a : fluids.a;
        },
        increaseFluids(state, payload) {

            if (state.fluids[payload.type] + payload.value > 205) {
                state.fluids[payload.type] = 205;
            } else {
                state.fluids[payload.type] += payload.value;
            }
        },
        decreaseFluids(state, payload) {
            if (state.fluids[payload.type] - payload.value < 4) {
                state.fluids[payload.type] = 4;
            } else {
                state.fluids[payload.type] -= payload.value;
            }

        },
        startFilling(state, payload) {
            state[payload.type] = payload.status;
            state.filling_started[payload.fluids_type] = true;
        },
        startManualLoad(state, payload) {
            state.manual_load_started[payload.type] = payload.status;
            state.filling_started[payload.type] = payload.status;
        },
        changePistonStatus(state, status) {
            state.pistons_in_move = status;
        },
        setFulfil(state, payload) {
            state.selected_kit[payload.index][payload.prop] = payload.status;
            if (state.current_id === 2 && state.selected_kit[0].right_fulfil && state.short_type === 'SDS-CTP-QFT') {
                state.current_id = 4;
            } else if (state.current_id === 2) {
                state.current_id = 3;
            } else if (state.current_id === 4 && state.short_type === 'SDS-CTP-QFT') {
                state.current_id = 2;
            } else {
                state.current_id--;
            }
        },
        removeHeaters(state) {
            state.heaters.set = false;
        },
        removeTube(state) {
            if (state.injector_inverted) {
                state['tube-SPD250'].fulfil = true;
                state.kit_installed = true;
            } else {
                state['tube-SPD250'].set = false;
                state.current_id--;
            }

        },
        setFlasksTranslateY(state, payload) {
            state.flasks_translateY[payload.type] = payload.translate;
        },
        setPumpingStatus(state, status) {
            state.pumping = status;
        },
        setManualPumping(state, payload) {
            state.manual_pumping[payload.type] = payload.status;
        },
        invertInjector(state) {
            state.injector_inverted = !state.injector_inverted;
            state.showDrop = false;
        },
        setConnectionStatus(state, status) {
            state.connected_to_patient = status;
        },
        setKitStatus(state, status) {
            state.kit_installed = status;
        },
        setCheckAirStatus(state, status) {
            state.check_air = status;
        },
        setArmStatus(state, status) {
            state.arm = status;
        },
        setInjectionStatus(state, status) {
            state.injection_started = status;
        },
        flaskStatusAfterInjection(state, phases) {


            let totalA = 0;
            let totalB = 0;
            for (let phase of phases) {
                if (phase.initialData.type === 'A') {
                    totalA += +phase.initialData.volume;
                } else if (phase.initialData.type === 'dualFlow') {
                    totalA += phase.initialData.volume * phase.initialData.contrast / 100;
                }
            }

            for (let phase of phases) {
                if (phase.initialData.type === 'B') {
                    totalB += +phase.initialData.volume;
                } else if (phase.initialData.type === 'dualFlow') {
                    totalB += phase.initialData.volume * phase.initialData.saline / 100;
                }
            }

            state.fluids.a -= totalA;
            state.fluids.b -= totalB;
            state.remaining_fluids.a = state.fluids.a;
            state.remaining_fluids.b = state.fluids.b;

            state.flasks_translateY.a = 0.17 * state.fluids.a;
            state.flasks_translateY.b = 0.17 * state.fluids.b;
        },
        setShowDropStatus(state, status) {
            state.showDrop = status;
        },
        reusing(state) {
            state.manual_load_started.a = false;
            state.manual_load_started.b = false;
            state.autoload_filling_a_started = false;
            state.autoload_filling_b_started = false;
            state.pumping = false;
            state.manual_pumping.a = false;
            state.manual_pumping.b = false;
            state.filling_started.a = false;
            state.filling_started.b = false;
            state.autoload_is_chosen = false;
            state.manualload_is_chosen = false;
            state.allowReuse = true;
            state.connected_to_patient = false;

        },
        changeModalNotificationVisibility(state, payload) {
            state.showModalNotification = payload;
        },
        changeModalOptionsVisibility(state, payload) {
            state.showModalOptions = payload;
        },
        changeEnlargedPanelStatus(state, status) {
            state.isEnlargedPanelOpened = status;
        },
        changeHumanStatus(state, status) {
            state.showHuman = status;
        },
        allowInvert(state) {
            state.injector_may_be_inverted = true;
        }
    },
    actions: {
        start_installation({commit}, payload) {
            commit('change_installation_status', payload.status);
            commit('SET_TYPE', payload.type);
        },
        reset_installation({commit}, payload) {
            commit('change_installation_status', payload.status);
            commit('RESET_DETAILS');
        },
        changeDetailsForInjectionProcedureTutorial({commit}) {
            commit('SET_TYPE', "Одноразовый набор SDS-CTP-SPK")
            commit('CHANGE_DETAILS_SET_STATUS_FOR_INJECTION_PROCEDURE_TUTORIAL');
            commit('setFluids', {a: 33, b: 113})
            commit('startFilling', {
                status: true,
                type: 'autoload_filling_a_started',
                fluids_type: "a"
                ,
            });
            commit('startFilling', {
                status: true,
                type: 'autoload_filling_b_started',
                fluids_type: "b"
                ,
            });
        },
        setDetail({commit}, payload) {
            commit('setDetail', payload);
        },
        removeDetail({commit}, index) {
            commit('removeDetail', index);
        },
        changeAnimateDetailStatus({commit}, index) {
            commit('changeAnimateDetailStatus', index);
        },
        setAutoloadStatus({commit}, payload) {
            commit('setAutoloadStatus', payload.status)
            commit('setFluids', payload)
        },
        setManualLoadStatus({commit}, payload) {
            commit('setManualLoadStatus', payload.status)
            commit('setFluids', payload)
        },
        decreaseFluids({commit}, payload) {
            commit('decreaseFluids', payload);
        },
        increaseFluids({commit}, payload) {
            commit('increaseFluids', payload);
        },
        startFilling({commit}, payload) {
            commit('startFilling', payload)
        },
        startManualLoad({commit}, status) {
            commit('startManualLoad', status);
        },
        setFulfil({commit}, payload) {
            commit('setFulfil', payload);
        },
        removeHeaters({commit}) {
            commit('removeHeaters');
        },
        removeTube({commit}) {
            commit('removeTube');
        },
        setPumpingStatus({commit}, status) {
            commit('setPumpingStatus', status);
        },
        setManualPumping({commit}, payload) {
            commit('setManualPumping', payload);
            commit('setFlasksTranslateY', payload);
            commit('setShowDropStatus', payload.status);
        },
        invertInjector({commit}) {
            commit('invertInjector', status)
        },
        connectToPatient({commit}, status) {
            commit('setConnectionStatus', status);
            commit('setFulfil', {
                index: 4,
                status: true,
            })
        },
        afterInjection({commit, state}, payload) {
            commit('setInjectionStatus', payload.status);
            commit('flaskStatusAfterInjection', payload.phases);
            if (state.short_type.includes('MP1')) {
                commit('reusing');
            } else {
                commit('RESET_DETAILS');
            }

        },
        changeModalNotificationVisibility({commit}, payload) {
            commit('changeModalNotificationVisibility', payload);
        },
        changeModalOptionsVisibility({commit}, payload) {
            commit('changeModalOptionsVisibility', payload);
        },
        changeEnlargedPanelStatus({commit}, status) {
            commit('changeEnlargedPanelStatus', status)
        },
        changeHumanStatus({commit}, status) {
            commit('changeHumanStatus', status)
        },
        setFluids({commit}, fluids) {
            commit('setFluids', fluids)
        },

    },
}