<template>
    <div class="container">
        <div class="computer">
            <div v-if="!workstation_is_on" key="off" class="screen-black"></div>
            <div v-else class="screen" key="enabled">
                <div class="manager-header">
                    <div>Менеджер протоколов</div>
                        <SequenceChunkWrapper
                                sequence-id="protocol_manager"
                                step-id="protocol_saved_press_Exit"
                                key="protocol_saved_press_Exit"
                                v-slot="{processChunkAction: processChunkAction_protocol_saved_press_Exit}"
                        >
                            <button
                                    class="exit-manager-btn"
                                    @click="
                                            () => processChunkAction_protocol_saved_press_Exit(
                                                () => $router.push({name: 'WorkStation'}), {enabledIfEducationIsInactive: true})
                                    "
                            >выход</button>
                        </SequenceChunkWrapper>
                </div>
                <div class="manager-content">
                    <div class="area">
                        <div class="content-header area-header">область</div>
                        <div class="column area-content">
                            <div class="column-top">
                                <template v-for="(part, index) in body">
                                    <template v-if="mirror_body[index] === 'chest'">
                                        <SequenceChunkWrapper
                                                sequence-id="protocol_manager"
                                                step-id="choose_the_area_where_you_saved_the_protocol_Chest_or_Show_all"
                                                key="choose_the_area_where_you_saved_the_protocol_Chest"
                                                chunk-id="chest"
                                                v-slot="{processChunkAction: processChunkAction_choose_the_area_where_you_saved_the_protocol_Chest}"
                                        >
                                            <SequenceChunkWrapper
                                                    sequence-id="protocol_manager"
                                                    step-id="select_area_to_save"
                                                    key="select_area_to_save"
                                                    v-slot="{processChunkAction: processChunkAction_select_area_to_save}"
                                            >
                                                <head-button
                                                        :part="part"
                                                        :index="index"
                                                        @select-part="event => {
                                                            const fn = () => selectPart(event)
                                                            processChunkAction_choose_the_area_where_you_saved_the_protocol_Chest(fn, {enabledIfEducationIsInactive: true})
                                                            processChunkAction_select_area_to_save(fn)
                                                        }"
                                                        :selected="selected === part"
                                                        :isHidden="hidden_body[index]"
                                                />
                                            </SequenceChunkWrapper>
                                        </SequenceChunkWrapper>
                                    </template>
                                    <StaticWrapperWithPopup
                                            v-else-if="part === 'таз'"
                                            :show="isEducationModeEnabled && getActiveSequenceId === 'setup_protocol_manager' && getActiveChunksIds.includes('press_Exit_button')"
                                            text="Область «Таз» - неактивна (скрыта)"
                                    >
                                        <head-button
                                                :part="part"
                                                :index="index"
                                                @select-part="selectPart($event)"
                                                :selected="selected === part"
                                                :isHidden="hidden_body[index]"
                                                :key="index"
                                        />
                                    </StaticWrapperWithPopup>
                                    <head-button
                                            v-else
                                            :part="part"
                                            :index="index"
                                            @select-part="selectPart($event)"
                                            :selected="selected === part"
                                            :isHidden="hidden_body[index]"
                                            :key="index"
                                    >
                                    </head-button>
                                </template>
                                <img class="area-background" src="../img/man.svg">
                            </div>
                            <div class="column-footer">
                                <SequenceChunkWrapper
                                        sequence-id="protocol_manager"
                                        step-id="choose_the_area_where_you_saved_the_protocol_Chest_or_Show_all"
                                        key="choose_the_area_where_you_saved_the_protocol_Show_all"
                                        chunk-id="show_all"
                                        v-slot="{processChunkAction}"
                                >
                                    <button class="btn area-btn show-all"
                                            @pointerdown="processChunkAction(() => showAllProtocols(), {enabledIfEducationIsInactive: true})"
                                            :class="activeAll"
                                    ><span>отобразить все</span></button>
                                </SequenceChunkWrapper>
                                <button @pointerdown="!isEducationModeEnabled && RZTShow()" :class="selectedButton"
                                        class="btn area-btn p3t"><img class="p3t-button-img"
                                                                      src="../img/components/P3T.svg"></button>
                            </div>
                        </div>
                    </div>
                    <div class="protocols">
                        <div class="content-header protocols-header">протоколы</div>
                        <div class="column protocols-content">
                            <div class="column-top">
                                <template v-for="(name, index) in getTargetArea">
                                    <SequenceChunkWrapper
                                            v-if="getNameOfProtocolCreatedWithTutorial === name"
                                            v-slot="{processChunkAction}"
                                            sequence-id="protocol_manager"
                                            step-id="select_required_protocol"
                                            key="select_required_protocol"
                                    >
                                        <protocol-button
                                                :protocol_name="name"
                                                :part_name="selected"
                                                :selected="protocol_name === name"
                                                @show-saved-protocol="processChunkAction(() => showSavedProtocol($event), {enabledIfEducationIsInactive: true})"
                                        ></protocol-button>
                                    </SequenceChunkWrapper>
                                    <protocol-button
                                            v-else
                                            :protocol_name="name"
                                            :part_name="selected"
                                            :selected="protocol_name === name"
                                            @show-saved-protocol="showSavedProtocol($event)"
                                    ></protocol-button>
                                </template>
                            </div>
                            <div class="column-footer">
                                <SequenceChunkWrapper
                                        sequence-id="protocol_manager"
                                        step-id="press_current_protocol"
                                        v-slot="{processChunkAction}"
                                >
                                    <div
                                            :class="protocolButton"
                                            @pointerdown="() => processChunkAction(() => setCurrentProtocol(), {enabledIfEducationIsInactive: true})"
                                    >текущий протокол</div>
                                </SequenceChunkWrapper>
                            </div>
                        </div>
                    </div>
                    <div class="preview" v-if="protocol_name === 'Test'">
                        <div class="content-header preview-header">МОДУЛЬ: РЗТ Cardiac</div>
                        <div class="column preview-content">
                            <div v-if="changePage" class="column-top active-protocol-top">
                                <div class="p3t-item">
                                    <div class="item-name">Марка контр. в-ва</div>
                                    <span class="units"></span>
                                    <div class="value">--</div>
                                </div>
                                <div class="p3t-item">
                                    <div class="item-name">Концентрация контр. в-ва</div>
                                    <span class="units">mg/ml</span>
                                    <div class="value">300</div>
                                </div>
                                <div class="p3t-item">
                                    <div class="item-name">Конт. в-во: разм. емк</div>
                                    <span class="units">ml</span>
                                    <div class="value">--</div>
                                </div>
                                <div class="p3t-item">
                                    <div class="item-name">Тестовое введение</div>
                                    <span class="units"></span>
                                    <div class="value">да</div>
                                </div>
                                <div class="p3t-item">
                                    <div class="item-name">Болюсное введение</div>
                                    <span class="units"></span>
                                    <div class="value">да</div>
                                </div>
                                <div class="p3t-item">
                                    <div class="item-name">Объём тест. введения</div>
                                    <span class="units">ml</span>
                                    <div class="value">10</div>
                                </div>
                                <div class="p3t-item">
                                    <div class="item-name">V ввод. болюс. к.в-ва</div>
                                    <span class="units">ml</span>
                                    <div class="value">20</div>
                                </div>
                                <div class="p3t-item">
                                    <div class="item-name">V в. болюс. физ. р-ра</div>
                                    <span class="units">ml</span>
                                    <div class="value">40</div>
                                </div>
                                <div class="p3t-item">
                                    <div class="item-name">Макс. расход</div>
                                    <span class="units">ml/s</span>
                                    <div class="value">6,0</div>
                                </div>
                                <div class="p3t-item no-border">
                                    <div class="item-name">Макс. загрузка йода</div>
                                    <span class="units">gl</span>
                                    <div class="value">38</div>
                                </div>
                            </div>
                            <div v-else class="column-top active-protocol-top">
                                <div class="p3t-item">
                                    <div class="item-name">Мин. загрузка йода</div>
                                    <span class="units">gl</span>
                                    <div class="value">20</div>
                                </div>
                                <div class="p3t-item">
                                    <div class="item-name">Макс. диагн. V к. в-ва</div>
                                    <span class="units">ml</span>
                                    <div class="value">194</div>
                                </div>
                                <div class="p3t-item">
                                    <div class="item-name">Макс. диагн. V ф. р-ра</div>
                                    <span class="units">ml</span>
                                    <div class="value">194</div>
                                </div>
                                <div class="p3t-item">
                                    <div class="item-name">Продолжит. сканир-я</div>
                                    <span class="units">mm ss</span>
                                    <div class="value">00:10</div>
                                </div>
                            </div>
                            <div class="column-footer active-protocol-footer rzt-footer">
                                <chevron-button @change-page="changePageStatus(!changePage)"
                                                :page="page"
                                />
                                <button class="btn preview-btn save-btn rzt-button" @click="$store.dispatch('station/manager/changeModalNotificationStatus', true)">
                                    ок
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="preview" v-else>
                        <div class="content-header preview-header">предпросмотр</div>
                        <div class="column preview-content">
                            <StaticWrapperWithPopup
                                    :show="isEducationModeEnabled && getActiveSequenceId === 'protocol_manager' && getActiveChunksIds.includes('select_area_to_save')"
                                    text="Справа отобразится текущий (созданный вами) протокол"
                            >
                                <div :class="backgroundTop" class="column-top">
                                    <div class="column-header">{{protocol_name}}</div>
                                    <div class="scripts">
                                        <manager-row class="manager-script" v-for="(row, index) in current_protocol"
                                                     :row="row"
                                                     :index="index"/>
                                    </div>
                                    <template v-if="current_protocol">
                                        <div class="total-section">
                                            <div class="pressure flex-overall">
                                                <p>Предел давл. (psi)</p>
                                                <div class="pressure__value overall">{{current_pressure}}</div>
                                            </div>
                                            <div class="total-values flex-overall">
                                                <p>Всего</p>
                                                <div class="contrast overall">{{totalContrast}}</div>
                                                <div class="saline overall">{{totalSaline}}</div>
                                                <div class="duration overall">{{totalDuration}}</div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </StaticWrapperWithPopup>
                            <div :class="backgroundFooter" class="column-footer">
                                <SequenceChunkWrapper
                                        v-if="protocol_name === 'Protocol*'"
                                        sequence-id="protocol_manager"
                                        step-id="press_button_Save_into_Chest"
                                        key="press_button_Save_into_Chest"
                                        v-slot="{processChunkAction}"
                                >
                                    <button
                                            class="btn preview-btn save-btn"
                                            :class="nonActive"
                                            @pointerdown="() => processChunkAction(() => showKeyboard(), {enabledIfEducationIsInactive: true})">
                                        сохранить в {{selected}}
                                    </button>
                                </SequenceChunkWrapper>
                                <SequenceChunkWrapper
                                        v-else-if="protocol_name !== 'Protocol*' && protocol_name"
                                        v-slot="{processChunkAction}"
                                        sequence-id="protocol_manager"
                                        step-id="protocol_will_be_displayed_press_Ok"
                                        key="protocol_will_be_displayed_press_Ok"
                                >
                                    <button class="btn preview-btn save-btn"
                                            @pointerdown="processChunkAction(() => {
                                                setGlobalProtocol()
                                                $router.push({name: 'WorkStation'})
                                            }, {enabledIfEducationIsInactive: true})"
                                    >
                                        ок
                                    </button>
                                </SequenceChunkWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-keyboard v-show="getShowKeyboard"/>
            <div class="stand">
                <comp-lamp class="light" :class="isLightOn"/>
                <input type="checkbox" class="stand__power-button" @input="switchOnComp(true)">
            </div>
        </div>
        <router-modal/>
        <ModalNotification v-if="showModalNotification" @confirm="confirmHandler">
          <template #information_1>
            Программирование протокола РЗТ в данной эмуляции не предусмотрено
          </template>
          <template #button_confirm>
            Понятно
          </template>
        </ModalNotification>
      <InfoButton style="width: 13.5%; min-width: 259px" @open-confirm="openModal"/>
      <TermDefinition v-if="modalEx" style="z-index: 99999" @close-confirm="closeModal"/>
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import ManagerRow from '@/components/ManagerRow'
    import HeadButton from '@/components/HeadButton'
    import ProtocolButton from '@/components/ProtocolButton'
    import AppKeyboard from '@/components/ScreenKeyboard'
    import ChevronButton from '@/components/ChevronBtn'
    import CompLamp from '@/components/AppLamp'
    import RouterModal from '@/components/modals/RouterModal'
    import ModalNotification from "@/components/modals/ModalNotification";
    import InfoButton from "@/components/InfoButton.vue";
    import TermDefinition from "@/components/TermDefinition.vue";

    export default {
        components: {
          TermDefinition,
            InfoButton,
            HeadButton,
            ProtocolButton,
            ManagerRow,
            AppKeyboard,
            ChevronButton,
            CompLamp,
            RouterModal,
            ModalNotification
        },
      data() {
        return {
          modalEx:false,
        }
      },
        methods: {
            confirmHandler() {
              this.$store.dispatch('station/manager/changeModalNotificationStatus', false);
              this.$router.push({name: 'WorkStation'});
            },
            selectPart(body) {
                this.$store.dispatch('station/manager/changeSelected', body.part);
                this.$store.dispatch('station/manager/changeMirrorSelected', this.mirror_body[body.index]);
                this.$store.dispatch('setTargetArea', this.mirror_body[body.index]);
                this.$store.dispatch('station/manager/changeShowAll', false);
                this.$store.dispatch('station/manager/changeShowRZT', false);
            },
            setCurrentProtocol() {
                this.$store.dispatch('station/manager/changeCurrentProtocol', this.getRows);
                this.$store.dispatch('station/manager/changeProtocolName', 'Protocol*');
                this.$store.dispatch('station/manager/changeCurrentPressure', parseInt(this.getPressureLimit));
            },
            showKeyboard() {
                this.$store.dispatch('showKeyboard', true);
            },
            showSavedProtocol(item) {

                if (item.name === 'Test') {
                    this.$store.dispatch('station/manager/changeProtocolName', 'Test');

                } else {
                    this.$store.dispatch('station/manager/changeProtocolName', item.name);
                    this.$store.dispatch('station/manager/changeCurrentProtocol', Object.assign([], this.$store.getters[this.mirror_body[this.body.indexOf(item.part_name)]][item.name]));
                    this.$store.dispatch('station/manager/changeCurrentPressure', parseInt(this.getManagerPressure[item.name + this.mirror_body[this.body.indexOf(item.part_name)]]));
                }


            },
            setGlobalProtocol() {
                this.$store.dispatch('setGlobalProtocol', {
                    phases: this.current_protocol,
                    pressure: this.current_pressure,
                });
            },
            showAllProtocols() {
                this.$store.dispatch('station/manager/changeShowAll', true);
                this.$store.dispatch('station/manager/changeSelected', '...');
            },
            RZTShow() {
                this.$store.dispatch('station/manager/changeShowAll', false);
                this.$store.dispatch('station/manager/changeShowRZT', true);
                this.$store.dispatch('station/manager/changeSelected', '...');
                this.$store.dispatch('station/manager/changeMirrorSelected', 'RZT');
            },
            switchOnComp() {
                if (!this.workstation_is_on) {
                    this.$router.push({name: 'WorkStation'});
                    this.$store.commit('switchStateOfWorkstation', {
                        state: true,
                        confirm: true,
                    });
                }
            },
            changePageStatus(status) {
                this.$store.dispatch('station/manager/changePageStatus', status);
            },
          openModal() {
            this.modalEx = true;
          },
          closeModal() {
            this.modalEx = false;
          },
        },
        computed: {
            ...mapGetters([
                'getRows',
                'getShowKeyboard',
                'getPressureLimit',
                'getManagerPressure',
                'getAllSavedProtocols',
                'body',
                'mirror_body',
                'hidden_body',
                'workstation_is_on',
                'getNameOfProtocolCreatedWithTutorial',
                'getActiveSequenceId',
                'getCurrentSequenceItemsIds'
            ]),
            ...mapGetters('station/manager', [
                    'selected',
                    'mirror_selected',
                    'protocol_name',
                    'current_protocol',
                    'current_pressure',
                    'showAll',
                    'showRZT',
                    'changePage',
                    'showModalNotification',
                ]
            ),
            ...mapGetters('sequences_store', ['getActiveSequenceId', 'getActiveChunksIds']),
            isLightOn() {
                return {
                    on: this.workstation_is_on,
                }
            },
            page() {
                if (this.changePage) {
                    return 1;
                } else {
                    return 2;
                }
            },
            getTargetArea() {
                if (this.showAll) {
                    return this.getAllSavedProtocols;
                } else {
                    return this.$store.getters[this.mirror_selected + 'Buttons'];
                }

            },
            protocolButton() {
                if (this.protocol_name === 'Protocol*') {
                    return {
                        'content-header': true,
                    }
                } else {
                    return {
                        'inactive-protocol': true,
                    }
                }
            },
            totalContrast() {
                let sum = 0;
                for (let volume of this.current_protocol) {
                    if (volume.type == 'A') {
                        sum += +volume.volume;
                    } else if (volume.type == 'dualFlow') {
                        sum += volume.volume * volume.contrast / 100;
                    }
                }

                return sum;
            },
            totalDuration() {
                let sum = '00:00';
                let total = 0;
                for (let phase of this.current_protocol) {
                    let min;
                    let sec;
                    let time;
                    if (phase.duration) {
                        min = phase.duration.slice(0, 2);
                        sec = phase.duration.slice(3);

                        if (min || sec) {
                            time = +min * 60 + +sec;
                        }

                        total += time;
                    }
                }

                if (total) {

                    let mins = Math.trunc(total / 60);
                    let secs = total % 60;
                    if (mins.toString().length === 1) {
                        mins = '0' + mins;
                    }

                    if (secs.toString().length === 1) {
                        secs = '0' + secs;
                    }

                    sum = `${mins}:${secs}`
                    return sum;
                } else {
                    return sum;
                }
            },
            totalSaline() {
                let sum = 0;
                for (let volume of this.current_protocol) {
                    if (volume.type == 'B') {
                        sum += +volume.volume;
                    } else if (volume.type == 'dualFlow') {
                        sum += volume.volume * volume.contrast / 100;
                    }
                }

                return sum;
            },
            backgroundTop() {
                return {
                    'active-protocol-top': !!this.protocol_name,
                }
            },
            backgroundFooter() {
                return {
                    'active-protocol-footer': !!this.protocol_name,
                }
            },
            nonActive() {
                if (this.selected !== '...') {
                    return {
                        'non-active': false,
                    }
                } else {
                    return {
                        'non-active': true,
                    }
                }

            },
            activeAll() {
                return {
                    'all-active': this.showAll,
                }
            },
            selectedButton() {
                return {
                    selected: this.showRZT && this.selected === '...' && !this.showAll,
                };
            },
            selectedRTZ() {
                return {
                    selected: this.protocol_name === 'RZT',
                }
            },

        },
        mounted() {
            if (this.isEducationModeEnabled && this.$store.getters.getActiveSequenceId === 'setup_protocol_manager' && this.$store.getters.getActiveChunksIds.includes('press_Exit_button'))
                this.selectPart({
                    part: this.body[2],
                    index: 2,
                    isHidden: false
                })
        },
        beforeDestroy() {
            this.$store.dispatch('station/manager/setDefaultModule');
        },
    }
</script>

<style scoped lang="scss">

    @import "../scss/pages/manager";

</style>