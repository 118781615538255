<template>
    <div class="script-item" :class="stylePause">
        <template v-if="row.type == 'A'">
            <SequenceChunkWrapper
                    v-slot="{processChunkAction: validate_to_delete_phase_choose_necessary_arrow}"
                    sequence-id="protocol_creation"
                    step-id="to_delete_phase_choose_necessary_arrow"
                    key="to_delete_phase_choose_necessary_arrow"
            >
                <SequenceChunkWrapper
                        v-slot="{processChunkAction: validate_create_or_choose_protocol}"
                        sequence-id="protocol_creation"
                        step-id="create_or_choose_protocol"
                        key="create_or_choose_protocol"
                >
                    <script-button :letter="row.type" :class="highlightRowFirstButton" @change-color=" e => {
                            const fn = () => {
                                startCreatingRow(e);
                                $store.dispatch('showRow', false);
                            };
                            validate_to_delete_phase_choose_necessary_arrow(fn, {enabledIfEducationIsInactive: true})
                            validate_create_or_choose_protocol(fn)
                        }"
                    />
                </SequenceChunkWrapper>
            </SequenceChunkWrapper>
            <SequenceChunkWrapper
                    v-slot="{processChunkAction}"
                    sequence-id="protocol_creation"
                    step-id="choose_Flow_phase_parameter_Contrast_A"
                    key="choose_Flow_phase_parameter_Contrast_A"
            >
                <flow-button :index="index" :class="highlightRow"
                             @focused="({event, f}) => {
                                 const fn = () => {
                                     f()
                                     enableButton(event)
                                 }
                                 processChunkAction(fn, {enabledIfEducationIsInactive: true})
                             }"
                             :flowRate="row.flowRate"/>
            </SequenceChunkWrapper>

            <SequenceChunkWrapper
                    v-slot="{processChunkAction}"
                    sequence-id="protocol_creation"
                    step-id="select_Volume_phase_parameter_Contrast_A"
                    key="select_Volume_phase_parameter_Contrast_A"
            >
                <volume-button :index="index" :class="highlightRow"
                               @focused="({event, f}) => {
                                 const fn = () => {
                                     f()
                                     enableButton(event)
                                 }
                                 processChunkAction(fn, {enabledIfEducationIsInactive: true})
                             }"
                               :volume="row.volume"/>
            </SequenceChunkWrapper>
            <duration-button :class="highlightRow" :duration="row.duration" :key="row.type"/>
        </template>

        <template v-else-if="row.type=='B'">
            <script-button :class="highlightRowFirstButton" class="b b-script" :letter="row.type"
                           @change-color="startCreatingRow($event)"/>
            <SequenceChunkWrapper
                    v-if="index === 5"
                    v-slot="{processChunkAction}"
                    sequence-id="protocol_creation"
                    step-id="choose_Flow_phase_parameter_Contrast_B"
                    key="choose_Flow_phase_parameter_Contrast_B"
            >
                <flow-button :index="index" :class="highlightRow" class="b"
                             @focused="({event, f}) => {
                                 const fn = () => {
                                     f()
                                     enableButton(event)
                                 }
                                 processChunkAction(fn, {enabledIfEducationIsInactive: true})
                             }"
                             :flowRate="row.flowRate"/>
            </SequenceChunkWrapper>


            <flow-button v-else :index="index" :class="highlightRow" class="b"
                         @focused="({event, f}) => {
                             f()
                             enableButton(event)
                         }"
                         :flowRate="row.flowRate"/>

            <SequenceChunkWrapper
                    v-if="index === 5"
                    v-slot="{processChunkAction}"
                    sequence-id="protocol_creation"
                    step-id="select_Volume_phase_parameter_Contrast_B"
                    key="select_Volume_phase_parameter_Contrast_B"
            >
                <volume-button :index="index" :class="highlightRow" class="b"
                               @focused="({event, f}) => {
                                 const fn = () => {
                                     f()
                                     enableButton(event)
                                 }
                                 processChunkAction(fn, {enabledIfEducationIsInactive: true})
                             }"
                               :volume="row.volume"/>
            </SequenceChunkWrapper>
            <volume-button v-else :index="index" :class="highlightRow" class="b"
                           @focused="({event, f}) => {
                             f()
                             enableButton(event)
                         }"
                           :volume="row.volume"/>

            <duration-button :class="highlightRow" :duration="row.duration" :key="row.type"/>
        </template>

        <template v-else-if="row.type =='Test'">
            <div class="script-text test">Пробное введение</div>
        </template>

        <template v-else-if="row.type == 'Question'">
            <SequenceChunkWrapper
                    v-slot="{processChunkAction: validate_press_arrow_question_test_B}"
                    sequence-id="protocol_creation"
                    step-id="press_arrow_question_test_B"
                    key="press_arrow_question_test_B"
            >
                <SequenceChunkWrapper
                        v-slot="{processChunkAction: validate_press_arrow_question_Pause}"
                        sequence-id="protocol_creation"
                        step-id="press_arrow_question_Pause"
                        key="press_arrow_question_Pause"
                >
                    <SequenceChunkWrapper
                            v-slot="{processChunkAction: validate_press_arrow_question_AB}"
                            sequence-id="protocol_creation"
                            step-id="press_arrow_question_AB"
                            key="press_arrow_question_AB"
                    >
                        <script-test letter="?" :class="highlightRowFirstButton" @change-color="function($event) {
                          const fn = () => startCreatingRow($event)
                            validate_press_arrow_question_test_B(fn, {enabledIfEducationIsInactive: true})
                            validate_press_arrow_question_Pause(fn)
                            validate_press_arrow_question_AB(fn)
                        }

                        " :index="index" @original-color="originalColor($event)"/>
                    </SequenceChunkWrapper>
                </SequenceChunkWrapper>
            </SequenceChunkWrapper>
            <template v-if="showRow">
                <flow-button :index="index" :class="highlightRow" :disabled="true" :flowRate="row.flowRate"/>
                <volume-button :index="index" :class="highlightRow" :disabled="true" :volume="row.volume"/>
                <duration-button :class="highlightRow" :duration="row.duration" :key="row.type"/>
            </template>
        </template>

        <template v-else-if="row.type == 'dualFlow'">
            <script-button :class="highlightRowFirstButton" class="dual dual-script" letter="%"
                           @change-color="startCreatingRow($event)"/>
            <SequenceChunkWrapper
                    v-slot="{processChunkAction}"
                    sequence-id="protocol_creation"
                    step-id="choose_Flow_phase_parameter_AB"
                    key="choose_Flow_phase_parameter_AB"
            >
                <flow-button :index="index" :class="highlightRow" class="dualflow"
                             @focused="({event, f}) => {
                                 const fn = () => {
                                     f()
                                     enableButton(event)
                                 }
                                 processChunkAction(fn, {enabledIfEducationIsInactive: true})
                             }"
                             :flowRate="row.flowRate"/>
            </SequenceChunkWrapper>
            <SequenceChunkWrapper
                    v-slot="{processChunkAction}"
                    sequence-id="protocol_creation"
                    step-id="select_Volume_phase_parameter_AB"
                    key="select_Volume_phase_parameter_AB"
            >
                <volume-button :index="index" :class="highlightRow" class="dualflow"
                               @focused="({event, f}) => {
                                 const fn = () => {
                                     f()
                                     enableButton(event)
                                 }
                                 processChunkAction(fn, {enabledIfEducationIsInactive: true})
                             }"
                               :volume="row.volume"
                               :contrast="row.contrast" :saline="row.saline"/>
            </SequenceChunkWrapper>
            <duration-button :class="highlightRow" :duration="row.duration" :key="row.type"/>
        </template>
        <template v-else-if="row.type == 'Pause'">
            <script-test :class="highlightRowFirstButton" @change-color="startCreatingRow($event)" :index="index"
                         @original-color="originalColor($event)"/>
            <div class="script-text">Пауза</div>
            <SequenceChunkWrapper
                    v-slot="{processChunkAction}"
                    sequence-id="protocol_creation"
                    step-id="select_parameter_value_Duration_Pause"
                    key="select_parameter_value_Duration_Pause"
            >
                <pause-button :class="highlightRow" :duration="row.duration" :index="index" :key="row.type"
                              @focused="function(e) {
                                  processChunkAction(() => enableButton(e), {enabledIfEducationIsInactive: true})
                              }"/>
            </SequenceChunkWrapper>
        </template>
        <template v-else-if="row.type == 'Awaiting'">
            <script-test :class="highlightRowFirstButton" @change-color="startCreatingRow($event)" :index="index"
                         @original-color="originalColor($event)"/>
            <div class="script-text">Ожидание</div>
            <awaiting-button :class="highlightRow" :duration="row.duration" :key="row.type"/>
        </template>
    </div>
</template>
<script>

    import FlowButton from '@/components/rowbuttons/FlowRate'
    import ScriptButton from '@/components/rowbuttons/Script'
    import ScriptTest from '@/components/rowbuttons/ScriptTest'
    import VolumeButton from '@/components/rowbuttons/Volume'
    import DurationButton from '@/components/rowbuttons/Duration'
    import PauseButton from '@/components/rowbuttons/PauseButton'
    import AwaitingButton from '@/components/rowbuttons/AwaitingButton'

    import {mapState, mapGetters} from 'vuex'

    export default {

        props: ['row', 'index'],
        components: {
            FlowButton,
            ScriptButton,
            ScriptTest,
            VolumeButton,
            DurationButton,
            AwaitingButton,
            PauseButton,
        },
        methods: {
            startCreatingRow(target) {
                this.$store.dispatch('control', {
                    calc: false,
                    control: true,
                });
                this.$store.commit('setEditableRow', {
                    index: this.index,
                    target: target,
                });
                this.$store.dispatch('setHighlightedRow', this.index);
                this.$store.dispatch('setSelectedRowButton', null);
            },

            enableButton(target) {
                if (target.classList.contains('enabled')) {
                    return;
                }

                this.$store.commit('setEditableRow', {
                    index: this.index,
                    target: target,
                });

                this.$store.dispatch('showRow', false);
            },
        },

        computed: {
            ...mapState([
                'showRow',
            ]),
            ...mapGetters([
                'getHighlightedRow'
            ]),

            stylePause() {
                if (this.row.type == 'Pause' || this.row.type == 'Awaiting') {
                    return {
                        'script-pause': true,
                    };
                } else {
                    return {
                        'script-pause': false,
                    };
                }
            },
            highlightRow() {
                return {
                    yellow: this.getHighlightedRow === this.index,
                }
            },
            highlightRowFirstButton() {
                return {
                    yellowa: this.getHighlightedRow === this.index,
                }
            },
        },
    }
</script>

<style scoped lang="scss">

    .dualflow {
        background-image: linear-gradient(180deg, #9DC76C 50%, #61C6F1 50%);
    }

    .b {
        background-color: #61C6F1;
        border-left: 2px solid #53A9CE;
        border-top: 2px solid #53A9CE;
        border-bottom: 2px solid #67D1FF;
        border-right: 2px solid #67D1FF;

        &-script {
            border-right: none;
            border-bottom: #53A9CE;

            &:after {
                border-left: 14px solid #61C6F1;
            }
        }
    }

    .yellow {
        background: #FFFF99 !important;
        opacity: 1;
        color: #000;
    }

    .yellowa {
        background: #FFFF99 !important;
        opacity: 1;
        color: #000;
        border-right: none;

        &:after {
            content: '';
            position: absolute;
            border: 18px solid transparent;
            border-left: 14px solid #FFFF99;
            top: -2px;
            left: 32px;
        }
    }

    .enabled {
        background: black !important;
        color: white;
        border: 2px solid black;
    }

    .script-text {
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        background: none !important;
    }

    .test {
        padding-left: 60px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 25px;
            height: 2px;
            width: 30px;
            background: #fff;
        }

        &:after {
            content: '';
            position: absolute;
            top: -3px;
            left: 12px;
            height: 2px;
            width: 25px;
            background: #fff;
            transform: rotate(90deg);
        }
    }

</style>