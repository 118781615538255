import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        modalShow: false,
        moving_process: false,
        default: false,
        selected: '...',
        mirror_selected: '',
        protocol_name: '',
        current_protocol: '',
        current_pressure: '',
        current_protocol_index: '',
        modalText: '',
        from: '',
        isHidden: '',
    },
    getters: {
        modalShow(state) {
            return state.modalShow
        },
        moving_process(state) {
            return state.moving_process
        },
        default(state) {
            return state.default
        },
        selected(state) {
            return state.selected
        },
        mirror_selected(state) {
            return state.mirror_selected
        },
        protocol_name(state) {
            return state.protocol_name
        },
        current_protocol(state) {
            return state.current_protocol
        },
        current_pressure(state) {
            return state.current_pressure
        },
        current_protocol_index(state) {
            return state.current_protocol_index
        },
        modalText(state) {
            return state.modalText
        },
        from(state) {
            return state.from
        },
        isHidden(state) {
            return state.isHidden
        },


    },
    mutations: {
        changemodalShowVisibility(state, payload) {
            state.modalShow = payload
        },
        changeDefault(state, payload) {
            state.default = payload;
        },
        changeMoving_process(state, payload) {
            state.moving_process = payload;
        },
        changeSelected(state, payload) {
            state.selected = payload;
        },
        changeMirror_selected(state, payload) {
            state.mirror_selected = payload;
        },
        changeProtocol_name(state, payload) {
            state.protocol_name = payload;
        },
        changeCurrent_protocol(state, payload) {
            state.current_protocol = payload;
        },
        changeCurrent_pressure(state, payload) {
            state.current_pressure = payload;
        },
        changeCurrent_protocol_index(state, payload) {
            state.current_protocol_index = payload;
        },
        changeModalText(state, payload) {
            state.modalText = payload;
        },
        changeFrom(state, payload) {
            state.from = payload;
        },
        changeIsHidden(state, payload) {
            state.isHidden = payload;
        },
        setDefaultModule(state) {
            state.modalShow = false;
            state.moving_process = false;
            state.default = false;
            state.selected = '...';
            state.mirror_selected = '';
            state.protocol_name = '';
            state.current_protocol = '';
            state.current_pressure = '';
            state.current_protocol_index = '';
            state.modalText = '';
            state.from = '';
            state.isHidden = '';
        },
    },
    actions: {
        changemodalShowVisibility({commit}, payload) {
            commit('changemodalShowVisibility', payload)
        },
        changeDefault({commit}, payload) {
            commit('changeDefault', payload)
        },
        changeMoving_process({commit}, payload) {
            commit('changeMoving_process', payload)
        },
        changeSelected({commit}, payload) {
            commit('changeSelected', payload)
        },
        changeMirror_selected({commit}, payload) {
            commit('changeMirror_selected', payload)
        },
        changeProtocol_name({commit}, payload) {
            commit('changeProtocol_name', payload)
        },
        changeCurrent_protocol({commit}, payload) {
            commit('changeCurrent_protocol', payload)
        },
        changeCurrent_pressure({commit}, payload) {
            commit('changeCurrent_pressure', payload)
        },
        changeCurrent_protocol_index({commit}, payload) {
            commit('changeCurrent_protocol_index', payload)
        },
        changeModalText({commit}, payload) {
            commit('changeModalText', payload)
        },
        changeFrom({commit}, payload) {
            commit('changeFrom', payload)
        },
        changeIsHidden({commit}, payload) {
            commit('changeIsHidden', payload)
        },
        setDefaultModule({commit}) {
            commit('setDefaultModule');
        },

    }
}