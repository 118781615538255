var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar"},[_c('div',[_c('div',{class:_vm.getActiveStepId === 'main' ? 'blurred' : ''},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)]),(_vm.getActiveSequenceId === 'workstation_general_info')?_c('div',{staticClass:"chunk-with-text-attached-to-arrow chunk-with-text-attached-to-arrow--position-left chunk-with-text-attached-to-arrow--align-bottom",staticStyle:{"top":"50%","right":"110%","width":"350px","font-weight":"normal"}},[_c('span',[_vm._v("Информационная панель, где могут быть отображены данные о пациенте. Ее мы пока не рассматриваем. В будущем она позволит автоматически через соединение с RIS подгружать данные о пациенте и процедуре и сохранять их вместе с данными исследования на сервере больницы.")])]):_vm._e()]),_vm._m(4),_c('div',{staticClass:"sidebar__element element flex"},[_c('SequenceChunkWrapper',{key:"liquid_delivery_press_Menu_button",attrs:{"sequence-id":"liquid_delivery","step-id":"press_Menu_button"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_liquid_delivery_press_Menu_button = ref.processChunkAction;
return [_c('SequenceChunkWrapper',{key:"setup_system_press_Menu_button",attrs:{"sequence-id":"setup_system","step-id":"press_Menu_button"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_setup_system_press_Menu_button = ref.processChunkAction;
return [_c('SequenceChunkWrapper',{key:"setup_protocol_manager_press_Menu_button",attrs:{"sequence-id":"setup_protocol_manager","step-id":"press_Menu_button"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_setup_protocol_manager_press_Menu_button = ref.processChunkAction;
return [_c('div',{staticClass:"element__icons shadow",on:{"pointerdown":function () {
                         var fn = function () {
                             _vm.showMenu();
                         }
                         processChunkAction_liquid_delivery_press_Menu_button(fn, {enabledIfEducationIsInactive: true})
                         processChunkAction_setup_protocol_manager_press_Menu_button(fn)
                         processChunkAction_setup_system_press_Menu_button(fn)
                     }}},[_c('img',{attrs:{"src":require("../img/icons.svg"),"alt":""}})])]}}],null,true)})]}}],null,true)})]}}])}),_c('div',{staticClass:"element__status shadow",on:{"click":_vm.openLiquidModal}},[_vm._v(" Жидк. А. ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar__element element"},[_c('div',{staticClass:"element__header shadow"},[_c('div',{staticClass:"human"},[_c('img',{attrs:{"src":require("../img/body.svg"),"alt":""}})])]),_c('div',{staticClass:"element__parameters"},[_c('p',[_vm._v("Patient ID: ")]),_c('p',[_vm._v("DOB:")]),_c('p',[_vm._v("Weight:")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar__element element"},[_c('div',{staticClass:"element__header shadow"},[_vm._v("Процедура")]),_c('div',{staticClass:"element__parameters"},[_c('p',[_vm._v("Accession:")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar__element element"},[_c('div',{staticClass:"element__header shadow"},[_vm._v("Жидкости")]),_c('div',{staticClass:"element__parameters"},[_c('p',{staticClass:"fluid-a"},[_vm._v("Fluid A:")]),_c('p',{staticClass:"fluid-b"},[_vm._v("Fluid B: ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar__element element"},[_c('div',{staticClass:"element__header shadow"},[_vm._v("События")]),_c('div',{staticClass:"element__parameters empty"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar__element element"},[_c('div',{staticClass:"element__header shadow small"},[_vm._v("Рабочий список пациентов")])])}]

export { render, staticRenderFns }