import { SequenceDefinition, SequenceInstance } from '@/sequences/Sequence/base_classes'
import { SequenceStepWithSingleChunkDefinition } from '@/sequences/SequenceStep/step_with_single_chunk'
import {
    SequenceStepChunkWithTextAndArrowVisibleWhileActive
} from '@/sequences/SequenceStepChunk/chunk_with_text_and_arrow_visible_while_active'
import { SequenceStepChunkWithArrow } from '@/sequences/SequenceStepChunk/chunk_with_arrow'
import { SequenceStepChunkWithText } from "../../sequences/SequenceStepChunk/chunk_with_text"
import { SequenceStepOneOfMultipleChunksDefinition } from '@/sequences/SequenceStep/step_one_of_multiple_chunks'
import Vue from 'vue'
import { store } from '@/store'
import router from "../../router/router";
import {
    SequenceStepWithSequenceAlertModalDefinition
} from "../../sequences/SequenceStep/step_with_sequence_alert_modal";
import { SequenceStepWithInternalStateDefinition } from '@/sequences/SequenceStep/step_with_internal_state'
import { ref, watch } from '@vue/composition-api'
import { ABCSequenceElementDefinition, assignInstanceConstructor } from '@/sequences/abc_sequence_element'
import { SequenceStepAllOfMultipleChunksDefinition } from '@/sequences/SequenceStep/step_all_of_multiple_chunks'
import img from '@/img/injector/connected-to-patient(drop to drop).svg'
import { SequenceStepChunkTextAttachedToArrow } from '@/sequences/SequenceStepChunk/chunk_text_attached_to_arrow'
import { gsap } from 'gsap'
import { makeChunksVisibleOnlyWhileActive } from '@/sequences/SequenceStepChunk/chunk_mixin_visible_while_active'
import { cloneDeep } from 'lodash'
import { SequenceStepChunkDefinition } from '@/sequences/SequenceStepChunk/base_classes'

const sequencesEventBus = new Vue()

export {
    sequencesEventBus
}


const workstation_general_info = new SequenceDefinition({
    id: 'workstation_general_info',
    prettyName: 'Общая информация',
    beforeStart: () => {
        if (router.currentRoute.name !== 'WorkStation')
            router.push({name: 'WorkStation'})
        return store.dispatch('cleanupStoreBeforeDefaultTutorial')
    },
    persistentHeader: 'Общая информация:<br>' +
        'В данном разделе показывается назначение основных элементов интерфейса главного экрана рабочей станции.',
    steps: [
        new SequenceStepWithSingleChunkDefinition({
            id: 'main',
            chunk: new SequenceStepChunkWithText({
                text: {
                    text: 'Общая информация:<br>' +
                        'В данном разделе показывается назначение основных элементов интерфейса главного экрана рабочей станции.'
                }
            })
        })
    ],
});

const protocol_manager = new SequenceDefinition({
    id: 'protocol_manager',
    prettyName: 'Менеджер протоколов',
    beforeStart: () => {
        if (router.currentRoute.name !== 'WorkStation')
            router.push({name: 'WorkStation'})
        return store.dispatch('cleanupStoreBeforeProtocolManagerTutorial')
    },
    persistentHeader: 'Менеджер протокола: <br>' +
        'Сохранение и управление протоколами',
    steps: [
        new SequenceStepWithSequenceAlertModalDefinition({
            text: 'В данном разделе представлены основные этапы работы с менеджером протоколов.',
            buttons: [
                {
                    text: 'Понятно',
                    action: ({nextStep}) => nextStep(),
                }
            ]
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'to_save_or_upload_select_protocol_manager',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 1: <br>Чтобы сохранять или загружать протоколы выберите «Менеджер протоколов»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'press_current_protocol',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 2: <br>Нажмите «Текущий протокол». Справа отобразится текущий (созданный вами) протокол.'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_area_to_save',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 3: <br>Выберите область, в которую хотите сохранить протокол, например «Грудная клетка»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'press_button_Save_into_Chest',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 4: <br>Нажмите на кнопку «Сохранить в грудная клетка»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'type_name_of_protocol',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 5: <br>Введите название протокола, например 123 и нажмите «Ввод». Такое название используется в условиях<br>' +
                        'данного учебного примера. B<br>' +
                        'условиях реальной практики вы можете вводить название по своему <br>' +
                        'усмотрению'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'protocol_saved_press_Exit',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 6: <br>Протокол сохранён, нажмите «Выход»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'press_reset_protocol_button',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 7: <br>Нажмите на кнопку сбросить протокол «R» (сбрасывает протокол до исходных значений по-умолчанию - действие не обязательное)'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'confirm_your_choice',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 8: <br>Подтвердите выбор'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'to_upload_existing_protocol_choose_protocol_manager',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 9: <br>Чтобы загрузить ранее сохранённый протокол, выберите «Менеджер протоколов»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepOneOfMultipleChunksDefinition({
            id: 'choose_the_area_where_you_saved_the_protocol_Chest_or_Show_all',
            chunks: [
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'chest',
                    text: {
                        text: 'ШАГ 10: <br>Выберите область в которую сохранили протокол или нажмите «Отобразить всё»'
                    },
                    arrow: {
                        position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                    }
                }),
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'show_all',
                    text: {
                        text: 'ШАГ 10: <br>Выберите область в которую сохранили протокол или нажмите «Отобразить всё»'
                    },
                    arrow: {
                        position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                    }
                })
            ]
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_required_protocol',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 11: <br>Выберите нужный протокол'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'protocol_will_be_displayed_press_Ok',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 12: <br>Протокол отобразится справа, нажмите кнопку «Ок»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSequenceAlertModalDefinition({
            text: 'ШАГ 13: <br>Протокол загрузится на главный экран',
            buttons: [
                {
                    text: 'Понятно',
                    action: ({nextStep}) => nextStep(),
                }
            ]
        })
    ]
});

const setup_protocol_manager = new SequenceDefinition({
    id: 'setup_protocol_manager',
    beforeStart: () => {
        if (router.currentRoute.name !== 'WorkStation')
            router.push({name: 'WorkStation'})
        return store.dispatch('cleanupStoreBeforeSetupProtocolManagerTutorial')
    },
    persistentHeader: 'Настройки менеджера протокола: <br>' +
        'Удаление, группировка, скрытие или переименование области исследований, где хранятся протоколы',
    prettyName: 'Настройка менеджера протоколов',
    steps: [
        new SequenceStepWithSequenceAlertModalDefinition({
           text: 'В данном разделе представлены основные этапы работы по настройке менеджера протоколов.',
           buttons: [
               {
                   text: 'Понятно',
                   action: ({nextStep}) => nextStep(),
               }
           ]
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'press_Menu_button',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 1: <br>Нажмите кнопку «Меню»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_section_settings',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 2: <br>Выберите раздел «Настройка»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_section_protocol_manager',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 3: <br>Выберите раздел «Менеджер протоколов»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_required_area_Head',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 4: <br>Выберите интересующую область, например, «Голова»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'to_change_order_of_protocol_press_on_required_protocol',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 5: <br>Чтобы изменить последовательность отображения протоколов нажмите на интересующий протокол'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'press_Up_to_change_position_of_protocol',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 6: <br>Нажмите «вверх», чтобы изменить позицию протокола'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.TOP
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_required_protocol_to_move_it_to_other_area',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 7: <br>Чтобы переместить протокол в другую область, выберите интересующий протокол'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_Change_area',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 8: <br>Выберите «Изменить область»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.TOP
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_other_area',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 9: <br>Выберите другую область, например, «Грудная клетка»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'setup_pm_confirm_your_choice',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 10: <br>Подтвердите выбор'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.TOP
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'to_rename_area',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 11: <br>Для того чтобы переименовать область (при необходимости) выберите нужную область, например «Грудная клетка» и нажмите «Переименовать»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'type_new_name_of_area',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 12: <br>Введите название "QWERTY" и нажмите "Ввод"'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'to_hide_area',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 13: <br>Для того чтобы скрыть область (при необходимости) выберите нужную область, например «Таз»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'press_Hide',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 14: <br>Нажмите «Скрыть»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'to_remove_protocol_select_area',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 15: <br>Чтобы удалить протокол из Менеджера протоколов выберите область в которой сохранён протокол'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'choose_protocol',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 16: <br>Выберите протокол'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'press_Remove_button',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 17: <br>Нажмите «Удалить»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'confirm_your_choice_again',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 18: <br>Подтвердите выбор'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'press_Exit_button_again',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 19: <br>Нажмите «Выход», чтобы вернуться на главный экран'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
    ]
});

const protocol_creation = new SequenceDefinition({
    id: 'protocol_creation',
    prettyName: 'Создание протокола',
    beforeStart: () => {
        if (router.currentRoute.name !== 'WorkStation')
            router.push({name: 'WorkStation'})
        return store.dispatch('cleanupStoreBeforeDefaultTutorial')
    },
    persistentHeader: 'Создание протокола: <br>' +
        'Пошаговое программирование протокола на конкретном примере',
    steps: [
        new SequenceStepWithSequenceAlertModalDefinition({
            text: 'В данном примере показано создание, редактирование, удаление фаз протокола. Последовательность фаз, их количество может быть произвольным (иным) в зависимости от целей и задач исследования.<br><br>' +
                'Данный пример не предназначен для какого-либо исследования. Создавайте протоколы исследований в соответствии с принятыми руководствами/рекомендациями.',
            buttons: [
                {
                    text: 'Понятно',
                    action: ({nextStep}) => nextStep()
                }
            ]
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 1
            id: 'create_or_choose_protocol',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 1: <br>Выберите элемент «стрелку» фазы, чтобы создать или изменить интересующую фазу, например стрелку «А»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.TOP
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  //STEP 2
            id: 'test_introduction_B',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 2: <br>Выберите тип фазы, например «Тестовое введение B» (физ. раствор). Данная фаза используется не всегда. Предназначена для оценки целостности системы, ее проходимости, и чтобы убедиться, что кончик катетера находится в просвете сосуда. После тестового введения инъектор переходит в режим ожидания и активируется оператором вручную для дальнейшего продолжения протокола.<br>По умолчанию тестовый ввод осуществляется с помощью физраствора'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),

        /** A **/

        new SequenceStepWithSingleChunkDefinition({ // STEP 3
            id: 'choose_Flow_phase_parameter_Contrast_A',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 3: <br>Чтобы изменить значения любой фазы (например, фазы А – контрастное вещество) выберите соответствующие параметры. Нажмите ячейку «Расход»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 4
            id: 'enter_parameter_value_Flow_Contrast_A',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 4: <br>Введите значение параметра в допустимом диапазоне'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  // STEP 5
            id: 'select_Volume_phase_parameter_Contrast_A',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 5: <br>Выберите ячейку «Объём»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  // STEP 6
            id: 'enter_parameter_value_Volume_Contrast_A',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 6: <br>Введите значение параметра в допустимом диапазоне'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),

        /** AB **/

        new SequenceStepWithSingleChunkDefinition({  //STEP 7
            id: 'press_arrow_question_AB',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 7: <br>Нажмите на стрелку со знаком вопроса, чтобы создать новую фазу'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({ //STEP 8
            id: 'choose_type_of_phase_AB',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 8: <br>Выберите тип фазы, например «A%B%» (DualFlow).<br>' +
                        'DualFlow позволяет программировать одновременную работу плунжеров шприцев с контрастным средством и физиологическим раствором. Пользователь может задавать различные соотношения для достижения желаемого объема подачи жидкостей. Используется не во всех исследованиях'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  //STEP 9
            id: 'enter_contrast_substance_AB',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 9: <br>Введите процентное содержание контрастного средства, которое будет вводиться в фазе DualFlow. Система автоматически определит процентное содержание физиологического раствора'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  //STEP 10
            id: 'choose_Flow_phase_parameter_AB',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 10: <br>Выберите ячейку «Расход»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  // STEP 11
            id: 'enter_parameter_value_Flow_AB',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 11: <br>Введите значение параметра в допустимом диапазоне'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  // STEP 12
            id: 'select_Volume_phase_parameter_AB',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 12: <br>Выберите ячейку «Объём»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  // STEP 13
            id: 'enter_parameter_value_Volume_AB',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 13: <br>Введите значение параметра в допустимом диапазоне'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),


        /** PAUSE **/

        new SequenceStepWithSingleChunkDefinition({ // STEP 14
            id: 'press_arrow_question_Pause',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 14: <br>Нажмите на стрелку со знаком вопроса, чтобы создать новую фазу'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  // STEP 15
            id: 'select_phase_type_Pause',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 15: <br>Для того, чтобы остановить выполнение протокола на определённое время (при необходимости) выберите тип фазы «Пауза»<br>' +
                        'Фаза «Пауза» – останавливает работу протокола на указанное оператором время, а затем автоматически продолжает его выполнение. Пауза может быть установлена до 15 мин.<br>' +
                        'Фаза «Ожидание» – останавливает работу протокола на неопределённое время. Продолжение выполнения протокола активируется оператором вручную'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 16
            id: 'select_parameter_value_Duration_Pause',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 16: <br>Выберите параметр паузы «Длит.»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  //STEP 17
            id: 'enter_parameter_value_Duration_Pause',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 17: <br>Введите значение параметра в допустимом диапазоне.<br>' +
                        'Через заданное время система автоматически продолжит выполнение протокола<br>'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),

        /** B **/

        new SequenceStepWithSingleChunkDefinition({
            id: 'press_arrow_question_test_B', //STEP 18
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 18: <br>Нажмите на стрелку со знаком вопроса, чтобы создать новую фазу'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  // STEP 19
            id: 'choose_type_phase_Flushing_B',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 19: <br>Выберите тип фазы «Промывка B»<br>' +
                        'Промывка осуществляется физиологическим раствором.'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 20
            id: 'choose_Flow_phase_parameter_Contrast_B',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 20: <br>Выберите ячейку «Расход»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 21
            id: 'enter_parameter_value_Flow_Contrast_B',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 21: <br>Введите значение параметра в допустимом диапазоне'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  // STEP 22
            id: 'select_Volume_phase_parameter_Contrast_B',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 22: <br>Выберите ячейку «Объём»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  // STEP 23
            id: 'enter_parameter_value_Volume_Contrast_B',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 23: <br>Введите значение параметра в допустимом диапазоне'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),

        /** DELETING **/

        new SequenceStepWithSingleChunkDefinition({  //STEP 24
            id: 'to_delete_phase_choose_necessary_arrow',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 24: <br>Чтобы удалить фазу из протокола (при необходимости) выберите нужную стрелку, например «A»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({  //STEP 25
            id: 'choose_Delete',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 25: <br>Выберите «Удалить»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.BOTTOM
                }
            })
        }),
        new SequenceStepWithSequenceAlertModalDefinition({
            text: 'Пример создания протокола завершен',
            buttons: [
                {
                    text: 'Понятно',
                    action: ({nextStep}) => nextStep()
                }
            ]
        })
    ]
});

const setup_system = new SequenceDefinition({
    id: 'setup_system',
    prettyName: 'Настройки системы',
    beforeStart: () => {
        if (router.currentRoute.name !== 'WorkStation')
            router.push({name: 'WorkStation'})
        return store.dispatch('cleanupStoreBeforeDefaultTutorial')
    },
    persistentHeader: 'Настройка системы:<br>' +
        'Ознакомление с настройками инъекционной системы, такие как язык отображения, единицы измерения, формат даты и времени, напоминания и т.д.\n',
    steps: [
        new SequenceStepWithSingleChunkDefinition({ // STEP 1
            id: 'press_Menu_button',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 1: <br>Нажмите на кнопку «Меню»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_section_settings',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 2: <br>Выберите раздел «Настройка»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_section_system',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 3: <br>Выберите раздел «Система»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepWithSequenceAlertModalDefinition({
            text: 'Чтобы получить информацию о функциях, наведите указатель мыши на интересующую кнопку.<br>' +
                'При нажатии на кнопку отобразятся ее параметры.</br>' +
                'Нажатие на кнопки «Ок» или «Отмена» - выход из Настроек. <br>',
            buttons: [
                {
                    text: 'Понятно',
                    action: ({nextStep}) => nextStep()
                }
            ]
        }),
        new SequenceStepOneOfMultipleChunksDefinition({
            id: 'press_Ok_button_or_Cancel_button_to_close_system_setup',
            chunks: [
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'press_Ok_button_quiet',
                    text: {
                        text: 'ШАГ 4: <br>Настройка системы.'
                    },
                    arrow: {
                        hidden: true
                    }
                }),
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'press_Cancel_button_quiet',
                    text: {
                        text: 'ШАГ 4: <br>Настройка системы.'
                    },
                    arrow: {
                        hidden: true
                    }
                }),
            ]
        }),
    ],
});

const liquid_delivery = new SequenceDefinition({
    id: 'liquid_delivery',
    prettyName: 'Доставка жидкости',
    beforeStart: () => {
        if (router.currentRoute.name !== 'WorkStation')
            router.push({name: 'WorkStation'})
        return store.dispatch('cleanupStoreBeforeDefaultTutorial')
    },
    persistentHeader: 'Доставка жидкости: <br>' +
        'Настройки подачи жидкости (контрастного средства и физиологического раствора) в инъекционной системе',
    steps: [
        new SequenceStepWithSequenceAlertModalDefinition({
            text: 'В данном разделе представлена информация по настройке подачи жидкости.Чтобы получить информацию о функциях, наведите указатель мыши на интересующую кнопку.<br> При нажатии на кнопку отобразятся ее параметры. ' +
                'Нажатие на кнопки «Ок» и «Отмена» - выход из «Доставки жидкости».',
            buttons: [
                {
                    text: 'Понятно',
                    action: ({nextStep}) => nextStep()
                }
            ]
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 1
            id: 'press_Menu_button',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 1: <br>Нажмите на кнопку «Меню»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_section_settings',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 2: <br>Выберите раздел «Настройка»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({
            id: 'select_section_liquid_delivery',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 3: <br>Выберите раздел «Доставка жидкости»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.LEFT
                }
            })
        }),
        new SequenceStepOneOfMultipleChunksDefinition({
            id: 'press_Ok_button_or_Cancel_button_to_close_liquid_delivery_setup',
            chunks: [
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'press_Ok_button_quiet',
                    text: {
                        text: 'ШАГ 4: <br>Настройка системы.'
                    },
                    arrow: {
                        hidden: true
                    }
                }),
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'press_Cancel_button_quiet',
                    text: {
                        text: 'ШАГ 4: <br>Настройка системы.'
                    },
                    arrow: {
                        hidden: true
                    }
                }),
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'press_Ok_button_quiet_second_page',
                    text: {
                        text: 'ШАГ 4: <br>Настройка системы.'
                    },
                    arrow: {
                        hidden: true
                    }
                }),
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'press_Cancel_button_quiet_second_page',
                    text: {
                        text: 'ШАГ 4: <br>Настройка системы.'
                    },
                    arrow: {
                        hidden: true
                    }
                }),
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'press_by_default_configuration_modal',
                    text: {
                        text: 'ШАГ 4: <br>Настройка системы.'
                    },
                    arrow: {
                        hidden: true
                    }
                }),
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'press_cancel_configuration_modal',
                    text: {
                        text: 'ШАГ 4: <br>Настройка системы.'
                    },
                    arrow: {
                        hidden: true
                    }
                }),
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'press_confirm_configuration_modal',
                    text: {
                        text: 'ШАГ 4: <br>Настройка системы.'
                    },
                    arrow: {
                        hidden: true
                    }
                }),
            ]
        }),

    ],
});


class InjectionProcedureSequenceInstance extends SequenceInstance {
    previousStep(...args) {
        const previousStep = super.previousStep(...args)

        if (['press_Start_Injection_button', 'continue_injection'].includes(previousStep.definition.id))
            this.previousStep(...args)
    }
}

class InjectionProcedureSequenceDefinition extends assignInstanceConstructor(InjectionProcedureSequenceInstance, SequenceDefinition) {}



const injection_procedure = new InjectionProcedureSequenceDefinition({
    id: 'injection_procedure',
    prettyName: 'Процедура инъекции',
    beforeStart: () => {
        if (router.currentRoute.name !== 'WorkStation')
            router.push({name: 'WorkStation'})
        return store.dispatch('cleanupStoreBeforeInjectionProcedureTutorial')
    },
    persistentHeader: 'Процедура инъекции:<br>' +
        'инициация, введение и завершение',
    steps: [
        new SequenceStepWithSequenceAlertModalDefinition({
            text: 'В данном примере показаны инициация, введение и завершение инъекции.',
            buttons: [
                {
                    text: 'Понятно',
                    action: ({nextStep}) => nextStep()
                }
            ]
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 1
            id: 'press_Lock_button',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 1: <br>После того, как протокол проверен и не содержит ошибок, нажмите на кнопку «Блокир.». Протокол будет заблокирован, изменить его будет нельзя.'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            }),
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 2
            id: 'press_Charger_button',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 2: <br>Протокол заблокирован. Далее нажмите на кнопку «Зарядка»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 3
            id: 'confirm_check_for_air',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 3: <br>Подтвердите отсутствие воздуха в системе. Данное сообщение появляется, если на голове инъектора не была нажата кнопка проверки воздуха - Check for Air.'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.TOP
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 4
            id: 'press_Charger_button_again',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 4: <br>Нажмите на кнопку «Зарядка»'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 5
            id: 'press_Start_Injection_button',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 5: <br>Для запуска процесса инъекции нажмите кнопку «Пуск/ожидание».'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.TOP
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 6
            id: 'continue_injection',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 6: <br>Так как в данном протоколе первой фазой было тестовое (пробное) введение, то после него аппарат переходит в режим ожидания. Продолжение инъекции инициируется оператором нажатием кнопки «Пуск/ожидание».'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.TOP
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 7
            id: 'choose_Graph',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 7: <br>Когда протокол выполнен (введение завершено), выводится информация (сводка) о проведённой процедуре инъекции. Чтобы просмотреть график (при необходимости) выберите «График».'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSingleChunkDefinition({ // STEP 8
            id: 'press_New_patient_button',
            chunk: new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                text: {
                    text: 'ШАГ 8: <br>На графике показаны этапы инъекции, последовательность фаз, временные интервалы, изменения давления в системе. Стрелками под графиком можно прокручивать график влево и вправо. Для того чтобы выйти на главный экран выберите «Тот же пациент», либо «Новый пациент».'
                },
                arrow: {
                    position: SequenceStepChunkWithArrow.POSITIONS.RIGHT
                }
            })
        }),
        new SequenceStepWithSequenceAlertModalDefinition({
            text: 'Пример выполнения процедуры инъекции завершён.',
            disableBackButton: true,
            buttons: [
                {
                    text: 'OK',
                    action: ({nextStep}) => nextStep()
                }
            ]
        }),
    ],
});

const injector_general_info = new SequenceDefinition({
    id: 'injector_general_info',
    prettyName: 'Общая информация',
    persistentHeader: 'Общая информация:<br>' +
        'В данном разделе показывается назначение расходных материалов и частей прибора',
    beforeStart: () => store.dispatch('cleanupStoreBeforeInjectorTutorial'),
    steps: [
        new SequenceStepWithSequenceAlertModalDefinition({
            text: 'В данном примере показывается назначение расходных материалов и частей прибора. <br><br>Кликните по комплекту расходных материалов и прибору, чтобы увидеть информацию о них.',
            buttons: [
                {
                    text: 'Понятно',
                    action: ({nextStep}) => nextStep()
                }
            ]
        }),
        new SequenceStepWithInternalStateDefinition({
            id: 'injector_general_info_toggleable',
            disableAllExceptAnchor: false,
            state: () => {
                const v = ref('')

                // const unwatch1 = watch(
                //     v,
                //     () => console.log(v.value)
                // )

                // const unwatch2 = watch(
                //     () => store.getters['sequences_store/getActiveStepId'],
                //     id => {
                //         if (id !== 'injector_general_info') {
                //             unwatch1()
                //             unwatch2()
                //         }
                //     }
                // )

                return v
            }
        })
    ],
});

class InjectorHeadSetupSequenceInstance extends SequenceInstance {

    takeSnapshot(stepId) {
        for (let step of this.remainingSteps.value)
            if (step.definition.id === stepId) {
                const {sequences_store, ...restState} = store.state

                const clonedState = cloneDeep(restState)

                if ([
                    'spk_set_needles_to_flasks',
                    'spk_set_flacons',

                    'mp1_set_flacons',
                    'mp1_set_tube_rolled_up',
                ].includes(stepId)) {
                    clonedState.injector.fluids.a = 0
                    clonedState.injector.fluids.b = 0
                    clonedState.injector.pistons_in_move = false
                }

                if ([
                    'mp1_set_tube_rolled_up'
                ].includes(stepId)) {
                    clonedState.injector.enlarged_panel.buttonsState.autoLoadButtonActive = true
                }

                if ([
                    'spk_press_fill_button',
                    'spk_take_off_flacons',
                    'spk_take_off_needles',
                    'spk_set_tube',
                    'spk_set_bleed_tube',

                    'mp1_press_fill_button',
                    'mp1_set_bleed_tube',
                    'mp1_set_tube_SDP250',
                    'mp1_set_card',
                    'mp1_set_heaters'
                ].includes(stepId)) {
                    clonedState.injector.fluids.a = 25
                    clonedState.injector.fluids.b = 65
                }


                if ([
                    'spk_set_heaters',
                    'spk_turn_down_injector_head',
                    'spk_take_off_bleed_tube',
                    'spk_connect_system_with_patient',

                    'mp1_turn_down_injector_head',
                    'mp1_connect_system_with_patient'
                ].includes(stepId)) {
                    clonedState.injector.fluids.a = 10
                    clonedState.injector.fluids.b = 0
                }


                console.log('TAKE SNAPSHOT ', stepId, clonedState)


                step.saveSnapshot({
                    routerFullPath: router.currentRoute.fullPath,
                    storeState: cloneDeep(clonedState),
                    passedSteps: this.passedSteps.value,
                    remainingSteps: this.remainingSteps.value
                })
                break
            }
    }

    nextStep(chunkId) {
        if (this.remainingSteps.value.length) {
            const passedStep = this.remainingSteps.value[0]
            if (passedStep.definition?.after)
                passedStep.definition?.after?.()
            if (passedStep.definition.id === 'select_injection_kit') {
                this.remainingSteps.value = this.definition.forkSteps[chunkId].map(step => step.generateInstance({parent: this}))
                this.passedSteps.value = [...this.passedSteps.value, passedStep]

                if (chunkId === 'select_MP1_kit')
                    store.dispatch('prepareForInjectorHeadSetupMP1')
            } else {
                return super.nextStep(chunkId)
            }
        }
    }

    previousStep(...args) {
        const previousStep = super.previousStep(...args)

        if (['mp1_complete_fill_button_filling', 'mp1_press_fill_button'].includes(previousStep.definition.id)) {
            return this.previousStep(...args)
        }

        if (['mp1_press_autoload_button', 'spk_press_autoload_button'].includes(previousStep.definition.id)) {
            gsap.to('.fill-b-off--panel', {opacity: 1});
            gsap.to('.fill-a-off--panel', {opacity: 1});
            gsap.to('.fill-b-off', {opacity: 1});
            gsap.to('.fill-a-off', {opacity: 1});
            let tl = gsap.timeline({defaults: {duration: 0}});
            tl.to(`.piston-a`, {y: 0}, 'back')
                .to(`.background-a`, {y: 0}, 'back')
                .to(`.flask-a`, {y: 0}, 'back')
                .to(`.piston-b`, {y: 0}, 'back')
                .to(`.background-b`, {y: 0}, 'back')
                .to(`.flask-b`, {y: 0}, 'back')
        }


        if (['spk_press_Check_For_Air_button', 'mp1_press_Check_For_Air_button'].includes(previousStep.definition.id)) {
            gsap.to('.check-off--panel', {
                opacity: 1
            })
            gsap.to('.check-off', {
                opacity: 1
            })
        }


        if (['spk_press_arm_button', 'mp1_press_arm_button'].includes(previousStep.definition.id)) {
            gsap.to('.arm-off--panel', {
                opacity: 1
            })
            gsap.to('.arm-off', {
                opacity: 1
            })
            gsap.to('.lock--panel', {
                fill: 'black',
            })
            gsap.to('.lock', {
                fill: 'black',
            })
        }
    }
}

class InjectorHeadSetupSequenceDefinition extends assignInstanceConstructor(InjectorHeadSetupSequenceInstance, SequenceDefinition) {
    constructor({forkSteps, ...restArgs}) {
        super(restArgs)

        this.forkSteps = forkSteps
    }

    get stepById() {
        return {...super.stepById, ...Object.fromEntries(Object.values(this.forkSteps).flat().map(step => [step.id, step]))}
    }
}

const injector_head_setup = new InjectorHeadSetupSequenceDefinition({
    id: 'injector_head_setup',
    prettyName: 'Работа с головой инъектора',
    persistentHeader: 'Работа с головой инъектора: <br>Выбор расходных материалов',
    beforeStart: () => store.dispatch('cleanupStoreBeforeInjectorTutorial'),

    steps: [
        new SequenceStepWithSequenceAlertModalDefinition({
            text: 'В данном разделе показывается установка расходных материалов и забор жидкостей на примере' +
                ' одноразового набор SDS-CTP-SPK и многоразового набор SDS MP1.',
            buttons: [
                {
                    text: 'Понятно',
                    action: ({nextStep}) => nextStep()
                }
            ]
        }),
        new SequenceStepOneOfMultipleChunksDefinition({
            id: 'select_injection_kit',
            chunks: [
                new SequenceStepChunkTextAttachedToArrow({
                    id: 'select_SPK_kit',
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.BOTTOM,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.RIGHT,
                        withoutArrow: true,
                        mainText: 'Одноразовые наборы различаются только способом наполнения шприцев:<br>SDS-CTP-SPK - с помощью игл<br>SDS-CTP-QFT - с помощью трубки',
                    }
                }),
                new SequenceStepChunkWithTextAndArrowVisibleWhileActive({
                    id: 'select_MP1_kit',
                    text: {
                        text: 'Выберите комплект расходных материалов.<br>' +
                            'Кликните по комплекту расходных материалов, чтобы выбрать его и приступить к установке\n'
                    },
                    arrow: {
                        hidden: true
                    }
                })
            ]
        })
    ],
    forkSteps: {
        select_SPK_kit: [
            new SequenceStepWithSequenceAlertModalDefinition({
                text: 'При использовании одноразового комплекта расходных материалов должны быть установлены следующие параметры подачи жидкости (Рабочая станция/Настройки/Подача жидкости):<br>' +
                    '1)\tТип LPCT = Стандартный LPCT<br>' +
                    '2)\tАвт.заполн. объема для удаления воздуха = 10 ml',
                buttons: [
                    {
                        text: '<div style="display: flex; align-items: center;"><span style="margin-right: 10px;">Дальше</span><span style="font-size: 19px">></span></div>',
                        action: ({nextStep}) => nextStep()
                    }
                ]
            }),
            new SequenceStepWithSequenceAlertModalDefinition({
                text: 'Клик на расходном материале установливает его на голову инъектора. <br>' +
                    'Клик на установленном - снимает его с головы инъектора.<br>',
                buttons: [
                    {
                        text: '<div style="display: flex; align-items: center;"><span style="font-size: 19px"><</span><span style="margin-left: 10px;">Назад</span></div>',
                        action: () => store.dispatch('sequences_store/goBack')
                    },
                    {
                        text: 'Понятно',
                        action: ({nextStep}) => nextStep()
                    }
                ]
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_set_flasks',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.BOTTOM,
                        mainText: 'Шаг 1. Установите шприцы',
                        addons: [
                            SequenceStepChunkTextAttachedToArrow.TEXT_BOTTOM_ADDON({
                                text: 'Шприцы можно устанавливать на голову инъектора как одновременно, так и по очереди'
                            })
                        ]
                    }
                }),
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_set_needles_to_flasks',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 2. Установите иглы на шприцы',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_set_flacons',
                after: () => store.dispatch('injector/changeEnlargedPanelStatus', true),
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 3. Установите емкости с жидкостями',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_press_autoload_button',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 4: Для забора жидкостей в режиме автозаполнения нажмите кнопку «Autoload» - на дисплее отображается объемы жидкостей, установленные в протоколе на рабочей станции.<br>' +
                            'Кнопками +/- добавьте или уменьшите объемы (при необходимости)\n',
                        addons: [
                            SequenceStepChunkTextAttachedToArrow.TEXT_BOTTOM_ADDON({
                                text: 'Существует возможность забора жидкости в режиме ручного управления поршнями – с помощью кнопки «Move piston» и стрелок управления'
                            })
                        ]
                    }
                })
            }),
            new SequenceStepAllOfMultipleChunksDefinition({
                id: 'spk_press_fill_button',
                chunks: [
                    new SequenceStepChunkTextAttachedToArrow({
                        id: 'spk_press_fillB_button',
                        text_arrow: {
                            position: SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT,
                            align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                            mainText: 'Шаг 5. Нажмите кнопку Fill B для забора физраствора',
                        }
                    }),
                    new SequenceStepChunkTextAttachedToArrow({
                        id: 'spk_press_fillA_button',
                        text_arrow: {
                            position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                            align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                            mainText: 'Шаг 5. Нажмите кнопку Fill A для забора контрастного средства',
                        }
                    }),
                ]
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_take_off_flacons',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 6: Снимите емкости с растворами',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_take_off_needles',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 7: Снимите иглы со шприцев',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_set_tube',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 8.<br>' +
                            'Установите Т-образную трубку.<br><br>' +
                            'Подключите прямой участок коннектора к контрастному средству, а удлиненный – к физраствору.',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_set_bleed_tube',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.TOP,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.LEFT,
                        mainText: 'Шаг 9.<br>' +
                            'Установите трубку для прокачки воздуха в системе\n',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_press_Prime_button',
                after: () => store.dispatch('injector/changeEnlargedPanelStatus', false),
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 10. Прокачайте воздух в системе в автоматическом режиме. Для этого нажмите кнопку «Prime» ',
                        addons: [
                            SequenceStepChunkTextAttachedToArrow.TEXT_BOTTOM_ADDON({
                                text: 'Существует возможность прокачки воздуха в режиме ручного управления поршнями – с помощью кнопки «Move piston» и стрелок управления или с помощью вращающихся ручек управления в нижней части головы инъектора.'
                            })
                        ]
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_set_heaters',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.BOTTOM,
                        mainText: 'Шаг 11. Установите подогреватели, надев их на шприцы сверху вниз.<br><br>Подогреватели расположены на голове инъектора. ',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_take_off_bleed_tube',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 12.<br>Снимите трубку для прокачки воздуха',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_turn_down_injector_head',
                after: () => store.dispatch('injector/changeEnlargedPanelStatus', true),
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 13. Поверните голову инъектора шприцами вниз',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_press_Check_For_Air_button',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 14.<br>Нажмите кнопку «Check for air» (Проверка на наличие воздуха в системе)',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_press_arm_button',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 15.<br>Нажмите кнопку «Arm» - Активация',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_connect_system_with_patient',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 16.<br>Подключите систему к пациенту',
                    }
                })
            }),
            new SequenceStepWithSequenceAlertModalDefinition({
                text: (() => {
                    const img = require('@/img/injector/connected-to-patient(drop to drop).svg')
                    return `
                        <p>Система подключена к пациенту.</p>
                        <img src="${img}"/>
                    `
                })(),
                buttons: [
                    {
                        text: 'Понятно',
                        action: ({nextStep}) => nextStep()
                    }
                ],
                after: () => store.dispatch('injector/changeEnlargedPanelStatus', true),
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'spk_press_run_button',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.BOTTOM,
                        mainText: 'Шаг 17.<br>Нажмите желтую кнопку ПУСК для запуска инъекции.',
                    }
                })
            }),
            new SequenceStepWithSequenceAlertModalDefinition({
                text: 'Процедура тестового введения началась.<br><br>' +
                    'С головы инъектора запускается только тестовое введение, после чего инъектор переходит в режим ожидания. ' +
                    'Необходимо убедиться, что с пациентом все в порядке и перейти в аппаратную для запуска инъекции с рабочей станции.',
                buttons: [
                    {
                        text: 'Вернуться в начало',
                        action: ({nextStep}) => {
                            nextStep()
                            store.dispatch('cleanupStoreBeforeInjectorTutorial')
                        }
                    }
                ],
            }),
            // {
            //     id: 'injection_started_confirmation',
            //     text: '',
            //     without_popup: true
            // }
        ],
        select_MP1_kit: [
            new SequenceStepWithSequenceAlertModalDefinition({
                text: 'При использовании многоразового комплекта расходных материалов должны быть установлены следующие параметры подачи жидкости (Рабочая станция/Настройки/Подача жидкости):<br>' +
                    '1)\tТип LPCT = P8100<br>' +
                    '2)\tАвт.заполн. объема для удаления воздуха = Выключено',
                buttons: [
                    {
                        text: '<div style="display: flex; align-items: center;"><span style="margin-right: 10px;">Дальше</span><span style="font-size: 19px">></span></div>',
                        action: ({nextStep}) => nextStep()
                    }
                ]
            }),
            new SequenceStepWithSequenceAlertModalDefinition({
                text: 'Клик на расходном материале установливает его на голову инъектора. <br>' +
                    'Клик на установленном - снимает его с головы инъектора.<br>',
                buttons: [
                    {
                        text: '<div style="display: flex; align-items: center;"><span style="font-size: 19px"><</span><span style="margin-left: 10px;">Назад</span></div>',
                        action: () => store.dispatch('sequences_store/goBack')
                    },
                    {
                        text: 'Понятно',
                        action: ({nextStep}) => nextStep()
                    }
                ]
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_set_flasks',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.BOTTOM,
                        mainText: 'Шаг 1. Установите шприцы',
                        addons: [
                            SequenceStepChunkTextAttachedToArrow.TEXT_BOTTOM_ADDON({
                                text: 'Шприцы можно устанавливать на голову инъектора как одновременно, так и по очереди'
                            })
                        ]
                    }
                }),
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_set_flacons',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 2. Установите емкости с жидкостями',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_set_tube_rolled_up',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 3. Подключите линии для заполнения шприцев',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_set_bleed_tube',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 4. Подсоедините Т-образную соединительную трубку. Подключите прямой участок коннектора к шприцу для контрастного средства, а удлиненный – к шприцу для физраствора.',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_set_card',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 5. Укажите на информационной карточке время начала работы с комплектом расходных материалов и время окончания работы, когда будет необходимо утилизировать компоненты набора. Максимальный срок использования – 12 часов.<br>' +
                            'Разместите информационную карточку на соединительной трубке.\n',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_set_tube_SDP250',
                after: () => store.dispatch('injector/changeEnlargedPanelStatus', true),
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 6. Подсоедините одноразовую трубку для пацента SPD-250.<br>' +
                            'Данный компонент одноразовый и утилизируется после каждого пациента.',
                    }
                })
            }),

            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_press_autoload_button',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 7: Для забора жидкостей в режиме автозаполнения нажмите кнопку «Autoload» - на дисплее отображаются объемы жидкостей, установленные в протоколе на рабочей станции.<br>' +
                            'Кнопками +/- добавьте или уменьшите объемы (при необходимости)\n',
                        addons: [
                            SequenceStepChunkTextAttachedToArrow.TEXT_BOTTOM_ADDON({
                                text: 'Существует возможность забора жидкости в режиме ручного управления поршнями – с помощью кнопки «Move piston» и стрелок управления'
                            })
                        ]
                    }
                })
            }),
            new SequenceStepAllOfMultipleChunksDefinition({
                id: 'mp1_press_fill_button',
                chunks: [
                    new SequenceStepChunkTextAttachedToArrow({
                        id: 'mp1_press_fillB_button',
                        text_arrow: {
                            position: SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT,
                            align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                            mainText: 'Шаг 8. Нажмите кнопку Fill B для забора физраствора',
                        }
                    }),
                    new SequenceStepChunkTextAttachedToArrow({
                        id: 'mp1_press_fillA_button',
                        text_arrow: {
                            position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                            align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                            mainText: 'Шаг 8. Нажмите кнопку Fill A для забора контрастного средства',
                        }
                    }),
                ]
            }),
            new SequenceStepAllOfMultipleChunksDefinition({
                id: 'mp1_complete_fill_button_filling',
                after: () => store.dispatch('injector/changeEnlargedPanelStatus', false),
                chunks: [
                    new SequenceStepChunkDefinition({
                        id: 'mp1_complete_fillB_button_filling',
                    }),
                    new SequenceStepChunkDefinition({
                        id: 'mp1_complete_fillA_button_filling',
                    }),
                ]
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_set_heaters',
                after: () => store.dispatch('injector/changeEnlargedPanelStatus', true),
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 9. Установите подогреватели, надев их на шприцы сверху вниз. <br>' +
                            'Подогреватели расположены на голове инъектора.',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_press_Prime_button',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 10. Прокачайте воздух в системе в автоматическом режиме. Для этого нажмите кнопку «Prime» ',
                        addons: [
                            SequenceStepChunkTextAttachedToArrow.TEXT_BOTTOM_ADDON({
                                text: 'Существует возможность прокачки воздуха в режиме ручного управления поршнями – с помощью кнопки «Move piston» и стрелок управления или с помощью вращающихся ручек управления в нижней части головы инъектора.'
                            })
                        ]
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_turn_down_injector_head',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 11. Поверните голову инъектора шприцами вниз',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_press_Check_For_Air_button',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 12.<br>Нажмите кнопку «Check for air» (Проверка на наличие воздуха в системе)',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_press_arm_button',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 13.<br>Нажмите кнопку «Arm» - Активация',
                    }
                })
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_connect_system_with_patient',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP,
                        mainText: 'Шаг 14.<br>Подключите систему к пациенту',
                    }
                })
            }),
            new SequenceStepWithSequenceAlertModalDefinition({
                text: (() => {
                    const img = require('@/img/injector/connected-to-patient(drop to drop).svg')
                    return `
                        <p>Система подключена к пациенту.</p>
                        <img src="${img}"/>
                    `
                })(),
                buttons: [
                    {
                        text: 'Понятно',
                        action: ({nextStep}) => nextStep()
                    }
                ],
            }),
            new SequenceStepWithSingleChunkDefinition({
                id: 'mp1_press_run_button',
                chunk: new SequenceStepChunkTextAttachedToArrow({
                    text_arrow: {
                        position: SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT,
                        align: SequenceStepChunkTextAttachedToArrow.ALIGNMENT.BOTTOM,
                        mainText: 'Шаг 15.<br>Нажмите желтую кнопку ПУСК для запуска инъекции.',
                    }
                })
            }),
            new SequenceStepWithSequenceAlertModalDefinition({
                text: 'Процедура тестового введения началась.<br><br>' +
                    'Обратите внимание! Перед запуском процедуры инъекции с головы инъектора<br>' +
                    'протокол должен быть заблокирован на рабочей станции. ' +
                    'С головы инъектора запускается только тестовое введение, после чего инъектор переходит в режим ожидания. ' +
                    'Необходимо убедиться, что с пациентом все в порядке и перейти в аппаратную для запуска инъекции с рабочей станции.',
                buttons: [
                    {
                        text: 'Вернуться в начало',
                        action: ({nextStep}) => {
                            nextStep()
                            store.dispatch('cleanupStoreBeforeInjectorTutorial')
                        }
                    }
                ],
            }),
        ]
    }
})


const sequencesList = [
    workstation_general_info,
    protocol_manager,
    setup_protocol_manager,
    protocol_creation,
    setup_system,
    liquid_delivery,
    injection_procedure,
    injector_general_info,
    injector_head_setup,
]
export const sequencesObj = Object.fromEntries(sequencesList.map(sequence => [sequence.id, sequence]))

export const SEQUENCE_ENDED = Symbol('SEQUENCE_ENDED')
Vue.prototype.SEQUENCES_CONTROL_ID = '#sequences-control'

export default {
    namespaced: true,
    state: {
        sequences: sequencesList,
        active_sequence: undefined,
        // active_wrapped_elements_bounding: [],
    },
    mutations: {
        SET_ACTIVE_SEQUENCE: (state, sequenceInstance) => {
            state.active_sequence = sequenceInstance
            // state.active_wrapped_elements_bonding = [] ?????? transfer from the ancient store
        },
    },
    actions: {
        startSequence: async ({commit}, {sequenceId}) => {
            const instance = await Promise.resolve(sequencesObj[sequenceId].generateInstance())
            commit('SET_ACTIVE_SEQUENCE', instance)
        },
        takeSnapshot: ({getters}, {stepId}) => getters.getActiveSequence.takeSnapshot(stepId),
        processChunkAction: ({getters, commit}, {chunkId} = {}) => {
            const result = getters.getActiveStep.chunkActionExecuted(chunkId)
            if (result === SEQUENCE_ENDED) // sequence ended
                commit('SET_ACTIVE_SEQUENCE', undefined)
        },
        goBack: ({getters}) => getters.getActiveSequence.previousStep(),
        endAllSequences: ({commit}) => commit('SET_ACTIVE_SEQUENCE', undefined)
    },
    getters: {
        getSequences: state => state.sequences,

        getActiveSequence: state => state.active_sequence,
        getActiveSequenceId: (_, getters) => getters.getActiveSequence?.definition?.id,
        getActiveStep: (_, getters) => getters.getActiveSequence?.activeStep?.value,
        getActiveStepId: (_, getters) => getters.getActiveStep?.definition?.id,
        getActiveStepsIds: (_, getters) => getters.getActiveSequence?.definition?.steps.map((step) => step.id),
        getActiveChunks: (_, getters) => getters.getActiveStep?.remainingChunks?.value,
        getActiveChunksIds: (_, getters) => getters.getActiveChunks?.map(({definition: {id}}) => id)
    }
}