import Vue from 'vue'
import '@/composition_api_setup'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)
import App from './App.vue'
import router from '@/router/router'
import vuetify from "./plugins/vuetify";

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

import { store } from '@/store/index.js'

Vue.config.productionTip = false

Vue.prototype.standEventBus = new Vue()
Vue.prototype.injectorEventBus = new Vue()
import {sequencesEventBus} from '@/store/modules/sequences'

Vue.prototype.sequencesEventBus = sequencesEventBus

Vue.component('SequenceItemWrapper', () => import('@/components/EducationMode/WrapSequence/SequenceItemWrapper'))
Vue.component('StaticWrapperWithPopup', () => import('@/components/EducationMode/WrapSequence/StaticWrapperWithPopup'))
Vue.mixin({
    data: () => ({
        isMounted_: false
    }),
    computed: {
        isEducationModeEnabled() {
            return store.getters['mainScreen/getEducationIsChosen']
        }
    },
    mounted() {
        Vue.nextTick(() => {
            this.isMounted_ = true
        })
    }
})

Vue.component('SequenceChunkWrapper', () => import('@/sequences/SequenceChunkWrapper'))


Vue.use(VueCompositionApi)

export const app = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
