import '@/composition_api_setup'
import {
    ABCSequenceElementDefinition,
    ABCSequenceElementInstance,
    assignInstanceConstructor
} from '@/sequences/abc_sequence_element'
import { SequenceStepChunkDefinition } from '@/sequences/SequenceStepChunk/base_classes'
import { isArray, cloneDeep } from 'lodash'
import { ref } from '@vue/composition-api'

export class SequenceStepInstance extends ABCSequenceElementInstance {
    snapshot

    constructor(...args) {
        super(...args)

        this.remainingChunks = ref(this.definition.chunks.map(chunk => chunk.generateInstance({parent: this})))
        this.passedChunks = ref([])
    }

    saveSnapshot(data) {
        this.clearSnapshot()
        this.snapshot = data
    }

    clearSnapshot() {
        this.snapshot = undefined
    }

    restoreChunks() {
        this.remainingChunks.value = [...this.remainingChunks.value, ...this.passedChunks.value]
        this.passedChunks.value = []
    }

    chunkActionExecuted(chunkId) {
        const result = this.processChunkActionExecution(chunkId)



        return result
    }

    processChunkActionExecution() { // this method must be overwritten in SequenceStepInstance children
        this.throwInitializationError('processChunkActionExecution - this method must be overwritten')
    }
}

export class SequenceStepDefinition extends assignInstanceConstructor(SequenceStepInstance, ABCSequenceElementDefinition) {
    constructor({
        id,
        chunks = [],
        after,
        disableAllExceptAnchor = true,
        disableBackButton = false,
    } = {}) {
        super()
        this.id = id
        this.chunks = chunks
        this.after = after
        this.disableBackButton = disableBackButton,
        this.mixins = [
            ...(disableAllExceptAnchor ? ['disable_all_except_anchor'] : [])
        ] // please someone fix this shit later
        this.#validateAfterChunksInitialization()
    }

    #validateAfterChunksInitialization() {
        if(!this.id) {
            this.throwInitializationError(`Chunks must have id`)
        }
        if (!isArray(this.chunks))
            this.throwInitializationError(`Chunks must be array`)

        // if (!this.chunks.length)
        //     this.throwInitializationError(`Step must have at least one chunk`)

        this.chunks.some((chunk, index, chunks) => {
            if (!(chunk instanceof SequenceStepChunkDefinition))
                this.throwInitializationError(`Chunk must be instance of ${SequenceStepChunkDefinition.name}`)
            if (chunks.findIndex(({id}) => id === chunk.id) !== index)
                this.throwInitializationError(`Duplicated chunks ids: ${chunk.id}`)
        })
    }

    get chunkById() {
        return Object.fromEntries(this.chunks.map(chunk => [chunk.id, chunk]))
    }
}