export const superMegaCoolErrorStyle = `margin: 15px; border-radius: 15px; color: #00ff11; padding: 40px; text-shadow: 0 2px 2px #dfdfdf, -2px 5px 1px rgba(184, 184, 184, 0.27), -4px 8px 0px rgba(151, 151, 151, 0.27), -6px 11px 0px rgba(116, 116, 116, 0.27), -8px 14px 0px rgba(86, 86, 86, 0.27); font-size: 20px; background-color: #242424; opacity: 1; background-image:  linear-gradient(30deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000), linear-gradient(150deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000), linear-gradient(30deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000), linear-gradient(150deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000), linear-gradient(60deg, #00000077 25%, transparent 25.5%, transparent 75%, #00000077 75%, #00000077), linear-gradient(60deg, #00000077 25%, transparent 25.5%, transparent 75%, #00000077 75%, #00000077); background-size: 20px 35px; background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;`

export const generateUUID = length => [...Array(length || 1)].map(() => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))).join('-')

export const getAbsoluteBoundingRect = (element, {
    round = false
} = {}) => {
    const boundingClientRect = element.getBoundingClientRect()
    let result = {
        top: boundingClientRect.top + window.scrollY,
        bottom: boundingClientRect.bottom + window.scrollY,
        left: boundingClientRect.left + window.scrollX,
        right: boundingClientRect.right + window.scrollX,
        height: boundingClientRect.height,
        width: boundingClientRect.width
    }

    if (round)
        result = Object.fromEntries(
            Object.entries(result)
                .map(([key, value]) => [key, Math.round(value)])
        )

    return result
}