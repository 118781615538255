import {gsap} from "gsap";

export default {
    methods: {
        handleAutoloadAnimation() {
            if (this.autoload_is_chosen) {

                if (this.autoload_filling_a_started && !this.autoload_filling_b_started && !this.flashing_fill_b_button.paused()) {
                    this.flashing_fill_b_button.pause();
                    gsap.to('.fill-b-off--panel', {opacity: 1});

                } else if (this.autoload_filling_b_started && !this.autoload_filling_a_started && !this.flashing_fill_a_button.paused()) {
                    this.flashing_fill_a_button.pause();
                    gsap.to('.fill-a-off--panel', {opacity: 1});

                } else if (!this.flashing_fill_b_button.paused() && !this.flashing_fill_a_button.paused()) {
                    this.flashing_fill_b_button.pause();
                    gsap.to('.fill-b-off--panel', {opacity: 1});
                    this.flashing_fill_a_button.pause();
                    gsap.to('.fill-a-off--panel', {opacity: 1});
                }

            } else {
                gsap.to('.move-off--panel', {opacity: 1});

                if (!this.autoload_filling_a_started && this.autoload_filling_b_started && this.flashing_fill_a_button.paused()) {
                    this.flashing_fill_a_button.restart();

                } else if (!this.autoload_filling_b_started && this.autoload_filling_a_started && this.flashing_fill_b_button.paused()) {
                    this.flashing_fill_b_button.restart();

                } else if (this.flashing_fill_a_button.paused() && this.flashing_fill_b_button.paused()) {
                    this.flashing_fill_a_button.restart();
                    this.flashing_fill_b_button.restart();
                }
            }
        },
        handleManualLoadAnimation() {
            if (this.manualload_is_chosen) {
                gsap.to('.move-off--panel', {
                    opacity: 1,
                })
            } else {
                this.flashing_fill_b_button.pause();
                this.flashing_fill_a_button.pause();
                gsap.to('.fill-a-off--panel', {opacity: 1,});
                gsap.to('.fill-b-off--panel', {opacity: 1,});
                gsap.to('.move-off--panel', {opacity: 0,});
            }
        },
        handleAutoFillFluidAnimation(type) {
            if (type === 'a') {
                this.flashing_fill_a_button.pause();
            } else {
                this.flashing_fill_b_button.pause();
            }
            gsap.to(`.fill-${type}-off--panel`, {
                duration: 0.1,
                opacity: 1,
            });
        },
        handleEnableAirAnimation() {
            gsap.to('.check-off--panel', {
                opacity: this.check_air ? 1 : 0,
            })
        },
        handleEnableArmAnimation() {
            gsap.to('.arm-off--panel', {
                opacity: this.arm ? 1 : 0,
            })

            if (!this.arm) {
                if (!this.check_air) {
                    this.button_check_air_animation.restart();
                }
                if (!this.protocol_locked) {
                    this.lock_animation.restart();
                }
                if (this.totalA - this.fluids.a > 0 || this.totalB - this.fluids.b > 0) {
                    this.numbers_animation.restart();
                }
            } else {
                this.numbers_animation.pause()
                gsap.to('.numbers--panel', {
                    opacity: 1,
                })
                if (!this.protocol_locked) {
                    this.lock_animation.pause();
                    gsap.to('.lock--panel', {
                        fill: 'black',
                    })
                }

                if (!this.check_air) {
                    this.button_check_air_animation.pause()
                    gsap.to('.check-off--panel', {
                        opacity: 1,
                    })
                }
            }
        },
        handleAutoPumpingAnimation() {
            console.log(1);
            gsap.to('.move-off--panel', {opacity: 1,})
            let tl = gsap.timeline({defaults: {duration: 1, delay: 0.2}})
            tl.to('.prime-off--panel', {opacity: 0, duration: 0})
                .to('.prime-off--panel', {opacity: 1, duration: 0, delay: 1.5})
        },
    },
}