<template>
    <div>
        <SequenceChunkWrapper
                v-slot="{processChunkAction: validate_enter_parameter_value_Volume_Contrast_A, isActive: isActive_enter_parameter_value_Volume_Contrast_A}"
                sequence-id="protocol_creation"
                step-id="enter_parameter_value_Volume_Contrast_A"
                :key="'enter_parameter_value_Volume_Contrast_A' + $store.getters['sequences_store/getActiveStepId'] + ($store.getters['sequences_store/getActiveChunksIds'] || []).join('a')"
        >
            <SequenceChunkWrapper
                    v-slot="{processChunkAction: validate_enter_parameter_value_Volume_Contrast_B, isActive: isActive_enter_parameter_value_Volume_Contrast_B}"
                    sequence-id="protocol_creation"
                    step-id="enter_parameter_value_Volume_Contrast_B"
                    key="enter_parameter_value_Volume_Contrast_B"
            >
                <SequenceChunkWrapper
                        v-slot="{processChunkAction: validate_enter_parameter_value_Flow_Contrast_A, isActive: isActive_enter_parameter_value_Flow_Contrast_A}"
                        sequence-id="protocol_creation"
                        step-id="enter_parameter_value_Flow_Contrast_A"
                        key="enter_parameter_value_Flow_Contrast_A"
                >
                    <SequenceChunkWrapper
                            v-slot="{processChunkAction: validate_enter_parameter_value_Flow_Contrast_B, isActive: isActive_enter_parameter_value_Flow_Contrast_B}"
                            sequence-id="protocol_creation"
                            step-id="enter_parameter_value_Flow_Contrast_B"
                            key="enter_parameter_value_Flow_Contrast_B"
                    >
                        <SequenceChunkWrapper
                                v-slot="{processChunkAction: validate_enter_parameter_value_Duration_Pause, isActive: isActive_enter_parameter_value_Duration_Pause}"
                                sequence-id="protocol_creation"
                                step-id="enter_parameter_value_Duration_Pause"
                                key="enter_parameter_value_Duration_Pause"
                        >
                            <SequenceChunkWrapper
                                    v-slot="{processChunkAction: validate_enter_parameter_value_Volume_AB, isActive: isActive_enter_parameter_value_Volume_AB}"
                                    sequence-id="protocol_creation"
                                    step-id="enter_parameter_value_Volume_AB"
                                    key="enter_parameter_value_Volume_AB"
                            >
                                <SequenceChunkWrapper
                                        v-slot="{processChunkAction: validate_enter_parameter_value_Flow_AB, isActive: isActive_enter_parameter_value_Flow_AB}"
                                        sequence-id="protocol_creation"
                                        step-id="enter_parameter_value_Flow_AB"
                                        key="enter_parameter_value_Flow_AB"
                                >
                                    <SequenceChunkWrapper
                                            v-slot="{processChunkAction: validate_enter_contrast_substance_AB, isActive: isActive_enter_contrast_substance_AB}"
                                            sequence-id="protocol_creation"
                                            step-id="enter_contrast_substance_AB"
                                            key="enter_contrast_substance_AB"
                                    >
                                        <div class="input"
                                             @click="function($event) {
                                         const fn = () => calculate($event.target,
                                                            isActive_enter_parameter_value_Volume_Contrast_A
                                                            || isActive_enter_parameter_value_Volume_Contrast_B
                                                            || isActive_enter_parameter_value_Flow_Contrast_A
                                                            || isActive_enter_parameter_value_Flow_Contrast_B
                                                            || isActive_enter_parameter_value_Duration_Pause
                                                            || isActive_enter_parameter_value_Volume_AB
                                                            || isActive_enter_parameter_value_Flow_AB
                                                            || isActive_enter_contrast_substance_AB)

                                validate_enter_parameter_value_Volume_Contrast_A(fn, {mustReturnTrue: true, enabledIfEducationIsInactive: true})
                                validate_enter_parameter_value_Volume_Contrast_B(fn, {mustReturnTrue: true})
                                validate_enter_parameter_value_Flow_Contrast_A(fn, {mustReturnTrue: true})
                                validate_enter_parameter_value_Flow_Contrast_B(fn, {mustReturnTrue: true})
                                validate_enter_parameter_value_Duration_Pause(fn, {mustReturnTrue: true})
                                validate_enter_parameter_value_Volume_AB(fn, {mustReturnTrue: true})
                                validate_enter_parameter_value_Flow_AB(fn, {mustReturnTrue: true})
                                validate_enter_contrast_substance_AB(fn, {mustReturnTrue: true})
                                     }"
                                             @pointerdown=" function($event) {
                                     const fn = () => dualFlow($event.target,
                                                            isActive_enter_parameter_value_Volume_Contrast_A
                                                            || isActive_enter_parameter_value_Volume_Contrast_B
                                                            || isActive_enter_parameter_value_Flow_Contrast_A
                                                            || isActive_enter_parameter_value_Flow_Contrast_B
                                                            || isActive_enter_parameter_value_Duration_Pause
                                                            || isActive_enter_parameter_value_Volume_AB
                                                            || isActive_enter_parameter_value_Flow_AB
                                                            || isActive_enter_contrast_substance_AB)

                                validate_enter_parameter_value_Volume_Contrast_A(fn, {mustReturnTrue: true, enabledIfEducationIsInactive: true})
                                validate_enter_parameter_value_Volume_Contrast_B(fn, {mustReturnTrue: true})
                                validate_enter_parameter_value_Flow_Contrast_A(fn, {mustReturnTrue: true})
                                validate_enter_parameter_value_Flow_Contrast_B(fn, {mustReturnTrue: true})
                                validate_enter_parameter_value_Duration_Pause(fn, {mustReturnTrue: true})
                                validate_enter_parameter_value_Volume_AB(fn, {mustReturnTrue: true})
                                validate_enter_parameter_value_Flow_AB(fn, {mustReturnTrue: true})
                                validate_enter_contrast_substance_AB(fn, {mustReturnTrue: true})
                            }"
                                             :class="setEvent"
                                        >
                                            <div class="input__display" v-if="getRange">
                                                <p>Диапазон параметра</p>
                                                <p>{{ getRange }}</p>
                                            </div>
                                            <table class="input__numbers">
                                                <tbody>
                                                <tr class="input__row row">
                                                    <td class="row__button">
                                                        <button class="btn-input">7</button>
                                                    </td>
                                                    <td class="row__button">
                                                        <button class="btn-input">8</button>
                                                    </td>
                                                    <td class="row__button">
                                                        <button class="btn-input">9</button>
                                                    </td>
                                                </tr>
                                                <tr class="input__row row">
                                                    <td class="row__button">
                                                        <button class="btn-input">4</button>
                                                    </td>
                                                    <td class="row__button">
                                                        <button class="btn-input">5</button>
                                                    </td>
                                                    <td class="row__button">
                                                        <button class="btn-input">6</button>
                                                    </td>
                                                </tr>
                                                <tr class="input__row row">
                                                    <td class="row__button">
                                                        <button class="btn-input">1</button>
                                                    </td>
                                                    <td class="row__button">
                                                        <button class="btn-input">2</button>
                                                    </td>
                                                    <td class="row__button">
                                                        <button class="btn-input">3</button>
                                                    </td>
                                                </tr>
                                                <tr class="input__row row" v-if="getRange == '0.1 - 10 (0.1) ml/s'">
                                                    <td class="row__button">
                                                        <button class="btn-input">,</button>
                                                    </td>
                                                    <td class="row__button">
                                                        <button class="btn-input">0</button>
                                                    </td>
                                                    <td class="row__button">
                                                        <button class="btn-input" data-delete="delete"><span
                                                                class="fas fa-backspace"></span></button>
                                                    </td>
                                                </tr>
                                                <tr class="input__row row" v-else>
                                                    <td class="row__button alternative">
                                                        <button class="btn-input">0</button>
                                                    </td>
                                                    <td class="row__button alternative">
                                                        <button class="btn-input" data-delete="delete"><span
                                                                class="fas fa-backspace"></span></button>
                                                    </td>
                                                </tr>
                                                <tr class="input__row row">
                                                    <td class="row__button action">
                                                        <button class="btn-input">Отменить</button>
                                                    </td>
                                                    <td class="row__button action">
                                                        <button class="btn-input" :class="setColor">Ввод</button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </SequenceChunkWrapper>
                                </SequenceChunkWrapper>
                            </SequenceChunkWrapper>
                        </SequenceChunkWrapper>
                    </SequenceChunkWrapper>
                </SequenceChunkWrapper>
            </SequenceChunkWrapper>
        </SequenceChunkWrapper>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex'

    export default {

        data() {
            return {
                propValue: '',
            }
        },

        methods: {
            calculate(target, isEducationModeOnThisStep) {
                if (!this.getRange) {
                    return;
                }


                if (!target.closest('button')) {
                    return;
                }

                if (this.propValue !== '') {
                    this.propValue = this.getRelatedValue;
                }

                if (target.classList.contains('fas') || target.dataset.delete) {
                    let spliced = this.propValue.split('');
                    spliced.splice(-1, 1);
                    this.propValue = spliced.join('');

                    if (this.getRange === '00:01 - 15:00 (1) sec') {
                        this.$store.dispatch('validatePause', this.propValue);
                    } else if (this.getRange === '00:01 - 20:00 (1) mm:ss') {
                        this.$store.dispatch('validateReminder', this.propValue);
                    } else {
                        this.$store.dispatch('validate', this.propValue);
                    }


                } else if (target.innerHTML === 'Отменить') {
                    if (isEducationModeOnThisStep)
                        return
                    if (this.getRange === '00:01 - 15:00 (1) sec') {
                        this.$store.dispatch('inputTimeCancel')
                    } else if (this.getRange === '00:01 - 20:00 (1) mm:ss') {
                        this.$store.dispatch('inputReminderCancel')
                    } else {
                        this.$store.dispatch('inputCancel');
                    }
                    this.$store.dispatch('setSelectedRowButton', null);
                } else if (target.innerHTML === 'Ввод') {
                    this.$store.dispatch('setSelectedRowButton', null);
                    if (this.getRange === '00:01 - 15:00 (1) sec') {
                        let sec;
                        let min;
                        if (this.propValue === '0000' || this.propValue === '000') {
                            this.propValue = 0;
                        }
                        if (this.propValue.toString().length <= 2) {
                            if (this.propValue !== '' && this.propValue < 60 && this.propValue > 0) {
                                this.$store.dispatch('inputTime', this.propValue)
                                if (isEducationModeOnThisStep) return true
                            }
                        } else if (this.propValue.toString().length === 3) {
                            min = this.propValue.slice(0, 1);
                            sec = this.propValue.slice(1);

                            if (this.propValue !== '' && min <= 15 && sec <= 59) {
                                this.$store.dispatch('inputTime', this.propValue)
                                if (isEducationModeOnThisStep) return true
                            }
                        } else {
                            min = this.propValue.slice(0, 2);
                            sec = this.propValue.slice(2);
                            if (this.propValue !== '' && min <= 15 && sec <= 59) {
                                this.$store.dispatch('inputTime', this.propValue)
                                if (isEducationModeOnThisStep) return true
                            }
                        }
                    } else if (this.getRange === '00:01 - 20:00 (1) mm:ss') {
                        let sec;
                        let min;
                        if (this.propValue === '0000' || this.propValue === '000') {
                            this.propValue = 0;
                        }
                        if (this.propValue.toString().length <= 2) {
                            this.propValue !== '' && this.propValue < 60 && this.propValue > 0 ? this.$store.dispatch('inputReminder', this.propValue) : true;
                        } else if (this.propValue.toString().length === 3) {
                            min = this.propValue.slice(0, 1);
                            sec = this.propValue.slice(1);

                            this.propValue !== '' && min <= 20 && sec <= 59 ? this.$store.dispatch('inputReminder', this.propValue) : true;
                        } else {
                            min = this.propValue.slice(0, 2);
                            sec = this.propValue.slice(2);
                            this.propValue !== '' && min <= 20 && sec <= 59 ? this.$store.dispatch('inputReminder', this.propValue) : true;
                        }
                    } else {
                        this.$store.dispatch('input')
                        if (isEducationModeOnThisStep)
                            return true
                    }
                } else {
                    if (this.propValue === 0) {

                        if (this.getRange === '00:01 - 15:00 (1) sec') {
                            this.$store.dispatch('validatePause', this.propValue);

                        } else if (this.getRange === '00:01 - 20:00 (1) mm:ss') {
                            this.$store.dispatch('validateReminder', this.propValue);
                        } else {
                            this.propValue = '';
                            this.$store.dispatch('validate', this.propValue);
                        }
                    } else {
                        this.propValue += String(target.innerHTML);
                        if (this.getRange === '00:01 - 15:00 (1) sec') {
                            this.$store.dispatch('validatePause', this.propValue);
                        } else if (this.getRange === '00:01 - 20:00 (1) mm:ss') {
                            this.$store.dispatch('validateReminder', this.propValue);
                        } else {
                            this.$store.dispatch('validate', this.propValue);
                        }
                    }

                }
            },
            dualFlow(target, isEducationModeOnThisStep) {
                if (this.getRange) {
                    return;
                }

                if (!target.closest('button')) {
                    return;
                }

                if (this.propValue !== '') {
                    this.propValue = this.getRelatedValue;
                }

                if (target.classList.contains('fas') || target.dataset.delete) {
                    let spliced = this.propValue.split('');
                    spliced.splice(-1, 1);
                    this.propValue = spliced.join('');

                    if (!this.propValue) this.propValue = '';

                    this.$store.dispatch('validateDual', this.propValue);
                } else if (target.innerHTML == 'Отменить') {
                    if (!isEducationModeOnThisStep)
                        this.$store.dispatch('inputDualCancel');
                } else if (target.innerHTML == 'Ввод') {
                    if (this.getContrast % 5 === 0) {
                        if (isEducationModeOnThisStep) {
                            this.$store.dispatch('inputDual');
                            return true
                        }
                        this.$store.dispatch('inputDual');
                    }
                } else {
                    if (this.propValue === 0) {
                        this.propValue = '';
                        this.$store.dispatch('validateDual', this.propValue);
                    }
                    this.propValue += String(target.innerHTML);
                    this.$store.dispatch('validateDual', this.propValue);
                }
            },
        },

        watch: {
            getTarget() {
                this.propValue = '';
            },
        },

        computed: {
            ...mapGetters([
                'getRange',
                'getTarget',
                'getRelatedValue',
                'getContrast',
                'getCalcEvents'
            ]),
            setColor() {
                if (this.getRange === '00:01 - 20:00 (1) mm:ss') {
                    return {
                        'yellow-btn': true,
                    }
                } else {
                    return {
                        'yellow-btn': false,
                    }
                }
            },
            setEvent() {
                return {
                    events: this.getCalcEvents,
                }
            },
        }

    }
</script>

<style scoped lang="scss">
    .input {
        background-color: #E0DDD8;
        width: 100%;
        border: 1px solid black;
        border-radius: 5%;
        box-shadow: 0px 0px 5px 0px rgba(15, 8, 8, 0.68);
        font-family: Open Sans;
        padding: 10px;

        &__display {
            font-size: 0.68rem;
            font-weight: bold;
            color: #FFF;
            background-color: #2F4F4F;
            text-align: left;
            padding: 0.5rem;
            border-radius: 5%;
            margin: 0 0 0.5rem 0;

            p {
                padding: 0.3rem 0;
            }


            p:last-child {
                font-weight: 400;
            }
        }

        &__numbers {
            width: 100%;
        }

        &__row {
            display: flex;
            margin: 0 -0.25rem 0.25rem;
        }


        .row {
            &__button {
                flex: 0 0 33.333%;
                padding: 0 2px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;


                .btn-input {
                    width: 100%;
                    font-size: 1.5rem;
                    font-weight: 700;
                    border: 1px solid rgba(110, 121, 128, .8);
                    border-top-color: rgba(0, 0, 0, .3);
                    box-shadow: 0 -1px rgba(10, 21, 28, .9) inset,
                    0 1px rgba(255, 255, 255, .5) inset;
                    padding: 0.3rem;
                    background: #E0DDD8;
                    transition-duration: 0.1s;
                }

                .btn-input:active {
                    box-shadow: none;
                }

                .btn-input:hover {
                    background: #C6C3BF;
                }

                .yellow-btn {
                    background: #FFFF99;

                    &:hover {
                        background: #E5E589;
                    }

                    &:active {
                        box-shadow: none;
                    }
                }
            }

            .action {
                flex: 0 0 50%;

                .btn-input {
                    font-size: 0.8rem;
                    padding: 0.8rem 0;
                }
            }

            .alternative {
                flex: 0 0 66.666%;
            }

            .alternative:last-child {
                flex: 0 0 33.333%;
            }
        }
    }

    .events {
        pointer-events: none;
    }
</style>