var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-modal second-level"},[_c('button',{staticClass:"btn settings-btn"},[_c('img',{attrs:{"src":require("../../img/components/settings_icons/settings.svg")}}),_c('SequenceChunkWrapper',{key:"select_section_system",attrs:{"sequence-id":"setup_system","step-id":"select_section_system"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_setup_system_select_section_system = ref.processChunkAction;
return [_c('a',{on:{"click":function($event){processChunkAction_setup_system_select_section_system(function () { return _vm.$router.push({name: 'SystemSettings'}); }, {enabledIfEducationIsInactive: true})}}},[_c('span',{staticClass:"name-btn"},[_vm._v("Система")]),_c('span',{staticClass:"value"},[_vm._v("Настройка")])])]}}])})],1),_c('button',{staticClass:"btn settings-btn"},[_c('img',{attrs:{"src":require("../../img/components/settings_icons/P3T.svg")}}),_c('a',{on:{"click":function($event){!_vm.isEducationModeEnabled && _vm.$router.push({name: 'SettingsPreset'})}}},[_c('span',{staticClass:"name-btn"},[_vm._v("Предустановка")]),_c('span',{staticClass:"value"},[_vm._v("Настройка")])])]),_c('button',{staticClass:"btn settings-btn"},[_c('img',{attrs:{"src":require("../../img/components/settings_icons/protocol-manage.svg")}}),_c('SequenceChunkWrapper',{key:"select_section_protocol_manager",attrs:{"sequence-id":"setup_protocol_manager","step-id":"select_section_protocol_manager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_setup_protocol_manager_select_section_protocol_manager = ref.processChunkAction;
return [_c('a',{on:{"click":function () { return processChunkAction_setup_protocol_manager_select_section_protocol_manager(
                        function () { return _vm.$router.push({name: 'ManagerSettings'}); }, {enabledIfEducationIsInactive: true}); }}},[_c('span',{staticClass:"name-btn"},[_vm._v("Менеджер протоколов")]),_c('span',{staticClass:"value"},[_vm._v("Настройка")])])]}}])})],1),_c('button',{staticClass:"btn settings-btn"},[_c('img',{attrs:{"src":require("../../img/components/settings_icons/delivery.svg")}}),_c('SequenceChunkWrapper',{key:"select_section_liquid_delivery",attrs:{"sequence-id":"liquid_delivery","step-id":"select_section_liquid_delivery"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var processChunkAction_liquid_delivery_select_section_liquid_delivery = ref.processChunkAction;
return [_c('a',{on:{"click":function($event){processChunkAction_liquid_delivery_select_section_liquid_delivery(function () { return _vm.$router.push({name: 'LiquidDelivery'}); }, {enabledIfEducationIsInactive: true})}}},[_c('span',{staticClass:"name-btn"},[_vm._v("Доставка жидкости")]),_c('span',{staticClass:"value"},[_vm._v("Настройка")])])]}}])})],1),_c('button',{staticClass:"btn settings-btn"},[_c('img',{attrs:{"src":require("../../img/components/settings_icons/info-sys.svg")}}),_c('a',{on:{"click":function($event){!_vm.isEducationModeEnabled && _vm.$router.push({name: 'InformationSystem'})}}},[_c('span',{staticClass:"name-btn"},[_vm._v("Информационная система")]),_c('span',{staticClass:"value"},[_vm._v("Настройка")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }