var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-modal"},[_c('SequenceChunkWrapper',{key:"select_section_settings",attrs:{"sequence-id":"liquid_delivery","step-id":"select_section_settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_liquid_delivery_select_section_settings = ref.processChunkAction;
return [_c('SequenceChunkWrapper',{key:"select_section_settings",attrs:{"sequence-id":"setup_system","step-id":"select_section_settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_setup_system_select_section_settings = ref.processChunkAction;
return [_c('SequenceChunkWrapper',{key:"select_section_settings",attrs:{"sequence-id":"setup_protocol_manager","step-id":"select_section_settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_setup_protocol_manager_setup_system_select_section_settings = ref.processChunkAction;
return [_c('button',{staticClass:"btn settings-btn",class:_vm.selected,on:{"pointerdown":function () {
                processChunkAction_liquid_delivery_select_section_settings(function () { return _vm.showSettings(); }, {enabledIfEducationIsInactive: true})
                processChunkAction_setup_system_select_section_settings(function () { return _vm.showSettings(); })
                processChunkAction_setup_protocol_manager_setup_system_select_section_settings(function () { return _vm.showSettings(); })
            }}},[_c('img',{attrs:{"src":require("../../img/components/settings_icons/settings.svg")}}),_c('span',[_vm._v("настройка")])])]}}],null,true)})]}}],null,true)})]}}])}),_c('button',{staticClass:"btn settings-btn"},[_c('a',{on:{"click":function($event){!_vm.isEducationModeEnabled && _vm.$router.push({name: 'VirtualCare'})}}},[_c('img',{attrs:{"src":require("../../img/components/settings_icons/Virtual-Care.svg")}}),_c('span',[_vm._v("Virtual Care")])])]),_c('button',{staticClass:"btn settings-btn"},[_c('a',{on:{"click":function($event){!_vm.isEducationModeEnabled && _vm.$router.push({name: 'LicenseManager'})}}},[_c('img',{attrs:{"src":require("../../img/components/settings_icons/manager-lic.svg")}}),_c('span',[_vm._v("Менеджер лицензий")])])]),_c('button',{staticClass:"btn settings-btn"},[_c('a',{on:{"click":function($event){!_vm.isEducationModeEnabled && _vm.$router.push({name: 'Reference'})}}},[_c('img',{attrs:{"src":require("../../img/components/settings_icons/ref.svg")}}),_c('span',[_vm._v("Справка")])])]),_c('button',{staticClass:"btn settings-btn",on:{"click":_vm.showShutdown}},[_c('img',{attrs:{"src":require("../../img/components/settings_icons/off.svg")}}),_c('span',[_vm._v("Выключение")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }