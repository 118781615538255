<template>
    <div class="container">
        <div class="computer">
            <div v-if="!workstation_is_on" key="off" class="screen-black"></div>
            <div v-else class="screen" key="enabled">
                <div class="license-manger">
                    <div class="license-manger__block header">
                        <div>
                            <img src="../img/license-manager.svg" alt="">
                            <h3 class="header__title">Менеджер лицензий</h3>
                        </div>
                        <router-link :to="{name: 'WorkStation'}"
                                     tag="button"
                                     class="header__btn"
                        >Выход</router-link>
                    </div>
                    <div class="license-manger__block top-block">
                        <p>Чтобы активировать лицензию или изменить срок действия существующих лицензий, обратитесь в службу поддержки Bayer для получения кода активации.</p>
                        <p>Примечание. Система автоматически перезапустится после применения кода активации.</p>
                        <div>
                            <span>MAC Адрес</span>
                            <span class="bordered">C400-AD35-46CB</span>
                            <span>Код активации</span>
                            <input type="text">
                            <button class="btn">Активировать лицензию</button>
                        </div>
                    </div>
                    <div class="license-manger__block bottom-block">
                        <table class="table table-bordered">
                            <tr>
                                <th>Лицензия</th>
                                <th>Срок действия лицензии</th>
                                <th>Оставшийся срок</th>
                            </tr>
                            <tr v-for="obj in licenses">
                                <td>
                                    <input type="checkbox"
                                           v-bind:checked="obj.checked"
                                           id="checkbox"
                                    >
                                    <label>{{obj.license_name}}</label>
                                </td>
                                <td>
                                    <select>
                                      <option v-bind:selected="obj.selected"
                                              v-bind:disabled="obj.disabled"
                                      >
                                          {{obj.select_name}}
                                      </option>
                                    </select>
                                </td>
                            </tr>

                        </table>
                        <button class="btn">Вернуться к текущим лицензиям</button>
                    </div>
                </div>
            </div>
            <div class="stand">
                <comp-lamp class="light" :class="isLightOn"/>
                <input type="checkbox" class="stand__power-button" @input="switchOnComp(true)">
            </div>
        </div>
        <router-modal/>
    </div>
</template>

<script>

    import {mapGetters, mapState} from 'vuex';

    import CompLamp from '@/components/AppLamp'
    import RouterModal from '@/components/modals/RouterModal'

    export default {
        components: {
            CompLamp,
            RouterModal
        },
        data() {
            return {
                licenses: [
                    {
                        license_name: 'P3T BUNDLE FOR CWS - A,C,PA',
                        checked: true,
                        select_name: 'Permanent',
                        selected: true,
                        disabled: true,
                    },
                    {
                        license_name: 'LICENSE ITEM, SW,P3T,ABDOMEN',
                        checked: true,
                        select_name: 'Permanent',
                        selected: true,
                        disabled: true,
                    },
                    {
                        license_name: 'LICENSE ITEM, SW,P3T,CARDIAC',
                        checked: true,
                        select_name: 'Permanent',
                        selected: true,
                        disabled: true,
                    },
                    {
                        license_name: 'LICENSE ITEM, SW,P3T,PA',
                        checked: true,
                        select_name: 'Permanent',
                        selected: true,
                        disabled: true,
                    },
                    {
                        license_name: 'SYNCRIGHT-CONNECT.CT',
                        checked: false,
                        select_name: '----',
                        selected: true,
                        disabled: true,
                    },
                    {
                        license_name: 'INFORMATICS STARTER PACKKAGE SW (ST2)',
                        checked: false,
                        select_name: '----',
                        selected: true,
                        disabled: true,
                    },
                    {
                        license_name: 'LIC, INTERFACE OB OPEN HL7',
                        checked: false,
                        select_name: '----',
                        selected: true,
                        disabled: true,
                    },
                    {
                        license_name: 'License for Nuance/SR',
                        checked: false,
                        select_name: '----',
                        selected: true,
                        disabled: true,
                    },
                    {
                        license_name: 'LIC, Multiple Accession Number',
                        checked: false,
                        select_name: '----',
                        selected: true,
                        disabled: true,
                    },

                ]
            }
        },
        methods: {
            switchOnComp() {
                if (!this.workstation_is_on) {
                    this.$router.push({name: 'WorkStation'});
                    this.$store.commit('switchStateOfWorkstation', {
                        state: true,
                        confirm: true,
                    });
                }
            },
        },
        computed: {
            ...mapGetters([
                'workstation_is_on'
            ]),
            isLightOn() {
                return {
                    on: this.workstation_is_on,
                }
            },
        },

    }
</script>

<style scoped lang="scss">
    @import "../scss/pages/license-manager";
    @import "../scss/style";
    @import "../scss/pages/system-settings";
</style>