<template>
    <div class="script-item" :class="stylePause">

        <template v-if="row.type == 'A'">
            <script-button class="manager-button first" :letter="row.type"/>
            <flow-button class="manager-button" :flowRate="row.flowRate"/>
            <volume-button class="manager-button" :volume="row.volume"/>
            <duration-button class="manager-button duration" :duration="row.duration" :key="row.type"/>
        </template>

        <template v-else-if="row.type=='B'">
            <script-button class="b b-script manager-button first" :letter="row.type"/>
            <flow-button class="b manager-button" :flowRate="row.flowRate"/>
            <volume-button class="b manager-button" :volume="row.volume"/>
            <duration-button class="manager-button duration" :duration="row.duration" :key="row.type"/>
        </template>

        <template v-else-if="row.type =='Test'">
            <div :class="rowType">Пробное введение</div>
        </template>

        <template v-else-if="row.type == 'Question'">
            <script-test class="manager-button first gray" letter="?"/>
        </template>

        <template v-else-if="row.type == 'dualFlow'">
            <script-button class="dual dual-script manager-button first" letter="%"/>
            <flow-button class="dualflow manager-button" :flowRate="row.flowRate"/>
            <volume-button class="dualflow manager-button" :manager="true" :volume="row.volume" :contrast="row.contrast" :saline="row.saline"/>
            <duration-button class="manager-button duration" :duration="row.duration" :key="row.type"/>
        </template>
        <template v-else-if="row.type == 'Pause'">
            <script-test class="manager-button first gray" :index="index"/>
            <div class="script-text">Пауза</div>
            <pause-button class="manager-button duration" :index="index" :duration="row.duration" :key="row.type"/>
        </template>
        <template v-else-if="row.type == 'Awaiting'">
            <div class="awaiting">Ожидание</div>
        </template>
    </div>
</template>
<script>

    import FlowButton from '@/components/rowbuttons/FlowRate'
    import ScriptButton from '@/components/rowbuttons/Script'
    import ScriptTest from '@/components/rowbuttons/ScriptTest'
    import VolumeButton from '@/components/rowbuttons/Volume'
    import DurationButton from '@/components/rowbuttons/Duration'
    import PauseButton from '@/components/rowbuttons/PauseButton'
    import AwaitingButton from '@/components/rowbuttons/AwaitingButton'

    import {mapState, mapGetters} from 'vuex'

    export default {

        props: ['row', 'index'],
        components: {
            FlowButton,
            ScriptButton,
            ScriptTest,
            VolumeButton,
            DurationButton,
            AwaitingButton,
            PauseButton,
        },
        computed: {
            ...mapGetters([
                'getRows',
            ]),
            rowType() {
                if (this.row.type === 'Test') {
                    if (this.getRows[this.index-1]?.type === 'B') {
                    return {
                        'text-saline': true,
                    }
                    } else {
                        return {
                            'text-contrast': true,
                        }
                    }
                }
            },
            stylePause() {
                if (this.row.type == 'Pause' || this.row.type == 'Awaiting') {
                    return {
                        'script-pause': true,
                    };
                } else {
                    return {
                        'script-pause': false,
                    };
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../scss/components/manager-row";
</style>