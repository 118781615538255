import { mixer } from '@/sequences/mixer'
import { superMegaCoolErrorStyle } from '@/utils/misc'

class ABCSequenceCustomError extends Error {
    constructor(message) {
        super(message)
        this.name = this.constructor.name
        console.error(`%c${this.constructor.name}: ${message}`, superMegaCoolErrorStyle)
    }
}

class SequenceElementInitializationError extends ABCSequenceCustomError {}

class ABCSequenceElement {
    throwInitializationError(message) {
        throw new SequenceElementInitializationError(`\nError while initializing ${this.constructor.name}.\n${message}`)
    }
}

export class ABCSequenceElementDefinition extends ABCSequenceElement {}


export class ABCSequenceElementInstance extends ABCSequenceElement {
    #parent
    constructor({
        parent,
        definition
    }) {
        super()
        this.#parent = parent
        this.definition = definition
    }

    get parent() {
        return this.#parent()
    }
}

export const assignInstanceConstructor = (instanceConstructor, superClass) => {
    class assignInstanceConstructorMixin extends superClass {
        constructor(...args) {
            super(...args)
        }

        generateInstance({
            parent,
            ...restOptions
        } = {}) {
            return new instanceConstructor({...restOptions, parent: () => parent, definition: this})
        }
    }
    return assignInstanceConstructorMixin
}