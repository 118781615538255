<template>
        <button  class="area-btn head-btn btn"
                 @pointerdown="selectPart(part)"
                 :class="isSelected"
                 :style="hidden"

        >{{part}}</button>
</template>

<script>

    export default {
        name: "HeadButton",
        props: {
            part: String,
            selected: Boolean,
            index: Number,
            isHidden: Boolean,
        },
        methods: {
            selectPart() {
                this.$emit('select-part', {
                    part: this.part,
                    index: this.index,
                    isHidden: this.isHidden,
                });
            }
        },
        computed: {
            isSelected() {
                return {

                    selected: this.selected,
                }
            },
            hidden(){
                if (this.isHidden) {
                    return {
                        background: 'gray',
                        opacity: 0.5,
                    }
                }

            },
        }
    }
</script>

<style scoped lang="scss">

    @import "../scss/mixin";
    @import "../scss/variable";

    .btn {
        @include btn;
        z-index: 1;
        background: rgba(255, 255, 255, 0.4);
        width: 120px;
    }
    .selected {
        background: $yellow;
    }

</style>