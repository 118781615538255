import '@/composition_api_setup'
import {
    ABCSequenceElementDefinition,
    ABCSequenceElementInstance,
    assignInstanceConstructor
} from '@/sequences/abc_sequence_element'
import { isArray, cloneDeep, isFunction } from 'lodash'
import { SequenceStepDefinition } from '@/sequences/SequenceStep/base_classes'
import { computed, ref } from '@vue/composition-api'
import { store } from '@/store'
import router from '@/router/router'
import { SEQUENCE_ENDED } from '@/store/modules/sequences'


export class SequenceInstance extends ABCSequenceElementInstance {
    constructor(...args) {
        super(...args)
        this.passedSteps = ref([])
        this.remainingSteps = ref(this.definition.steps
            .map(step => step.generateInstance({parent: this})))

        this.activeStep = computed(() => this.remainingSteps.value[0])
        this.init()
    }

    async init() {
        if (this.definition.beforeStart)
           await Promise.resolve(this.definition.beforeStart())


    }

    takeSnapshot(stepId) {
        for (let step of this.remainingSteps.value)
            if (step.definition.id === stepId) {
                const {sequences_store, ...restState} = store.state
                step.saveSnapshot({
                    routerFullPath: router.currentRoute.fullPath,
                    storeState: cloneDeep(restState),
                    passedSteps: this.passedSteps.value,
                    remainingSteps: this.remainingSteps.value
                })
                break
            }
    }

    nextStep() {
        if (this.remainingSteps.value.length) {
            const passedStep = this.remainingSteps.value[0]
            if (passedStep.definition?.after)
                passedStep.definition?.after?.()
            this.passedSteps.value = [...this.passedSteps.value, passedStep]
            this.remainingSteps.value = this.remainingSteps.value.slice(1)
        }

        if (!this.remainingSteps.value.length) {
            return SEQUENCE_ENDED
        }
    }

    previousStep() {
        const previousStep = this.passedSteps.value[this.passedSteps.value.length - 1]
        const currentStep = this.activeStep.value

        const previousSnapshot = previousStep.snapshot
        if (router.currentRoute.fullPath !== previousSnapshot.routerFullPath)
            router.push({path: previousSnapshot.routerFullPath})
        const newState = {
            ...previousSnapshot.storeState,
            sequences_store: store.state.sequences_store
        }

        store.replaceState(newState)
        this.passedSteps.value = previousSnapshot.passedSteps
        this.remainingSteps.value = previousSnapshot.remainingSteps

        previousStep.clearSnapshot()

        previousStep.restoreChunks()
        currentStep.restoreChunks()

        return previousStep
    }
}


export class SequenceDefinition extends assignInstanceConstructor(SequenceInstance, ABCSequenceElementDefinition) {
    constructor({
        id,
        prettyName,
        steps,
        beforeStart,
        persistentHeader
    } = {}) {
        super()
        this.id = id
        this.prettyName = prettyName
        this.steps = steps
        this.beforeStart = beforeStart
        this.persistentHeader = persistentHeader

        this.#validateAfterInitialization()
    }

    #validateAfterInitialization() {
        if (!isArray(this.steps))
            this.throwInitializationError(`steps must be array`)

        this.steps.some((step, index, steps) => {
            if (!(step instanceof SequenceStepDefinition))
                this.throwInitializationError(`Step must be instance of ${SequenceStepDefinition.name}`)
            if (steps.findIndex(({id}) => id === step.id) !== index)
                this.throwInitializationError(`Duplicated steps ids: ${step.id}`)
        })

        if (this.beforeStart && !isFunction(this.beforeStart)) {
            this.throwInitializationError(`beforeStart should be function!`)
        }
    }

    get stepById() {
        return Object.fromEntries(this.steps.map(step => [step.id, step]))
    }
}
