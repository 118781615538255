<template>
    <div>
        <button class="volume">
            - -
        </button>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex'

    export default {
        name: "Pause",
        props: {
            duration: String,
        },
    }
</script>

<style scoped lang="scss">

    @import "@/scss/variable";
    @import "@/scss/mixin";
    div {
        color: white;

        .volume {

        background: rgba(255, 255, 255, 0.1);
        width: 78px;
        height: 36px;
        font-size: 24px;
        font-weight: normal;
        cursor: default;
        color: inherit;
        }
    }
</style>