<template>
    <input type="range" min="-10" max="10" :value="value" step="1" @input="onRange">
</template>

<script>

    export default {
        name: "Range",
        props: {
            value: [String, Number],
        },
        methods: {
            onRange(e) {
                this.$emit('on-range', e.target.value);
            }
        },
        watch: {
            value() {
                this.$emit('on-range', this.value);
            }
        }
    }
</script>

<style scoped lang="scss">

    @import "../scss/variable";

    input[type=range] {
        /*width: 100%;*/
        margin: 15px 0;
        background-color: transparent;
        -webkit-appearance: none;
        transform: rotate(-90deg);
    }

    input[type=range]:focus {
        outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
        background: #ffffff;
        border: 1px solid #010101;
        width: 100%;
        height: 4px;
        cursor: pointer;
    }

    input[type=range]::-webkit-slider-thumb {
        margin-top: -14px;
        width: 15px;
        height: 30px;
        background: #ffffff;
        border: 1.8px solid #000000;
        cursor: pointer;
        -webkit-appearance: none;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
        background: #ffffff;
    }

    input[type=range]::-moz-range-track {
        background: #ffffff;
        border: 1px solid #010101;
        width: 100%;
        height: 4px;
        cursor: pointer;
    }

    input[type=range]::-moz-range-thumb {
        width: 15px;
        height: 30px;
        background: #ffffff;
        border: 1.8px solid #000000;
        cursor: pointer;
    }

    input[type=range]::-ms-track {
        background: transparent;
        border-color: transparent;
        border-width: 13.9px 0;
        color: transparent;
        width: 100%;
        height: 4px;
        cursor: pointer;
    }

    input[type=range]::-ms-fill-lower {
        background: #f2f2f2;
        border: 1px solid #010101;
    }

    input[type=range]::-ms-fill-upper {
        background: #ffffff;
        border: 1px solid #010101;
    }

    input[type=range]::-ms-thumb {
        width: 15px;
        height: 30px;
        background: #ffffff;
        border: 1.8px solid #000000;
        cursor: pointer;
        margin-top: 0px;
        /*Needed to keep the Edge thumb centred*/
    }

    input[type=range]:focus::-ms-fill-lower {
        background: #ffffff;
    }

    input[type=range]:focus::-ms-fill-upper {
        background: #ffffff;
    }

    /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
    how to remove the virtical space around the range input in IE*/
    @supports (-ms-ime-align:auto) {
        /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
        input[type=range] {
            margin: 0;
            /*Edge starts the margin from the thumb, not the track as other browsers do*/
        }
    }


</style>