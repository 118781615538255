import { assignInstanceConstructor } from '@/sequences/abc_sequence_element'
import { SequenceStepDefinition, SequenceStepInstance } from '@/sequences/SequenceStep/base_classes'

class SequenceStepWithSingleChunkInstance extends SequenceStepInstance {
    processChunkActionExecution(chunkId) {
        this.passedChunks.value = this.remainingChunks.value
        this.remainingChunks.value = []
        return this.parent.nextStep(chunkId)
    }
}

export class SequenceStepWithSingleChunkDefinition extends assignInstanceConstructor(SequenceStepWithSingleChunkInstance, SequenceStepDefinition) {
    constructor({
        chunk,
        chunks, // just to exclude it from restArgs
        ...restArgs
    } = {}) {
        super({...restArgs, chunks: [chunk]})
    }
}