import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    namespaced: true,
    modules: {},
    state: {
        reminder: false,
        reminder_confirmed: 0,
        showPressure: false,
        modalReset: false,
        showMenu: false,
        showSettings: false,
        showShutdown: false,
        showLiquidModal: false,
        showOptionsModal: false,
        showModalNotification: false,
        showAddFluidsModal: false,
    },
    getters: {
        reminder(state) {
            return state.reminder
        },
        reminder_confirmed(state) {
            return state.reminder_confirmed
        },
        showPressure(state) {
            return state.showPressure
        },
        modalReset(state) {
            return state.modalReset
        },
        showMenu(state) {
            return state.showMenu
        },
        showSettings(state) {
            return state.showSettings
        },
        showShutdown(state) {
            return state.showShutdown
        },
        showLiquidModal(state) {
            return state.showLiquidModal
        },
        showOptionsModal(state) {
            return state.showOptionsModal
        },
        showModalNotification(state) {
            return state.showModalNotification
        },
        showAddFluidsModal(state) {
            return state.showAddFluidsModal
        },


    },
    mutations: {
        changeReminder(state, payload) {
            state.reminder = payload;
        },
        changeReminder_confirmed(state) {
            state.reminder_confirmed++
        },
        changePressureVisibility(state, payload) {
            state.showPressure = payload
        },
        changeModalResetVisibility(state, payload) {
            state.modalReset = payload
        },
        changeMenuVisibility(state, payload) {
            state.showMenu = payload
        },
        changeSettingsVisibility(state, payload) {
            state.showSettings = payload
        },
        changeShutdownVisibility(state, payload) {
            state.showShutdown = payload
        },
        changeLiquidModalVisibility(state, payload) {
            state.showLiquidModal = payload
        },
        changeOptionsModalVisibility(state, payload) {
            state.showOptionsModal = payload
        },
        changeModalNotificationVisibility(state, payload) {
            state.showModalNotification = payload
        },
        changeAddFluidsModalVisibility(state, payload) {
            state.showAddFluidsModal = payload
        },

    },
    actions: {
        changeReminder({commit}, payload) {
            commit('changeReminder', payload)
        },
        changeReminder_confirmed({commit}) {
            commit('changeReminder_confirmed')
        },
        changeMenuVisibility({commit}, payload) {
            commit('changeMenuVisibility', payload)
        },
        changeSettingsVisibility({commit}, payload) {
            commit('changeSettingsVisibility', payload)
        },
        changeShutdownVisibility({commit}, payload) {
            commit('changeShutdownVisibility', payload)
        },
        changeLiquidModalVisibility({commit}, payload) {
            commit('changeLiquidModalVisibility', payload)
        },
        changeOptionsModalVisibility({commit}, payload) {
            commit('changeOptionsModalVisibility', payload)
        },
        changeModalNotificationVisibility({commit}, payload) {
            commit('changeModalNotificationVisibility', payload)
        },
        changeAddFluidsModalVisibility({commit}, payload) {
            commit('changeAddFluidsModalVisibility', payload)
        },
        changePressureVisibility({commit}, payload) {
            commit('changePressureVisibility', payload)
        },
        changeModalResetVisibility({commit}, payload) {
            commit('changeModalResetVisibility', payload)
        },

    }
}