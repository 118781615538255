var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reset-protocol",attrs:{"id":"resetProtocol"}},[_c('div',{staticClass:"reset-protocol-content"},[_c('span',{staticClass:"reset-protocol-header"},[_vm._v(_vm._s(_vm.text))]),_c('SequenceChunkWrapper',{key:"confirm_your_choice_again",attrs:{"sequence-id":"setup_protocol_manager","step-id":"confirm_your_choice_again"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_setup_protocol_manager_confirm_your_choice_again = ref.processChunkAction;
var isActive_setup_protocol_manager_confirm_your_choice_again = ref.isActive;
return [_c('SequenceChunkWrapper',{key:"setup_pm_confirm_your_choice",attrs:{"sequence-id":"setup_protocol_manager","step-id":"setup_pm_confirm_your_choice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_setup_protocol_manager_confirm_your_choice = ref.processChunkAction;
var isActive_setup_protocol_manager_confirm_your_choice = ref.isActive;
return [_c('SequenceChunkWrapper',{key:"confirm_your_choice",attrs:{"sequence-id":"protocol_manager","step-id":"confirm_your_choice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var processChunkAction_protocol_manager_confirm_your_choice = ref.processChunkAction;
var isActive_protocol_manager_confirm_your_choice = ref.isActive;
return [_c('div',{staticClass:"reset-protocol-block-button"},[_c('button',{staticClass:"reset-protocol-button",on:{"pointerdown":function () {
                                                        var fn = function () { return _vm.confirm() || (_vm.link && _vm.$router.push({name: 'WorkStation'})); }
                                                        processChunkAction_setup_protocol_manager_confirm_your_choice_again(fn, {enabledIfEducationIsInactive: true})
                                                        processChunkAction_setup_protocol_manager_confirm_your_choice(fn)
                                                        processChunkAction_protocol_manager_confirm_your_choice(fn)
                                                    }}},[_c('span',[_vm._v("да")])]),_c('button',{staticClass:"reset-protocol-button",on:{"pointerdown":function () {
                                    if (!_vm.isEducationModeEnabled)
                                        { _vm.cancel() }
                                }}},[_c('span',[_vm._v("нет")])])])]}}],null,true)})]}}],null,true)})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }