<template>
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="50px" height="191px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
viewBox="0 0 3.81 14.58"
 xmlns:xlink="http://www.w3.org/1999/xlink"
 xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
 <defs>
  <font id="FontID0" horiz-adv-x="666" font-variant="normal" style="fill-rule:nonzero" font-weight="400">
	<font-face
		font-family="Arial">
		<font-face-src>
			<font-face-name name="Arial"/>
		</font-face-src>
	</font-face>
   <missing-glyph><path d="M0 0z"/></missing-glyph>
   <glyph unicode="0" horiz-adv-x="556" d="M41.995 353.308c0,84.497 8.66636,152.821 25.9991,204.481 17.5017,51.8368 43.3318,91.6652 77.6592,119.493 34.166,27.8353 77.3288,41.6646 129.327,41.6646 38.3302,0 71.9968,-7.66758 101.161,-23.1641 28.9954,-15.4965 52.9969,-37.8308 71.6588,-66.9952 18.6695,-28.9954 33.3286,-64.4983 44.0002,-106.324 10.8329,-41.8336 16.1649,-98.3263 16.1649,-169.155 0,-83.9976 -8.66636,-151.992 -25.83,-203.652 -17.1714,-51.8291 -43.0014,-91.6575 -77.3288,-119.662 -34.166,-27.9966 -77.4978,-41.995 -129.826,-41.995 -68.8315,0 -122.989,24.5009 -162.325,73.664 -47.1579,59.9961 -70.66,157.155 -70.66,291.644zm89.998 -0.330366c0,-117.826 13.6603,-196.153 41.1575,-234.821 27.5049,-38.8296 61.5019,-58.1599 101.83,-58.1599 40.3278,0 74.3248,19.4993 101.822,58.3289 27.5049,38.9986 41.1652,117.157 41.1652,234.652 0,117.818 -13.6603,196.145 -41.1652,234.814 -27.3282,38.8296 -61.6556,58.1599 -102.821,58.1599 -40.3354,0 -72.6653,-17.1637 -96.8281,-51.3297 -30.1632,-43.4931 -45.1603,-124.156 -45.1603,-241.644z"/>
   <glyph unicode="1" horiz-adv-x="556" d="M371.97 0l-87.9927 0 0 560.294c-21.1665,-20.3367 -48.9941,-40.4968 -83.3215,-60.6645 -34.3351,-20.1677 -65.3357,-35.3262 -92.664,-45.3294l0 84.9964c49.1631,22.995 92.1569,50.9917 128.989,83.9899 36.832,32.8292 63.0001,64.6673 78.3276,95.6603l56.6617 0 0 -718.947z"/>
  </font>
 </defs>
 <g id="Слой_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="fluidA">
   <path class="fil0" d="M2.05 14.58l-0.29 0c-0.08,0 -0.14,-0.06 -0.14,-0.13l0 -0.44 -1.28 -0.93 0 -12.72 -0.17 0c-0.09,0 -0.17,-0.08 -0.17,-0.19 0,-0.09 0.08,-0.17 0.17,-0.17l3.46 0c0.09,0 0.18,0.08 0.18,0.17 0,0.11 -0.09,0.19 -0.18,0.19l-0.16 0 0 12.72 -1.29 0.93 0 0.44c0.01,0.08 -0.05,0.13 -0.13,0.13l0 0zm-1.62 -1.55l1.3 0.93 0 0.49c0,0.02 0.02,0.05 0.03,0.05l0.31 0c0.03,0 0.05,-0.03 0.05,-0.05l0 -0.49 1.28 -0.93 0 -12.75 0.24 0c0.07,0 0.1,-0.05 0.1,-0.09 0,-0.05 -0.05,-0.1 -0.1,-0.1l-3.44 0c-0.06,0 -0.09,0.05 -0.09,0.1 0,0.04 0.04,0.09 0.09,0.09l0.25 0 0 12.75 -0.02 0 0 0z"/>
   <path class="fil1" d="M3.64 0.09l-3.45 0c-0.05,0 -0.1,0.05 -0.1,0.08 0,0.06 0.05,0.09 0.1,0.09l0.24 0 0 12.77 1.3 0.93 0 0.49c0,0.02 0.02,0.05 0.03,0.05l0.31 0c0.03,0 0.05,-0.03 0.05,-0.05l0 -0.49 1.28 -0.93 0 -12.75 0.24 0c0.07,0 0.1,-0.05 0.1,-0.09 -0.02,-0.05 -0.05,-0.1 -0.1,-0.1l0 0z"/>
    <path class="fil2" :style="fluids_level" d="M3.64 0.09l-3.45 0c-0.05,0 -0.1,0.05 -0.1,0.08 0,0.06 0.05,0.09 0.1,0.09l0.24 0 0 12.77 1.3 0.93 0 0.49c0,0.02 0.02,0.05 0.03,0.05l0.31 0c0.03,0 0.05,-0.03 0.05,-0.05l0 -0.49 1.28 -0.93 0 -12.75 0.24 0c0.07,0 0.1,-0.05 0.1,-0.09 -0.02,-0.05 -0.05,-0.1 -0.1,-0.1l0 0z"/>
   <path class="fil0" d="M2.19 5.3l-0.07 -0.3 -0.45 0 -0.09 0.3 -0.28 0 0.43 -1.24 0.31 0 0.45 1.24 -0.3 0 0 0zm-0.14 -0.51c-0.09,-0.27 -0.14,-0.42 -0.14,-0.45 -0.01,-0.03 -0.01,-0.06 -0.03,-0.08 -0.01,0.06 -0.06,0.25 -0.15,0.53l0.32 0z"/>
   <path class="fil0" d="M1.82 8.97l-0.14 0 0 -0.29c0,-0.05 0,-0.08 -0.01,-0.09 -0.02,-0.02 -0.03,-0.03 -0.06,-0.03 -0.03,0 -0.07,0.01 -0.08,0.04 -0.02,0.03 -0.02,0.06 -0.02,0.13l0 0.24 -0.14 0 0 -0.51 0.1 0 0.03 0.06 0 0c0.01,-0.03 0.03,-0.04 0.06,-0.06 0.03,-0.01 0.06,-0.01 0.09,-0.01 0.08,0 0.13,0.03 0.16,0.07l0.01 0c0.02,-0.03 0.03,-0.04 0.06,-0.06 0.02,-0.01 0.05,-0.01 0.1,-0.01 0.04,0 0.09,0.01 0.12,0.04 0.03,0.03 0.05,0.08 0.05,0.14l0 0.34 -0.14 0 0 -0.29c0,-0.05 0,-0.08 -0.02,-0.09 -0.01,-0.02 -0.03,-0.03 -0.06,-0.03 -0.03,0 -0.06,0.01 -0.08,0.04 -0.01,0.02 -0.03,0.06 -0.03,0.11l0 0.26 0 0zm0.6 0l-0.13 0 0 -0.71 0.13 0 0 0.71z"/>
   <g transform="matrix(1 0 0 1 -24.4131 -2.63738)">
    <text v-if="!fluids.a" x="25.9" y="10.5" id="_21" class="fil3 fnt0">0</text>
    <text v-else-if="fluids.a < 100" x="25.6" y="10.5" id="_21" class="fil3 fnt0">{{fluids.a}}</text>
    <text v-else x="25.1" y="10.5" id="_21" class="fil3 fnt0">{{fluids.a}}</text>
   </g>
  </g>
 </g>
</svg>
</template>

<script>

    import {mapGetters} from 'vuex';

    export default {
        name: "FluidB",
        computed: {
            ...mapGetters('injector', {
                fluids: 'fluids',
            }),
            fluids_level() {
                return {
                    'clip-path': `inset(${100 - (this.fluids.a / 205 * 100)}% 0% 0% 0%)`
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    @font-face { font-family:"Arial";font-variant:normal;font-weight:normal;src:url("#FontID0") format(svg)}
    .fil3 {fill:#2B2A29}
    .fil0 {fill:#36393B;fill-rule:nonzero}
    .fil2 {fill:#9DC76C;fill-rule:nonzero}
    .fil1 {fill:white;fill-rule:nonzero}
    .fnt0 {font-weight:normal;font-size:1.39px;font-family:'Arial'}
</style>