import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        settings_left: {
            'Язык': 'Русский',
            'Уровень звукового сигнала дисплея': 'Средне',
            'Напоминание о калибровке': '01/2023',
            'Единицы давления': 'psi',
            'Единицы веса': 'Kg',
        },
        settings_right: {
            'Дата/время': '02/09/2021 12:13',
            'Формат даты': 'dd/mm/yyyy',
            'Формат времени': 'hh mm:ss',
            'Звуковое напоминание': 'Включение',
            'ISI': 'Выключение',
        },
        settings_second_page: {
            'XDS': 'Выключение',
            'Режим дисплея отключения инъектора': 'Выключение',
            'Яркость дисплея': 9,
        },
        texts_for_settings_tooltips: {
            'Язык': 'Задает язык дисплея',
            'Уровень звукового сигнала дисплея': 'Устанавливает громкость звука дисплея',
            'Напоминание о калибровке': 'Устанавливает дату напоминания о проведении ежегодного технического обслуживания',
            'Единицы давления': 'Устанавливает единицы измерения давления: фунты на кв.дюйм или кПа',
            'Единицы веса': 'Устанавливает единицы веса',
            'Дата/время': 'Устанавливает дату и время системы',
            'Формат даты': 'Устанавливает формат даты',
            'Формат времени': 'Устанавливает формат времени',
            'Звуковое напоминание': 'Включает или выключает звуки для напоминаний',
            'ISI': 'Активирует ISI, если доступно',
            'XDS': 'Активирует XDS, но эта функция уже не используется',
            'Режим дисплея отключения инъектора': 'Устанавливает напоминание на дисплее о нахождении инъектора в спящем режиме',
            'Яркость дисплея': 'Устанавливает уровень яркости дисплея',
        },
        advanced_settings: {
            'Режим просмотра': 'Простой вид',
            'Задержка напоминания автом. сканир.': 'Выключение',
        },

        language: ['简体中文', 'Русский', 'Dansk', 'Nederlands', 'English (UK)'],
        additional_language: [],
        volume_level: ['Громко', 'Средне', 'Тихо', 'Выключение'],
        pressure: ['psi', 'kpa',],
        weight: ['Lb', 'Kg'],
        date: ['mm/dd/yyyy', 'dd/mm/yyyy', 'yyyy/mm/dd'],
        time: ['hh:mm:ss', 'hh:mm:ss AM/PM', 'hh:mm:ss A/P'],
        volume_reminder: ['Включение', 'Выключение'],
        ISI: ['Выключение', 'Legacy ISI (700/800/900)', 'ISI2:CAN, ID:1', '\'ISI2:CAN, ID:2', '\'ISI2:CAN, ID:3'],
        additional_ISI: ['ISI2:DIO'],
        XDS: ['Включение', 'Выключение'],
        display_off: ['Включение', 'Выключение'],
    },
    getters: {
        settings_left(state) {
            return state.settings_left;
        },
        settings_right(state) {
            return state.settings_right;
        },
        settings_second_page(state) {
            return state.settings_second_page;
        },
        texts_for_settings_tooltips(state) {
            return state.texts_for_settings_tooltips;
        },
        advanced_settings(state) {
            return state.advanced_settings;
        },
        language(state) {
            return state.language;
        },
        volume_level(state) {
            return state.volume_level;
        },
        pressure(state) {
            return state.pressure;
        },
        weight(state) {
            return state.weight;
        },
        date(state) {
            return state.date;
        },
        time(state) {
            return state.time;
        },
        volume_reminder(state) {
            return state.volume_reminder;
        },
        ISI(state) {
            return state.ISI;
        },
        additional_language(state) {
            return state.additional_language;
        },
        additional_ISI(state) {
            return state.additional_ISI;
        },
        XDS(state) {
            return state.XDS;
        },
        display_off(state) {
            return state.display_off;
        },
    },
    mutations: {
        setNewValue(state, payload) {
            if (state.settings_left.hasOwnProperty(payload.name)) {
                state.settings_left[payload.name] = payload.value;
            } else if (state.settings_right.hasOwnProperty(payload.name)) {
                state.settings_right[payload.name] = payload.value;
            } else {
                state.settings_second_page[payload.name] = payload.value;
            }
        },
        defaultSettings(state) {
            state.settings_left = Object.assign({}, {
                'Язык': 'Русский',
                'Уровень звукового сигнала дисплея': 'Средне',
                'Напоминание о калибровке': '01/2023',
                'Единицы давления': 'psi',
                'Единицы веса': 'Kg',
            })
            state.settings_right = Object.assign({}, {
                'Дата/время': '02/09/2021 12:13',
                'Формат даты': 'dd/mm/yyyy',
                'Формат времени': 'hh mm:ss',
                'Звуковое напоминание': 'Включение',
                'ISI': 'Выключение',
            })
            state.settings_second_page = Object.assign({}, {
                 XDS: 'Выключение',
                'Режим дисплея отключения инъектора': 'Выключение',
                'Яркость дисплея': 9,
            })
        },
        cancelSettings(state, payload) {
            state.settings_left = Object.assign({}, payload.left);
            state.settings_right = Object.assign({}, payload.right);
            state.settings_second_page = Object.assign({}, payload.second_page);
        },
        setAdvancedSetting(state, payload) {
            state.advanced_settings[payload.name] = payload.value;
        },
    },
    actions: {
        setNewValue(store, payload) {
            store.commit('setNewValue', payload);
        },
        defaultSettings(store) {
            store.commit('defaultSettings');
        },
        cancelSettings(store, payload) {
            store.commit('cancelSettings', payload);
        },
        setAdvancedSetting(store, payload) {
            store.commit('setAdvancedSetting', payload)
        },
    },
};